import {
  IRuleDateFields,
  ISelectorOption,
  NotifyType,
  RuleValueType,
} from 'providence-types';
import { createReducer, on } from '@core/utils/store';
import {
  DirectMessageData,
  OptionProps,
  PayloadAndState,
  Rule,
  Schedule,
} from '@models/interfaces';
import { NotificationActions } from '@store/actions';

export interface NotificationState {
  paceTasksList: OptionProps[];
  userList: ISelectorOption<string>[];
  ruleFields: IRuleDateFields[];
  schedule: Schedule[];
  rules: Rule[];
  emailCertificationDate: string;
  directMessages: DirectMessageData[];
}

const initialState: NotificationState = {
  paceTasksList: [],
  userList: [],
  ruleFields: [],
  schedule: [],
  rules: [],
  emailCertificationDate: '',
  directMessages: [],
};

export const reducer = createReducer(
  initialState,
  on(
    NotificationActions.getPaceTaskList,
    ({ payload }: PayloadAndState<OptionProps[], NotificationState>) => ({
      paceTasksList: payload,
    }),
  ),
  on(
    NotificationActions.getUserList,
    ({
      payload,
    }: PayloadAndState<ISelectorOption<string>[], NotificationState>) => ({
      userList: payload,
    }),
  ),
  on(
    NotificationActions.getRuleFields,
    ({ payload }: PayloadAndState<IRuleDateFields[], NotificationState>) => ({
      ruleFields: payload,
    }),
  ),
  // Schedule
  on(
    NotificationActions.getSchedule,
    ({ payload }: PayloadAndState<Schedule[], NotificationState>) => ({
      schedule: payload,
    }),
  ),
  on(
    NotificationActions.createSchedule,
    ({ payload, state }: PayloadAndState<Schedule, NotificationState>) => ({
      ...state,
      schedule: [...state.schedule, payload],
    }),
  ),
  on(
    NotificationActions.updateSchedule,
    ({
      payload: {
        _id,
        userIds,
        roles,
        engineeringManagerType,
        towerOwners,
        ...updateData
      },
      state,
    }: PayloadAndState<Schedule, NotificationState>) => ({
      ...state,
      schedule: state.schedule.map((schedule: Schedule) => {
        const condition = schedule._id !== _id;
        const overrideFields = {
          ...(updateData.notifyType === NotifyType.Role
            ? {
                roles,
                userIds: [],
                engineeringManagerType,
                towerOwners: towerOwners?.length ? towerOwners : [],
              }
            : {
                roles: [],
                userIds,
                engineeringManagerType: undefined,
                towerOwners: [],
              }),
          ...(updateData.autoWarning
            ? {}
            : {
                warningDaysCount: undefined,
                warningMessage: undefined,
                notifyAdmin: undefined,
              }),
        };

        return condition
          ? schedule
          : { ...schedule, ...updateData, ...overrideFields };
      }),
    }),
  ),
  on(
    NotificationActions.deleteSchedule,
    ({ payload: _id, state }: PayloadAndState<string, NotificationState>) => ({
      ...state,
      schedule: state.schedule.filter(
        (schedule: Schedule) => schedule._id !== _id,
      ),
    }),
  ),
  // Rules
  on(
    NotificationActions.getRules,
    ({ payload }: PayloadAndState<Rule[], NotificationState>) => ({
      rules: payload,
    }),
  ),
  on(
    NotificationActions.createRule,
    ({ payload, state }: PayloadAndState<Rule, NotificationState>) => ({
      ...state,
      rules: [...state.rules, payload],
    }),
  ),
  on(
    NotificationActions.updateRule,
    ({
      payload: {
        _id,
        userIds,
        roles,
        engineeringManagerType,
        value,
        daysCount,
        dateType,
        towerOwners,
        ...updateData
      },
      state,
    }: PayloadAndState<Rule, NotificationState>) => ({
      ...state,
      rules: state.rules.map((rule: Rule) => {
        const condition = rule._id !== _id;

        const overrideFields = {
          ...(updateData.notifyType === NotifyType.Role
            ? {
                roles,
                userIds: [],
                engineeringManagerType,
                towerOwners: towerOwners?.length ? towerOwners : [],
              }
            : {
                roles: [],
                userIds,
                engineeringManagerType: undefined,
                towerOwners: [],
              }),
          ...(updateData.valueType === RuleValueType.Preset
            ? { value, daysCount: undefined }
            : {
                value: undefined,
                daysCount,
              }),
          dateType,
        };

        return condition ? rule : { ...rule, ...updateData, ...overrideFields };
      }),
    }),
  ),
  on(
    NotificationActions.deleteRule,
    ({ payload: _id, state }: PayloadAndState<string, NotificationState>) => ({
      ...state,
      rules: state.rules.filter((rule: Rule) => rule._id !== _id),
    }),
  ),
  // Mailer
  on(
    NotificationActions.getEmailCertificationDate,
    ({
      payload: emailCertificationDate,
    }: PayloadAndState<string, NotificationState>) => ({
      emailCertificationDate,
    }),
  ),
  // Direct Messages
  on(
    NotificationActions.getDirectMessages,
    ({
      payload: directMessages,
    }: PayloadAndState<DirectMessageData[], NotificationState>) => ({
      directMessages,
    }),
  ),
  on(
    NotificationActions.removeDirectMessage,
    ({
      payload: directMessageId,
      state: { directMessages },
    }: PayloadAndState<string, NotificationState>) => ({
      directMessages: directMessages.filter(
        ({ _id }: DirectMessageData) => _id !== directMessageId,
      ),
    }),
  ),
  on(
    NotificationActions.multiRemoveDirectMessages,
    ({
      payload: directMessageIds,
      state: { directMessages },
    }: PayloadAndState<string[], NotificationState>) => ({
      directMessages: directMessages.filter(
        ({ _id }: DirectMessageData) => !directMessageIds.includes(_id),
      ),
    }),
  ),
);
