import React, { FC, PropsWithChildren, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'antd';
import classNames from 'classnames';
import { Store } from 'rc-field-form/es/interface';
import { HttpService, ObjectComparatorService } from '@core/services';
import { ScopingHttpService } from '@core/services/http';
import {
  useCountingTotalEquipment,
  useDidUpdateEffect,
  useSaveChanged,
} from '@core/utils/hooks';
import {
  formDiffs,
  isValidForm,
  momentizeObjectDates,
} from '@core/utils/methods';
import { EQUIPMENT_DEFAULT_VALUE, SCOPING_SECTORS } from '@models/constants';
import { ModalMainTypes } from '@models/enums';
import {
  EditableComponentProps,
  EquipmentModal,
  OtherEquipmentData,
  ScopingEquipmentInfo,
  ScopingNotes as IScopingNotes,
} from '@models/interfaces';
import { SectorDataState } from '@models/types';
import {
  EquipmentTotals,
  NotificationsLoader,
  OtherEquipment,
  ScopingNotes,
  SectorsOfEquipment,
} from '@shared/components';
import { PrimaryButton } from '@shared/modules';
import { CommonActions } from '@store/actions';
import {
  EquipmentSelectors,
  ProjectCategoriesSelectors,
  ScopingSelectors,
} from '@store/selectors';
import { ScopingInformation } from './components';

import './styles.scss';

const { useForm } = Form;

export const Equipment: FC<EditableComponentProps> = ({
  isEditing,
  toggleEditing,
}: PropsWithChildren<EditableComponentProps>) => {
  const dispatch = useDispatch();

  const [scopingInfoForm] = useForm();
  const [scopingNotesForm] = useForm();

  const [equipmentForm, setEquipmentForm] = useState<Array<Store>>([]);
  const [initialForm, setInitialForm] = useState<Array<Store>>([]);

  const arraysCompare = ObjectComparatorService.arraysCompare(
    initialForm,
    equipmentForm,
  );

  const {
    currentProjectId,
    scopingInfo,
    RFDSPhase1,
    regulatoryCompliance,
    otherEquipment,
    scopingSectors,
    scopingLeasingInfo,
  } = useSelector(ScopingSelectors.getScopingEquipmentData);
  const projectCategoriesTableVisible = useSelector(
    ProjectCategoriesSelectors.getProjectCategoriesTableVisible,
  );
  const equipmentTypes = useSelector(EquipmentSelectors.getEquipmentTypes);
  const currentModalType = useSelector(ScopingSelectors.getCurrentModalType);
  const scopingFetching = useSelector(ScopingSelectors.isFetching);

  const [otherEquipments, setOtherEquipments] = useState<OtherEquipmentData[]>(
    otherEquipment || [],
  );
  const [equipmentItem, setEquipmentItem] = useState<EquipmentModal>(
    EQUIPMENT_DEFAULT_VALUE,
  );
  const [sectorsData, setSectorsData] =
    useState<SectorDataState>(SCOPING_SECTORS);

  const totalEquipments = useCountingTotalEquipment(
    'scoping',
    otherEquipments,
    sectorsData,
    scopingSectors,
    equipmentTypes,
  );

  useEffect(() => {
    setSectorsData(scopingSectors ?? SCOPING_SECTORS);
  }, [scopingSectors]);

  const onSubmit = async (): Promise<void> => {
    if (
      (await isValidForm(scopingInfoForm)) &&
      (await isValidForm(scopingNotesForm))
    ) {
      try {
        const [scopingInfoFormInitial, scopingNotesFormInitial] = initialForm;
        const [infoForm, notesForm] = equipmentForm;

        await HttpService.getHttpRequests(
          ScopingHttpService,
        ).updateScopingEquipment(currentProjectId, {
          scopingNotes: formDiffs(
            scopingNotesFormInitial as IScopingNotes,
            notesForm as IScopingNotes,
          ),
          scopingInformation: formDiffs<ScopingEquipmentInfo>(
            momentizeObjectDates(
              scopingInfoFormInitial,
              ['prelimRFDSReceived'],
              true,
            ) as ScopingEquipmentInfo,
            momentizeObjectDates(
              infoForm,
              ['prelimRFDSReceived'],
              true,
            ) as ScopingEquipmentInfo,
          ),
          otherEquipment: otherEquipments,
          sectors: sectorsData,
        });

        setInitialForm(equipmentForm);

        toggleEditing?.();

        dispatch(CommonActions.setHasUnsubmittedData.done(false));

        NotificationsLoader.notificationSuccess(
          `Information has been updated!`,
        );
      } catch (e) {
        console.error(e);
      }
    }
  };

  const onCancel = (): void => {
    const [infoForm, notesForm] = initialForm;

    scopingNotesForm.setFieldsValue(notesForm);
    scopingInfoForm.setFieldsValue(
      momentizeObjectDates(infoForm, ['prelimRFDSReceived']),
    );

    setOtherEquipments(otherEquipment);
    setSectorsData(scopingSectors);

    setEquipmentForm(initialForm);

    dispatch(CommonActions.setHasUnsubmittedData.done(false));

    toggleEditing?.();
  };

  const onValuesChange = (): void => {
    if (Object.keys(scopingInfoForm.getFieldsValue()).length) {
      const data = [
        scopingInfoForm.getFieldsValue(),
        scopingNotesForm.getFieldsValue(),
      ];

      setEquipmentForm(data);

      if (!Object.keys(initialForm).length) {
        setInitialForm(data);
      }
    }
  };

  useDidUpdateEffect(() => {
    onValuesChange();
  }, [isEditing]);

  useEffect(
    () => (): void => {
      dispatch(CommonActions.setHasUnsubmittedData.done(false));
    },

    [],
  );

  useDidUpdateEffect(() => {
    dispatch(
      CommonActions.setHasUnsubmittedData.done(
        !(
          ObjectComparatorService.objectsCompare(sectorsData, scopingSectors) &&
          ObjectComparatorService.arraysCompare(
            otherEquipments,
            otherEquipment,
          ) &&
          arraysCompare
        ),
      ),
    );
  }, [
    sectorsData,
    scopingSectors,
    otherEquipments,
    otherEquipment,
    arraysCompare,
  ]);

  useDidUpdateEffect(() => {
    const newDataState =
      currentModalType === ModalMainTypes.Add
        ? [...otherEquipments, equipmentItem]
        : otherEquipments.map((other: OtherEquipmentData) =>
            other.generatedId === equipmentItem.generatedId
              ? { ...other, ...equipmentItem }
              : other,
          );

    setOtherEquipments(newDataState);
  }, [equipmentItem]);

  useSaveChanged(isEditing, onSubmit, onCancel);

  return (
    <div className="prov-scoping-equipment">
      <div
        className={classNames('tabs-wrap', {
          'tabs-wrap_with-actions': isEditing,
          'categories-table-open': projectCategoriesTableVisible,
        })}
      >
        <ScopingInformation
          className="prov-scoping-equipment__information"
          isEditing={isEditing}
          form={scopingInfoForm}
          expectedId={currentProjectId}
          data={{
            ...scopingInfo,
            ...RFDSPhase1,
            ...regulatoryCompliance,
            ...(scopingLeasingInfo?.leaseAudit || {}),
          }}
          onValuesChange={onValuesChange}
        />
        <ScopingNotes
          className="prov-scoping-equipment__information"
          isEditing={isEditing}
          form={scopingNotesForm}
          data={{ ...scopingInfo, ...RFDSPhase1 }}
          onValuesChange={onValuesChange}
        />
        <SectorsOfEquipment
          className="prov-scoping-equipment__information"
          isEditing={isEditing}
          sectorsData={sectorsData}
          setSectorsData={setSectorsData}
        />
        <OtherEquipment
          className="prov-scoping-equipment__information"
          isEditing={isEditing}
          setOtherEquipments={setOtherEquipments}
          setEquipmentItem={setEquipmentItem}
          data={otherEquipments}
        />
        <EquipmentTotals
          className="prov-scoping-equipment__information"
          data={totalEquipments}
        />
      </div>
      {isEditing && (
        <div className="prov-site-information__btn-wrap">
          <div>
            <PrimaryButton
              htmlType="submit"
              title="Submit"
              disabled={scopingFetching}
              onClick={onSubmit}
            />
            <PrimaryButton title="Cancel" type="default" onClick={onCancel} />
          </div>
        </div>
      )}
    </div>
  );
};
