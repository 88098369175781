import { PermittingSection, ZoningSection } from './workflow-data.interface';

export const PERMITTING_INFO_DATES: Array<keyof PermittingSection> = [
  'e911AddressValidationDate',
  'jurisdictionAuditComplete',
  'prelimCDsReviewedPermitting',
];

export const ZONING_INFO_DATES: Array<keyof ZoningSection> = [
  'BPReceived',
  'BPSubmitted',
  'zoningAppDeemedComplete',
  'zoningApproved',
  'zoningSubmitted',
];
