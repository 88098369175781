import React, { FC, PropsWithChildren } from 'react';
import { useSelector } from 'react-redux';
import { UserRole } from 'providence-types';
import { IdAndValue } from '@models/interfaces';
import { UserSelectors } from '@store/selectors';
import { CustomTable } from '../../components/custom-table';
import { PrimaryButton } from '../../modules/ui-kit/primary-button';
import { ASSOCIATED_PROJECTS_COLUMNS } from './models/constants';
import { AssociatedProjectsProps } from './models/types';

import './styles.scss';

export const AssociatedProjects: FC<AssociatedProjectsProps> = ({
  history,
  data,
  linkId,
  siteOn,
  onRowClick,
}: PropsWithChildren<AssociatedProjectsProps>) => {
  const activeUser = useSelector(UserSelectors.activeUser);

  return (
    <div className="prov-associated-projects">
      <div className="prov-associated-projects__btn-container">
        {siteOn && activeUser?.roles?.includes(UserRole.Admin) && (
          <PrimaryButton
            title="Add New Project"
            onClick={(): void => history.push(`/project/create/${linkId}`)}
            icon="+"
          />
        )}
      </div>
      <CustomTable
        columns={ASSOCIATED_PROJECTS_COLUMNS}
        dataSource={data}
        pagination={false}
        onRowClick={(row: IdAndValue): void =>
          onRowClick
            ? onRowClick(row.id)
            : history.push(`/project/${row.id}/details`)
        }
      />
    </div>
  );
};
