import React, { Dispatch, FC, ReactText, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { HttpService } from '@core/services';
import { ProjectCategoryHttpService } from '@core/services/http';
import { toggleModal } from '@core/utils/methods';
import { ModalMainTypes } from '@models/enums';
import { AdminProjectCategoryData } from '@models/interfaces';
import { DeleteModal, VirtualTable } from '@shared/components';
import { PrimaryButton } from '@shared/modules';
import { AdminProjectCategoriesSelectors } from '@store/selectors';
import {
  getAdminProjectCategoryColumns,
  PROJECT_CATEGORY_DEFAULT_VALUE,
} from './models/constants';
import { ProjectCategoryModal } from './components';

import './styles.scss';

export const AdminProjectCategory: FC = () => {
  const projectCategories = useSelector(
    AdminProjectCategoriesSelectors.getAdminProjectCategories,
  );

  const [selectedRows, setSelectedRows] = useState<ReactText[]>([]);
  const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false);
  const [isAddEditModalVisible, setAddEditModalVisible] =
    useState<boolean>(false);
  const [currentModalType, setCurrentModalType] = useState<ModalMainTypes>(
    ModalMainTypes.Add,
  );
  const [selectedItem, setSelectedItem] = useState<AdminProjectCategoryData>(
    PROJECT_CATEGORY_DEFAULT_VALUE,
  );

  useEffect(() => {
    setSelectedRows([
      ...selectedRows,
      ...projectCategories
        .filter(
          (category: AdminProjectCategoryData) =>
            category.canBeDuplicated === true,
        )
        .map((category: AdminProjectCategoryData) => category.id!),
    ]);
  }, [projectCategories]);

  const toggleAddAndEditModal = (type: ModalMainTypes): void => {
    setCurrentModalType(type);
    setAddEditModalVisible(!isAddEditModalVisible);

    if (isAddEditModalVisible) {
      setSelectedItem(PROJECT_CATEGORY_DEFAULT_VALUE);
    }
  };

  const selectCurrentProjectCategory = (
    currentProjectCategory: AdminProjectCategoryData,
    setter: Dispatch<boolean>,
    prevState: boolean,
  ): void => {
    setSelectedItem(currentProjectCategory);
    setter(!prevState);
  };

  const onDelete = async (): Promise<void> => {
    await HttpService.getHttpRequests(
      ProjectCategoryHttpService,
    ).deleteProjectCategory(selectedItem.id!);
    setDeleteModalVisible(false);
    setSelectedItem(PROJECT_CATEGORY_DEFAULT_VALUE);
  };

  return (
    <div className="prov-admin-project-category">
      <VirtualTable
        dataSource={projectCategories}
        columns={getAdminProjectCategoryColumns(
          (category: AdminProjectCategoryData): void =>
            selectCurrentProjectCategory(
              category,
              () => toggleAddAndEditModal(ModalMainTypes.Edit),
              isAddEditModalVisible,
            ),
          (category: AdminProjectCategoryData): void =>
            selectCurrentProjectCategory(
              category,
              setDeleteModalVisible,
              deleteModalVisible,
            ),
        )}
        maxViewRow={10}
      />
      <PrimaryButton
        icon="+"
        title="Add Project Category"
        className="prov-admin-project-category__add-button"
        onClick={(): void => toggleAddAndEditModal(ModalMainTypes.Add)}
      />
      <ProjectCategoryModal
        visible={isAddEditModalVisible}
        modalType={currentModalType}
        toggleModal={toggleAddAndEditModal}
        itemValue={selectedItem}
      />
      <DeleteModal
        onOk={onDelete}
        onCancel={toggleModal(setDeleteModalVisible)}
        visible={deleteModalVisible}
        type={currentModalType}
      />
    </div>
  );
};
