import React, { FC, PropsWithChildren } from 'react';
import { OptionProps } from '@models/interfaces';
import { ROSelect } from '../models';

export const ReadOnlySelect: FC<ROSelect> = ({
  options,
  value,
  render,
}: PropsWithChildren<ROSelect>) => {
  const viewValue = options.find(
    (option: OptionProps) => option.value === value,
  )?.viewValue;

  return <>{render ? render(viewValue) : viewValue}</>;
};
