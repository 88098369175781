import React, {
  Dispatch,
  FC,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { Col, Form, Row } from 'antd';
import { SelectValue } from 'antd/lib/select';
import { Undefinable } from 'providence-types';
import { HttpService } from '@core/services';
import { DefaultTowerAgentHttpService } from '@core/services/http';
import { toggleModal } from '@core/utils/methods';
import { ModalMainTypes } from '@models/enums';
import { AdminDefaultTowerAgentData } from '@models/interfaces';
import { ScrollToInitRef } from '@models/types';
import { DeleteModal, VirtualTable } from '@shared/components';
import { Autocomplete, PrimaryButton } from '@shared/modules';
import { DefaultTowerAgentSelectors } from '@store/selectors';
import {
  DEFAULT_TOWER_AGENT_DEFAULT_VALUE,
  getDefaultTowerAgentColumns,
} from './models/constants';
import { DefaultTowerAgentModal } from './components';

import './styles.scss';

export const DefaultTowerAgent: FC = () => {
  const defaultTowerAgentService = useMemo(
    () => HttpService.getHttpRequests(DefaultTowerAgentHttpService),
    [],
  );

  const agentsOptions = useSelector(DefaultTowerAgentSelectors.getAgentOptions);
  const ownersOptions = useSelector(
    DefaultTowerAgentSelectors.getOwnersOptions,
  );
  const defaultTowerAgentRecords = useSelector(
    DefaultTowerAgentSelectors.getDefaultTowerAgentRecords,
  );

  const virtualTableRef = useRef<ScrollToInitRef>(null);

  const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false);
  const [isAddEditModalVisible, setAddEditModalVisible] =
    useState<boolean>(false);
  const [currentModalType, setCurrentModalType] = useState<ModalMainTypes>(
    ModalMainTypes.Add,
  );
  const [providenceAgentSelectorValue, setProvidenceAgentSelectorValue] =
    useState<Undefinable<string>>(undefined);
  const [towerOwnerSelectorValue, setTowerOwnerSelectorValue] =
    useState<Undefinable<string>>(undefined);
  const [selectedItem, setSelectedItem] = useState<AdminDefaultTowerAgentData>(
    DEFAULT_TOWER_AGENT_DEFAULT_VALUE,
  );

  const toggleAddAndEditModal = (type: ModalMainTypes): void => {
    setCurrentModalType(type);
    setAddEditModalVisible(!isAddEditModalVisible);

    if (isAddEditModalVisible) {
      setSelectedItem(DEFAULT_TOWER_AGENT_DEFAULT_VALUE);
    }
  };

  useEffect(() => {
    defaultTowerAgentService.getDefaultTowerAgentData({
      towerOwnerId: towerOwnerSelectorValue,
      providenceAgentId: providenceAgentSelectorValue,
    });
  }, [providenceAgentSelectorValue, towerOwnerSelectorValue]);

  const selectDefaultTowerAgent = (
    defaultTowerAgent: AdminDefaultTowerAgentData,
    setter: Dispatch<boolean>,
    prevState: boolean,
  ): void => {
    setSelectedItem(defaultTowerAgent);
    setter(!prevState);
  };

  const onDelete = async (): Promise<void> => {
    await defaultTowerAgentService.deleteDefaultTowerAgent(selectedItem.id);
    setDeleteModalVisible(false);
    setSelectedItem(DEFAULT_TOWER_AGENT_DEFAULT_VALUE);
  };

  return (
    <div className="prov-default-tower-agent">
      <Form>
        <Row className="prov-default-tower-agent__table-handler">
          <Col span="5">
            <Autocomplete
              label="Providence Agent"
              id="leasing-agent"
              options={agentsOptions}
              elementProps={{
                allowClear: true,
                onChange: (value: SelectValue): void => {
                  setProvidenceAgentSelectorValue(value as string);
                  virtualTableRef?.current?.scrollToInit();
                },
              }}
            />
          </Col>
          <Col span="5">
            <Autocomplete
              label="Tower Owner"
              id="tower-owner"
              options={ownersOptions}
              elementProps={{
                allowClear: true,
                onChange: (value: SelectValue): void => {
                  setTowerOwnerSelectorValue(value as string);
                  virtualTableRef?.current?.scrollToInit();
                },
              }}
            />
          </Col>
        </Row>
      </Form>
      <VirtualTable
        ref={virtualTableRef}
        dataSource={defaultTowerAgentRecords}
        columns={getDefaultTowerAgentColumns(
          (defaultTowerAgent: AdminDefaultTowerAgentData): void =>
            selectDefaultTowerAgent(
              defaultTowerAgent,
              () => toggleAddAndEditModal(ModalMainTypes.Edit),
              isAddEditModalVisible,
            ),
          (defaultTowerAgent: AdminDefaultTowerAgentData): void =>
            selectDefaultTowerAgent(
              defaultTowerAgent,
              setDeleteModalVisible,
              deleteModalVisible,
            ),
        )}
        maxViewRow={10}
      />
      <PrimaryButton
        icon="+"
        title="Add"
        className="prov-default-tower-agent__add-button"
        onClick={(): void => toggleAddAndEditModal(ModalMainTypes.Add)}
      />
      <DefaultTowerAgentModal
        visible={isAddEditModalVisible}
        modalType={currentModalType}
        toggleModal={toggleAddAndEditModal}
        formValue={selectedItem}
      />
      <DeleteModal
        onOk={onDelete}
        onCancel={toggleModal(setDeleteModalVisible)}
        visible={deleteModalVisible}
      />
    </div>
  );
};
