import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { DropDownSelectorValues } from 'providence-types';
import { HttpService } from '@core/services';
import { NotificationsHttpService } from '@core/services/http';
import { ModalMainTypes } from '@models/enums';
import { OptionProps, Rule as IRule } from '@models/interfaces';
import { DeleteModal, VirtualTable } from '@shared/components';
import { PrimaryButton } from '@shared/modules';
import { DDVSelectors, NotificationSelectors } from '@store/selectors';
import { TowerOwnerFilter } from '../../components';
import { RuleModal } from './modals';
import { getRulesTableColumns } from './models';

export const Rules: FC = () => {
  const rules = useSelector(NotificationSelectors.getRules);
  const userList = useSelector(NotificationSelectors.getUserList);
  const ruleFields = useSelector(NotificationSelectors.getRuleFields);
  const towerOwnerDDV = useSelector(
    DDVSelectors.getDDVValuesByType(DropDownSelectorValues.TowerOwner),
  ) as OptionProps[];

  const [isAddEditModalVisible, setAddEditModalVisible] =
    useState<boolean>(false);
  const [currentModalType, setCurrentModalType] = useState<ModalMainTypes>(
    ModalMainTypes.Add,
  );
  const [modalValue, setModalValues] = useState<IRule | null>(null);
  const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false);

  const toggleAddAndEditModal = (
    type: ModalMainTypes,
    formValue?: IRule,
  ): void => {
    setCurrentModalType(type);
    setModalValues(formValue || null);
    setAddEditModalVisible(!isAddEditModalVisible);
  };

  const toggleDeleteModal = (formValue?: IRule): void => {
    setDeleteModalVisible(!deleteModalVisible);
    setModalValues(formValue || null);
  };

  const onDelete = async (): Promise<void> => {
    await HttpService.getHttpRequests(NotificationsHttpService).deleteRule(
      modalValue!._id,
    );

    toggleDeleteModal();
  };

  return (
    <div className="prov-notification">
      <div className="prov-notification__header-controls">
        <TowerOwnerFilter towerOwners={towerOwnerDDV} place="rule" />
        <PrimaryButton
          title="Add Rule"
          icon="+"
          onClick={(): void => toggleAddAndEditModal(ModalMainTypes.Add)}
        />
      </div>
      <VirtualTable
        dataSource={rules}
        columns={getRulesTableColumns(
          ruleFields,
          userList,
          towerOwnerDDV,
          (rule: IRule): void =>
            toggleAddAndEditModal(ModalMainTypes.Edit, rule),
          (rule: IRule): void => toggleDeleteModal(rule),
        )}
        maxViewRow={5}
        rowHeight={72}
        hasLongCellData
        verticalScrollCorrection={30}
      />
      <RuleModal
        visible={isAddEditModalVisible}
        modalType={currentModalType}
        toggleModal={toggleAddAndEditModal}
        itemValue={modalValue}
        ruleFields={ruleFields}
        userList={userList}
        towerOwners={towerOwnerDDV}
      />
      <DeleteModal
        visible={deleteModalVisible}
        onOk={onDelete}
        onCancel={(): void => toggleDeleteModal()}
      />
    </div>
  );
};
