import { RuleArea } from 'providence-types';

export const getCurrentRuleArea = (value: RuleArea): string => {
  switch (value) {
    case RuleArea.Engineering:
      return 'Engineering';
    case RuleArea.Leasing:
      return 'Leasing';
    case RuleArea.PaceTasks:
      return 'Pace Tasks';
    default:
      throw new Error('Incorrect value');
  }
};
