import { DeepPartial } from 'redux';
import { PaceTask } from '../interfaces';

export const PACE_TASK_PERMISSIONS: DeepPartial<PaceTask> = {
  permissions: {
    editPACEActualDate: [],
    editPACEForecastedDate: [],
    editForecastedDate: [],
    editActualDate: [],
    canBeDeleted: true,
  },
};
