import { ProjectStatus, Undefinable } from 'providence-types';
import {
  CuttedProjectDetails,
  ProjectDetailsWithoutCategories,
} from '@models/types';
import { transformFields } from '../../core/utils/methods';
import { browseReducer } from '../../core/utils/methods/browse-sorter-reducer';
import { createReducer, on } from '../../core/utils/store';
import {
  PROJECT_INFO_TRANSFORM_FIELDS,
  SITE_INFO_TRANSFORM_FIELDS,
} from '../../models/constants/transform-fields';
import {
  HoldOptions,
  IdAndValue,
  IncomingProjectDetails,
  OptionProps,
  PaginatedResponse,
  PayloadAndState,
  ProjectBrowse,
  ProjectDetails,
  ProjectPaceTask,
  SiteBrowse,
  TransformedProjectDetails,
  User,
} from '../../models/interfaces';
import { ProjectActions } from '../actions/project.actions';

export interface ProjectAdditionalNotesState {
  siteNotes?: string;
  engineeringNotes?: string;
  leasingNotes?: string;
  projectPermittingNotes?: string;
}

export interface ProjectState {
  projectBrowse: PaginatedResponse<ProjectBrowse>;
  currentProject: Omit<
    TransformedProjectDetails,
    'categories' | 'paceTasks' | 'site'
  >;
  currentProjectType: IdAndValue;
  currentProvidenceAgent?: string;
  projectPaceTasks: ProjectPaceTask[];
  projectSite: SiteBrowse;
  notes: ProjectAdditionalNotesState;
  hold: HoldOptions;
  providenceAgents: OptionProps[];
  isFetching: boolean;
  errors: boolean;
}

const initialState: ProjectState = {
  projectBrowse: {
    items: [],
    total: 0,
  },
  currentProject: {} as TransformedProjectDetails,
  projectSite: {} as SiteBrowse,
  currentProjectType: {} as IdAndValue,
  projectPaceTasks: [],
  providenceAgents: [],
  notes: {},
  hold: {} as HoldOptions,
  isFetching: false,
  errors: false,
};

const providenceAgentToString = (agent?: User): Undefinable<string> =>
  agent?.firstName && agent?.lastName
    ? `${agent.firstName} ${agent.lastName}`
    : undefined;

export const reducer = createReducer(
  initialState,
  on(ProjectActions.getProjectBrowseDataAction, browseReducer('projectBrowse')),
  on(
    ProjectActions.getProjectAction,
    ({
      payload: { paceTasks, site, engineering, leasing, ...project },
    }: PayloadAndState<ProjectDetailsWithoutCategories, ProjectState>) => ({
      currentProject: transformFields<
        CuttedProjectDetails,
        TransformedProjectDetails
      >(project, PROJECT_INFO_TRANSFORM_FIELDS) as TransformedProjectDetails,
      currentProjectType: project.projectType,
      currentProvidenceAgent: providenceAgentToString(
        (project as IncomingProjectDetails).providenceAgent,
      ),
      projectPaceTasks: paceTasks,
      projectSite: transformFields<SiteBrowse>(
        site,
        SITE_INFO_TRANSFORM_FIELDS,
      ),
      notes: {
        engineeringNotes: engineering?.RFDSPhase1?.engineeringNotes,
        leasingNotes: leasing?.leasingNotes,
        siteNotes: site?.notes,
        projectPermittingNotes: project?.projectPermittingNotes,
      },
      hold: {
        projectStatus: project.projectStatus?.id as ProjectStatus,
        holdReason: project.holdReason,
      },
    }),
  ),
  on(
    ProjectActions.updateProjectAction,
    ({
      payload: { paceTasks, site, engineering, leasing, ...project },
    }: PayloadAndState<ProjectDetailsWithoutCategories, ProjectState>) => ({
      currentProject: transformFields<
        CuttedProjectDetails,
        TransformedProjectDetails
      >(project, PROJECT_INFO_TRANSFORM_FIELDS) as TransformedProjectDetails,
      currentProvidenceAgent: providenceAgentToString(
        (project as IncomingProjectDetails).providenceAgent,
      ),
      projectPaceTasks: paceTasks,
      projectSite: transformFields<SiteBrowse>(
        site,
        SITE_INFO_TRANSFORM_FIELDS,
      ),
      notes: {
        engineeringNotes: engineering?.RFDSPhase1?.engineeringNotes,
        leasingNotes: leasing?.leasingNotes,
        siteNotes: site?.notes,
        projectPermittingNotes: project?.projectPermittingNotes,
      },
      hold: {
        projectStatus: project.projectStatus?.id as ProjectStatus,
        holdReason: project.holdReason,
      },
    }),
  ),
  on(
    ProjectActions.getProvidenceAgent,
    ({
      payload: providenceAgent,
      state: { currentProject },
    }: PayloadAndState<Undefinable<string>, ProjectState>) => ({
      currentProject: {
        ...currentProject,
        providenceAgent,
      },
    }),
  ),
  on(
    ProjectActions.getProvidenceAgents,
    ({
      payload: providenceAgents,
    }: PayloadAndState<OptionProps[], ProjectState>) => ({ providenceAgents }),
  ),
  on(
    ProjectActions.setCurrentProvidenceAgent,
    ({ payload: agent }: PayloadAndState<User, ProjectState>) => ({
      currentProvidenceAgent: providenceAgentToString(agent),
    }),
  ),
  on(
    ProjectActions.setHoldOptions,
    ({
      payload: { projectStatus, holdReason },
    }: PayloadAndState<
      Pick<ProjectDetails, 'projectStatus' | 'holdReason'>,
      ProjectState
    >) => ({
      hold: {
        holdReason,
        projectStatus: projectStatus?.id as ProjectStatus,
      },
    }),
  ),
);
