import { ProjectType } from 'providence-types';
import { AccountingBrowseFilter } from '@models/interfaces';
import { RadioButtonProps } from '@shared/modules/ui-kit/models/interfaces';

export const LTEModeColumnsArray: string[] = [
  'SS010',
  'SS010InvoiceNumber',
  'SS010InvoiceDate',
  'RE066',
  'RE066InvoiceNumber',
  'RE066InvoiceDate',
  'towerOwner',
];

export const radioGroupButtons: RadioButtonProps[] = [
  { viewValue: ProjectType.LTE, value: ProjectType.LTE },
  { viewValue: ProjectType.NSB, value: ProjectType.NSB },
];

export const ACCOUNTING_DEFAULT_BROWSE_CONFIGURE_FILTER: AccountingBrowseFilter =
  {
    FALocationNumber: undefined,
    PACEProjectNumber: undefined,
    projectWorkTypeId: undefined,
    RE020ActualDate: undefined,
  };
