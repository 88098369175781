import React, {
  FC,
  PropsWithChildren,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Form } from 'antd';
import { SelectValue } from 'antd/es/select';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import {
  LinkPatternsKey,
  NotificationType,
  NotifyType,
  PaceTaskType,
  ScheduleOperator,
} from 'providence-types';
import { HttpService } from '@core/services';
import { NotificationsHttpService } from '@core/services/http';
import {
  arraySort,
  getCurrentDateType,
  getCurrentOptions,
  getEnumKeyByEnumValue,
  isValidForm,
  stringEnumToSelectableObject,
} from '@core/utils/methods';
import { ModalMainTypes } from '@models/enums';
import { Schedule } from '@models/interfaces';
import { NotifyTypeControls } from '@notifications/components';
import { getCurrentOperator } from '@notifications/helpers';
import { NotifyTypeControlsRefType } from '@notifications/models';
import { AddAndEditModal } from '@shared/components';
import {
  Autocomplete,
  Checkbox,
  InputNumber,
  Select,
  TextArea,
} from '@shared/modules';
import { SCHEDULE_DEFAULT_VALUES, ScheduleModalProps } from '../../models';

import './styles.scss';

const { useForm } = Form;

export const ScheduleModal: FC<ScheduleModalProps> = ({
  itemValue,
  visible,
  modalType,
  toggleModal,
  paceTaskList,
  userList,
  towerOwners,
}: PropsWithChildren<ScheduleModalProps>) => {
  const [form] = useForm();

  const notificationsService = useMemo(
    () => HttpService.getHttpRequests(NotificationsHttpService),
    [],
  );

  const [autoWarning, setAutoWarning] = useState<boolean>(false);
  const [notificationType, setNotificationType] =
    useState<NotificationType | null>(null);

  const ref = useRef<NotifyTypeControlsRefType>(null);

  useEffect(() => {
    if (visible) {
      form.setFieldsValue({
        ...(itemValue || SCHEDULE_DEFAULT_VALUES),
      });

      if (itemValue) {
        setAutoWarning(!!itemValue.autoWarning);
        setNotificationType(itemValue.notificationType);

        ref.current?.changeNotifyType(
          getEnumKeyByEnumValue(NotifyType, itemValue.notifyType),
        );
        ref.current?.changeRoles(itemValue.roles || []);
      }
    }
  }, [visible, itemValue, form]);

  const onClose = (): void => {
    toggleModal(modalType);
    ref.current?.changeNotifyType(null);
    ref.current?.changeRoles(null);

    setAutoWarning(false);
    setNotificationType(null);
  };

  const add = async (): Promise<void> => {
    if (await isValidForm(form)) {
      await notificationsService.createSchedule(
        form.getFieldsValue() as Omit<Schedule, '_id'>,
      );

      onClose();
    }
  };

  const edit = async (): Promise<void> => {
    if (await isValidForm(form)) {
      await notificationsService.updateSchedule(
        itemValue!._id,
        form.getFieldsValue() as Omit<Schedule, '_id'>,
      );

      onClose();
    }
  };

  const onOk = async (): Promise<void> => {
    await (modalType === ModalMainTypes.Add ? add() : edit());
  };

  const paceTaskTypeOptions = arraySort(
    getCurrentOptions(PaceTaskType, getCurrentDateType),
    'ASC',
    'viewValue',
  );

  return (
    <AddAndEditModal
      onOk={onOk}
      onCancel={onClose}
      visible={visible}
      type={modalType}
      title="Schedule"
      okText="Save"
      cancelText="Cancel"
      className="prov-schedule-modal"
      formProps={{
        labelCol: { span: 10 },
        wrapperCol: { span: 14 },
        form,
      }}
    >
      <Autocomplete
        id="startingField"
        label="Starting Field"
        formItemProps={{
          rules: [{ required: true, message: 'Starting Field is required!' }],
        }}
        options={paceTaskList}
      />
      <Select
        id="startingDateType"
        label="Starting Date Type"
        formItemProps={{
          rules: [
            { required: true, message: 'Starting Date Type is required!' },
          ],
        }}
        options={paceTaskTypeOptions}
      />
      <Autocomplete
        id="endingField"
        label="Ending Field"
        formItemProps={{
          rules: [{ required: true, message: 'Ending Field is required!' }],
        }}
        options={paceTaskList}
      />
      <Select
        id="endingDateType"
        label="Ending Date Type"
        formItemProps={{
          rules: [{ required: true, message: 'Ending Date Type is required!' }],
        }}
        options={paceTaskTypeOptions}
      />
      <Select
        id="operator"
        label="Operator"
        formItemProps={{
          rules: [{ required: true, message: 'Operator is required!' }],
        }}
        options={arraySort(
          getCurrentOptions(ScheduleOperator, getCurrentOperator),
          'ASC',
          'viewValue',
        )}
      />
      <InputNumber
        id="daysCount"
        label="Days Count"
        formItemProps={{
          rules: [{ required: true, message: 'Days Count is required!' }],
        }}
        elementProps={{ min: 0 }}
      />
      <Select
        id="notificationType"
        label="Notification Type"
        formItemProps={{
          rules: [
            { required: true, message: 'Notification Type is required!' },
          ],
        }}
        options={getCurrentOptions(
          NotificationType,
          (value: NotificationType) =>
            `${value.charAt(0).toUpperCase()}${value.slice(1)}`,
        )}
        elementProps={{
          onChange: (v: SelectValue): void => {
            setNotificationType(v as NotificationType);
            setAutoWarning(false);

            form.setFieldsValue({
              autoWarning: false,
            });
          },
        }}
      />
      <NotifyTypeControls
        ref={ref}
        form={form}
        userList={userList}
        towerOwners={towerOwners}
      />
      <TextArea
        id="message"
        label="Message"
        formItemProps={{
          rules: [{ required: true, message: 'Message is required!' }],
        }}
      />
      <Checkbox
        id="autoWarning"
        label="Auto Warning"
        tooltipMessage="Only available when Notification Type equals Info."
        elementProps={{
          onChange: (v: CheckboxChangeEvent): void =>
            setAutoWarning(v.target.checked),
          disabled: notificationType !== NotificationType.Info,
        }}
      />
      {autoWarning && (
        <>
          <InputNumber
            id="warningDaysCount"
            label="Warning Days Count"
            formItemProps={{
              rules: [
                { required: true, message: 'Warning Days Count is required!' },
              ],
            }}
            elementProps={{ min: 0 }}
          />
          <TextArea
            id="warningMessage"
            label="Warning Message"
            formItemProps={{
              rules: [
                { required: true, message: 'Warning Message is required!' },
              ],
            }}
          />
          <Checkbox id="notifyAdmin" label="Notify Admin" />
        </>
      )}
      <Autocomplete
        id="redirectLinkKey"
        label="Redirect page"
        formItemProps={{
          rules: [{ required: true, message: 'Redirect page is required!' }],
        }}
        options={arraySort(
          stringEnumToSelectableObject(LinkPatternsKey),
          'ASC',
          'viewValue',
        )}
      />
    </AddAndEditModal>
  );
};
