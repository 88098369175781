import {
  JurisdictionDetailsPermitting,
  JurisdictionDetailsZoning,
} from '../interfaces';

export const DEFAULT_JURISDICTION_ZONING_PANEL_FIELDS: Omit<
  JurisdictionDetailsZoning,
  'id' | 'projectType' | 'projectSubType'
> = {
  applicationRequirements: null,
  describeOverallProcess: null,
  estimatedZoningApprovalTimeline: null,
  otherFees: null,
  separationRequirements: null,
  setbacks: null,
  specialLicensingOrBondingConditions: null,
  thirdPartyCostRequired: null,
  towerHeightRestrictions: null,
  writtenDocumentationFromJurisdiction: null,
  notes: null,
  zoningRequired: null,
  jurisdictionReviewDate: null,
};

export const DEFAULT_JURISDICTION_PERMITTING_PANEL_FIELDS: Omit<
  JurisdictionDetailsPermitting,
  'id' | 'projectType' | 'projectSubType'
> = {
  permittingRequired: null,
  expeditingOrResubmissionApprovalTimeline: null,
  specialLicensingOrBondingConditions: null,
  writtenDocumentationFromJurisdiction: null,
  notes: null,
  permittingTypes: [],
  jurisdictionReviewDate: null,
};
