import { MainPaceTask, UserRole } from 'providence-types';
import { createSelector, OutputSelector } from 'reselect';
import {
  PaceTaskPermissions,
  ProjectPaceTask,
  ProjectPaceTaskData,
} from '../../models/interfaces';
import { PaceTaskDates } from '../../models/types';
import { AppState } from '../reducers';
import { PaceTasksState } from '../reducers/pace-tasks.reducer';
import { UserState } from '../reducers/user.reducer';

interface PaceTasksPermissionConfig {
  dateType: keyof Omit<ProjectPaceTaskData, 'id'>;
  paceTaskId: MainPaceTask;
}

const paceTasksState = (state: AppState): PaceTasksState => state.paceTasks;
const usersState = (state: AppState): UserState => state.user;

export namespace PaceTasksSelectors {
  export const getPaceTasks = createSelector(
    [paceTasksState],
    (state: PaceTasksState) => state.currentPaceTasks,
  );

  export const getPaceTaskFields = createSelector(
    [paceTasksState],
    (state: PaceTasksState) => state.paceTaskFields,
  );

  export const getMainPaceTaskDates = (
    dateType: keyof Omit<ProjectPaceTaskData, 'id'>,
    ...mainPaceTasks: Array<keyof typeof MainPaceTask>
  ): OutputSelector<
    [typeof paceTasksState],
    PaceTaskDates,
    (state: PaceTasksState) => PaceTaskDates
  > =>
    createSelector(
      [paceTasksState],
      (state: PaceTasksState): PaceTaskDates =>
        mainPaceTasks.reduce(
          (acc: PaceTaskDates, task: keyof typeof MainPaceTask) => ({
            ...acc,
            [task]: state.paceTaskFields.find(
              (paceTask: ProjectPaceTask) => paceTask.id === MainPaceTask[task],
            )?.projectPaceTaskData?.[dateType],
          }),
          {},
        ),
    );

  export const arePaseTasksChanged = createSelector(
    [paceTasksState],
    (state: PaceTasksState) => state.arePaseTasksChanged,
  );

  export const getPaceTasksPermissions = (
    config: Array<PaceTasksPermissionConfig>,
  ): OutputSelector<
    [typeof paceTasksState, typeof usersState],
    boolean[],
    (paceTaskState: PaceTasksState, userState: UserState) => boolean[]
  > =>
    createSelector(
      [paceTasksState, usersState],
      (paceTaskState: PaceTasksState, userState: UserState) => {
        const result: boolean[] = [];
        const userRoles: UserRole[] = userState.activeUser.roles;

        config.forEach(
          ({ paceTaskId, dateType }: PaceTasksPermissionConfig) => {
            const currentPaceTasks = paceTaskState.currentPaceTasks.find(
              (paceTasks: ProjectPaceTask) => paceTasks.id === paceTaskId,
            );

            if (currentPaceTasks) {
              const currentType = `edit${dateType
                .charAt(0)
                .toUpperCase()}${dateType.slice(
                1,
              )}` as keyof PaceTaskPermissions;
              const permissions = currentPaceTasks.permissions[
                currentType
              ] as UserRole[];

              result.push(
                permissions.some((userRole: UserRole) =>
                  userRoles.includes(userRole),
                ),
              );
            } else {
              result.push(false);
            }
          },
        );

        return result;
      },
    );

  export const isFetching = createSelector(
    [paceTasksState],
    (state: PaceTasksState) => state.isFetching,
  );
}
