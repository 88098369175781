import { RADField } from 'providence-types';
import { browseReducer } from '@core/utils/methods/browse-sorter-reducer';
import { transformFields } from '@core/utils/methods/transform-fields';
import { createReducer, on } from '@core/utils/store';
import {
  getOtherEquipmentInfo,
  getScopingSectorsInfo,
  REGULATORY_COMPLIANCE_INFO_TRANSFORM_FIELDS,
  RFDS_INFO_TRANSFORM_FIELDS,
  SCOPING_SECTORS,
  STRUCTURAL_INFO_TRANSFORM_FIELDS,
} from '@models/constants';
import {
  EngineeringBrowse,
  EngineeringDetails,
  EngineeringLeasingInformation,
  EngineeringRegulatoryCompliance,
  EngineeringRFDSPhase1,
  EngineeringWorkflowUpdateData,
  OtherEquipmentData,
  PaginatedResponse,
  PayloadAndState,
  RadData,
  ScopingInfo,
  SiteBrowse,
  StructuralInformation,
} from '@models/interfaces';
import { CuttedProjectDetails, SectorDataState } from '@models/types';
import { EngineeringActions } from '@store/actions';

type RADType = { [key in RADField]?: number };

export interface EngineeringBrowseState {
  engineeringBrowseData: PaginatedResponse<EngineeringBrowse>;
  engineeringSiteData: SiteBrowse;
  engineeringProjectData: CuttedProjectDetails;
  engineeringLeasingData: EngineeringLeasingInformation;
  engineeringWorkflowData: EngineeringWorkflowUpdateData;
  otherEquipment: OtherEquipmentData[];
  equipmentTypeNames: string[];
  scopingSectors: SectorDataState;
  scopingInfo: Omit<ScopingInfo, 'otherEquipment' | 'scopingSectors'>;
  isFetching: boolean;
  errors: boolean;
  RAD: RADType;
}

const initialState: EngineeringBrowseState = {
  engineeringSiteData: {} as SiteBrowse,
  engineeringProjectData: {} as CuttedProjectDetails,
  engineeringLeasingData: {} as EngineeringLeasingInformation,
  engineeringWorkflowData: {} as EngineeringWorkflowUpdateData,
  engineeringBrowseData: { items: [], total: 0 },
  isFetching: false,
  errors: false,
  scopingInfo: {} as ScopingInfo,
  scopingSectors: SCOPING_SECTORS,
  otherEquipment: [],
  equipmentTypeNames: [],
  RAD: {
    [RADField.A]: undefined,
    [RADField.B]: undefined,
    [RADField.D]: undefined,
    [RADField.G]: undefined,
  },
};

export const reducer = createReducer(
  initialState,
  // GET ENGINEERING BROWSE DATA
  on(
    EngineeringActions.getEngineeringBrowseDataAction,
    browseReducer('engineeringBrowseData'),
  ),
  // GET ENGINEERING DETAILS DATA
  on(
    EngineeringActions.getEngineeringAction,
    ({
      payload: {
        RAD = [],
        site,
        leasing: leasingPayload,
        engineering: engineeringPayload,
        scoping: scopingPayload,
        ...engineeringProjectData
      },
      state: { RAD: RADState, engineeringLeasingData },
    }: PayloadAndState<EngineeringDetails, EngineeringBrowseState>) => {
      const leasing = leasingPayload ?? {};
      const engineering =
        engineeringPayload ?? ({} as EngineeringWorkflowUpdateData);
      const { structural, PALOrNPARequired, ...restLeasingData } = leasing;
      const { RFDSPhase1, regulatoryCompliance } = engineering;
      const scopingData = scopingPayload ?? ({} as ScopingInfo);
      const { otherEquipment, scopingSectors, ...scopingInfo } = scopingData;

      const otherEquipmentInfo = getOtherEquipmentInfo(otherEquipment ?? []);

      const scopingSectorsInfo = getScopingSectorsInfo(scopingSectors ?? []);

      return {
        scopingInfo,
        scopingSectors: scopingSectorsInfo?.scopingSectors,
        otherEquipment: otherEquipmentInfo?.otherEquipment || [],
        equipmentTypeNames: [
          ...(otherEquipmentInfo?.equipmentTypeNames || []),
          ...(scopingSectorsInfo?.equipmentTypeNames || []),
        ],
        RAD: (Object.keys(RADState) as RADField[]).reduce(
          (acc: RADType, key: RADField) => ({
            ...acc,
            [key]: RAD?.find((RADData: RadData) => RADData.RADField === key)
              ?.scoping,
          }),
          {} as RADType,
        ),
        engineeringProjectData,
        engineeringSiteData: transformFields<SiteBrowse>(site, [
          'towerOwner',
          'riskCategory',
        ]),
        engineeringLeasingData: {
          ...engineeringLeasingData,
          ...restLeasingData,
          structural: transformFields<StructuralInformation>(
            structural,
            STRUCTURAL_INFO_TRANSFORM_FIELDS.splice(1, 1),
          ),
        },
        engineeringWorkflowData: {
          RFDSPhase1: transformFields<EngineeringRFDSPhase1>(
            RFDSPhase1,
            RFDS_INFO_TRANSFORM_FIELDS,
          ),
          regulatoryCompliance:
            transformFields<EngineeringRegulatoryCompliance>(
              { ...regulatoryCompliance, PALOrNPARequired },
              REGULATORY_COMPLIANCE_INFO_TRANSFORM_FIELDS,
            ),
        },
      };
    },
  ),
  // UPDATE ENGINEERING DETAILS
  on(
    EngineeringActions.updateEngineeringWorkflowAction,
    ({
      payload: { RFDSPhase1, regulatoryCompliance },
    }: PayloadAndState<
      EngineeringWorkflowUpdateData,
      EngineeringBrowseState
    >) => ({
      engineeringWorkflowData: {
        RFDSPhase1,
        regulatoryCompliance,
      },
    }),
  ),
);
