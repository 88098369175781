import { MainPaceTask } from 'providence-types';
import {
  LeasingEngineeringWorkflow,
  LeasingInformation,
  StructuralInformation,
} from '@models/interfaces';
import { ProjectsEffects } from '@store/effects';

export const updateLeasingPaceTaskFields = ({
  engineeringSection,
  leasingSection,
  structuralSection,
}: {
  engineeringSection: LeasingEngineeringWorkflow;
  leasingSection: Omit<LeasingInformation, 'id'>;
  structuralSection: StructuralInformation;
}): void => {
  ProjectsEffects.updatePaceTaskFieldsByConfig(engineeringSection, [
    {
      dateType: 'actualDate',
      field: 'finalCDsReviewedLeasing',
      task: MainPaceTask.FinalCDsReviewedLeasing,
    },
    {
      dateType: 'actualDate',
      field: 'passingMountAnalysisReviewed',
      task: MainPaceTask.PassingMountAnalysisReviewedLeasing,
    },
  ]);
  ProjectsEffects.updatePaceTaskFieldsByConfig(leasingSection, [
    {
      dateType: 'actualDate',
      field: 'auditDate',
      task: MainPaceTask.LeaseAuditComplete,
    },
    {
      dateType: 'actualDate',
      field: 'SOFIssuedDate',
      task: MainPaceTask.SOFIssuedDate,
    },
    {
      dateType: 'actualDate',
      field: 'coloAppDrafted',
      task: MainPaceTask.ColoAppDrafted,
    },
    {
      dateType: 'actualDate',
      field: 'coloAppApproved',
      task: MainPaceTask.ColoAppApproved,
    },
    {
      dateType: 'actualDate',
      field: 'coloAppSubmitted',
      task: MainPaceTask.ColoAppSubmitted,
    },
    {
      dateType: 'actualDate',
      field: 'PALOrNPAReceived',
      task: MainPaceTask.PALNPAReceived,
    },
    {
      dateType: 'actualDate',
      field: 'leaseDraftReceived',
      task: MainPaceTask.LeaseDraftReceived,
    },
    {
      dateType: 'actualDate',
      field: 'LMTSubmitted',
      task: MainPaceTask.LMTSubmitted,
    },
    {
      dateType: 'actualDate',
      field: 'LMTApproved',
      task: MainPaceTask.LMTApproved,
    },
    {
      dateType: 'actualDate',
      field: 'leaseAmendmentFullyExecuted',
      task: MainPaceTask.LeaseAmendmentFullyExecuted,
    },
    {
      dateType: 'actualDate',
      field: 'NTPApproved',
      task: MainPaceTask.NTPApproved,
    },
    {
      dateType: 'actualDate',
      field: 'NTPRequested',
      task: MainPaceTask.NTPRequested,
    },
  ]);
  ProjectsEffects.updatePaceTaskFieldsByConfig(structuralSection, [
    {
      dateType: 'actualDate',
      field: 'structuralOrdered',
      task: MainPaceTask.StructuralOrdered,
    },
    {
      dateType: 'actualDate',
      field: 'passingStructuralReceived',
      task: MainPaceTask.PassingStructuralReceived,
    },
    {
      dateType: 'actualDate',
      field: 'failingStructuralReceived',
      task: MainPaceTask.FailingStructuralReceived,
    },
    {
      dateType: 'actualDate',
      field: 'modDesignComplete',
      task: MainPaceTask.ModDesignComplete,
    },
    {
      dateType: 'actualDate',
      field: 'IBMApproved',
      task: MainPaceTask.IBMApproved,
    },
    {
      dateType: 'actualDate',
      field: 'modConstructionStart',
      task: MainPaceTask.ModConstructionStart,
    },
    {
      dateType: 'actualDate',
      field: 'modConstructionComplete',
      task: MainPaceTask.ModConstructionComplete,
    },
  ]);
};
