import { PaceTaskType } from 'providence-types';
import { OptionProps } from '@models/interfaces';

export const REPORT_DATE_TYPE_OPTIONS: OptionProps[] = [
  {
    value: PaceTaskType.ActualDate,
    viewValue: 'Actual Date',
  },
  {
    value: PaceTaskType.PACEForecastedDate,
    viewValue: 'PACE Forecasted Date',
  },
  { value: 'Both', viewValue: 'Both Dates' },
];
