import {
  IDeviceToken,
  IRuleDateFields,
  NotificationStatus,
} from 'providence-types';
import { StoredRequest } from '@core/utils/store/stored-request-decorator';
import {
  DirectMessageData,
  OptionProps,
  Rule,
  Schedule,
} from '@models/interfaces';
import { NotificationActions, UserActions } from '@store/actions';
import { CommonHttpService } from './common-http.service';

export class NotificationsHttpService extends CommonHttpService {
  // USER REQUESTS
  changeSubscriptionToken(deviceTokens: IDeviceToken): Promise<void> {
    return this.http.callApi.patch(`${this.apiUrl}/users/device-token`, {
      deviceTokens,
    });
  }

  changeSubscriptionStatus(
    subscriptionStatus: NotificationStatus,
  ): Promise<void> {
    return this.http.callApi.patch(`${this.apiUrl}/users/subscription-status`, {
      subscriptionStatus,
    });
  }

  @StoredRequest(UserActions.clearAll)
  removeAllNotifications(): Promise<void> {
    return this.http.callApi.patch(
      `${this.apiUrl}/users/remove-all-notifications`,
    );
  }

  @StoredRequest(UserActions.clearCurrent)
  async removeCurrentNotification(_id: string): Promise<string> {
    await this.http.callApi.patch(
      `${this.apiUrl}/users/remove-current-notification`,
      {
        _id,
      },
    );

    return _id;
  }

  // ADMIN REQUESTS
  @StoredRequest(NotificationActions.getPaceTaskList)
  getPaceTaskList(): Promise<OptionProps[]> {
    return this.http.callApi.get(
      `${this.apiUrl}/admin/notifications/pace-tasks`,
    );
  }

  @StoredRequest(NotificationActions.getUserList)
  getUsersList(): Promise<OptionProps[]> {
    return this.http.callApi.get(`${this.apiUrl}/admin/notifications/users`);
  }

  @StoredRequest(NotificationActions.getRuleFields)
  getRuleFields(): Promise<IRuleDateFields[]> {
    return this.http.callApi.get(
      `${this.apiUrl}/admin/notifications/rule/fields`,
    );
  }

  @StoredRequest(NotificationActions.getSchedule)
  getSchedule(towerOwnerIds?: string[]): Promise<Schedule[]> {
    return this.http.callApi.get(
      `${this.apiUrl}/admin/notifications/schedule`,
      null,
      {
        params: { towerOwnerIds },
      },
    );
  }

  @StoredRequest(NotificationActions.createSchedule)
  async createSchedule(schedule: Omit<Schedule, '_id'>): Promise<Schedule> {
    const response = await this.http.callApi.post(
      `${this.apiUrl}/admin/notifications/schedule`,
      schedule,
    );

    return {
      _id: response,
      ...schedule,
    };
  }

  @StoredRequest(NotificationActions.updateSchedule)
  async updateSchedule(
    scheduleId: string,
    schedule: Omit<Schedule, '_id'>,
  ): Promise<Schedule> {
    await this.http.callApi.patch(
      `${this.apiUrl}/admin/notifications/schedule`,
      schedule,
      { params: { scheduleId } },
    );

    return { _id: scheduleId, ...schedule };
  }

  @StoredRequest(NotificationActions.deleteSchedule)
  async deleteSchedule(scheduleId: string): Promise<string> {
    await this.http.callApi.delete(
      `${this.apiUrl}/admin/notifications/schedule`,
      null,
      { params: { scheduleId } },
    );

    return scheduleId;
  }

  @StoredRequest(NotificationActions.getRules)
  getRules(towerOwnerIds?: string[]): Promise<Rule[]> {
    return this.http.callApi.get(
      `${this.apiUrl}/admin/notifications/rule`,
      null,
      {
        params: { towerOwnerIds },
      },
    );
  }

  @StoredRequest(NotificationActions.createRule)
  async createRule(rule: Omit<Rule, '_id'>): Promise<Rule> {
    const response = await this.http.callApi.post(
      `${this.apiUrl}/admin/notifications/rule`,
      rule,
    );

    return {
      _id: response,
      ...rule,
    };
  }

  @StoredRequest(NotificationActions.updateRule)
  async updateRule(ruleId: string, rule: Omit<Rule, '_id'>): Promise<Rule> {
    await this.http.callApi.patch(
      `${this.apiUrl}/admin/notifications/rule`,
      rule,
      { params: { ruleId } },
    );

    return { _id: ruleId, ...rule };
  }

  @StoredRequest(NotificationActions.deleteRule)
  async deleteRule(ruleId: string): Promise<string> {
    await this.http.callApi.delete(
      `${this.apiUrl}/admin/notifications/rule`,
      null,
      { params: { ruleId } },
    );

    return ruleId;
  }

  // Email Certification Date
  @StoredRequest(NotificationActions.getEmailCertificationDate)
  getEmailCertificationDate(): Promise<string> {
    return this.http.callApi.get(
      `${this.apiUrl}/admin/notifications/email-certification-date`,
    );
  }

  // Direct Messages
  @StoredRequest(NotificationActions.getDirectMessages)
  getDirectMessages(): Promise<DirectMessageData[]> {
    return this.http.callApi.get(
      `${this.apiUrl}/admin/notifications/direct-message`,
    );
  }

  @StoredRequest(NotificationActions.removeDirectMessage)
  async removeDirectMessages(_id: string): Promise<string> {
    await this.http.callApi.delete(
      `${this.apiUrl}/admin/notifications/direct-message`,
      null,
      { params: { _id } },
    );

    return _id;
  }

  @StoredRequest(NotificationActions.multiRemoveDirectMessages)
  async multiRemoveDirectMessages(recordIds: string[]): Promise<string[]> {
    await this.http.callApi.delete(
      `${this.apiUrl}/admin/notifications/direct-message/multi`,
      null,
      { params: { recordIds } },
    );

    return recordIds;
  }
}
