import { ScopingBrowseFilter } from '@models/interfaces';

export const SCOPING_BROWSE_DEFAULT_CONFIGURE_FILTER: ScopingBrowseFilter = {
  projectWorkTypeId: undefined,
  projectStatusId: undefined,
  projectTypeId: undefined,
  towerOwnerId: undefined,
  stateId: undefined,
  jurisdictionId: undefined,
};
