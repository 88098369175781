import { Resolver } from '@wellyes/react-router-extended';
import { HttpService } from '@core/services';
import {
  AccountingHttpService,
  DriverManagementHttpService,
  ProjectCategoryHttpService,
  SiteHttpService,
} from '@core/services/http';
import { getIdParamFromUrl } from '@core/utils/methods';

export class AccountingDetailsResolver implements Resolver {
  async resolve(): Promise<void> {
    const [id] = getIdParamFromUrl();

    await HttpService.getHttpRequests(AccountingHttpService).getAccounting(id);
    await HttpService.getHttpRequests(
      ProjectCategoryHttpService,
    ).getProjectCategories();
    await HttpService.getHttpRequests(
      SiteHttpService,
    ).getFALocationNumberOptions();
    await HttpService.getHttpRequests(
      DriverManagementHttpService,
    ).getDriversListByWorkflow();
  }
}
