import React, { Dispatch, FC, PropsWithChildren, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { getRanking, toggleModal } from '@core/utils/methods';
import {
  getNotEditableProjectCategoryColumns,
  getProjectCategoryColumns,
} from '@models/constants';
import { CustomRow, ModalMainTypes } from '@models/enums';
import { ProjectCategory as ProjectCategoryInterface } from '@models/interfaces';
import { ProjectCategoriesActions } from '@store/actions';
import { DDVSelectors, ProjectCategoriesSelectors } from '@store/selectors';
import { CustomTable } from '../../components/custom-table';
import { DeleteModal } from '../../components/delete-modal';
import { PrimaryButton } from '../../modules/ui-kit/primary-button';
import { ProjectCategoryModal } from './components';
import {
  PROJECT_CATEGORY_SUM_DEFAULT_VALUE,
  ProjectCategoryProps,
} from './models';

import './styles.scss';

export const ProjectCategory: FC<ProjectCategoryProps> = ({
  projectCreate = false,
  editable = false,
}: PropsWithChildren<ProjectCategoryProps>) => {
  const dispatch = useDispatch();

  const dropdownsOptions = useSelector(DDVSelectors.getDDVOptions);
  const {
    projectCategoriesTableVisible,
    currentProjectCategories,
    isCategoriesTableEditing,
  } = useSelector(ProjectCategoriesSelectors.getProjectCategoryInfo);

  const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false);
  const [isAddEditModalVisible, setAddEditModalVisible] =
    useState<boolean>(false);
  const [currentModalType, setCurrentModalType] = useState<ModalMainTypes>(
    ModalMainTypes.Add,
  );
  const [selectedItem, setSelectedItem] = useState<ProjectCategoryInterface>(
    PROJECT_CATEGORY_SUM_DEFAULT_VALUE,
  );

  const toggleAddAndEditModal = (type: ModalMainTypes): void => {
    setCurrentModalType(type);
    setAddEditModalVisible(!isAddEditModalVisible);

    if (isAddEditModalVisible) {
      setSelectedItem(PROJECT_CATEGORY_SUM_DEFAULT_VALUE);
    }
  };

  const selectCurrentProjectCategory = (
    currentProjectCategory: ProjectCategoryInterface,
    setter: Dispatch<boolean>,
    prevState: boolean,
  ): void => {
    setSelectedItem(currentProjectCategory);
    setter(!prevState);
  };

  const onDelete = (): void => {
    dispatch(
      ProjectCategoriesActions.deleteProjectCategoriesItemAction.done({
        generatedId: selectedItem.generatedId || '',
      }),
    );

    setDeleteModalVisible(!deleteModalVisible);
    setSelectedItem(PROJECT_CATEGORY_SUM_DEFAULT_VALUE);
  };

  const isEditing = (editable && isCategoriesTableEditing) || projectCreate;

  return projectCategoriesTableVisible || projectCreate ? (
    <div className="prov-project-category">
      <CustomTable
        columns={
          isEditing
            ? getProjectCategoryColumns(
                (projectCategory: ProjectCategoryInterface): void =>
                  selectCurrentProjectCategory(
                    projectCategory,
                    () => toggleAddAndEditModal(ModalMainTypes.Edit),
                    isAddEditModalVisible,
                  ),
                (projectCategory: ProjectCategoryInterface): void =>
                  selectCurrentProjectCategory(
                    projectCategory,
                    setDeleteModalVisible,
                    deleteModalVisible,
                  ),
              )
            : getNotEditableProjectCategoryColumns()
        }
        dataSource={currentProjectCategories}
        rowClassName={(record: any): string =>
          classNames({
            [CustomRow.Primary]:
              getRanking(record, dropdownsOptions) === 'Primary',
          })
        }
        pagination={false}
        hoverable={false}
      />
      {isEditing ? (
        <div className="prov-project-category__add-button">
          <PrimaryButton
            icon="+"
            title="Add"
            onClick={(): void => toggleAddAndEditModal(ModalMainTypes.Add)}
          />
        </div>
      ) : null}
      <ProjectCategoryModal
        visible={isAddEditModalVisible}
        modalType={currentModalType}
        toggleModal={toggleAddAndEditModal}
        itemValue={selectedItem}
        projectCreate={projectCreate}
      />
      <DeleteModal
        onOk={onDelete}
        onCancel={toggleModal(setDeleteModalVisible)}
        visible={deleteModalVisible}
      />
    </div>
  ) : null;
};
