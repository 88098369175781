import axios, { AxiosError, AxiosResponse, Method } from 'axios';
import qs from 'qs';
import { ApiMethods, StorageItem } from '../../models/enums';
import {
  CallApi,
  HttpClientResult,
  ProcessEnvMap,
} from '../../models/interfaces';
import { NotificationsLoader } from '../../shared/components';
import { Config } from '../config/config';

type TimoutError = {
  response: Partial<AxiosResponse>;
};

export class HttpClient {
  callApi: CallApi;

  constructor() {
    this.callApi = HttpClient._callApi();
  }

  private static readonly REQUEST_TIMEOUT: number = 30000; // ms
  private static readonly TIMEOUT_ERROR: TimoutError = {
    response: {
      statusText: 'Timeout',
      data: {
        message:
          'Your request is taking a long time. Please try reload page in few minutes.',
      },
    },
  };

  private static get _accessToken(): string {
    return localStorage.getItem(StorageItem.ACCESS_TOKEN)!;
  }

  private static _httpHeaders(): ProcessEnvMap {
    return {
      'Content-Type': 'application/json',
      Authorization: HttpClient._accessToken
        ? `Bearer ${HttpClient._accessToken}`
        : '',
    };
  }

  private static _callApi(): CallApi {
    const accumulator = {};

    return [
      ApiMethods.Post,
      ApiMethods.Get,
      ApiMethods.Put,
      ApiMethods.Delete,
      ApiMethods.Patch,
    ].reduce(
      (obj: CallApi, method: Method): CallApi => ({
        ...obj,
        [method]: (
          url: string,
          body: any = {},
          options: any = {},
        ): HttpClientResult =>
          HttpClient._doRequest(method, url, body, options),
      }),
      accumulator as CallApi,
    );
  }

  private static async _doRequest(
    method: Method,
    url: string,
    body: any,
    options: any,
  ): HttpClientResult {
    const credentialHeaders = HttpClient._httpHeaders();
    const {
      params,
      headers,
      cOnSuccess,
      cOnFail,
      needHeaders,
      ...restOptions
    } = options;

    try {
      const cancelRequestSource = axios.CancelToken.source();
      const timeout = setTimeout(() => {
        cancelRequestSource.cancel();
      }, Config.get().connectionConfig.requestTimeout);

      const resp = await axios({
        method,
        url,
        ...(body ? { data: body } : {}),
        params,
        headers: {
          ...headers,
          ...credentialHeaders,
        },
        ...restOptions,
        paramsSerializer: (sendParams: any) => qs.stringify(sendParams),
        cancelToken: cancelRequestSource.token,
      });

      clearTimeout(timeout);

      if (cOnSuccess) {
        await cOnSuccess(resp);
      }

      return await Promise.resolve(
        needHeaders ? { data: resp.data, headers: resp.headers } : resp.data,
      );
    } catch (e) {
      const error = axios.isCancel(e) ? HttpClient.TIMEOUT_ERROR : e;
      const {
        response: { statusText = '', data: { message = [] } = {} } = {},
      } = error as AxiosError<{ statusText: string; message: string }>;

      const isString = typeof message === 'string';

      if (isString || Array.isArray(message)) {
        NotificationsLoader.notificationError({
          message: isString ? message : message.join(' '),
          description: statusText,
        });
      }

      if (cOnFail) {
        await cOnFail(error);
      }

      return Promise.reject(error);
    }
  }
}
