import React, { FC, PropsWithChildren, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EditablePageProps } from '@models/interfaces';
import { ProjectCategoriesActions } from '@store/actions';
import { CommonSelectors } from '@store/selectors';
import { UnsavedDataModal } from '../../components/unsaved-data-modal';
import { SwitchBox } from '../../modules/ui-kit';

import './styles.scss';

export const EditablePage: FC<EditablePageProps> = ({
  canUserEdit = true,
  editableComponent,
  children,
  defaultEditing = false,
  permissions,
  history,
  data,
  isActive,
  unsubmittedDataNotification,
}: PropsWithChildren<EditablePageProps>) => {
  const dispatch = useDispatch();

  const [isEditing, setEditing] = useState(defaultEditing);
  const [modalVisible, setModalVisible] = useState<boolean>(false);

  const hasUnsubmittedData = useSelector(CommonSelectors.getHasUnsubmittedData);

  const toggleEditing = (): void => {
    unsubmittedDataNotification && isEditing
      ? setModalVisible(!modalVisible)
      : setEditing(!isEditing);
  };

  const onOk = (): void => {
    setModalVisible(!modalVisible);
    setEditing(!isEditing);
  };

  useEffect((): void => {
    dispatch(
      ProjectCategoriesActions.setCategoriesTableEditing.done(isEditing),
    );
  }, [isEditing]);

  return (
    <div className="prov-editable-page">
      <div className="prov-editable-page__header">
        {canUserEdit && (
          <SwitchBox
            className="editable-switch"
            checked={isEditing}
            onChange={toggleEditing}
            title="Enable edit"
            disabled={hasUnsubmittedData}
            tooltip={
              hasUnsubmittedData
                ? `The form has been changed. Please click "Submit" to save the changes or "Cancel" to cancel the changes.`
                : undefined
            }
          />
        )}
        {children}
      </div>
      <div className="prov-editable-page__content">
        {React.createElement(editableComponent, {
          isEditing,
          isActive,
          permissions,
          data,
          history,
          toggleEditing,
        })}
      </div>
      {unsubmittedDataNotification && (
        <UnsavedDataModal
          visible={modalVisible}
          onCancel={(): void => setModalVisible(!modalVisible)}
          onOk={onOk}
        />
      )}
    </div>
  );
};
