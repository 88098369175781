import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { SiteAuditComponent } from '@client/pages/engineering/components';
import { withDispatchRouter } from '@core/utils/HOC';
import { canUserEditByProjectStatusAndRoles } from '@core/utils/methods';
import { HeaderBar } from '@shared/components';
import {
  ProjectSelectors,
  SiteAuditSelectors,
  UserSelectors,
} from '@store/selectors';

import './styles.scss';

const SiteAuditDetailsComponent: FC = () => {
  const siteAuditSiteInfo = useSelector(
    SiteAuditSelectors.getSiteAuditSiteInfo,
  );
  const permissions = useSelector(
    UserSelectors.getEngineeringSiteAuditPermissions,
  );
  const { projectType } = useSelector(
    SiteAuditSelectors.getProjectInfo('projectType'),
  );
  const projectTypePermission = useSelector(
    UserSelectors.canEditProjectByProjectType(projectType as string),
  );

  const userRoles = useSelector(UserSelectors.getUserRoles);
  const projectStatus = useSelector(ProjectSelectors.getProjectStatus);

  return (
    <div className="site-audit-details">
      <HeaderBar
        className="site-audit-details-header"
        category
        site={siteAuditSiteInfo}
        withoutTabPanel
      />
      <SiteAuditComponent
        permissions={permissions}
        canUserEdit={
          projectTypePermission &&
          canUserEditByProjectStatusAndRoles(projectStatus, userRoles)
        }
      />
    </div>
  );
};

export const SiteAuditDetails = withDispatchRouter(SiteAuditDetailsComponent);
