import { EngineeringBrowseFilter } from '@models/interfaces';

export const ENGINEERING_BROWSE_CONFIGURE_DEFAULT_FILTER: EngineeringBrowseFilter =
  {
    countyId: undefined,
    jurisdictionId: undefined,
    progressStatusId: undefined,
    projectStatusId: undefined,
    projectTypeId: undefined,
    stateId: undefined,
    projectWorkTypeId: undefined,
    towerOwnerId: undefined,
  };
