import React, { FC, PropsWithChildren } from 'react';
import { GoBackButton } from '@shared/components';
import { PrimaryButton } from '@shared/modules';
import { SOFToolActionButtonsProps } from './models';

import './styles.scss';

export const SOFActionButtons: FC<SOFToolActionButtonsProps> = ({
  send,
  preview,
  disabled,
  cancelDisabled = false,
}: PropsWithChildren<SOFToolActionButtonsProps>) => (
  <div className="prov-sof-tool-action-buttons">
    <div className="prov-sof-tool-action-buttons__general-actions">
      <PrimaryButton
        title="Send"
        type="primary"
        onClick={send}
        disabled={disabled}
        className="send-button"
      />
      <GoBackButton disabled={cancelDisabled} closingWindow />
    </div>
    <PrimaryButton
      title="Preview"
      type="primary"
      onClick={preview}
      disabled={disabled}
    />
  </div>
);
