import React, { ReactElement, ReactNode, ReactText } from 'react';
import classNames from 'classnames';
import { projectCategoryModifier } from '@models/constants/modifiers';
import {
  AdminDefaultTowerAgentAgent,
  AdminName,
  AdminProjectCategoryData,
  OptionProps,
  ProjectCategory,
  RenderProps,
} from '@models/interfaces';
import {
  DateString,
  ProjectPaceTaskDataType,
  RenderProjectPaceTaskDataPropsType,
} from '@models/types';
import {
  ReadOnlyBoolean,
  ReadOnlyDate,
  ReadOnlyInputNumber,
  ReadOnlySelect,
} from '@shared/modules/ui-kit/read-only-elements';
import { mainDateFormat } from '../methods/main-date-format';

export const projectCategoryRender =
  ({
    field,
    className = '',
    renderElement = (element: any): ReactNode => element,
    dateField = false,
  }: RenderProps<ProjectCategory>) =>
  (categories: ProjectCategory[]): ReactElement =>
    (
      <div className={classNames(className, 'project-category-cell')}>
        {[...categories]?.map((category: ProjectCategory) => {
          const value = dateField
            ? mainDateFormat(category[field] as string)
            : category[field];

          return (
            <span
              key={
                category?.id ||
                category?.generatedId ||
                `${category.PACEProjectNumber}_${category.oracleProjectNumber}`
              }
              className={
                projectCategoryModifier[
                  category?.projectRanking?.value ?? 'Secondary'
                ]
              }
            >
              {renderElement(
                ['projectCategory', 'projectRanking'].includes(field)
                  ? (value as AdminProjectCategoryData)?.value
                  : value,
              )}
              {field === 'projectCategory' && category.isFirstNet ? ' FN' : ''}
            </span>
          );
        })}
      </div>
    );

export const readOnlyDatePickerRenderer = (date?: DateString): ReactNode => (
  <ReadOnlyDate date={date} />
);

export const readOnlyInputNumberRender = (
  numberValue?: ReactText,
): ReactNode => <ReadOnlyInputNumber value={numberValue} />;

export const readOnlyPriceRender = (priceValue?: ReactText): ReactNode => (
  <span>
    <ReadOnlyInputNumber value={priceValue} suffix="$" />
  </span>
);

export const readOnlyPercentRender = (percentValue?: ReactText): ReactNode => (
  <span>
    <ReadOnlyInputNumber value={percentValue} prefix="%" />
  </span>
);

export const readOnlySelectRender = (
  options: OptionProps[],
  value?: string,
): ReactNode => <ReadOnlySelect value={value} options={options} />;

export const readonlyBooleanRender = (booleanValue?: boolean): ReactNode => (
  <ReadOnlyBoolean value={booleanValue} />
);

export const projectPaceTaskDataRender =
  ({ field, task }: RenderProjectPaceTaskDataPropsType) =>
  (projectPaceTaskData: Array<ProjectPaceTaskDataType>): ReactElement => {
    const currentPaceTask = [...projectPaceTaskData]?.find(
      ({ paceTask: { id } }: ProjectPaceTaskDataType) => id === task,
    );

    return field === 'invoiceNumber' ? (
      <>{currentPaceTask?.[field]}</>
    ) : (
      <ReadOnlyDate date={currentPaceTask?.[field] as Date} />
    );
  };

export const phoneElementRender = (phone?: string): string =>
  phone?.replace(/(\d{1})(\d{3})(\d{3})(\d{4})/gm, '+$1 ($2) $3-$4') ?? '';

export const providenceAgentRender = (
  providenceAgent?: AdminDefaultTowerAgentAgent,
): ReactNode =>
  providenceAgent && `${providenceAgent.firstName} ${providenceAgent.lastName}`;

export const adminNameRender = (
  adminsList: AdminName[],
  adminId: string,
): string => {
  const foundAdmin = adminsList.find(
    (admin: AdminName) => admin.id === adminId,
  );

  return foundAdmin ? `${foundAdmin.firstName} ${foundAdmin.lastName}` : '';
};

export const wrappedTextRender = (text: string): ReactNode => (
  <div className={classNames({ 'text-wrapper': text })}>{text}</div>
);
