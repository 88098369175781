import { IRuleDateFields, ISelectorOption } from 'providence-types';
import { actionCreatorFactory } from '@core/utils/factories';
import {
  DirectMessageData,
  OptionProps,
  Rule,
  Schedule,
} from '@models/interfaces';

const actionCreator = actionCreatorFactory('NOTIFICATION');

export namespace NotificationActions {
  export const getPaceTaskList = actionCreator.createAsyncAction<OptionProps[]>(
    'GET_PACE_TASKS_LIST',
  );
  export const getUserList =
    actionCreator.createAsyncAction<ISelectorOption<string>[]>('GET_USER_LIST');
  export const getRuleFields =
    actionCreator.createAsyncAction<IRuleDateFields[]>('GET_RULE_FIELDS');
  // Schedule
  export const getSchedule =
    actionCreator.createAsyncAction<Schedule[]>('GET_SCHEDULE');
  export const createSchedule =
    actionCreator.createAsyncAction<Schedule>('CREATE_SCHEDULE');
  export const updateSchedule =
    actionCreator.createAsyncAction<Schedule>('UPDATE_SCHEDULE');
  export const deleteSchedule =
    actionCreator.createAsyncAction<string>('DELETE_SCHEDULE');
  // Rules
  export const getRules = actionCreator.createAsyncAction<Rule[]>('GET_RULES');
  export const createRule =
    actionCreator.createAsyncAction<Rule>('CREATE_RULE');
  export const updateRule =
    actionCreator.createAsyncAction<Rule>('UPDATE_RULE');
  export const deleteRule =
    actionCreator.createAsyncAction<string>('DELETE_RULE');
  // Email
  export const getEmailCertificationDate =
    actionCreator.createAsyncAction<string>('GET_EMAIL_CERTIFICATION_DATE');
  // Direct Message
  export const getDirectMessages = actionCreator.createAsyncAction<
    DirectMessageData[]
  >('GET_DIRECT_MESSAGES');
  export const removeDirectMessage = actionCreator.createAsyncAction<string>(
    'REMOVE_DIRECT_MESSAGE',
  );
  export const multiRemoveDirectMessages = actionCreator.createAsyncAction<
    string[]
  >('MULTI_REMOVE_DIRECT_MESSAGES');
}
