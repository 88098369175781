import React, { FC, useEffect } from 'react';
import { Form } from 'antd';
import { IAssignee, ISelectorOption } from 'providence-types';
import { isValidForm } from '@core/utils/methods';
import { DashboardUpdatableItem } from '@models/interfaces';
import { AddAndEditModal } from '@shared/components';
import { Autocomplete, TextArea } from '@shared/modules';
import { EditDashboardItemProps } from '../models';

export const EditDashboardItem: FC<EditDashboardItemProps> = ({
  visible,
  closeHandler,
  saveHandler,
  editableRecord,
  adminList,
  title,
  userId,
  isAdminUser,
}: EditDashboardItemProps) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (editableRecord) {
      const {
        notificationData: { assignees, taskNote },
      } = editableRecord;

      form.setFieldsValue({
        taskNote,
        assignees: assignees.map(({ assignee }: IAssignee) => assignee),
      });
    }
  }, [editableRecord, form]);

  const onCancel = (): void => {
    form.resetFields();
    closeHandler();
  };

  const onOk = async (): Promise<void> => {
    if (await isValidForm(form)) {
      saveHandler({
        _id: editableRecord!._id,
        reporter: userId,
        ...form.getFieldsValue(),
      } as DashboardUpdatableItem);
      onCancel();
    }
  };

  return (
    <AddAndEditModal
      title={title}
      visible={visible}
      onOk={onOk}
      onCancel={onCancel}
      formProps={{
        labelCol: { span: 6 },
        wrapperCol: { span: 15 },
        form,
      }}
      cancelText="Cancel"
      cancelButtonProps={{
        onClick: onCancel,
      }}
    >
      <Autocomplete
        label="Assigners"
        id="assignees"
        options={adminList.map(({ value, viewValue }: ISelectorOption) => ({
          value,
          viewValue,
          disabled: isAdminUser
            ? userId === value
            : !!editableRecord?.notificationData.assignees.find(
                ({ reporter, assignee }: IAssignee) =>
                  userId !== reporter && value === assignee,
              ),
        }))}
        elementProps={{
          mode: 'multiple',
        }}
      />
      <TextArea label="Task Note" id="taskNote" />
    </AddAndEditModal>
  );
};
