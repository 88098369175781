import React, { FC, PropsWithChildren, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Col, Form, Row } from 'antd';
import { MainPaceTask } from 'providence-types';
import { momentizeObjectDates } from '@core/utils/methods';
import { YES_NO_GROUP } from '@models/constants';
import { EngineeringRFDSPhase1, FormProps } from '@models/interfaces';
import { Panel } from '@shared/components';
import {
  Autocomplete,
  DatePicker,
  RadioGroup,
  TextArea,
} from '@shared/modules';
import { DDVSelectors, PaceTasksSelectors } from '@store/selectors';
import { RFDS_INFO_DATES } from '../../models/constants';

export const RFDSPhase1Panel: FC<FormProps> = ({
  form,
  isEditing,
  data,
  permissions: {
    prelimRFDSReceived,
    RFDSStatus,
    prelimRFDSDemotedTo,
    revisedRFDSReceived,
    prelimRFDSPromoted,
    revisedRFDSDemotedTo,
    finalRFDSReceived,
    finalRFDSPromotedToCX,
    finalRFDSPromotionSuccessful,
    RFDSPromotionFailureNote,
    dateOfSuccessfulPromotion,
    CI004,
    CSSDataComplete,
    phase1Required,
    phase1Ordered,
    phase1Complete,
    phase1Vendor,
    phase1Notes,
    phase1Uploaded,
    engineeringNotes,
  },
  onValuesChange,
}: PropsWithChildren<FormProps>) => {
  const dropdownsOptions = useSelector(DDVSelectors.getDDVOptions);

  const [CI004PTP, finalRFDSReceivedPTP] = useSelector(
    PaceTasksSelectors.getPaceTasksPermissions([
      {
        dateType: 'PACEActualDate',
        paceTaskId: MainPaceTask.RBSDataEntered,
      },
      {
        dateType: 'actualDate',
        paceTaskId: MainPaceTask.FinalRFDSReceived,
      },
    ]),
  );

  useEffect(() => {
    if (data) {
      form?.setFieldsValue(
        momentizeObjectDates<EngineeringRFDSPhase1>(
          data as EngineeringRFDSPhase1,
          RFDS_INFO_DATES,
        ),
      );
    }
  }, [data, isEditing]);

  return (
    <Panel
      header="RFDS/Phase 1"
      collapsible
      className="prov-engineering-workflow__panel rfds-panel"
    >
      <Form
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 12 }}
        form={form}
        onValuesChange={onValuesChange}
      >
        <Row>
          <Col span="10">
            <DatePicker
              id="prelimRFDSReceived"
              label="Prelim RFDS Received"
              isEditing={isEditing && !!prelimRFDSReceived}
            />
            <Autocomplete
              id="RFDSStatus"
              label="RFDS Status"
              isEditing={isEditing && !!RFDSStatus}
              options={dropdownsOptions?.RFDSStatus}
            />
            <Autocomplete
              id="prelimRFDSDemotedTo"
              label="Prelim RFDS Demoted To"
              isEditing={isEditing && !!prelimRFDSDemotedTo}
              options={dropdownsOptions?.PrelimRFDSDemotedTo}
            />
            <DatePicker
              id="revisedRFDSReceived"
              label="Revised RFDS Received"
              isEditing={isEditing && !!revisedRFDSReceived}
            />
            <DatePicker
              id="prelimRFDSPromoted"
              label="Prelim RFDS Promoted"
              isEditing={isEditing && !!prelimRFDSPromoted}
            />
            <Autocomplete
              id="revisedRFDSDemotedTo"
              label="Revised RFDS Demoted To"
              options={dropdownsOptions?.RevisedRFDSDemotedTo}
              isEditing={isEditing && !!revisedRFDSDemotedTo}
            />
            <DatePicker
              id="finalRFDSReceived"
              label="Final RFDS Received"
              isEditing={
                isEditing && !!finalRFDSReceived && finalRFDSReceivedPTP
              }
            />
            <DatePicker
              id="finalRFDSPromotedToCX"
              label="Final RFDS Promoted to CX"
              isEditing={isEditing && !!finalRFDSPromotedToCX}
            />
            <RadioGroup
              id="finalRFDSPromotionSuccessful"
              label="Final RFDS Promotion Successful"
              buttons={YES_NO_GROUP}
              isEditing={isEditing && !!finalRFDSPromotionSuccessful}
            />
            <TextArea
              id="RFDSPromotionFailureNote"
              label="RFDS Promotion Failure Note"
              isEditing={isEditing && !!RFDSPromotionFailureNote}
            />
          </Col>
          <Col span="12">
            <DatePicker
              id="dateOfSuccessfulPromotion"
              label="Date of Successful Promotion"
              isEditing={isEditing && !!dateOfSuccessfulPromotion}
            />
            <DatePicker
              id="CI004"
              label="CI004 PACE Actual Date"
              isEditing={isEditing && !!CI004 && CI004PTP}
            />
            <DatePicker
              id="CSSDataComplete"
              label="CSS Data Complete"
              isEditing={isEditing && !!CSSDataComplete}
            />
            <RadioGroup
              id="phase1Required"
              label="Phase 1 Required"
              buttons={YES_NO_GROUP}
              isEditing={isEditing && !!phase1Required}
            />
            <DatePicker
              id="phase1Ordered"
              label="Phase 1 Ordered"
              isEditing={isEditing && !!phase1Ordered}
            />
            <DatePicker
              id="phase1Complete"
              label="Phase 1 Complete"
              isEditing={isEditing && !!phase1Complete}
            />
            <Autocomplete
              id="phase1Vendor"
              label="Phase 1 Vendor"
              isEditing={isEditing && !!phase1Vendor}
              options={dropdownsOptions?.Phase1Vendor}
            />
            <TextArea
              id="phase1Notes"
              label="Phase 1 Notes"
              isEditing={isEditing && !!phase1Notes}
            />
            <DatePicker
              id="phase1Uploaded"
              label="Phase 1 Uploaded"
              isEditing={isEditing && !!phase1Uploaded}
            />
            <TextArea
              id="engineeringNotes"
              label="Engineering Notes"
              isEditing={isEditing && !!engineeringNotes}
            />
          </Col>
        </Row>
      </Form>
    </Panel>
  );
};
