export const getIdParamFromUrl = (): string[] => {
  const regExp = /\w{8}-\w{4}-\w{4}-\w{4}-\w{12}/;
  const preResult = window.location.pathname.match(regExp);

  if (!preResult) {
    throw new Error('UUID in url is not exist!');
  }

  return preResult;
};
