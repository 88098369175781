import { Dispatch, SetStateAction } from 'react';
import { FormInstance } from 'antd/es/form';

export const createOnChangeModalVisibility =
  <TConfigureFilter, TAppliedFilter>(
    form: FormInstance,
    configureFilter: TConfigureFilter,
    appliedFilter: TAppliedFilter,
    setConfigureFilter: Dispatch<SetStateAction<TConfigureFilter>>,
  ) =>
  (visible: boolean): void => {
    if (!visible) {
      const keys = Object.keys(configureFilter) as Array<
        keyof TConfigureFilter
      >;
      const filter = keys.reduce(
        (acc: TConfigureFilter, key: keyof TConfigureFilter) => ({
          ...acc,
          [key]: (appliedFilter as any)[key],
        }),
        {} as TConfigureFilter,
      );

      form.resetFields();
      setConfigureFilter(filter);
      form.setFieldsValue(filter);
    }
  };
