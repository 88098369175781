import {
  IReceivedDirectMessage,
  ISelectorOption,
  Undefinable,
} from 'providence-types';
import { findFromSelectorOptions, mainDateFormat } from '@core/utils/methods';
import { DashboardItem } from '@models/interfaces';

export const directMessagesSearcher = (
  data: DashboardItem<IReceivedDirectMessage>[],
  usersList: ISelectorOption<string>[],
  searchValue: string,
): DashboardItem<IReceivedDirectMessage>[] => {
  const regExp = new RegExp(searchValue, 'gi');

  return !searchValue
    ? data
    : data.filter(
        ({
          notificationData: { senderId, message, createdAt, areaKey },
          projectData: {
            projectBundleId,
            site: { siteNumber },
          },
        }: DashboardItem<IReceivedDirectMessage>) =>
          [
            projectBundleId.toString(),
            siteNumber,
            message,
            areaKey,
            mainDateFormat(createdAt),
            findFromSelectorOptions(senderId, usersList),
          ].some((item: Undefinable<string>) => item && regExp.test(item)),
      );
};
