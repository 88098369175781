import React, { FC } from 'react';
import { Col, Form, Row } from 'antd';
import { YES_NO_GROUP } from '@models/constants';
import { EditableInformationProps } from '@models/interfaces';
import { Panel } from '@shared/components';
import { DatePicker, Input, RadioGroup } from '@shared/modules';

export const ProjectInformation: FC<EditableInformationProps> = ({
  isEditing,
  ...formProps
}: EditableInformationProps) => (
  <Panel header="Project Information">
    <Form wrapperCol={{ span: 14 }} labelCol={{ span: 12 }} {...formProps}>
      <Row>
        <Col span="12">
          <Input id="projectType" label="Project Type" isEditing={isEditing} />
          <DatePicker
            id="scopingComplete"
            label="Scoping Complete"
            isEditing={isEditing}
          />
          <DatePicker
            id="finalRFDSDate"
            label="Final RFDS Date"
            isEditing={isEditing}
          />
          <Input id="mountType" label="Mount Type" isEditing={isEditing} />
          <RadioGroup
            id="craneRequired"
            label="Crane Required"
            isEditing={isEditing}
            buttons={YES_NO_GROUP}
          />
          <Input id="craneHeight" label="Crane Height" isEditing={isEditing} />
          <Input id="civilVendor" label="Civil Vendor" isEditing={isEditing} />
        </Col>
      </Row>
      <Row>
        <Col span="12">
          <Input id="azimuthA" label="Azimuth A" isEditing={isEditing} />
          <Input id="azimuthB" label="Azimuth B" isEditing={isEditing} />
          <Input id="azimuthG" label="Azimuth G" isEditing={isEditing} />
          <Input id="azimuthD" label="Azimuth D" isEditing={isEditing} />
        </Col>
        <Col span="12">
          <Input id="RADA" label="RAD A" isEditing={isEditing} />
          <Input id="RADB" label="RAD B" isEditing={isEditing} />
          <Input id="RADG" label="RAD G" isEditing={isEditing} />
          <Input id="RADD" label="RAD D" isEditing={isEditing} />
        </Col>
      </Row>
    </Form>
  </Panel>
);
