import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Form } from 'antd';
import { DropDownSelectorValues } from 'providence-types';
import { EditableInformationProps, OptionProps } from '@models/interfaces';
import { Panel } from '@shared/components';
import { Autocomplete, Coordinate, Input } from '@shared/modules';
import { DDVSelectors } from '@store/selectors';

export const SiteInformation: FC<EditableInformationProps> = ({
  isEditing,
  ...formProps
}: EditableInformationProps) => {
  const towerOwnerOptions = useSelector(
    DDVSelectors.getDDVValuesByType(DropDownSelectorValues.TowerOwner),
  ) as OptionProps[];

  return (
    <Panel header="Site Information">
      <Form labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} {...formProps}>
        <Input
          id="FALocationNumber"
          label="FA Location Number"
          isEditing={isEditing}
        />
        <Input id="siteNumber" label="Site Number" isEditing={isEditing} />
        <Input
          id="streetAddress"
          label="Street Address"
          isEditing={isEditing}
        />
        <Input id="city" label="City" isEditing={isEditing} />
        <Input id="state" label="State" isEditing={isEditing} />
        <Input id="zip" label="Zip" isEditing={isEditing} />
        <Coordinate id="latitude" label="Latitude" isEditing={isEditing} />
        <Coordinate id="longitude" label="Longitude" isEditing={isEditing} />
        <Autocomplete
          id="towerOwner"
          label="Tower Owner"
          isEditing={isEditing}
          options={towerOwnerOptions}
        />
        <Input id="towerType" label="Tower Type" isEditing={isEditing} />
        <Input id="towerHeight" label="Tower Height" isEditing={isEditing} />
        <Input id="equipmentType" label="EquipmentType" isEditing={isEditing} />
      </Form>
    </Panel>
  );
};
