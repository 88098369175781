import React, { FC, PropsWithChildren, useEffect, useMemo } from 'react';
import { Form } from 'antd';
import { HttpService } from '@core/services';
import { DriverManagementHttpService } from '@core/services/http';
import { getFormData, isValidForm } from '@core/utils/methods';
import {
  serverValidator,
  validationRequestParams,
} from '@core/utils/validators';
import {
  DOLLAR_PREFIX,
  INPUT_POSITIVE_NUMBER_VALIDATOR,
  WHITESPACE_INPUT_LIMIT_RULES,
  YES_NO_GROUP,
} from '@models/constants';
import { ModalMainTypes } from '@models/enums';
import { AdminDriverManagementData } from '@models/interfaces';
import { AddAndEditModal } from '@shared/components';
import {
  CheckboxGroup,
  Input,
  InputNumber,
  RadioGroup,
  TextArea,
} from '@shared/modules';
import { DriverManagementModalProps, WORKFLOW_OPTIONS } from './models';

import './styles.scss';

const { useForm } = Form;

export const DriverManagementModal: FC<DriverManagementModalProps> = ({
  formValue,
  visible,
  modalType,
  toggleModal,
}: PropsWithChildren<DriverManagementModalProps>) => {
  const [form] = useForm();

  const driverManagementService = useMemo(
    () => HttpService.getHttpRequests(DriverManagementHttpService),
    [],
  );

  const add = async ({
    id,
    ...data
  }: AdminDriverManagementData): Promise<void> => {
    if (await isValidForm(form)) {
      await driverManagementService.addDriver(data);
      toggleModal(modalType);
    }
  };

  const edit = async (values: AdminDriverManagementData): Promise<void> => {
    if (await isValidForm(form)) {
      await driverManagementService.editDriver(values);
      toggleModal(modalType);
    }
  };

  useEffect(() => {
    if (visible) {
      form.setFieldsValue(formValue);
    }
  }, [visible, formValue, form]);

  return (
    <AddAndEditModal
      onOk={
        modalType === ModalMainTypes.Add
          ? (): Promise<void> => add(getFormData(formValue, form))
          : (): Promise<void> => edit(getFormData(formValue, form))
      }
      onCancel={(): void => {
        form.resetFields();
        toggleModal(modalType);
      }}
      afterClose={(): void => form.resetFields()}
      visible={visible}
      type={modalType}
      title="Driver"
      okText="Save"
      cancelText="Cancel"
      className="prov-admin-driver-management-modal"
      formProps={{
        labelCol: { span: 7 },
        wrapperCol: { span: 15 },
        form,
        initialValues: formValue,
      }}
    >
      <Input
        id="driverId"
        label="Driver ID"
        formItemProps={{
          rules: [
            { required: true, message: 'Driver ID is required!' },
            serverValidator(
              {
                method: 'getDriverValidation',
                errorMessage: 'Driver ID must be unique!',
              },
              DriverManagementHttpService,
              validationRequestParams(modalType, formValue, 'driverId'),
            ),
            ...WHITESPACE_INPUT_LIMIT_RULES,
          ],
        }}
      />
      <Input
        id="driverName"
        label="Driver Name"
        formItemProps={{
          rules: [
            { required: true, message: 'Driver Name is required!' },
            serverValidator(
              {
                method: 'getDriverValidation',
                errorMessage: 'Driver Name must be unique!',
              },
              DriverManagementHttpService,
              validationRequestParams(modalType, formValue, 'driverName'),
            ),
            ...WHITESPACE_INPUT_LIMIT_RULES,
          ],
        }}
      />
      <TextArea
        id="driverNotes"
        label="Driver Notes"
        formItemProps={{
          className: 'aligned-form-item',
        }}
      />
      <CheckboxGroup
        label="Workflow"
        id="workflow"
        elementProps={{
          options: WORKFLOW_OPTIONS,
        }}
        formItemProps={{ className: 'aligned-form-item' }}
      />
      <InputNumber
        id="priceToATandT"
        label="Price to AT&T"
        elementProps={{ formatter: DOLLAR_PREFIX }}
        formItemProps={{
          rules: [INPUT_POSITIVE_NUMBER_VALIDATOR],
        }}
      />
      <RadioGroup
        id="includedInBundlePricing"
        label="Included in Bundle Pricing"
        buttons={YES_NO_GROUP}
      />
    </AddAndEditModal>
  );
};
