import { ExportType, MainPaceTask, ProjectType } from 'providence-types';
import { OptionProps } from '@models/interfaces';

export const ACCOUNTING_BROWSE_EMPTY_INVOICE_DATES: Record<string, undefined> =
  {
    [MainPaceTask.LeasingComplete]: undefined,
    [MainPaceTask.SiteAcqComplete]: undefined,
    [MainPaceTask.CandidateApproved]: undefined,
    [MainPaceTask.ZoningAppDeemedComplete]: undefined,
  };

export const getInvoiceDatePaceTaskIdOptions = (
  projectTypeMode: ProjectType,
): OptionProps[] => [
  ...(projectTypeMode === ProjectType.NSB
    ? [
        {
          viewValue: 'SS010 Invoice Date',
          value: MainPaceTask.CandidateApproved,
        },
      ]
    : []),
  {
    viewValue: 'RE015 Invoice Date',
    value: MainPaceTask.LeasingComplete,
  },
  {
    viewValue: 'RE020 Invoice Date',
    value: MainPaceTask.SiteAcqComplete,
  },
  ...(projectTypeMode === ProjectType.NSB
    ? [
        {
          viewValue: 'RE066 Invoice Date',
          value: MainPaceTask.ZoningAppDeemedComplete,
        },
      ]
    : []),
];

export const EXPORT_TYPE_BUTTONS: OptionProps[] = [
  { viewValue: '.csv', value: ExportType.CSV },
  { viewValue: '.xlsx', value: ExportType.XLSX },
];
