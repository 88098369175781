import { Resolver } from '@wellyes/react-router-extended';
import { HttpService } from '@core/services';
import { ProjectCategoryHttpService, SiteHttpService } from '../services/http';

export class DetailsResolver implements Resolver {
  async resolve(): Promise<void> {
    await HttpService.getHttpRequests(
      ProjectCategoryHttpService,
    ).getProjectCategories();

    await HttpService.getHttpRequests(
      SiteHttpService,
    ).getFALocationNumberOptions();
  }
}
