import { ISelectorOption, IToDo, Undefinable } from 'providence-types';
import { mainDateFormat } from '@core/utils/methods';
import { DashboardItem } from '@models/interfaces';
import {
  assigneesIdsToNamedAssignees,
  projectCategoriesToStringArr,
} from '../../../helpers';

export const toDoSearcher = (
  data: DashboardItem<IToDo>[],
  searchValue: string,
  adminsList: ISelectorOption<string>[],
): DashboardItem<IToDo>[] => {
  const regExp = new RegExp(searchValue, 'gi');

  return !searchValue
    ? data
    : data.filter(
        ({
          notificationData: { date, taskNote, actionField, message, assignees },
          projectData: {
            projectType: { value },
            projectBundleId,
            site: { siteNumber },
            categories,
          },
        }: DashboardItem<IToDo>) =>
          [
            mainDateFormat(date),
            taskNote,
            actionField,
            message,
            ...assigneesIdsToNamedAssignees(assignees, adminsList),
            projectBundleId.toString(),
            siteNumber,
            value,
            ...projectCategoriesToStringArr(categories),
          ].some((item: Undefinable<string>) => item && regExp.test(item)),
      );
};
