import { OptionProps } from 'antd/es/mentions';
import { convertToDecimal } from '@core/utils/methods';
import { StoredRequest } from '@core/utils/store/stored-request-decorator';
import { ApiMethods } from '@models/enums';
import {
  ContactsData,
  MainBrowseFilter,
  PaginatedResponse,
  ProjectDetails,
  RequestContactsModalData,
  SiteBrowse,
} from '@models/interfaces';
import { FileType, IdResponse } from '@models/types';
import { BreadcrumbsActions, SiteActions } from '@store/actions';
import { ProjectsEffects } from '@store/effects';
import { CommonHttpService } from './common-http.service';

export class SiteHttpService extends CommonHttpService {
  @StoredRequest(SiteActions.getSiteBrowseDataAction)
  getSiteBrowse(
    params: MainBrowseFilter,
  ): Promise<PaginatedResponse<SiteBrowse>> {
    return this.http.callApi.get(`${this.apiUrl}/sites/browse`, null, {
      params,
    });
  }

  @StoredRequest(SiteActions.getFALocationNumberOptionsList)
  getFALocationNumberOptions(): Promise<OptionProps[]> {
    return this.http.callApi.get(
      `${this.apiUrl}/sites/fa-location-number-options-list`,
    );
  }

  @StoredRequest(SiteActions.createSiteAction)
  createSite(siteBrowse: SiteBrowse): Promise<string> {
    return this.http.callApi.post(
      `${this.apiUrl}/sites`,
      SiteHttpService._createUpdateSiteResult(siteBrowse),
    );
  }

  importSites(file: FileType): Promise<void> {
    return this.uploadFile(
      ApiMethods.Post,
      `${this.apiUrl}/sites/import`,
      file,
    ) as Promise<void>;
  }

  importUpdateSites(file: FileType): Promise<void> {
    return this.uploadFile(
      ApiMethods.Patch,
      `${this.apiUrl}/sites/import`,
      file,
    ) as Promise<void>;
  }

  @StoredRequest(SiteActions.updateSiteAction)
  async updateSite(id: string, siteBrowse: SiteBrowse): Promise<SiteBrowse> {
    const result = SiteHttpService._createUpdateSiteResult(siteBrowse);

    await this.http.callApi.patch(`${this.apiUrl}/sites`, result, {
      params: { id },
    });

    return { ...result, id };
  }

  @StoredRequest(SiteActions.getSiteAction, BreadcrumbsActions.site)
  getSite(id: string): Promise<SiteBrowse> {
    ProjectsEffects.setHoldOptions({} as ProjectDetails);

    return this.http.callApi.get(`${this.apiUrl}/sites/${id}`, null);
  }

  deleteSite(id: string): Promise<IdResponse> {
    return this.http.callApi.delete(`${this.apiUrl}/sites`, null, {
      params: { id },
    });
  }

  @StoredRequest(SiteActions.createSiteContactAction)
  async createSiteContact(
    siteId: string,
    values: Omit<RequestContactsModalData, 'id'>,
  ): Promise<ContactsData> {
    const { contactType, projectType, state, ...body } = values;

    const id = await this.http.callApi.post(
      `${this.apiUrl}/sites/contacts`,
      {
        ...body,
        state: state?.id,
        projectType: projectType?.id,
        contactType: contactType?.id,
      },
      {
        params: {
          siteId,
        },
      },
    );

    return {
      id,
      ...values,
    };
  }

  async validateFALocationNumber(
    FALocationNumber: number,
    id?: string,
  ): Promise<boolean> {
    return this.http.callApi.get(`${this.apiUrl}/sites/validation`, null, {
      params: { FALocationNumber, id },
    });
  }

  @StoredRequest(SiteActions.updateSiteContactAction)
  async updateSiteContact(
    values: RequestContactsModalData,
  ): Promise<RequestContactsModalData> {
    const { contactType, projectType, state, id, ...body } = values;

    await this.http.callApi.patch(
      `${this.apiUrl}/sites/contacts`,
      {
        ...body,
        state: state?.id,
        projectType: projectType?.id,
        contactType: contactType?.id,
      },
      {
        params: { contactId: id },
      },
    );

    return {
      ...values,
    };
  }

  @StoredRequest(SiteActions.deleteSiteContactAction)
  async deleteSiteContact(contactId: string): Promise<string> {
    await this.http.callApi.delete(`${this.apiUrl}/sites/contacts`, null, {
      params: { contactId },
    });

    return contactId;
  }

  private static _createUpdateSiteResult({
    latitude,
    longitude,
    ...body
  }: SiteBrowse): SiteBrowse {
    return {
      ...body,
      ...(latitude ? { latitude: convertToDecimal(`${latitude}`) } : {}),
      ...(longitude ? { longitude: convertToDecimal(`${longitude}`) } : {}),
    };
  }
}
