import { Undefinable } from 'providence-types';
import { StoredRequest } from '@core/utils/store/stored-request-decorator';
import {
  CreateEquipmentManufacturerResponse,
  EquipmentFullInfo,
  EquipmentManufacturerData,
  EquipmentManufacturerResponse,
  EquipmentsBrowseResponse,
  EquipmentTabData,
  EquipmentTypeData,
  MainBrowseFilter,
  RemoveEquipmentManufacturerResponse,
} from '@models/interfaces';
import { EquipmentActions } from '@store/actions';
import { CommonHttpService } from './common-http.service';

export class EquipmentHttpService extends CommonHttpService {
  // Equipment Management - Equipment Types
  @StoredRequest(EquipmentActions.getEquipmentTypes)
  getEquipmentTypes(
    equipmentManufacturerId?: string,
  ): Promise<EquipmentTypeData[]> {
    return this.http.callApi.get(`${this.apiUrl}/equipment-types`, null, {
      params: { equipmentManufacturerId },
    });
  }

  @StoredRequest(EquipmentActions.addEquipmentType)
  async addEquipmentType(name: string): Promise<EquipmentTypeData> {
    const id = await this.http.callApi.post(`${this.apiUrl}/equipment-types`, {
      name: name.trim(),
    });

    return { id, name, manufacturers: [] };
  }

  @StoredRequest(EquipmentActions.editEquipmentType)
  async editEquipmentType(
    equipmentType: EquipmentTypeData,
  ): Promise<EquipmentTypeData> {
    const { id: equipmentTypeId, name } = equipmentType;

    await this.http.callApi.patch(
      `${this.apiUrl}/equipment-types`,
      { name: name.trim() },
      { params: { equipmentTypeId } },
    );

    return equipmentType;
  }

  @StoredRequest(EquipmentActions.removeEquipmentType)
  async removeEquipmentType(equipmentTypeId: string): Promise<string> {
    await this.http.callApi.delete(`${this.apiUrl}/equipment-types`, null, {
      params: { equipmentTypeId },
    });

    return equipmentTypeId;
  }

  getEquipmentTypeValidation(id: string, value: string): Promise<boolean> {
    return this.http.callApi.get(
      `${this.apiUrl}/equipment-types/validation`,
      null,
      {
        params: { name: value.trim(), id },
      },
    );
  }

  // Equipment Management - Equipment Manufacturers
  @StoredRequest(EquipmentActions.getEquipmentManufacturers)
  getEquipmentManufacturers(
    equipmentTypeId: Undefinable<string>,
  ): Promise<EquipmentManufacturerData[]> {
    return this.http.callApi.get(
      `${this.apiUrl}/equipment-manufacturers`,
      null,
      {
        params: equipmentTypeId && { equipmentTypeId },
      },
    );
  }

  @StoredRequest(EquipmentActions.addEquipmentManufacturer)
  addEquipmentManufacturer(
    equipmentTypeId: string,
    name: string,
  ): Promise<CreateEquipmentManufacturerResponse> {
    return this.http.callApi.post(
      `${this.apiUrl}/equipment-manufacturers`,
      { name: name.trim() },
      {
        params: { equipmentTypeId },
      },
    );
  }

  @StoredRequest(EquipmentActions.editEquipmentManufacturer)
  editEquipmentManufacturer(
    equipmentManufacturer: EquipmentManufacturerData,
    equipmentTypeId: string,
  ): Promise<EquipmentManufacturerResponse> {
    const { id: equipmentManufacturerId, name } = equipmentManufacturer;

    return this.http.callApi.patch(
      `${this.apiUrl}/equipment-manufacturers`,
      { name: name.trim() },
      {
        params: {
          equipmentManufacturerId,
          ...(equipmentTypeId && { equipmentTypeId }),
        },
      },
    );
  }

  getEquipmentManufacturerValidation(
    requestIds: { equipmentTypeId: string; equipmentManufacturerId: string },
    value: string,
  ): Promise<EquipmentManufacturerResponse> {
    const { equipmentTypeId, equipmentManufacturerId } = requestIds;

    return this.http.callApi.get(
      `${this.apiUrl}/equipment-manufacturers/validation`,
      null,
      {
        params: {
          name: value.trim(),
          equipmentTypeId,
          ...(equipmentManufacturerId && { equipmentManufacturerId }),
        },
      },
    );
  }

  @StoredRequest(EquipmentActions.removeEquipmentManufacturer)
  removeEquipmentManufacturer(
    equipmentManufacturerId: string,
    equipmentTypeId: string,
  ): Promise<RemoveEquipmentManufacturerResponse> {
    return this.http.callApi.delete(
      `${this.apiUrl}/equipment-manufacturers`,
      null,
      {
        params: { equipmentManufacturerId, equipmentTypeId },
      },
    );
  }

  // Equipment Management - Equipments
  @StoredRequest(EquipmentActions.getEquipments)
  getEquipments(
    equipmentTypeId: Undefinable<string>,
    equipmentManufacturerId: Undefinable<string>,
  ): Promise<EquipmentTabData[]> {
    return this.http.callApi.get(`${this.apiUrl}/equipments`, null, {
      params: {
        ...(equipmentTypeId && { equipmentTypeId }),
        ...(equipmentManufacturerId && { equipmentManufacturerId }),
      },
    });
  }

  @StoredRequest(EquipmentActions.addEquipment)
  async addEquipment(
    equipmentTypeId: string,
    equipmentManufacturerId: string,
    equipment: Omit<EquipmentTabData, 'id'>,
  ): Promise<EquipmentTabData> {
    const id = await this.http.callApi.post(
      `${this.apiUrl}/equipments`,
      equipment,
      {
        params: { equipmentTypeId, equipmentManufacturerId },
      },
    );

    return { id, ...equipment };
  }

  @StoredRequest(EquipmentActions.editEquipment)
  async editEquipment(equipment: EquipmentTabData): Promise<EquipmentTabData> {
    const { id: equipmentId, ...otherValues } = equipment;

    await this.http.callApi.patch(`${this.apiUrl}/equipments`, otherValues, {
      params: { equipmentId },
    });

    return { id: equipmentId, ...otherValues };
  }

  @StoredRequest(EquipmentActions.removeEquipment)
  async removeEquipment(equipmentId: string): Promise<string> {
    await this.http.callApi.delete(`${this.apiUrl}/equipments`, null, {
      params: { equipmentId },
    });

    return equipmentId;
  }

  @StoredRequest(EquipmentActions.getEquipmentsByTypes)
  getEquipmentsByTypes(equipmentTypes: string[]): Promise<EquipmentFullInfo> {
    return this.http.callApi.get(
      `${this.apiUrl}/equipments/equipments-by-types`,
      null,
      {
        params: { equipmentTypes },
      },
    );
  }

  @StoredRequest(EquipmentActions.getEquipmentsByManufacturers)
  getEquipmentsByManufacturers(
    manufacturers: string[],
  ): Promise<EquipmentFullInfo> {
    return this.http.callApi.get(
      `${this.apiUrl}/equipments/equipments-by-manufacturers`,
      null,
      {
        params: { manufacturers },
      },
    );
  }

  // Equipment Browse - Equipments
  @StoredRequest(EquipmentActions.getEquipmentsByFilter)
  getEquipmentsByFilter(
    params: MainBrowseFilter,
  ): Promise<EquipmentsBrowseResponse> {
    return this.http.callApi.get(`${this.apiUrl}/equipments/browse`, null, {
      params,
    });
  }
}
