import { StoredRequest } from '@core/utils/store/stored-request-decorator';
import {
  EngineeringBrowseFilter,
  EngineeringWorkflowUpdateData,
  PaginatedResponse,
  SiteAuditBrowse,
} from '@models/interfaces';
import { ProjectDetailsWithoutCategories } from '@models/types';
import { BreadcrumbsActions, EngineeringActions } from '@store/actions';
import { ProjectCategoriesEffects, ProjectsEffects } from '@store/effects';
import { CommonHttpService } from './common-http.service';

export class EngineeringHttpService extends CommonHttpService {
  @StoredRequest(
    EngineeringActions.getEngineeringAction,
    BreadcrumbsActions.Engineering.engineering,
  )
  async getEngineering(
    id: string,
  ): Promise<Omit<ProjectDetailsWithoutCategories, 'paceTasks'>> {
    const response = await this.http.callApi.get(
      `${this.apiUrl}/engineering/${id}`,
    );

    return ProjectsEffects.setHoldOptions(
      ProjectsEffects.setCurrentProvidenceAgent(
        ProjectsEffects.setPaceTaskFields(
          ProjectsEffects.setPaceTasks(
            ProjectCategoriesEffects.setProjectCategories(response),
          ),
        ),
      ),
    );
  }

  @StoredRequest(EngineeringActions.updateEngineeringWorkflowAction)
  async updateEngineering(
    id: string,
    data: EngineeringWorkflowUpdateData,
  ): Promise<EngineeringWorkflowUpdateData> {
    await this.http.callApi.patch(`${this.apiUrl}/engineering`, data, {
      params: { id },
    });

    return data;
  }

  @StoredRequest(EngineeringActions.getEngineeringBrowseDataAction)
  getEngineeringBrowse(
    params: EngineeringBrowseFilter,
  ): Promise<PaginatedResponse<SiteAuditBrowse[]>> {
    return this.http.callApi.get(`${this.apiUrl}/engineering`, null, {
      params,
    });
  }
}
