import React, { FC } from 'react';
import { USER_ROLE_OPTIONS, YES_NO_GROUP } from '@models/constants';
import { OptionProps } from '@models/interfaces';
import { Autocomplete } from '@shared/modules';

export const Permissions: FC = () => (
  <>
    <Autocomplete
      id="canBeDeleted"
      label="Can Be Deleted"
      options={YES_NO_GROUP as OptionProps[]}
      formItemProps={{
        name: ['permissions', 'canBeDeleted'],
      }}
    />
    <Autocomplete
      id="editForecastedDate"
      elementProps={{ mode: 'multiple' }}
      label="Edit Forecasted Date"
      options={USER_ROLE_OPTIONS}
      formItemProps={{
        name: ['permissions', 'editForecastedDate'],
      }}
    />
    <Autocomplete
      id="editActualDate"
      elementProps={{ mode: 'multiple' }}
      label="Edit Actual Date"
      options={USER_ROLE_OPTIONS}
      formItemProps={{
        name: ['permissions', 'editActualDate'],
      }}
    />
    <Autocomplete
      id="editPACEForecastedDate"
      elementProps={{ mode: 'multiple' }}
      label="Edit PACE Forecasted Date"
      options={USER_ROLE_OPTIONS}
      formItemProps={{
        name: ['permissions', 'editPACEForecastedDate'],
      }}
    />
    <Autocomplete
      id="editPACEActualDate"
      elementProps={{ mode: 'multiple' }}
      label="Edit PACE Actual Date"
      options={USER_ROLE_OPTIONS}
      formItemProps={{
        name: ['permissions', 'editPACEActualDate'],
      }}
    />
  </>
);
