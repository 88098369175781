import React, { FC, PropsWithChildren, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Prompt, RouterProps } from 'react-router';
import { BrowserRouter, useHistory, useLocation } from 'react-router-dom';
import { Tabs as AntTabs } from 'antd';
import { useDidUpdateEffect } from '@core/utils/hooks';
import { Tab } from '@models/classes';
import { promptCallback } from '@models/constants';
import { CommonActions } from '@store/actions';
import { CommonSelectors } from '@store/selectors';
import { ProjectCategory } from '../project-category';
import { getProjectCategoriesVisibility } from './models/constants';
import { TabsProps } from './models/interfaces';

const { TabPane } = AntTabs;

const PrivateTabs: FC<TabsProps & RouterProps> = ({
  tabs,
  params,
  destroyInactiveTabPane = false,
  history: parentHistory,
  animated = true,
  manual,
}: PropsWithChildren<TabsProps & RouterProps>) => {
  const ref = useRef<AntTabs>(null);
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const currentPath: string = location.pathname;

  const hasUnsubmittedData = useSelector(CommonSelectors.getHasUnsubmittedData);

  const changeTab = (path: string): void => {
    const selectedTab = tabs.find(
      (tab: Tab) => tab.replacePath(params) === path,
    );

    if (selectedTab?.isLink) {
      parentHistory.replace(path);

      return;
    }

    history.replace(path);
  };

  useEffect(() => {
    if (
      tabs.length &&
      !tabs.find((tab: Tab) => tab.replacePath(params) === currentPath)
    ) {
      const [firstTab] = tabs;

      changeTab(firstTab.replacePath(params));
    }
  });

  useEffect(() => {
    dispatch(CommonActions.setRoutePath.done(currentPath));

    return () => {
      dispatch(CommonActions.setRoutePath.done(''));
    };
  }, [currentPath]);

  useDidUpdateEffect(() => {
    if (ref.current && manual) {
      ref.current.handleChange(manual.activeKey);
    }
  }, [manual]);

  return (
    <AntTabs
      animated={animated}
      ref={ref}
      activeKey={currentPath}
      onChange={changeTab}
      destroyInactiveTabPane={destroyInactiveTabPane}
      tabBarStyle={{ display: manual ? 'none' : 'block' }}
    >
      {tabs.map((tab: Tab) => {
        const path = tab.replacePath(params);

        return (
          <TabPane key={path} tab={tab.name}>
            {!manual &&
              (getProjectCategoriesVisibility(location.pathname) ? (
                <ProjectCategory />
              ) : null)}
            <Prompt when={hasUnsubmittedData} message="" />
            {React.createElement(tab.component, {
              isActive: currentPath === path,
              history: parentHistory,
              params,
              ...tab.componentProps,
            })}
          </TabPane>
        );
      })}
    </AntTabs>
  );
};

export const Tabs: FC<TabsProps> = (props: PropsWithChildren<TabsProps>) => {
  const history = useHistory();

  return (
    <BrowserRouter getUserConfirmation={promptCallback}>
      <PrivateTabs history={history} {...props} />
    </BrowserRouter>
  );
};
