import { NotifyType } from 'providence-types';

export const getCurrentNotifyType = (value: NotifyType): string => {
  switch (value) {
    case NotifyType.Role:
      return 'Roles';
    case NotifyType.User:
      return 'Users';
    default:
      throw new Error('Incorrect value');
  }
};
