import React, { FC, ReactText, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Col, Form, Row } from 'antd';
import { Store } from 'antd/es/form/interface';
import { HttpService } from '@core/services';
import { SiteAuditHttpService } from '@core/services/http';
import { useDidUpdateEffect } from '@core/utils/hooks';
import {
  createOnChangeModalVisibility,
  momentizeRangePickerValues,
} from '@core/utils/methods';
import {
  BROWSE_DEFAULT_FILTER_VALUE,
  browseFiltersChange,
  tableCount,
} from '@models/constants';
import { AdditionalFilter, SiteAuditBrowseFilter } from '@models/interfaces';
import { MainSiteAuditBrowseFilter, SwitchType } from '@models/types';
import { AdditionalFilters } from '@shared/components';
import { DatePicker, PrimaryButton } from '@shared/modules';
import { BrowsePage } from '@shared/modules/browse-page';
import { SiteAuditSelectors } from '@store/selectors';
import {
  ADDITIONAL_FILTERS,
  SITE_AUDIT_COLUMNS,
  SITE_AUDIT_DEFAULT_BROWSE_CONFIGURE_FILTER,
  SITE_AUDIT_RANGE_PICKERS,
} from './models/constants';
import { Filters } from './filters';

import './styles.scss';

const { useForm } = Form;

export const SiteAuditBrowse: FC = () => {
  const [form] = useForm();
  const [scheduleForm] = useForm();

  const siteAuditService = useMemo(
    () => HttpService.getHttpRequests(SiteAuditHttpService),
    [],
  );

  const siteAuditBrowse = useSelector(
    SiteAuditSelectors.getSiteAuditBrowseData,
  );
  const [selectedRows, setSelectedRows] = useState<ReactText[]>([]);
  const [activeFilters, setActiveFilters] = useState<string[]>([]);
  const [scheduleDate, setScheduleDate] = useState<Store>({ date: null });
  const [filterValue, setFilterValue] = useState<MainSiteAuditBrowseFilter>({
    ...BROWSE_DEFAULT_FILTER_VALUE,
    showCompletedSiteAudits: false,
  });
  const [configureFilter, setConfigureFilter] = useState<SiteAuditBrowseFilter>(
    SITE_AUDIT_DEFAULT_BROWSE_CONFIGURE_FILTER,
  );

  const rowSelection = {
    selectedRowKeys: selectedRows,
    onChange: (rows: ReactText[]): void => setSelectedRows(rows),
  };

  useDidUpdateEffect(() => {
    siteAuditService.getSiteAuditBrowse(
      momentizeRangePickerValues(filterValue, SITE_AUDIT_RANGE_PICKERS, true),
    );
  }, [filterValue]);

  useEffect(
    () =>
      setFilterValue((prevState: MainSiteAuditBrowseFilter) => ({
        ...prevState,
        ...ADDITIONAL_FILTERS.reduce(
          (
            additionalFilters: Record<string, boolean>,
            filter: AdditionalFilter,
          ) => ({
            ...additionalFilters,
            [filter.key]: activeFilters.includes(filter.key),
          }),
          {},
        ),
      })),
    [activeFilters],
  );

  const onSearchChanges = (search: string): void => {
    setFilterValue((prevState: MainSiteAuditBrowseFilter) => ({
      ...prevState,
      search,
    }));
  };

  const onApplyConfigureFilter = (): void => {
    setFilterValue((prevState: MainSiteAuditBrowseFilter) => ({
      ...prevState,
      ...configureFilter,
    }));
  };

  const onOnlyCompletedSwitch = (value: boolean): void =>
    setFilterValue((prevState: MainSiteAuditBrowseFilter) => ({
      ...prevState,
      showCompletedSiteAudits: value as SwitchType,
    }));

  const onSubmitDateScheduleDate = (): void => {
    siteAuditService.updateSiteAuditBrowse(
      { ...filterValue, projectIds: selectedRows },
      scheduleDate as { date: string },
    );
    setScheduleDate({ date: null });
  };

  const onChangeConfigureFilter = (filter: SiteAuditBrowseFilter): void =>
    setConfigureFilter((prevState: SiteAuditBrowseFilter) => ({
      ...prevState,
      ...filter,
    }));

  return (
    <BrowsePage
      columns={SITE_AUDIT_COLUMNS}
      data={siteAuditBrowse?.items}
      switchInfo="Show Completed Site Audits"
      filters={
        <Filters
          formProps={{ form }}
          value={configureFilter}
          onChanges={onChangeConfigureFilter}
        />
      }
      onChangeFilterModalVisibility={createOnChangeModalVisibility(
        form,
        configureFilter,
        momentizeRangePickerValues(filterValue, SITE_AUDIT_RANGE_PICKERS),
        setConfigureFilter,
      )}
      tableProps={{
        rowSelection,
        pagination: {
          total: siteAuditBrowse?.total,
        },
        module: 'siteAudit',
      }}
      className="prov-site-audit-browse"
      place={tableCount(true)}
      filterFormProps={{ labelCol: { span: 11 }, form }}
      cancelButtonProps={{
        onClick: (): void => {
          setConfigureFilter(SITE_AUDIT_DEFAULT_BROWSE_CONFIGURE_FILTER);
          form.resetFields();
        },
      }}
      onChange={browseFiltersChange(setFilterValue)}
      onSearchChanges={onSearchChanges}
      onApplyFilter={onApplyConfigureFilter}
      validation
      onSwitch={onOnlyCompletedSwitch}
      additional={
        <>
          <Row>
            <AdditionalFilters
              filters={ADDITIONAL_FILTERS}
              onChange={(filters: string | string[]): void =>
                setActiveFilters(filters as string[])
              }
              activeFilters={activeFilters}
            />
          </Row>
          <Row className="prov-site-audit-browse__schedule-form">
            <Col>
              <Form
                onValuesChange={(value: Store): void => setScheduleDate(value)}
                form={scheduleForm}
              >
                <DatePicker id="date" label="Schedule Site Walk for Selected" />
              </Form>
            </Col>
            <Col>
              <PrimaryButton
                title="Submit"
                disabled={scheduleDate.date === null || !selectedRows.length}
                onClick={onSubmitDateScheduleDate}
              />
            </Col>
          </Row>
        </>
      }
    />
  );
};
