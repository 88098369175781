import { createSelector } from 'reselect';
import { AppState } from '../reducers';
import { LeasingState } from '../reducers/leasing.reducer';

const leasingState = (state: AppState): LeasingState => state.leasing;
const appState = (state: AppState): AppState => state;

export namespace LeasingSelectors {
  export const getLeasingBrowseInfo = createSelector(
    [leasingState],
    (leasing: LeasingState) => leasing.leasingBrowseData,
  );

  export const getLeasingSite = createSelector(
    [leasingState],
    (leasing: LeasingState) => leasing.leasingSite,
  );

  export const getLeasingSummary = createSelector(
    [leasingState],
    ({
      leasingInformation,
      leasingSite,
      currentProject,
      leasingAgents,
    }: LeasingState) => ({
      leasingInformation,
      leasingSite,
      currentProject,
      leasingAgents,
    }),
  );

  export const getLeasingWorkflowInfo = createSelector(
    [leasingState],
    ({ leasingInformation, currentProject }) => ({
      leasingInformation,
      currentProject,
    }),
  );

  export const getLeasingEquipmentSectionData = createSelector(
    [appState],
    ({
      equipment: { allUsedEquipments },
      leasing: {
        leasingEquipmentData: {
          curPriorTableData,
          scopingTableData,
          curFinalTableData,
        },
      },
    }: AppState) => ({
      allUsedEquipments,
      curPriorTableData,
      scopingTableData,
      curFinalTableData,
    }),
  );

  export const getLeasingEquipmentData = createSelector(
    [leasingState],
    ({
      equipmentTypeNames,
      currentProject: { id },
      leasingEquipmentData: {
        curPriorTableData,
        curFinalTableData,
        scopingTableData,
        priorTableData,
        finalTableData,
        scopingNotes,
        priorLeasingNotes,
        finalLeasingNotes,
      },
    }: LeasingState) => ({
      equipmentTypeNames,
      id,
      curPriorTableData,
      curFinalTableData,
      priorTableData,
      finalTableData,
      scopingTableData,
      scopingNotes,
      priorLeasingNotes,
      finalLeasingNotes,
    }),
  );

  export const getProjectType = createSelector(
    [leasingState],
    (state: LeasingState) => state.currentProject.projectType.value,
  );

  export const isFetching = createSelector(
    [leasingState],
    (state: LeasingState) => state.isFetching,
  );
}
