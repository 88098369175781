import { Schedule } from '@models/interfaces';

export const SCHEDULE_DEFAULT_VALUES: Partial<Schedule> = {
  _id: undefined,
  startingField: undefined,
  startingDateType: undefined,
  endingField: undefined,
  endingDateType: undefined,
  operator: undefined,
  daysCount: undefined,
  notificationType: undefined,
  notifyType: undefined,
  roles: undefined,
  userIds: undefined,
  engineeringManagerType: undefined,
  towerOwners: [],
  message: undefined,
  autoWarning: false,
  warningDaysCount: undefined,
  warningMessage: undefined,
  notifyAdmin: false,
  redirectLinkKey: undefined,
};
