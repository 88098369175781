// eslint-disable-next-line import/no-extraneous-dependencies
import { isEqual } from 'lodash';

export const formDiffs = <I>(initial: I, final: I): I => {
  const keys = Object.keys(initial) as Array<keyof I>;

  return initial && keys.length
    ? keys.reduce((acc: I, key: keyof I) => {
        if (!isEqual(initial[key], final[key])) {
          acc[key] = final[key];
        }

        return initial && Object.keys(initial).length ? acc : final;
      }, {} as I)
    : final;
};
