import React, {
  FC,
  PropsWithChildren,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'antd';
import { MainPaceTask, SubPage, Tab } from 'providence-types';
import { Store } from 'rc-field-form/lib/interface';
import { HttpService, ObjectComparatorService } from '@core/services';
import { PermittingHttpService } from '@core/services/http';
import { useDidUpdateEffect, useSaveChanged } from '@core/utils/hooks';
import {
  canUserEditByProjectStatusAndRoles,
  getIdParamFromUrl,
  isValidForm,
  momentizeObjectDates,
} from '@core/utils/methods';
import { JURISDICTION_DATES_FOR_MOMENTIZE } from '@models/constants';
import { PermittingDetailsJurisdictionPermitting } from '@models/interfaces';
import { EditablePage, NotificationsLoader } from '@shared/components';
import { PrimaryButton } from '@shared/modules';
import { CommonActions } from '@store/actions';
import { ProjectsEffects } from '@store/effects';
import {
  PaceTasksSelectors,
  PermittingSelectors,
  ProjectSelectors,
  UserSelectors,
} from '@store/selectors';
import {
  JurisdictionAuditPermittingSection,
  JurisdictionAuditSummarySection,
  JurisdictionAuditZoningSection,
  NoJurisdiction,
} from './components';
import { dataToBodyMapper } from './helpers';
import { JurisdictionAuditProps } from './models';

import './styles.scss';

const { useForm } = Form;

const JurisdictionAudit: FC<JurisdictionAuditProps> = ({
  isEditing,
  permissions,
  data: jurisdictionData,
  toggleEditing,
}: PropsWithChildren<JurisdictionAuditProps>) => {
  const dispatch = useDispatch();

  const [summaryForm] = useForm();
  const [permittingForm] = useForm();
  const [zoningForm] = useForm();

  const [workflowForm, setWorkflowForm] = useState<Array<Store>>([]);
  const [initialForm, setInitialForm] = useState<Array<Store>>([]);

  const arraysCompare = ObjectComparatorService.arraysCompare(
    initialForm,
    workflowForm,
  );

  const { permittingUsers } = useSelector(
    PermittingSelectors.getPermittingDetailsData(['permittingUsers']),
  );
  const { JurisdictionAuditComplete } = useSelector(
    PaceTasksSelectors.getMainPaceTaskDates(
      'actualDate',
      'JurisdictionAuditComplete',
    ),
  );

  const [submitDisabled, setSubmitDisabled] = useState<boolean>(false);

  const { zoning, contacts, ...summaryDefaultData } = useMemo(
    () => jurisdictionData!,
    [jurisdictionData],
  );

  const { permittingTypes, ...permittingDefaultData } =
    (jurisdictionData?.permitting ??
      {}) as PermittingDetailsJurisdictionPermitting;

  useEffect(
    () => (): void => {
      dispatch(CommonActions.setHasUnsubmittedData.done(false));
    },

    [],
  );

  useDidUpdateEffect(() => {
    dispatch(CommonActions.setHasUnsubmittedData.done(!arraysCompare));
  }, [arraysCompare]);

  const onSubmit = async (): Promise<void> => {
    if (
      (await isValidForm(summaryForm)) &&
      (await isValidForm(zoningForm)) &&
      (await isValidForm(permittingForm))
    )
      try {
        const [projectId] = getIdParamFromUrl();

        setSubmitDisabled(true);

        await HttpService.getHttpRequests(
          PermittingHttpService,
        ).updatePermittingJurisdictionAuditData(
          projectId,
          dataToBodyMapper(
            initialForm,
            workflowForm,
            jurisdictionData!.id,
            jurisdictionData!.zoning?.id,
            jurisdictionData!.permitting?.id,
          ),
        );

        ProjectsEffects.updatePaceTaskFieldsByConfig(workflowForm[0], [
          {
            dateType: 'actualDate',
            task: MainPaceTask.JurisdictionAuditComplete,
            field: 'jurisdictionAuditComplete',
          },
        ]);

        toggleEditing?.();

        setSubmitDisabled(false);

        setInitialForm(workflowForm);

        if (isEditing) {
          toggleEditing?.();
        }

        NotificationsLoader.notificationSuccess(
          `Information has been updated!`,
        );
      } catch (e) {
        console.error(e);
      }
  };

  const onCancel = (): void => {
    const [summaryData, zoningData, permittingData] = initialForm;

    summaryForm?.setFieldsValue(
      momentizeObjectDates(summaryData || [], ['jurisdictionAuditComplete']),
    );
    zoningForm?.setFieldsValue(
      momentizeObjectDates(zoningData || [], JURISDICTION_DATES_FOR_MOMENTIZE),
    );
    permittingForm?.setFieldsValue(
      momentizeObjectDates(
        permittingData || [],
        JURISDICTION_DATES_FOR_MOMENTIZE,
      ),
    );

    setWorkflowForm(initialForm);

    if (isEditing) {
      toggleEditing?.();
    }
  };

  const onValuesChange = (): void => {
    if (Object.keys(summaryForm.getFieldsValue()).length) {
      const formsData = [
        summaryForm.getFieldsValue(),
        zoningForm.getFieldsValue(),
        permittingForm.getFieldsValue(),
      ];

      setWorkflowForm(formsData);

      if (!Object.keys(initialForm).length) {
        setInitialForm(formsData);
      }
    }
  };

  useDidUpdateEffect(() => {
    onValuesChange();
  }, [isEditing]);

  useSaveChanged(isEditing, onSubmit, onCancel);

  return (
    <main className="prov-permitting-jurisdiction-audit">
      <JurisdictionAuditSummarySection
        jurisdictionAuditSummaryFields={
          permissions!.jurisdictionAuditSummaryFields
        }
        isEditing={isEditing}
        onValuesChange={onValuesChange}
        data={{
          ...summaryDefaultData,
          jurisdictionAuditComplete: JurisdictionAuditComplete || null,
        }}
        permittingUsers={permittingUsers}
        form={summaryForm}
      />
      <JurisdictionAuditZoningSection
        jurisdictionAuditZoningFields={
          permissions!.jurisdictionAuditZoningFields
        }
        isEditing={isEditing}
        onValuesChange={onValuesChange}
        data={zoning}
        form={zoningForm}
        hasZoning={jurisdictionData?.zoning !== null}
      />
      <JurisdictionAuditPermittingSection
        jurisdictionAuditPermittingFields={
          permissions!.jurisdictionAuditPermittingFields
        }
        isEditing={isEditing}
        onValuesChange={onValuesChange}
        data={permittingDefaultData}
        tableData={permittingTypes}
        form={permittingForm}
        hasPermitting={jurisdictionData?.permitting !== null}
      />
      {isEditing && (
        <section className="prov-permitting-jurisdiction-audit__actions">
          <PrimaryButton
            title="Submit"
            type="primary"
            onClick={onSubmit}
            disabled={arraysCompare || submitDisabled}
          />
          <PrimaryButton title="Cancel" type="default" onClick={onCancel} />
        </section>
      )}
    </main>
  );
};

export const PermittingJurisdictionAudit: FC = () => {
  const permissions = useSelector(UserSelectors.getPermittingBrowsePermissions);

  const { site } = useSelector(
    PermittingSelectors.getPermittingDetailsData(['site']),
  );

  const projectType = useSelector(PermittingSelectors.getProjectType);
  const projectTypePermission = useSelector(
    UserSelectors.canEditProjectByProjectType(projectType),
  );
  const canEdit = useSelector(
    UserSelectors.canEdit(
      'permitting',
      SubPage.PermittingBrowse,
      Tab.JurisdictionAudit,
    ),
  );

  const userRoles = useSelector(UserSelectors.getUserRoles);
  const projectStatus = useSelector(ProjectSelectors.getProjectStatus);

  return (
    <EditablePage
      editableComponent={
        site?.jurisdiction ? JurisdictionAudit : NoJurisdiction
      }
      permissions={permissions}
      canUserEdit={
        !!site?.jurisdiction &&
        canEdit &&
        projectTypePermission &&
        canUserEditByProjectStatusAndRoles(projectStatus, userRoles)
      }
      data={site?.jurisdiction}
    />
  );
};
