import React, { ReactNode } from 'react';
import { ColumnsType } from 'antd/es/table';
import {
  IRequiresAttention,
  ISelectorOption,
  NotificationType,
} from 'providence-types';
import { projectCategoryRender, tableSorter } from '@core/utils/methods';
import { actionButtons } from '@models/constants';
import { DashboardItem, DashboardProjectData } from '@models/interfaces';
import { ColumnsFunc } from '@models/types';
import { ReadOnlyDate } from '@shared/modules';
import { assigneesRenderer } from '../../../helpers';

export const REQUIRES_ATTENTION_COLUMNS = (
  adminsList: ISelectorOption<string>[],
  editFunc: ColumnsFunc<DashboardItem<IRequiresAttention>>,
): ColumnsType => [
  {
    dataIndex: 'notificationData',
    key: 'notificationType',
    width: 45,
    sorter: tableSorter(['notificationData', 'notificationType']),
    defaultSortOrder: 'descend',
    className: 'empty-header',
    render: ({ notificationType }: IRequiresAttention): ReactNode => (
      <i
        className={`circle circle_${
          notificationType === NotificationType.Info ? 'gray' : 'high'
        }`}
      />
    ),
  },
  {
    title: 'Date',
    dataIndex: 'notificationData',
    key: 'date',
    sorter: tableSorter(['notificationData', 'date'], true),
    render: ({ date }: IRequiresAttention): ReactNode => (
      <ReadOnlyDate date={date} utc />
    ),
  },
  {
    title: 'Site Number',
    dataIndex: 'projectData',
    key: 'siteNumber',
    sorter: tableSorter(['projectData', 'site', 'siteNumber']),
    render: ({ site }: DashboardProjectData): ReactNode => site?.siteNumber,
  },
  {
    title: 'Project Bundle ID',
    dataIndex: 'projectData',
    key: 'projectBundleId',
    sorter: tableSorter(['projectData', 'projectBundleId']),
    render: ({ projectBundleId }: DashboardProjectData): ReactNode =>
      projectBundleId,
  },
  {
    title: 'PACE Project',
    dataIndex: 'projectData',
    key: 'PACEProjectNumber',
    width: 150,
    render: ({ categories }: DashboardProjectData): ReactNode =>
      projectCategoryRender({
        field: 'PACEProjectNumber',
      })(categories),
  },
  {
    title: 'Oracle Project',
    dataIndex: 'projectData',
    key: 'oracleProjectNumber',
    width: 150,
    render: ({ categories }: DashboardProjectData): ReactNode =>
      projectCategoryRender({
        field: 'oracleProjectNumber',
      })(categories),
  },
  {
    title: 'Action Required',
    dataIndex: 'notificationData',
    key: 'message',
    sorter: tableSorter(['notificationData', 'message']),
    render: ({ message }: IRequiresAttention): ReactNode => message,
  },
  {
    title: 'Action Field',
    dataIndex: 'notificationData',
    key: 'actionField',
    sorter: tableSorter(['notificationData', 'actionField']),
    render: ({ actionField }: IRequiresAttention): ReactNode => actionField,
  },
  {
    title: 'Due Date',
    dataIndex: 'notificationData',
    key: 'dueDate',
    sorter: tableSorter(['notificationData', 'dueDate'], true),
    render: ({ dueDate }: IRequiresAttention): ReactNode => (
      <ReadOnlyDate date={dueDate} />
    ),
  },
  {
    title: 'Past Due Date',
    dataIndex: 'notificationData',
    sorter: tableSorter(['notificationData', 'pastDueDate']),
    render: ({ pastDueDate }: IRequiresAttention): ReactNode =>
      `${pastDueDate} day(s)`,
  },
  {
    title: 'Task Note',
    dataIndex: 'notificationData',
    key: 'taskNote',
    sorter: tableSorter(['notificationData', 'taskNote']),
    render: ({ taskNote }: IRequiresAttention): ReactNode => taskNote,
  },
  {
    title: 'Assignee',
    dataIndex: 'notificationData',
    key: 'assignee',
    render: assigneesRenderer(adminsList),
  },
  {
    title: 'Project Type',
    dataIndex: 'projectData',
    key: 'projectType',
    width: 140,
    sorter: tableSorter(['projectData', 'projectType', 'value']),
    render: ({ projectType }: DashboardProjectData): ReactNode =>
      projectType?.value,
  },
  actionButtons({ editFunc, width: 86 }),
];
