import { DetailsResolver } from '@core/resolvers';
import { HttpService } from '@core/services';
import { ProjectHttpService } from '@core/services/http';
import { getIdParamFromUrl } from '@core/utils/methods';

export class ProjectDetailsResolver extends DetailsResolver {
  async resolve(): Promise<void> {
    await super.resolve();

    const [id] = getIdParamFromUrl();

    await HttpService.getHttpRequests(ProjectHttpService).getProject(id);
  }
}
