import moment, { Moment } from 'moment';
import { FRONTEND_DATE_FORMAT } from 'providence-types';
import { MomentNull } from '@models/types';

export class ObjectComparatorService {
  static objectsCompare<T extends Record<string, any>>(
    obj1: T,
    obj2: T,
    nullAndUndefinedNotStrict?: boolean,
  ): boolean {
    const keys = Object.keys(obj1);

    return keys.every((key: string) => {
      switch (true) {
        case obj1[key] instanceof moment:
          return this.dateCompare(obj1[key], obj2[key]);
        case Array.isArray(obj1[key]):
          return this.arraysCompare(obj1[key], obj2[key]);
        case this.isObjects(obj1[key], obj2[key]):
          return this.objectsCompare(obj1[key], obj2[key]);
        case nullAndUndefinedNotStrict &&
          (obj1[key] === null || obj1[key] === undefined) &&
          (obj2[key] === null || obj2[key] === undefined):
          return true;
        default:
          return obj1[key] === obj2[key];
      }
    });
  }

  static arraysCompare<T>(arr1: T[], arr2: T[]): boolean {
    const arr1Sorted = [...arr1].sort();
    const arr2Sorted = [...arr2].sort();

    return (
      arr1Sorted.length === arr2Sorted.length &&
      arr1Sorted.every((element: T, index: number): boolean =>
        this.isObjects(element, arr2Sorted[index])
          ? this.objectsCompare(element, arr2Sorted[index])
          : element === arr2Sorted[index],
      )
    );
  }

  static isObjects<T>(element1: T, element2: T): boolean {
    return (
      typeof element1 === 'object' &&
      element1 !== null &&
      typeof element2 === 'object' &&
      element2 !== null
    );
  }

  static dateCompare(date1: Moment, date2: MomentNull): boolean {
    if (!date2) {
      return false;
    }

    return (
      moment(date1).format(FRONTEND_DATE_FORMAT) ===
      moment(date2).format(FRONTEND_DATE_FORMAT)
    );
  }
}
