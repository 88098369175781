import React, { FC, PropsWithChildren, useEffect } from 'react';
import { Col, Form, Row } from 'antd';
import { InformationProps } from '@models/interfaces';
import { Panel } from '@shared/components';
import { Input, PrimaryButton } from '@shared/modules';

const { useForm } = Form;

export const ProjectInformation: FC<InformationProps> = ({
  className,
  data,
  expectedId,
}: PropsWithChildren<InformationProps>) => {
  const [form] = useForm();

  useEffect(() => {
    const {
      projectBundleId,
      projectType,
      projectSubtype,
      projectStatus,
      progressStatus,
      civilVendor,
      AEVendor,
      AEMountVendor,
      notes,
    } = data;

    form.setFieldsValue({
      projectBundleId,
      projectType: projectType?.value,
      projectStatus: projectStatus?.value,
      progressStatus: progressStatus?.value,
      civilVendor: civilVendor?.value,
      AEVendor: AEVendor?.value,
      AEMountVendor: AEMountVendor?.value,
      projectSubtype: projectSubtype?.value,
      notes,
    });
  }, [form, data]);

  return (
    <Panel header="Project Information" className={className}>
      <Form labelCol={{ span: 12 }} wrapperCol={{ span: 12 }} form={form}>
        <Row>
          <Col span="12">
            <Input
              id="projectBundleId"
              label="Project Bundle ID"
              isEditing={false}
            />
            <Input id="projectType" label="Project Type" isEditing={false} />
            <Input
              id="projectStatus"
              label="Project Status"
              isEditing={false}
            />
            <Input
              id="progressStatus"
              label="Progress Status"
              isEditing={false}
            />
          </Col>
          <Col span="12">
            <Input id="civilVendor" label="Civil Vendor" isEditing={false} />
            <Input
              id="projectSubtype"
              label="Project Subtype"
              isEditing={false}
            />
            <Input id="notes" label="Project Notes" isEditing={false} />
          </Col>
        </Row>
        <Row className="action-buttons">
          <PrimaryButton
            title="View details"
            target="_blank"
            href={`/project/${expectedId}/details`}
            type="primary"
          />
        </Row>
      </Form>
    </Panel>
  );
};
