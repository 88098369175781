import React, { ReactNode } from 'react';
import { FileSearchOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import { IReceivedDirectMessage, ISelectorOption } from 'providence-types';
import {
  findFromSelectorOptions,
  mainDateFormat,
  tableSorter,
} from '@core/utils/methods';
import { DashboardItem, DashboardProjectData } from '@models/interfaces';

export const DIRECT_MESSAGE_COLUMNS = (
  usersList: ISelectorOption<string>[],
  showMessage: (dashboardItem: DashboardItem<IReceivedDirectMessage>) => void,
): ColumnsType =>
  [
    {
      dataIndex: 'notificationData',
      key: 'status',
      width: 45,
      sorter: tableSorter(['notificationData', 'completed']),
      defaultSortOrder: 'ascend',
      className: 'empty-header',
      render: ({ completed }: IReceivedDirectMessage): ReactNode => (
        <i className={`circle circle_${completed ? 'gray' : 'green'}`} />
      ),
    },
    {
      title: 'Sender',
      dataIndex: 'notificationData',
      key: 'senderId',
      width: 250,
      render: ({ senderId }: IReceivedDirectMessage): ReactNode =>
        findFromSelectorOptions(senderId, usersList),
    },
    {
      title: 'Area',
      dataIndex: 'notificationData',
      key: 'area',
      width: 250,
      sorter: tableSorter(['notificationData', 'areaKey']),
      render: ({ areaKey }: IReceivedDirectMessage): ReactNode => areaKey,
    },
    {
      title: 'Project Bundle Id',
      dataIndex: 'projectData',
      key: 'projectBundleId',
      width: 250,
      sorter: tableSorter(['projectData', 'projectBundleId']),
      render: ({ projectBundleId }: DashboardProjectData): ReactNode =>
        projectBundleId,
    },
    {
      title: 'Site Number',
      dataIndex: 'projectData',
      key: 'siteNumber',
      width: 250,
      sorter: tableSorter(['projectData', 'site', 'siteNumber']),
      render: ({ site }: DashboardProjectData): ReactNode => site?.siteNumber,
    },
    {
      title: 'Message',
      dataIndex: 'notificationData',
      key: 'message',
      sorter: tableSorter(['notificationData', 'message']),
      className: 'overflow',
      render: ({ message }: IReceivedDirectMessage): ReactNode => message,
    },
    {
      title: 'Created At',
      dataIndex: 'notificationData',
      key: 'createdAt',
      width: 150,
      sorter: tableSorter(['notificationData', 'createdAt'], true),
      render: ({ createdAt }: IReceivedDirectMessage): ReactNode =>
        mainDateFormat(createdAt),
    },
    {
      key: 'action-button',
      width: 56,
      render: (
        dashboardItem: DashboardItem<IReceivedDirectMessage>,
      ): ReactNode => (
        <FileSearchOutlined
          className="view-icon"
          onClick={(): void => showMessage(dashboardItem)}
        />
      ),
    },
  ] as ColumnsType;
