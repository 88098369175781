import React, { FC, PropsWithChildren, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Col, Form, Row } from 'antd';
import { DropDownSelectorValues, MainPaceTask } from 'providence-types';
import { momentizeObjectDates } from '@core/utils/methods';
import { YES_NO_GROUP } from '@models/constants';
import {
  EngineeringRegulatoryCompliance,
  FormProps,
  OptionProps,
} from '@models/interfaces';
import { Panel } from '@shared/components';
import {
  Autocomplete,
  DatePicker,
  Input,
  InputNumber,
  RadioGroup,
  TextArea,
} from '@shared/modules';
import { DDVSelectors, PaceTasksSelectors } from '@store/selectors';
import { REGULATORY_COMPLIANCE_INFO_DATES } from '../../models/constants';

export const RegulatoryCompliancePanel: FC<FormProps> = ({
  form,
  isEditing,
  data,
  permissions: {
    SOWNotes,
    SOWSubmit,
    EHSApproved,
    EHSRequired,
    FAAFileDate,
    RFSSRVendor,
    SOWRejected,
    craneHeight,
    EHSSubmitted,
    CNTPReceived,
    escalateDate,
    FAACaseNumber,
    RFSSRRequired,
    craneRequired,
    PALOrNPAReceived,
    PALOrNPARequired,
    RFSSROrderedDate,
    RFSSRReceivedDate,
    determinationDate,
  },
  onValuesChange,
}: PropsWithChildren<FormProps>) => {
  const PALOrNPARequiredDDV = useSelector(
    DDVSelectors.getDDVValuesByType(DropDownSelectorValues.PALNPARequired),
  ) as OptionProps[];
  const RFSSRVendorDDV = useSelector(
    DDVSelectors.getDDVValuesByType(DropDownSelectorValues.RFSSRVendor),
  ) as OptionProps[];
  // PTP - PaceTask permission
  const [RFSSROrderedDatePTP, RFSSRReceivedDatePTP] = useSelector(
    PaceTasksSelectors.getPaceTasksPermissions([
      {
        dateType: 'actualDate',
        paceTaskId: MainPaceTask.RFSSROrderedDate,
      },
      {
        dateType: 'actualDate',
        paceTaskId: MainPaceTask.RFSSRReceivedDate,
      },
    ]),
  );

  useEffect(() => {
    if (data) {
      form?.setFieldsValue(
        momentizeObjectDates<EngineeringRegulatoryCompliance>(
          data as EngineeringRegulatoryCompliance,
          REGULATORY_COMPLIANCE_INFO_DATES,
        ),
      );
    }
  }, [data, isEditing]);

  return (
    <Panel
      header="Regulatory/Compliance"
      collapsible
      className="prov-engineering-workflow__panel"
    >
      <Form
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 12 }}
        form={form}
        onValuesChange={onValuesChange}
      >
        <Row>
          <Col span="10">
            <DatePicker
              id="SOWSubmit"
              label="SOW Submit"
              isEditing={isEditing && !!SOWSubmit}
            />
            <DatePicker
              id="SOWRejected"
              label="SOW Rejected"
              isEditing={isEditing && !!SOWRejected}
            />
            <TextArea
              id="SOWNotes"
              label="SOW Notes"
              isEditing={isEditing && !!SOWNotes}
            />
            <DatePicker
              id="CNTPReceived"
              label="CNTP Received"
              isEditing={isEditing && !!CNTPReceived}
            />
            <RadioGroup
              id="craneRequired"
              label="Crane Required"
              buttons={YES_NO_GROUP}
              isEditing={isEditing && !!craneRequired}
            />
            <InputNumber
              id="craneHeight"
              label="Crane Height"
              isEditing={isEditing && !!craneHeight}
            />
            <Input
              id="FAACaseNumber"
              label="FAA Case Number"
              isEditing={isEditing && !!FAACaseNumber}
            />
            <DatePicker
              id="FAAFileDate"
              label="FAA File Date"
              isEditing={isEditing && !!FAAFileDate}
            />
            <DatePicker
              id="escalateDate"
              label="Escalate Date"
              isEditing={isEditing && !!escalateDate}
            />
            <DatePicker
              id="determinationDate"
              label="Determination Date"
              isEditing={isEditing && !!determinationDate}
            />
          </Col>
          <Col span="12">
            <RadioGroup
              id="EHSRequired"
              label="EHS Required"
              buttons={YES_NO_GROUP}
              isEditing={isEditing && !!EHSRequired}
            />
            <DatePicker
              id="EHSSubmitted"
              label="EHS Submitted"
              isEditing={isEditing && !!EHSSubmitted}
            />
            <DatePicker
              id="EHSApproved"
              label="EHS Approved"
              isEditing={isEditing && !!EHSApproved}
            />
            <RadioGroup
              id="RFSSRRequired"
              label="RFSSR Required"
              buttons={YES_NO_GROUP}
              isEditing={isEditing && !!RFSSRRequired}
            />
            <DatePicker
              id="RFSSROrderedDate"
              label="RFSSR Ordered Date"
              isEditing={isEditing && !!RFSSROrderedDate && RFSSROrderedDatePTP}
            />
            <DatePicker
              id="RFSSRReceivedDate"
              label="RFSSR Received Date"
              isEditing={
                isEditing && !!RFSSRReceivedDate && RFSSRReceivedDatePTP
              }
            />
            <Autocomplete
              id="RFSSRVendor"
              label="RFSSR Vendor"
              isEditing={isEditing && !!RFSSRVendor}
              options={RFSSRVendorDDV}
            />
            <Autocomplete
              id="PALOrNPARequired"
              label="PAL/NPA Required"
              isEditing={isEditing && !!PALOrNPARequired}
              options={PALOrNPARequiredDDV}
            />
            <DatePicker
              id="PALOrNPAReceived"
              label="PAL/NPA Received"
              isEditing={isEditing && !!PALOrNPAReceived}
            />
          </Col>
        </Row>
      </Form>
    </Panel>
  );
};
