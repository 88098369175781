import { NotifyType, UserRole, UserRoleView } from 'providence-types';
import { mapFromBackendToUserRole } from '@core/utils/methods';
import { OptionProps, Rule, Schedule } from '@models/interfaces';
import { getCurrentEngineeringManagerType } from './get-current-engineering-manager-type';
import { getCurrentTowerOwners } from './get-current-tower-owners';

export const getValueForUsersOrRolesColumn = (
  item: Schedule | Rule,
  usersList: OptionProps[],
  towerOwners: OptionProps[],
): string => {
  switch (item.notifyType) {
    case NotifyType.User:
      return (item.userIds || [])
        .map(
          (userId: string) =>
            usersList.find(({ value }: OptionProps) => value === userId)
              ?.viewValue,
        )
        .join(',\n');
    case NotifyType.Role:
      return (item.roles || [])
        .map((role: UserRole) => {
          const userRoleView = mapFromBackendToUserRole(role);
          let result = userRoleView as string;

          if (
            userRoleView === UserRoleView.EngineeringManager &&
            item.engineeringManagerType != null
          ) {
            result = `${userRoleView} - ${getCurrentEngineeringManagerType(
              item.engineeringManagerType,
            )}`;
          }

          if (
            userRoleView === UserRoleView.LeasingAgent &&
            item.towerOwners &&
            item.towerOwners.length
          ) {
            result = `${userRoleView} (${getCurrentTowerOwners(
              towerOwners,
              item.towerOwners,
            )})`;
          }

          return result;
        })
        .join(',\n');
    default:
      throw new Error('Incorrect value');
  }
};
