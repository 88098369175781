import { arraySort } from '@core/utils/methods';
import { createReducer, on } from '@core/utils/store';
import {
  AdminDriverManagementData,
  Driver,
  PayloadAndState,
} from '@models/interfaces';
import { DriverManagementActions } from '@store/actions';

export interface DriverManagementState {
  drivers: AdminDriverManagementData[];
  driversList: Driver[];
  isFetching: boolean;
  errors: boolean;
}

const initialState: DriverManagementState = {
  drivers: [],
  driversList: [],
  isFetching: false,
  errors: false,
};

const mainSorter = (
  data: AdminDriverManagementData[],
): AdminDriverManagementData[] => arraySort(data, 'ASC', 'driverId');

export const reducer = createReducer(
  initialState,
  // GET ALL DRIVERS
  on(
    DriverManagementActions.getDriverManagementDataAction,
    ({
      payload: drivers,
    }: PayloadAndState<
      AdminDriverManagementData[],
      DriverManagementState
    >) => ({
      drivers,
    }),
  ),
  // ADD DRIVER
  on(
    DriverManagementActions.addDriverAction,
    ({
      payload,
      state: { drivers },
    }: PayloadAndState<AdminDriverManagementData, DriverManagementState>) => ({
      drivers: mainSorter([...drivers, payload]),
    }),
  ),
  // EDIT DRIVER
  on(
    DriverManagementActions.updateDriverAction,
    ({
      payload,
      state: { drivers },
    }: PayloadAndState<AdminDriverManagementData, DriverManagementState>) => ({
      drivers: mainSorter([
        ...drivers.filter(
          (driver: AdminDriverManagementData) => driver.id !== payload.id,
        ),
        payload,
      ]),
    }),
  ),
  // DELETE DRIVER
  on(
    DriverManagementActions.deleteDriverAction,
    ({
      payload: id,
      state: { drivers },
    }: PayloadAndState<string, DriverManagementState>) => ({
      drivers: drivers.filter(
        (driver: AdminDriverManagementData) => driver.id !== id,
      ),
    }),
  ),
  // GET DRIVERS LIST BY WORKFLOW
  on(
    DriverManagementActions.getDriversListByWorkflowAction,
    ({
      payload: driversList,
    }: PayloadAndState<Driver[], DriverManagementState>) => ({
      driversList,
    }),
  ),
);
