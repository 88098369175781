import React, { FC, PropsWithChildren, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Col, Form, Row } from 'antd';
import { InformationProps } from '@models/interfaces';
import { Panel } from '@shared/components';
import { Autocomplete, Input, PrimaryButton } from '@shared/modules';
import { DDVSelectors } from '@store/selectors';

const { useForm } = Form;

export const LeasingInformation: FC<InformationProps> = ({
  className,
  data: { structural, leasingNotes },
  expectedId,
}: PropsWithChildren<InformationProps>) => {
  const [form] = useForm();

  const dropdownsOptions = useSelector(DDVSelectors.getDDVOptions);

  useEffect(
    () =>
      form.setFieldsValue({
        structuralResult: structural?.structuralResult,
        leasingNotes,
      }),

    [structural, leasingNotes],
  );

  return (
    <Panel header="Leasing Information" className={className}>
      <Form labelCol={{ span: 10 }} wrapperCol={{ span: 14 }} form={form}>
        <Row>
          <Col span="12">
            <Autocomplete
              id="structuralResult"
              label="Structural Result"
              isEditing={false}
              options={dropdownsOptions?.StructuralResult}
            />
          </Col>
          <Col span="12">
            <Input id="leasingNotes" label="Leasing Notes" isEditing={false} />
          </Col>
        </Row>
        <Row className="action-buttons">
          <PrimaryButton
            title="View details"
            target="_blank"
            href={`/leasing/${expectedId}/details/workflow`}
            type="primary"
          />
        </Row>
      </Form>
    </Panel>
  );
};
