import { LeaseAuditData } from '@models/interfaces';

export const LEASE_AUDIT_DATES: Array<keyof LeaseAuditData> = [
  'auditDate',
  'documentDate',
  'currentTermStartDate',
  'currentTermEndDate',
  'finalTermEndDate',
  'mostRecentSADate',
];
