import React, { ReactNode } from 'react';
import { RuleOperator } from 'providence-types';
import { getCurrentDateType } from '@core/utils/methods';
import { actionButtons } from '@models/constants';
import { OptionProps, Schedule } from '@models/interfaces';
import { ColumnsFunc } from '@models/types';
import {
  getCurrentNotifyType,
  getValueForUsersOrRolesColumn,
} from '@notifications/helpers';
import { VirtualTableColumn } from '@shared/components';

export const getScheduleTableColumns = (
  paceTasksList: OptionProps[],
  usersList: OptionProps[],
  towerOwners: OptionProps[],
  editFunc: ColumnsFunc<Schedule>,
  deleteFunc: ColumnsFunc<Schedule>,
): Array<VirtualTableColumn<Schedule>> => [
  {
    title: 'Starting Field',
    key: 'startingField',
    width: 150,
    render: (scheduleItem: Schedule): ReactNode => {
      const found = paceTasksList.find(
        ({ value }: OptionProps) => value === scheduleItem.startingField,
      );

      return found
        ? `${found?.viewValue} - ${getCurrentDateType(
            scheduleItem.startingDateType,
          )}`
        : '';
    },
  },
  {
    title: 'EndingField',
    key: 'endingField',
    width: 150,
    render: (scheduleItem: Schedule): ReactNode => {
      const found = paceTasksList.find(
        ({ value }: OptionProps) => value === scheduleItem.endingField,
      );

      return found
        ? `${found?.viewValue} - ${getCurrentDateType(
            scheduleItem.endingDateType,
          )}`
        : '';
    },
  },
  {
    title: 'Operator',
    key: 'operator',
    width: 150,
    dataIndex: 'operator',
    render: (operator: RuleOperator): ReactNode => (
      <span className="cell cell--capitalize">{operator}</span>
    ),
  },
  {
    title: 'Day Count',
    key: 'dayCount',
    width: 150,
    render: (scheduleItem: Schedule): ReactNode =>
      scheduleItem?.daysCount ? `${scheduleItem.daysCount} days` : '',
  },
  {
    title: 'Notification Type',
    key: 'notificationType',
    width: 170,
    render: ({ notificationType }: Schedule): ReactNode => (
      <span className="cell cell--capitalize">{notificationType}</span>
    ),
  },
  {
    title: 'Notify Type',
    key: 'notifyType',
    width: 150,
    dataIndex: 'notifyType',
    render: getCurrentNotifyType,
  },
  {
    title: 'Users/Roles',
    key: 'usersOrRoles',
    width: 150,
    render: (scheduleItem: Schedule): ReactNode => (
      <span className="cell cell--pre-wrap">
        {getValueForUsersOrRolesColumn(scheduleItem, usersList, towerOwners)}
      </span>
    ),
  },
  {
    title: 'Message',
    key: 'message',
    dataIndex: 'message',
    width: 150,
  },
  {
    title: 'Auto Warning',
    key: 'autoWarning',
    dataIndex: 'autoWarning',
    width: 150,
    render: (autoWarning: boolean): ReactNode => (
      <span>{autoWarning ? 'Yes' : 'No'}</span>
    ),
  },
  {
    title: 'Warning Days Count',
    key: 'warningDaysCount',
    dataIndex: 'warningDaysCount',
    width: 200,
    render: (warningDaysCount?: number): ReactNode =>
      warningDaysCount ? `${warningDaysCount} days` : '',
  },
  {
    title: 'Notify Admin',
    key: 'notifyAdmin',
    dataIndex: 'notifyAdmin',
    width: 150,
    render: (notifyAdmin?: boolean): ReactNode => {
      let strValue = '';

      if (notifyAdmin !== undefined) {
        strValue = notifyAdmin ? 'Yes' : 'No';
      }

      return <span>{strValue}</span>;
    },
  },
  {
    title: 'Warning Message',
    key: 'warningMessage',
    dataIndex: 'warningMessage',
    width: 170,
  },
  {
    title: 'Redirect link',
    key: 'redirectLink',
    dataIndex: 'redirectLinkKey',
    width: 150,
  },
  actionButtons({ editFunc, deleteFunc }) as VirtualTableColumn,
];
