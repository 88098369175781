import React, {
  FC,
  PropsWithChildren,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Form } from 'antd';
import { SelectValue } from 'antd/lib/select';
import {
  IRuleDateFields,
  ISelectorOption,
  LinkPatternsKey,
  NotifyType,
  PaceTaskType,
  RuleArea,
  RuleOperator,
  RulePresetValue,
  RuleValueType,
} from 'providence-types';
import { HttpService } from '@core/services';
import { NotificationsHttpService } from '@core/services/http';
import {
  arraySort,
  getCurrentDateType,
  getCurrentOptions,
  getEnumKeyByEnumValue,
  isValidForm,
  stringEnumToSelectableObject,
} from '@core/utils/methods';
import { ModalMainTypes } from '@models/enums';
import { Rule } from '@models/interfaces';
import { NotifyTypeControls } from '@notifications/components';
import {
  actualizeControl,
  getCurrentOperator,
  getCurrentRuleArea,
  getCurrentRuleValue,
  getCurrentValueType,
} from '@notifications/helpers';
import { NotifyTypeControlsRefType } from '@notifications/models';
import { AddAndEditModal } from '@shared/components';
import { Autocomplete, InputNumber, Select, TextArea } from '@shared/modules';
import { RULE_DEFAULT_VALUES, RuleModalProps } from '../../models';
import { isCurrentValueType } from '../../validators';

const { useForm } = Form;

export const RuleModal: FC<RuleModalProps> = ({
  itemValue,
  visible,
  modalType,
  toggleModal,
  ruleFields,
  userList,
  towerOwners,
}: PropsWithChildren<RuleModalProps>) => {
  const [form] = useForm();

  const notificationsService = useMemo(
    () => HttpService.getHttpRequests(NotificationsHttpService),
    [],
  );

  const ref = useRef<NotifyTypeControlsRefType>(null);

  const [currentArea, setArea] = useState<string | null>(null);
  const [currentValueType, setValueType] = useState<string | null>(null);

  useEffect(() => {
    if (visible) {
      form.setFieldsValue({
        ...(itemValue || RULE_DEFAULT_VALUES),
      });

      if (itemValue) {
        const { area } = ruleFields.find((ruleDateField: IRuleDateFields) =>
          ruleDateField.options
            .map((o: ISelectorOption) => o.value)
            .includes(itemValue.fieldName),
        ) as IRuleDateFields;

        form.setFieldsValue({
          area,
        });

        setArea(getEnumKeyByEnumValue(RuleArea, area));
        setValueType(getEnumKeyByEnumValue(RuleValueType, itemValue.valueType));
        ref.current?.changeNotifyType(
          getEnumKeyByEnumValue(NotifyType, itemValue.notifyType),
        );
        ref.current?.changeRoles(itemValue.roles || []);
      }
    }
  }, [visible, itemValue, form, ruleFields]);

  const onClose = (): void => {
    toggleModal(modalType);
    form.resetFields();
    setArea(null);
    setValueType(null);
    ref.current?.changeNotifyType(null);
    ref.current?.changeRoles(null);
  };

  const add = async (): Promise<void> => {
    if (await isValidForm(form)) {
      const { area, ...otherFields } = form.getFieldsValue();

      await notificationsService.createRule(otherFields as Omit<Rule, '_id'>);

      onClose();
    }
  };

  const edit = async (): Promise<void> => {
    if (await isValidForm(form)) {
      const { area, ...otherFields } = form.getFieldsValue();

      await notificationsService.updateRule(
        itemValue!._id,
        otherFields as Omit<Rule, '_id'>,
      );

      onClose();
    }
  };

  const onOk = async (): Promise<void> => {
    await (modalType === ModalMainTypes.Add ? add() : edit());
  };

  const isCurrentArea = (value: RuleArea): boolean =>
    getEnumKeyByEnumValue(RuleArea, value) === currentArea;

  const ruleValueByValueType =
    getEnumKeyByEnumValue(RuleValueType, RuleValueType.DayCount) ===
    currentValueType ? (
      <InputNumber
        id="daysCount"
        label="Value"
        formItemProps={{
          rules: [{ required: true, message: 'Value is required!' }],
        }}
        elementProps={{ min: 0 }}
      />
    ) : (
      <Select
        id="value"
        label="Value"
        formItemProps={{
          rules: [{ required: true, message: 'Value is required!' }],
        }}
        options={arraySort(
          getCurrentOptions(RulePresetValue, getCurrentRuleValue),
          'ASC',
          'viewValue',
        )}
      />
    );

  return (
    <AddAndEditModal
      onOk={onOk}
      onCancel={onClose}
      visible={visible}
      type={modalType}
      title="Rule"
      okText="Save"
      cancelText="Cancel"
      formProps={{
        labelCol: { span: 10 },
        wrapperCol: { span: 14 },
        form,
      }}
    >
      <Select
        id="area"
        label="Area"
        formItemProps={{
          rules: [{ required: true, message: 'Area is required!' }],
        }}
        elementProps={{
          onChange: (value: SelectValue): void =>
            actualizeControl(setArea, form, RuleArea, value, [
              'fieldName',
              'dateType',
            ]),
        }}
        options={arraySort(
          getCurrentOptions(RuleArea, getCurrentRuleArea),
          'ASC',
          'viewValue',
        )}
      />
      <Autocomplete
        id="fieldName"
        label="Field"
        formItemProps={{
          rules: [{ required: true, message: 'Field is required!' }],
        }}
        options={arraySort(
          ruleFields.find((field: IRuleDateFields) => isCurrentArea(field.area))
            ?.options || [],
          'ASC',
          'viewValue',
        )}
      />
      {isCurrentArea(RuleArea.PaceTasks) && (
        <Select
          id="dateType"
          label="Date Type"
          formItemProps={{
            rules: [{ required: true, message: 'Date Type is required!' }],
          }}
          options={arraySort(
            getCurrentOptions(PaceTaskType, getCurrentDateType),
            'ASC',
            'viewValue',
          )}
        />
      )}
      <Select
        id="operator"
        label="Operator"
        formItemProps={{
          rules: [{ required: true, message: 'Operator is required!' }],
        }}
        options={arraySort(
          getCurrentOptions(RuleOperator, getCurrentOperator),
          'ASC',
          'viewValue',
        )}
      />
      <Select
        id="valueType"
        label="Value Type"
        formItemProps={{
          dependencies: ['operator'],
          rules: [
            { required: true, message: 'Value Type is required!' },
            isCurrentValueType(form),
          ],
        }}
        elementProps={{
          onChange: (value: SelectValue): void =>
            actualizeControl(setValueType, form, RuleValueType, value, [
              'value',
              'daysCount',
            ]),
        }}
        options={arraySort(
          getCurrentOptions(RuleValueType, getCurrentValueType),
          'ASC',
          'viewValue',
        )}
      />
      {currentValueType && ruleValueByValueType}
      <NotifyTypeControls
        ref={ref}
        form={form}
        userList={userList}
        towerOwners={towerOwners}
      />
      <TextArea
        id="message"
        label="Message"
        formItemProps={{
          rules: [{ required: true, message: 'Message is required!' }],
        }}
      />
      <Autocomplete
        id="redirectLinkKey"
        label="Redirect page"
        formItemProps={{
          rules: [{ required: true, message: 'Redirect page is required!' }],
        }}
        options={arraySort(
          stringEnumToSelectableObject(LinkPatternsKey),
          'ASC',
          'viewValue',
        )}
      />
    </AddAndEditModal>
  );
};
