import {
  IDirectMessageReceivers,
  IReceivedDirectMessageForArea,
  ISentDirectMessage,
} from 'providence-types';
import { createReducer, on } from '../../core/utils/store';
import { PayloadAndState } from '../../models/interfaces';
import { DirectMessageActions } from '../actions/direct-message.actions';

export interface DirectMessageState {
  userList: IDirectMessageReceivers[];
  directMessages: ISentDirectMessage[];
  receivedDirectMessages: IReceivedDirectMessageForArea[];
  isFetching: boolean;
  errors: boolean;
}

const initialState: DirectMessageState = {
  userList: [],
  directMessages: [],
  receivedDirectMessages: [],
  isFetching: false,
  errors: false,
};

export const reducer = createReducer(
  initialState,
  on(
    DirectMessageActions.getUsersByArea,
    ({
      payload: userList,
    }: PayloadAndState<IDirectMessageReceivers[], DirectMessageState>) => ({
      userList,
    }),
  ),
  on(
    DirectMessageActions.getDirectMessages,
    ({
      payload: directMessages,
    }: PayloadAndState<ISentDirectMessage[], DirectMessageState>) => ({
      directMessages,
    }),
  ),
  on(
    DirectMessageActions.getReceivedDirectMessages,
    ({
      payload: receivedDirectMessages,
    }: PayloadAndState<
      IReceivedDirectMessageForArea[],
      DirectMessageState
    >) => ({
      receivedDirectMessages,
    }),
  ),
  on(
    DirectMessageActions.createDirectMessage,
    ({
      payload: directMessage,
      state: { directMessages },
    }: PayloadAndState<ISentDirectMessage, DirectMessageState>) => ({
      directMessages: [...directMessages, directMessage],
    }),
  ),
  on(
    DirectMessageActions.updateDirectMessage,
    ({
      payload: { _id, message, receivers },
      state: { directMessages },
    }: PayloadAndState<
      Omit<ISentDirectMessage, 'completed'>,
      DirectMessageState
    >) => ({
      directMessages: directMessages.map((directMessage: ISentDirectMessage) =>
        directMessage._id === _id
          ? { ...directMessage, message, receivers }
          : directMessage,
      ),
    }),
  ),
  on(
    DirectMessageActions.setCompletedDirectMessage,
    ({
      payload: _id,
      state: { receivedDirectMessages },
    }: PayloadAndState<string, DirectMessageState>) => ({
      receivedDirectMessages: receivedDirectMessages.map(
        (receivedDirectMessage: IReceivedDirectMessageForArea) => ({
          ...receivedDirectMessage,
          completed:
            receivedDirectMessage._id === _id
              ? true
              : receivedDirectMessage.completed,
        }),
      ),
    }),
  ),
  on(
    DirectMessageActions.multiSetCompletedDirectMessage,
    ({
      payload: recordIds,
      state: { receivedDirectMessages },
    }: PayloadAndState<string[], DirectMessageState>) => ({
      receivedDirectMessages: receivedDirectMessages.map(
        (receivedDirectMessage: IReceivedDirectMessageForArea) => ({
          ...receivedDirectMessage,
          completed: recordIds.includes(receivedDirectMessage._id)
            ? true
            : receivedDirectMessage.completed,
        }),
      ),
    }),
  ),
  on(
    DirectMessageActions.removeDirectMessage,
    ({
      payload: _id,
      state: { directMessages },
    }: PayloadAndState<string, DirectMessageState>) => ({
      directMessages: directMessages.filter(
        (directMessage: ISentDirectMessage) => directMessage._id !== _id,
      ),
    }),
  ),
);
