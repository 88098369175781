import { StoredRequest } from '@core/utils/store/stored-request-decorator';
import {
  PaginatedResponse,
  SOFToolBrowse,
  SOFToolBrowseFilter,
  SofToolBrowseFilterOptionsResponse,
  SOFToolDetails,
  SOFToolOrderInfo,
  SOFToolOrderInfoForSend,
} from '@models/interfaces';
import { BreadcrumbsActions, SOFToolActions } from '@store/actions';
import { ProjectsEffects } from '@store/effects';
import { CommonHttpService } from './common-http.service';

export class SOFToolHttpService extends CommonHttpService {
  @StoredRequest(SOFToolActions.getSOFToolDataAction)
  getSOFTool(
    params: SOFToolBrowseFilter,
  ): Promise<PaginatedResponse<SOFToolBrowse>> {
    return this.http.callApi.get(`${this.apiUrl}/engineering/sof-tool`, null, {
      params,
    });
  }

  @StoredRequest(SOFToolActions.getSOFToolBrowseFilters)
  getSOFToolBrowseFiltersOptions(): Promise<SofToolBrowseFilterOptionsResponse> {
    return this.http.callApi.get(
      `${this.apiUrl}/engineering/sof-tool/filter-options`,
    );
  }

  @StoredRequest(
    SOFToolActions.getSOFToolDetails,
    BreadcrumbsActions.Engineering.SOFTool,
  )
  async getSOFToolDetails(projectId: string): Promise<SOFToolDetails> {
    const SOF = await this.http.callApi.get(
      `${this.apiUrl}/engineering/sof-tool/${projectId}`,
    );

    ProjectsEffects.setHoldOptions(SOF.generalInfo);

    return SOF;
  }

  @StoredRequest(SOFToolActions.setSOFToolPreviewSrcPDF)
  previewPDF(
    projectId: string,
    data: Omit<SOFToolOrderInfo, 'attachment'>,
  ): Promise<string> {
    return this.http.callApi.post(
      `${this.apiUrl}/engineering/sof-tool/${projectId}/preview`,
      data,
    );
  }

  @StoredRequest(SOFToolActions.sendSOFToolPDF)
  sendPDF(projectId: string, data: SOFToolOrderInfoForSend): Promise<void> {
    const formData = new FormData();

    Object.keys(data).forEach((key: string) => formData.append(key, data[key]));

    return this.http.callApi.post(
      `${this.apiUrl}/engineering/sof-tool/${projectId}/send`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
  }
}
