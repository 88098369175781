import { ToolOutlined } from '@ant-design/icons';
import { DriverWorkflow, SubPage, Tab as ETab } from 'providence-types';
import { RedirectGuard } from '@core/guards/redirect.guard';
import { TabAccessGuard } from '@core/guards/tab-access.guard';
import { tabPropsToTabs } from '@core/utils/methods';
import { Route, RouteGroup, Tab } from '@models/classes';
import { ENGINEERING_TABS_PROPS, SCOPING_TABS_PROPS } from '@models/constants';
import { MenuPlacement } from '@models/enums';
import { Financial } from '@shared/components/financial';
import { FinancialResolver } from '@shared/components/financial/resolvers';
import { EngineeringSelectors } from '@store/selectors/engineering';
import { EngineeringBrowseResolver } from './components/browse/engineering-browse.resolver';
import { ScopingBrowseResolver } from './components/scoping/browse/scoping-browse.resolver';
import { SiteAuditBrowseResolver } from './components/site-audit/browse/site-audit-browse.resolver';
import { SofToolResolver } from './components/sof-tool/browse/sof-tool.resolver';
import { SOFToolDetailsResolver } from './components/sof-tool/details/sof-tool-details.resolver';
import {
  EngineeringBrowse,
  EngineeringDetails,
  EngineeringDetailsSiteAuditTab,
  EngineeringSOFTab,
  ScopingBrowse,
  ScopingDetails,
  SiteAuditBrowse,
  SiteAuditDetails,
  SOFToolBrowse,
  SOFToolDetails,
} from './components';
import {
  EngineeringDetailsResolver,
  ScopingDetailsResolver,
  SiteAuditDetailsResolver,
} from './resolvers';

export const siteAuditDetailsRoute = new Route({
  name: 'Details',
  breadcrumbName: ':siteNumber(:projectBundleId)',
  path: '/:siteAuditId/details',
  component: SiteAuditDetails,
  extendPath: false,
})
  .addResolver(new SiteAuditDetailsResolver())
  .breadcrumb();

export const engineeringSiteAuditTab = new Tab({
  path: '/site-audit',
  name: 'Site Audit',
  component: EngineeringDetailsSiteAuditTab,
}).addGuard(
  new TabAccessGuard('engineering', SubPage.EngineeringBrowse, ETab.SiteAudit),
  '/',
);

const scopingTabs = tabPropsToTabs(SCOPING_TABS_PROPS);

export const scopingSiteAuditTab = scopingTabs[2];

export const engineeringRoutes = new RouteGroup({
  name: 'Engineering',
  path: '/engineering',
})
  .setMenu(MenuPlacement.Main, ToolOutlined)
  .breadcrumb()
  .addGuard(new RedirectGuard('engineering'), '/')
  .addRoutes([
    new Route({
      name: 'Browse',
      path: '/browse',
      component: EngineeringBrowse,
    })
      .setMenu(MenuPlacement.Main)
      .addGuard(
        new RedirectGuard('engineering', SubPage.EngineeringBrowse),
        '/',
      )
      .addResolver(new EngineeringBrowseResolver(), false)
      .breadcrumb()
      .addRoute(
        new Route({
          name: ':siteNumber(:projectBundleId)',
          path: '/:engineeringId/details',
          component: EngineeringDetails,
          extendPath: false,
        })
          .addResolver(new EngineeringDetailsResolver())
          .addResolver(
            new FinancialResolver('engineering', DriverWorkflow.Engineering),
          )
          .breadcrumb()
          .addTabs([
            ...tabPropsToTabs(ENGINEERING_TABS_PROPS),
            engineeringSiteAuditTab,
            new Tab({
              breadcrumbName: 'SOF Tool-:siteNumber(:projectBundleId)',
              path: '/sof',
              name: 'SOF',
              component: EngineeringSOFTab,
            })
              .breadcrumb()
              .addGuard(
                new TabAccessGuard(
                  'engineering',
                  SubPage.EngineeringBrowse,
                  ETab.SOF,
                ),
                '/',
              ),
            new Tab({
              path: '/financial',
              name: 'Financial',
              component: Financial,
              isLink: false,
              componentProps: {
                feeAccessPath: 'engineering',
                siteDataRootPath: [
                  'engineering',
                  'browse',
                  'engineeringSiteData',
                ],
                projectTypeSelector: EngineeringSelectors.getProjectType,
              },
            }).addGuard(
              new TabAccessGuard(
                'engineering',
                SubPage.EngineeringBrowse,
                ETab.Financial,
              ),
              '/',
            ),
          ]),
      ),
    new RouteGroup({
      name: 'SOF Tool',
      path: '/sof-tool',
    }).addRoute(
      new Route({
        name: 'SOF Tool',
        path: '/browse',
        component: SOFToolBrowse,
      })
        .setMenu(MenuPlacement.Main)
        .addGuard(
          new RedirectGuard('engineering', SubPage.EngineeringSOFTool),
          '/',
        )
        .addResolver(new SofToolResolver())
        .breadcrumb()
        .addRoute(
          new Route({
            name: 'Services Order Form',
            breadcrumbName: ':siteNumber(:projectBundleId)',
            path: '/:SOFToolId/details',
            component: SOFToolDetails,
            extendPath: false,
          })
            .addResolver(new SOFToolDetailsResolver())
            .breadcrumb(),
        ),
    ),
    new RouteGroup({
      name: 'Scoping',
      path: '/scoping',
    }).addRoute(
      new Route({
        name: 'Scoping',
        path: '/browse',
        component: ScopingBrowse,
      })
        .setMenu(MenuPlacement.Main)
        .addResolver(new ScopingBrowseResolver(), false)
        .addGuard(
          new RedirectGuard('engineering', SubPage.EngineeringScoping),
          '/',
        )
        .breadcrumb()
        .addRoute(
          new Route({
            name: 'Details',
            breadcrumbName: ':siteNumber(:projectBundleId)',
            path: '/:scopingId/details',
            component: ScopingDetails,
            extendPath: false,
          })
            .addTabs(scopingTabs)
            .addResolver(new ScopingDetailsResolver())
            .addResolver(new SiteAuditDetailsResolver())
            .breadcrumb(),
        ),
    ),
    new RouteGroup({
      name: 'Site Audit',
      path: '/site-audit',
    }).addRoute(
      new Route({
        name: 'Site Audit',
        path: '/browse',
        component: SiteAuditBrowse,
      })
        .setMenu(MenuPlacement.Main)
        .addResolver(new SiteAuditBrowseResolver(), false)
        .addGuard(
          new RedirectGuard('engineering', SubPage.EngineeringSiteAudit),
          '/',
        )
        .breadcrumb()
        .addRoute(siteAuditDetailsRoute),
    ),
  ]);
