import React, { FC, PropsWithChildren, useEffect } from 'react';
import { Col, Form, Row } from 'antd';
import { InformationProps } from '@models/interfaces';
import { Panel } from '@shared/components';
import { Input } from '@shared/modules';

const { useForm } = Form;

export const ProjectInformation: FC<InformationProps> = ({
  className,
  data,
}: PropsWithChildren<InformationProps>) => {
  const [form] = useForm();

  useEffect(() => {
    const {
      ATnTPM,
      civilVendor,
      progressStatus,
      projectBundleId,
      projectStatus,
      projectType,
      projectWorkType,
      scoping,
      siteAcqVendor,
      structuralNotes,
      structuralResult,
    } = data;

    form.setFieldsValue({
      AEVendor: scoping?.AEVendor?.value,
      ATnTPM: ATnTPM?.value,
      civilVendor: civilVendor?.value,
      progressStatus: progressStatus?.value,
      projectBundleId,
      projectStatus: projectStatus?.value,
      projectType: projectType?.value,
      projectWorkType: projectWorkType?.value,
      siteAcqVendor: siteAcqVendor?.value,
      structuralNotes,
      structuralResult,
    });
  }, []);

  useEffect(() => {
    const {
      ATnTPM,
      civilVendor,
      progressStatus,
      projectBundleId,
      projectStatus,
      projectType,
      projectWorkType,
      scoping,
      siteAcqVendor,
      structuralNotes,
      structuralResult,
    } = data;

    form.setFieldsValue({
      AEVendor: scoping?.AEVendor?.value,
      ATnTPM: ATnTPM?.value,
      civilVendor: civilVendor?.value,
      progressStatus: progressStatus?.value,
      projectBundleId,
      projectStatus: projectStatus?.value,
      projectType: projectType?.value,
      projectWorkType: projectWorkType?.value,
      siteAcqVendor: siteAcqVendor?.value,
      structuralNotes,
      structuralResult,
    });
  }, []);

  return (
    <Panel header="Project Information" className={className}>
      <Form labelCol={{ span: 14 }} wrapperCol={{ span: 10 }} form={form}>
        <Row>
          <Col span="12">
            <Input
              id="projectBundleId"
              label="Project Bundle ID"
              isEditing={false}
            />
            <Input id="projectType" label="Project Type" isEditing={false} />
            <Input
              id="projectWorkType"
              label="Project Work Type"
              isEditing={false}
            />
            <Input
              id="projectStatus"
              label="Project Status"
              isEditing={false}
            />
            <Input
              id="progressStatus"
              label="Progress Status"
              isEditing={false}
            />
            <Input id="ATnTPM" label="AT&T PM" isEditing={false} />
          </Col>
          <Col span="12">
            <Input
              id="siteAcqVendor"
              label="Site Acq Vendor"
              isEditing={false}
            />
            <Input id="civilVendor" label="Civil Vendor" isEditing={false} />
            <Input id="AEVendor" label="AE Vendor" isEditing={false} />
            <Input
              id="structuralResult"
              label="Structural Result"
              isEditing={false}
            />
            <Input
              id="structuralNotes"
              label="Structural Notes"
              isEditing={false}
            />
          </Col>
        </Row>
      </Form>
    </Panel>
  );
};
