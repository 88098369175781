import React, { FC, PropsWithChildren, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { SelectValue } from 'antd/lib/select';
import { arrsToSelectableObject } from '@core/utils/methods';
import {
  ConfigureFilterProps,
  EquipmentBrowseConfigureFilter,
  EquipmentManufacturerData,
  EquipmentTypeData,
  OptionProps,
} from '@models/interfaces';
import { Autocomplete } from '@shared/modules';
import { EquipmentSelectors } from '@store/selectors';

export const Filters: FC<
  ConfigureFilterProps<EquipmentBrowseConfigureFilter>
> = ({
  value,
  onChanges,
  formProps,
}: PropsWithChildren<ConfigureFilterProps<EquipmentBrowseConfigureFilter>>) => {
  const equipmentTypes = useSelector(EquipmentSelectors.getEquipmentTypes);

  const [equipmentTypesOptions, setEquipmentOptions] = useState<OptionProps[]>(
    [],
  );
  const [equipmentManufacturersOptions, setManufacturersOptions] = useState<
    OptionProps[]
  >([]);

  useEffect(() => {
    setEquipmentOptions(
      arrsToSelectableObject<EquipmentTypeData>(equipmentTypes, 'id', 'name'),
    );
  }, [equipmentTypes]);

  useEffect(() => {
    setManufacturersOptions(
      arrsToSelectableObject<EquipmentManufacturerData>(
        equipmentTypes.find(
          (type: EquipmentTypeData) => type.id === value.equipmentTypeId,
        )?.manufacturers || [],
        'id',
        'name',
      ),
    );
  }, [value.equipmentTypeId]);

  return (
    <>
      <Autocomplete
        id="equipmentTypeId"
        key="equipmentTypeId"
        label="Equipment Type"
        options={equipmentTypesOptions}
        elementProps={{
          value: value.equipmentTypeId,
          onChange: (e: SelectValue): void => {
            onChanges({
              equipmentTypeId: e as string,
              equipmentManufacturerId: undefined,
            });
            formProps?.form.setFieldsValue({
              equipmentManufacturerId: undefined,
            });
          },
        }}
      />
      <Autocomplete
        id="equipmentManufacturerId"
        key="equipmentManufacturerId"
        label="Manufacturer"
        options={equipmentManufacturersOptions}
        elementProps={{
          value: value.equipmentManufacturerId,
          onChange: (e: SelectValue): void => {
            onChanges({ equipmentManufacturerId: e as string });
          },
        }}
      />
    </>
  );
};
