import React, { FC, PropsWithChildren, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Form } from 'antd';
import { DropDownSelectorValues } from 'providence-types';
import {
  arraySort,
  canBeEditable,
  momentizeObjectDates,
} from '@core/utils/methods';
import {
  JURISDICTION_DATES_FOR_MOMENTIZE,
  YES_NO_GROUP,
} from '@models/constants';
import { OptionProps } from '@models/interfaces';
import { Panel } from '@shared/components';
import {
  Autocomplete,
  Checkbox,
  DatePicker,
  Input,
  RadioGroup,
} from '@shared/modules';
import { DDVSelectors } from '@store/selectors';
import { JurisdictionAuditZoningSectionProps } from '../../models';

export const JurisdictionAuditZoningSection: FC<
  JurisdictionAuditZoningSectionProps
> = ({
  isEditing,
  jurisdictionAuditZoningFields: {
    zoningRequired,
    zoningReqBasedOnProjectScope,
    reasonForZoningReqChange,
  },
  onValuesChange,
  data,
  form,
  hasZoning,
}: PropsWithChildren<JurisdictionAuditZoningSectionProps>) => {
  const zoningRequiredDDV = useSelector(
    DDVSelectors.getDDVValuesByType(DropDownSelectorValues.ZoningRequired),
  ) as OptionProps[];

  const estimatedZoningApprovalTimelineDDV = useSelector(
    DDVSelectors.getDDVValuesByType(
      DropDownSelectorValues.EstimatedZoningApprovalTimeline,
    ),
  ) as OptionProps[];

  useEffect(() => {
    if (data) {
      form?.setFieldsValue(
        momentizeObjectDates(data, JURISDICTION_DATES_FOR_MOMENTIZE),
      );
    }
  }, [isEditing]);

  return (
    <Panel
      header="Zoning"
      className="prov-permitting-jurisdiction-audit__section"
      collapsible={hasZoning}
      restrictionText="Jurisdiction does not have Zoning for Project's Type and Subtype"
    >
      <Form
        className="prov-permitting-jurisdiction-audit__section__form"
        form={form}
        labelCol={{ span: 10 }}
        onValuesChange={onValuesChange}
      >
        <Autocomplete
          id="zoningRequired"
          className="left"
          label="Zoning Required"
          options={arraySort(zoningRequiredDDV, 'DESC', 'viewValue')}
          isEditing={canBeEditable(zoningRequired, isEditing)}
        />
        <Input
          id="applicationRequirements"
          className="right"
          label="Application Requirements"
          isEditing={false}
        />
        <Checkbox
          id="zoningReqBasedOnProjectScope"
          className="left"
          label="Zoning Req Based On Project Scope"
          isEditing={canBeEditable(zoningReqBasedOnProjectScope, isEditing)}
        />
        <Input
          id="towerHeightRestrictions"
          className="right"
          label="Tower Height Restrictions"
          isEditing={false}
        />
        <Input
          id="reasonForZoningReqChange"
          className="left"
          label="Reason For Zoning Req Change"
          isEditing={canBeEditable(reasonForZoningReqChange, isEditing)}
        />
        <Input
          id="separationRequirements"
          className="right"
          label="Separation Requirements"
          isEditing={false}
        />
        <RadioGroup
          id="writtenDocumentationFromJurisdiction"
          className="left"
          label="Written Documentation from Jurisdiction"
          isEditing={false}
          buttons={YES_NO_GROUP}
        />
        <Input
          id="specialLicensingOrBondingConditions"
          className="right"
          label="Special Licensing/Bonding Conditions"
          isEditing={false}
        />
        <Input
          id="describeOverallProcess"
          className="left"
          label="Describe Overall Process"
          isEditing={false}
        />
        <Input
          id="otherFees"
          className="right"
          label="Other Fees"
          isEditing={false}
        />
        <Autocomplete
          id="estimatedZoningApprovalTimeline"
          className="left"
          label="Estimated Zoning Approval Timeline"
          options={estimatedZoningApprovalTimelineDDV}
          isEditing={false}
        />
        <Input
          id="notes"
          className="right"
          label="Zoning Notes"
          isEditing={false}
        />
        <DatePicker
          id="jurisdictionReviewDate"
          className="right"
          label="Jurisdiction Review Date"
          isEditing={false}
        />
      </Form>
    </Panel>
  );
};
