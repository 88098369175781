import { InboxOutlined } from '@ant-design/icons';
import { SubPage, Tab as ETab } from 'providence-types';
import { LeasingDetails } from '@client/pages/leasing/components';
import { RedirectGuard } from '@core/guards/redirect.guard';
import { TabAccessGuard } from '@core/guards/tab-access.guard';
import { tabPropsToTabs } from '@core/utils/methods';
import { Route, RouteGroup, Tab } from '@models/classes';
import {
  ENGINEERING_TABS_PROPS,
  LEASING_TABS_PROPS,
  SITE_TABS_PROPS,
} from '@models/constants';
import { MenuPlacement } from '@models/enums';
import { Financial } from '@shared/components/financial';
import { FinancialResolver } from '@shared/components/financial/resolvers';
import { ProjectSelectors } from '@store/selectors/project.selectors';
import { EngineeringDetails } from '../engineering/components';
import { EngineeringDetailsResolver } from '../engineering/resolvers';
import { LeasingDetailsResolver } from '../leasing/resolvers';
import {
  PERMITTING_TABS_PROPS,
  PermittingDetails,
  PermittingDetailsResolver,
} from '../permitting/browse';
import { SiteDetails } from '../site/components';
import { ProjectBrowseResolver } from './components/browse/project-browse.resolver';
import { ProjectCreateResolver } from './components/create/project-create.resolver';
import {
  ProjectBrowse,
  ProjectCreate,
  ProjectDatesImport,
  ProjectDetails,
  ProjectImport,
  ProjectImportUpdate,
  ProjectSummaryTab,
} from './components';
import { ProjectDetailsResolver, ProvidenceAgentsResolver } from './resolvers';

export const projectDetailsSummaryTab = new Tab({
  path: '/summary',
  name: 'Summary',
  component: ProjectSummaryTab,
  guards: [
    new TabAccessGuard('projects', SubPage.ProjectsBrowse, ETab.Summary),
  ],
});

export const projectRoutes = new RouteGroup({
  path: '/project',
  name: 'Project',
})
  .setMenu(MenuPlacement.Main, InboxOutlined)
  .breadcrumb()
  .addGuard(new RedirectGuard('projects'), '/')
  .addRoutes([
    new Route({
      path: '/browse',
      name: 'Browse',
      component: ProjectBrowse,
    })
      .setMenu(MenuPlacement.Main)
      .addGuard(new RedirectGuard('projects', SubPage.ProjectsBrowse), '/')
      .breadcrumb()
      .addResolver(new ProjectBrowseResolver(), false)
      .addRoute(
        new Route({
          path: '/:projectId/details',
          name: ':siteNumber(:projectBundleId)',
          component: ProjectDetails,
          extendPath: false,
        })
          .addResolver(new ProjectDetailsResolver())
          .addResolver(new FinancialResolver('projects'), false)
          .addResolver(new ProvidenceAgentsResolver(), false)
          .breadcrumb()
          .addTabs([
            projectDetailsSummaryTab,
            new Tab({
              path: '/site',
              name: 'Site',
              breadcrumbName: 'Site-:siteNumber',
              component: SiteDetails,
              isLink: true,
            })
              .breadcrumb()
              .addGuard(new RedirectGuard('site', SubPage.SiteBrowse), '/')
              .addTabs(tabPropsToTabs(SITE_TABS_PROPS)),
            new Tab({
              path: '/engineering',
              name: 'Engineering',
              breadcrumbName: 'Engineering-:siteNumber(:projectBundleId)',
              component: EngineeringDetails,
              isLink: true,
            })
              .addGuard(
                new RedirectGuard('engineering', SubPage.EngineeringBrowse),
                '/',
              )
              .addResolver(new EngineeringDetailsResolver())
              .breadcrumb()
              .addTabs(tabPropsToTabs(ENGINEERING_TABS_PROPS)),
            new Tab({
              path: '/leasing',
              name: 'Leasing',
              breadcrumbName: 'Leasing-:siteNumber(:projectBundleId)',
              component: LeasingDetails,
              isLink: true,
            })
              .addGuard(
                new RedirectGuard('leasing', SubPage.LeasingBrowse),
                '/',
              )
              .addResolver(new LeasingDetailsResolver())
              .breadcrumb()
              .addTabs(tabPropsToTabs(LEASING_TABS_PROPS)),
            new Tab({
              path: '/permitting',
              name: 'Permitting',
              breadcrumbName: 'Permitting-:siteNumber(:projectBundleId)',
              component: PermittingDetails,
              isLink: true,
            })
              .addTabs(tabPropsToTabs(PERMITTING_TABS_PROPS))
              .addResolver(new PermittingDetailsResolver())
              .breadcrumb()
              .addGuard(new RedirectGuard('permitting'), '/'),
            new Tab({
              path: '/financial',
              name: 'Financial',
              component: Financial,
              isLink: false,
              componentProps: {
                feeAccessPath: 'projects',
                siteDataRootPath: ['project', 'projectSite'],
                projectTypeSelector: ProjectSelectors.getProjectType,
              },
            }).addGuard(
              new TabAccessGuard(
                'projects',
                SubPage.ProjectsBrowse,
                ETab.Financial,
              ),
              '/',
            ),
          ]),
      ),
    new Route({
      path: '/create/:id',
      name: 'Create',
      component: ProjectCreate,
    })
      .addGuard(new RedirectGuard('projects', SubPage.ProjectsCreate), '/')
      .addResolver(new ProjectCreateResolver())
      .addResolver(new ProvidenceAgentsResolver(), false)
      .breadcrumb(),
    new Route({
      path: '/create',
      name: 'Create',
      component: ProjectCreate,
    })
      .setMenu(MenuPlacement.Main)
      .addGuard(new RedirectGuard('projects', SubPage.ProjectsCreate), '/')
      .addResolver(new ProjectCreateResolver())
      .addResolver(new ProvidenceAgentsResolver(), false)
      .breadcrumb(),
    new Route({
      path: '/import',
      name: 'Import',
      component: ProjectImport,
    })
      .setMenu(MenuPlacement.Main)
      .addGuard(new RedirectGuard('projects', SubPage.ProjectsImport), '/')
      .breadcrumb(),
    new Route({
      path: '/import-update',
      name: 'Import Update',
      component: ProjectImportUpdate,
    })
      .setMenu(MenuPlacement.Main)
      .addGuard(
        new RedirectGuard('projects', SubPage.ProjectsImportUpdate),
        '/',
      )
      .breadcrumb(),
    new Route({
      path: '/import-project-dates',
      name: 'Import Project Dates',
      component: ProjectDatesImport,
    })
      .setMenu(MenuPlacement.Main)
      .addGuard(
        new RedirectGuard('projects', SubPage.ProjectsImportPACEDates),
        '/',
      )
      .breadcrumb(),
  ]);
