import { ProjectManagementFilterField } from 'providence-types';
import { arrsToSelectableObject } from '@core/utils/methods';
import { OptionProps, StateWithCounties } from '@models/interfaces';
import {
  AutocompleteWithOperator,
  InputNumberWithOperator,
  RangePicker,
} from '@shared/modules';
import store from '@store/index';
import { FieldsListsOptions } from './fields-lists-options.type';
import { ItemToRender } from './item-to-render.type';

const getOptionsWithNullValue = (options?: OptionProps[]): OptionProps[] => [
  { value: 'null', viewValue: 'Null' },
  ...(options || []),
];

export const getSiteFieldsList = (
  props?: FieldsListsOptions,
): ItemToRender[] => {
  const {
    admin: {
      dropdownValues: { dropdownsOptions },
      countyValues: { states },
    },
  } = store.getState();

  const {
    countyOptions,
    jurisdictionOptions,
    FALocationNumbers,
    siteNumbers,
    ZIPs,
    USIDs,
  } = props ?? {};

  return [
    {
      component: AutocompleteWithOperator,
      id: ProjectManagementFilterField.FALocationNumber,
      label: 'FA Location Number',
      componentProps: {
        mainItemProps: { options: FALocationNumbers },
      },
    },
    {
      component: AutocompleteWithOperator,
      id: ProjectManagementFilterField.SiteNumber,
      label: 'Site Number',
      componentProps: {
        mainItemProps: { options: getOptionsWithNullValue(siteNumbers) },
      },
    },
    {
      component: InputNumberWithOperator,
      id: ProjectManagementFilterField.Longitude,
      label: 'Longitude',
    },
    {
      component: AutocompleteWithOperator,
      id: ProjectManagementFilterField.TowerType,
      label: 'Tower Type',
      componentProps: {
        mainItemProps: {
          options: getOptionsWithNullValue(dropdownsOptions?.TowerType),
        },
      },
    },
    {
      component: InputNumberWithOperator,
      id: ProjectManagementFilterField.TowerHeight,
      label: 'Tower Height',
    },
    {
      component: AutocompleteWithOperator,
      id: ProjectManagementFilterField.State,
      label: 'State',
      componentProps: {
        mainItemProps: {
          options: getOptionsWithNullValue(
            arrsToSelectableObject<StateWithCounties>(states, 'id', 'name'),
          ),
        },
      },
    },
    {
      component: AutocompleteWithOperator,
      id: ProjectManagementFilterField.Zip,
      label: 'Zip',
      componentProps: {
        mainItemProps: { options: getOptionsWithNullValue(ZIPs) },
      },
    },
    {
      component: AutocompleteWithOperator,
      id: ProjectManagementFilterField.County,
      label: 'County',
      componentProps: {
        mainItemProps: { options: getOptionsWithNullValue(countyOptions) },
      },
    },
    {
      component: AutocompleteWithOperator,
      id: ProjectManagementFilterField.Jurisdiction,
      label: 'Jurisdiction',
      componentProps: {
        mainItemProps: {
          options: getOptionsWithNullValue(jurisdictionOptions),
        },
      },
    },
    {
      component: AutocompleteWithOperator,
      id: ProjectManagementFilterField.USID,
      label: 'USID',
      componentProps: {
        mainItemProps: { options: getOptionsWithNullValue(USIDs) },
      },
    },
    {
      component: AutocompleteWithOperator,
      id: ProjectManagementFilterField.TowerOwner,
      label: 'Tower Owner',
      componentProps: {
        mainItemProps: {
          options: getOptionsWithNullValue(dropdownsOptions?.TowerOwner),
        },
      },
    },
    {
      component: AutocompleteWithOperator,
      id: ProjectManagementFilterField.Submarket,
      label: 'Submarket',
      componentProps: {
        mainItemProps: {
          options: getOptionsWithNullValue(dropdownsOptions?.Submarket),
        },
      },
    },
  ];
};

export const getProjectFieldsList = (
  props?: FieldsListsOptions,
): ItemToRender[] => {
  const {
    admin: {
      dropdownValues: { dropdownsOptions },
      projectCategory: { projectCategories },
    },
    project: { providenceAgents },
  } = store.getState();

  const { PACEProjectNumbers, OracleProjectNumbers, projectBundleIds } =
    props ?? {};

  return [
    {
      component: AutocompleteWithOperator,
      id: ProjectManagementFilterField.ProjectBundleId,
      label: 'Project Bundle ID',
      componentProps: {
        mainItemProps: { options: projectBundleIds },
      },
    },
    {
      component: AutocompleteWithOperator,
      id: ProjectManagementFilterField.ProjectWorkType,
      label: 'Project Work Type',
      componentProps: {
        mainItemProps: {
          options: getOptionsWithNullValue(dropdownsOptions?.ProjectWorkType),
        },
      },
    },
    {
      component: AutocompleteWithOperator,
      id: ProjectManagementFilterField.ProjectStatus,
      label: 'Project Status',
      componentProps: {
        mainItemProps: {
          options: getOptionsWithNullValue(dropdownsOptions?.ProjectStatus),
        },
      },
    },
    {
      component: AutocompleteWithOperator,
      id: ProjectManagementFilterField.ProgressStatus,
      label: 'Progress Status',
      componentProps: {
        mainItemProps: {
          options: getOptionsWithNullValue(dropdownsOptions?.ProgressStatus),
        },
      },
    },
    {
      component: AutocompleteWithOperator,
      id: ProjectManagementFilterField.ATnTPM,
      label: 'AT&T PM',
      componentProps: {
        mainItemProps: {
          options: getOptionsWithNullValue(dropdownsOptions?.ATnTPM),
        },
      },
    },
    {
      component: AutocompleteWithOperator,
      id: ProjectManagementFilterField.SiteAcqVendor,
      label: 'Site Acq Vendor',
      componentProps: {
        mainItemProps: {
          options: dropdownsOptions?.SiteACQVendor,
        },
      },
    },
    {
      component: AutocompleteWithOperator,
      id: ProjectManagementFilterField.ProvidenceAgent,
      label: 'Providence Agent',
      componentProps: {
        mainItemProps: { options: getOptionsWithNullValue(providenceAgents) },
      },
    },
    {
      component: AutocompleteWithOperator,
      id: ProjectManagementFilterField.CivilVendor,
      label: 'Civil Vendor',
      componentProps: {
        mainItemProps: {
          options: getOptionsWithNullValue(dropdownsOptions?.CivilVendor),
        },
      },
    },
    {
      component: AutocompleteWithOperator,
      label: 'Project Category',
      id: ProjectManagementFilterField.ProjectCategory,
      componentProps: {
        mainItemProps: {
          options: arrsToSelectableObject(projectCategories, 'id', 'value'),
        },
      },
    },
    {
      component: AutocompleteWithOperator,
      label: 'PACE Project Number',
      id: ProjectManagementFilterField.PACEProjectNumber,
      componentProps: {
        mainItemProps: {
          options: PACEProjectNumbers,
        },
      },
    },
    {
      component: AutocompleteWithOperator,
      label: 'Oracle Project Number',
      id: ProjectManagementFilterField.OracleProjectNumber,
      componentProps: {
        mainItemProps: {
          options: OracleProjectNumbers,
        },
      },
    },
    {
      component: AutocompleteWithOperator,
      label: 'Project Ranking',
      id: ProjectManagementFilterField.ProjectRanking,
      componentProps: {
        mainItemProps: {
          options: getOptionsWithNullValue(dropdownsOptions?.ProjectRanking),
        },
      },
    },
    {
      component: AutocompleteWithOperator,
      label: 'Frequency',
      id: ProjectManagementFilterField.Frequency,
      componentProps: {
        mainItemProps: {
          options: getOptionsWithNullValue(dropdownsOptions.Frequency),
        },
      },
    },
    {
      component: RangePicker,
      label: 'Project Assigned Date',
      id: ProjectManagementFilterField.ProjectAssignedDate,
    },
    // not project fields
    {
      component: AutocompleteWithOperator,
      id: ProjectManagementFilterField.AEVendor,
      label: 'A/E CD Vendor',
      componentProps: {
        mainItemProps: {
          options: getOptionsWithNullValue(dropdownsOptions?.AEVendor),
        },
      },
    },
    {
      component: AutocompleteWithOperator,
      id: ProjectManagementFilterField.StructuralResult,
      label: 'Structural Result',
      componentProps: {
        mainItemProps: {
          options: getOptionsWithNullValue(dropdownsOptions?.StructuralResult),
        },
      },
    },
  ];
};
