import React, {
  FC,
  PropsWithChildren,
  ReactNode,
  useEffect,
  useState,
} from 'react';
import { Form } from 'antd';
import classNames from 'classnames';
import { ModalMainTypes } from '@models/enums';
import { ModalWithFormProps } from '@models/interfaces';
import { Modal } from '../../modules/ui-kit/modal';

import './styles.scss';

const getTitleComponent = (
  title: ReactNode,
  type: ModalMainTypes,
): ReactNode => (
  <span className="prov-add-and-edit-modal__title">
    <p className="hideable-text">
      {type === ModalMainTypes.Add ? 'Add' : 'Edit'}
    </p>
    {title}
  </span>
);

export const AddAndEditModal: FC<ModalWithFormProps> = ({
  okText = 'Save',
  cancelText = 'Clear',
  children,
  className,
  type,
  title,
  visible,
  formProps: { labelCol = { span: 6 }, ...formProps } = {},
  ...props
}: PropsWithChildren<ModalWithFormProps>) => {
  const [titleComponent, setTitleComponent] = useState(
    getTitleComponent(title, type!),
  );

  const [childrenComponent, setChildrenComponent] = useState(children);

  useEffect(() => {
    if (visible) {
      setTitleComponent(getTitleComponent(title, type!));
      setChildrenComponent(children);
    }
  }, [visible, children, title, type]);

  return (
    <Modal
      title={titleComponent}
      forceRender
      okText={okText}
      visible={visible}
      cancelText={cancelText}
      className={classNames(className, 'prov-add-and-edit-modal')}
      {...props}
    >
      <Form labelCol={labelCol} {...formProps}>
        {childrenComponent}
      </Form>
    </Modal>
  );
};
