import { RuleOperator } from 'providence-types';

export const getCurrentOperator = (value: RuleOperator): string => {
  switch (value) {
    case RuleOperator.Equal:
      return 'Equal';
    case RuleOperator.NotEqual:
      return 'Not Equal';
    case RuleOperator.GreaterThan:
      return 'Greater Than';
    case RuleOperator.LessThan:
      return 'Less Than';
    default:
      throw new Error('Incorrect value');
  }
};
