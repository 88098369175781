import React, {
  forwardRef,
  PropsWithChildren,
  Ref,
  useImperativeHandle,
  useRef,
} from 'react';
import { Col, Form, Row } from 'antd';
import { ProjectType } from 'providence-types';
import { YES_NO_GROUP } from '@models/constants';
import { SiteInformationType } from '@models/types';
import {
  Autocomplete,
  DatePicker,
  RadioGroup,
  TextArea,
} from '@shared/modules';
import { SiteInformationControlsProps } from '../../models/interfaces';
import {
  LTEControlsRefType,
  SiteInformationFormRefType,
} from '../../models/types';
import { LTEControls } from './lte-controls';
import { onSiteInfoFormChange, onSiteInfoFormSubmit } from './models';
import { NSBControls } from './nsb-controls';

export const SiteInformationForm = forwardRef(
  (
    {
      form,
      data,
      projectType,
      isEditing,
      dropdownsOptions,
      permissions: {
        generalFields: {
          siteAuditRequired,
          scheduledSiteWalk,
          siteWalkCompleted,
          siteAuditCompletedBy,
          siteAuditNote,
          followUpRequired,
          savedToFile,
          towerType,
          equipmentType,
        },
        LTEFields,
        NSBFields,
      },
      setIsSiteInfoCompare,
    }: PropsWithChildren<SiteInformationControlsProps>,
    forwardedRef: Ref<SiteInformationFormRefType>,
  ) => {
    const LTEControlsRef = useRef<LTEControlsRefType>(null);

    const unknownSwitchState = LTEControlsRef.current?.unknownSwitchState;

    useImperativeHandle(forwardedRef, () => ({
      onSubmit(): SiteInformationType {
        return onSiteInfoFormSubmit(
          form,
          data,
          projectType,
          unknownSwitchState,
        );
      },
      // getter and setter unknownSwitchState
      unknownSwitchState: !!unknownSwitchState,
      changeUnknownSwitchState(value: boolean): void {
        LTEControlsRef.current?.changeUnknownSwitchState(value);
      },
      //
      setUnknownSwitch(): void {
        if (data?.isUnknownPowerPlantType !== undefined) {
          LTEControlsRef.current?.setUnknownSwitch(
            data?.isUnknownPowerPlantType,
          );
        }
      },
    }));

    return (
      <Form
        wrapperCol={{ span: 13 }}
        labelCol={{ span: isEditing ? 8 : 11 }}
        form={form}
        onValuesChange={(): void => {
          onSiteInfoFormChange(
            form,
            data,
            setIsSiteInfoCompare,
            projectType,
            unknownSwitchState,
          );
        }}
      >
        <Row>
          <Col span="12">
            <RadioGroup
              id="siteAuditRequired"
              label="Site Audit Required"
              buttons={YES_NO_GROUP}
              isEditing={isEditing && !!siteAuditRequired}
            />
            <DatePicker
              id="scheduledSiteWalk"
              label="Scheduled Site Walk"
              isEditing={isEditing && !!scheduledSiteWalk}
            />
            <DatePicker
              id="siteWalkComplete"
              label="Site Walk Complete"
              isEditing={isEditing && !!siteWalkCompleted}
            />
            <Autocomplete
              id="siteAuditCompletedBy"
              label="Site Audit Completed By"
              options={dropdownsOptions?.SiteAuditCompletedBy}
              isEditing={isEditing && !!siteAuditCompletedBy}
            />
            <Autocomplete
              id="towerType"
              label="Tower Type"
              options={dropdownsOptions?.TowerType}
              isEditing={isEditing && !!towerType}
            />
            <Autocomplete
              id="equipmentType"
              label="Equipment Type"
              options={dropdownsOptions?.EquipmentType}
              isEditing={isEditing && !!equipmentType}
            />
            <TextArea
              id="siteAuditNote"
              label="Site Audit Note"
              isEditing={isEditing && !!siteAuditNote}
            />
            <RadioGroup
              id="followUpRequired"
              label="Follow Up Required"
              buttons={YES_NO_GROUP}
              isEditing={isEditing && !!followUpRequired}
            />
            <DatePicker
              id="siteAuditSavedToFile"
              label="Saved to File"
              isEditing={isEditing && !!savedToFile}
            />
          </Col>
          <Col span="12">
            {projectType === ProjectType.LTE ? (
              <LTEControls
                ref={LTEControlsRef}
                isEditing={isEditing}
                permissions={LTEFields}
                dropdownsOptions={dropdownsOptions}
              />
            ) : (
              <NSBControls
                isEditing={isEditing}
                permissions={NSBFields}
                dropdownsOptions={dropdownsOptions}
              />
            )}
          </Col>
        </Row>
      </Form>
    );
  },
);
