import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Col, Form, Row } from 'antd';
import { useCountingTotalEquipment } from '@core/utils/hooks';
import { SCOPING_SECTORS } from '@models/constants';
import { SectorDataState } from '@models/types';
import {
  EquipmentTotals,
  OtherEquipment,
  ScopingNotes,
  SectorsOfEquipment,
} from '@shared/components';
import { EngineeringSelectors } from '@store/selectors';
import { ProjectInformation, SiteInformation } from './components';

import './styles.scss';

const { useForm } = Form;

export const EngineeringSOFTab: FC = () => {
  const [scopingForm] = useForm();
  const [siteForm] = useForm();
  const [projectForm] = useForm();

  const [sectorsData, setSectorsData] =
    useState<SectorDataState>(SCOPING_SECTORS);

  const {
    projectInfo,
    siteInfo,
    scopingInfo,
    scopingSectors = SCOPING_SECTORS,
    otherEquipment,
  } = useSelector(EngineeringSelectors.getSOFData);

  const totalEquipments = useCountingTotalEquipment(
    'sof',
    otherEquipment,
    sectorsData,
    scopingSectors,
  );

  useEffect(() => {
    projectForm.setFieldsValue(projectInfo);
    siteForm.setFieldsValue(siteInfo);
    scopingForm.setFieldsValue(scopingInfo);
  }, [projectInfo]);

  useEffect(() => {
    setSectorsData(scopingSectors);
  }, [scopingSectors]);

  return (
    <div className="prov-engineering-sof-tab">
      <Row justify="space-between" className="prov-engineering-sof-tab__row">
        <Col span="12">
          <SiteInformation form={siteForm} isEditing={false} />
        </Col>
        <Col span="12">
          <ProjectInformation form={projectForm} isEditing={false} />
        </Col>
      </Row>
      <ScopingNotes form={scopingForm} isEditing={false} />
      <SectorsOfEquipment
        sectorsData={sectorsData}
        setSectorsData={setSectorsData}
      />
      <OtherEquipment data={otherEquipment} isEditing={false} />
      <EquipmentTotals data={totalEquipments} />
    </div>
  );
};
