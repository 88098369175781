import { ReactNode } from 'react';
import { ColumnsType } from 'antd/es/table';
import { tableSorter } from '@core/utils/methods/table-sorter';
import {
  AccountingNotificationData,
  DashboardProjectData,
} from '@models/interfaces';

export const ACCOUNTING_NOTIFICATIONS_COLUMNS: ColumnsType = [
  {
    title: 'Site Number',
    dataIndex: 'projectData',
    key: 'siteNumber',
    sorter: tableSorter(['projectData', 'site', 'siteNumber']),
    render: ({ site }: DashboardProjectData): ReactNode => site?.siteNumber,
  },
  {
    title: 'Project Bundle Id',
    dataIndex: 'projectData',
    key: 'projectBundleId',
    sorter: tableSorter(['projectData', 'projectBundleId']),
    render: ({ projectBundleId }: DashboardProjectData): ReactNode =>
      projectBundleId,
  },
  {
    title: 'Type',
    dataIndex: 'notificationData',
    key: 'type',
    sorter: tableSorter(['notificationData', 'type']),
    render: ({ type }: AccountingNotificationData): ReactNode => type,
  },
] as ColumnsType;
