import React, { FC, PropsWithChildren, useEffect } from 'react';
import { Col, Form, Row } from 'antd';
import { YES_NO_GROUP } from '@models/constants';
import { InformationProps, OptionProps } from '@models/interfaces';
import { Panel } from '@shared/components';
import {
  Autocomplete,
  DatePicker,
  Input,
  PrimaryButton,
  TextArea,
} from '@shared/modules';

const { useForm } = Form;

export const LeasingInformation: FC<InformationProps> = ({
  className,
  data: { structural },
  expectedId,
}: PropsWithChildren<InformationProps>) => {
  const [form] = useForm();

  useEffect(() => {
    if (structural) {
      form.setFieldsValue({
        ...structural,
        structuralResult: structural?.structuralResult?.value,
      });
    }
  }, [structural]);

  return (
    <Panel header="Leasing Information" className={className}>
      <Form labelCol={{ span: 12 }} wrapperCol={{ span: 14 }} form={form}>
        <Row>
          <Col span="12">
            <Autocomplete
              id="structuralRequired"
              label="Structural Required"
              options={YES_NO_GROUP as OptionProps[]}
              isEditing={false}
            />
            <DatePicker
              id="structuralOrdered"
              label="Structural Ordered"
              isEditing={false}
            />
            <Input
              id="structuralResult"
              label="Structural Result"
              isEditing={false}
            />
          </Col>
          <Col span="12">
            <DatePicker
              id="passingStructuralReceived"
              label="Passing Structural Received"
              isEditing={false}
            />
            <TextArea
              id="structuralComments"
              label="Structural Comments"
              isEditing={false}
            />
          </Col>
        </Row>
        <Row className="action-buttons">
          <PrimaryButton
            title="View details"
            target="_blank"
            href={`/leasing/${expectedId}/details/workflow`}
            type="primary"
          />
        </Row>
      </Form>
    </Panel>
  );
};
