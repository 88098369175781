import { IAssignee, ISelectorOption } from 'providence-types';
import { findFromSelectorOptions } from '@core/utils/methods';

export const assigneesIdsToNamedAssignees = (
  assignees: IAssignee[],
  adminsList: ISelectorOption<string>[],
): string[] =>
  assignees
    .map(
      ({ assignee }: IAssignee) =>
        findFromSelectorOptions(assignee, adminsList) || '',
    )
    .filter((v: string) => !!v);
