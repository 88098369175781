import { Store } from 'antd/es/form/interface';
import { ExportType, MainPaceTask, ProjectType } from 'providence-types';

export const getAccountingBrowseFormDefValues = (
  projectTypeMode: ProjectType,
): Store => ({
  invoiceDatePaceTaskId:
    projectTypeMode === ProjectType.LTE
      ? MainPaceTask.LeasingComplete
      : MainPaceTask.CandidateApproved,
  exportType: ExportType.CSV,
});
