import { ReactText } from 'react';
import { PaginationConfig } from 'antd/lib/pagination';
import { SorterResult } from 'antd/lib/table/interface';
import { MainBrowseFilter } from '../interfaces';

export const browseFiltersChange =
  (setFilter: (value: React.SetStateAction<MainBrowseFilter>) => void) =>
  (
    { current: page, pageSize: size }: PaginationConfig,
    filters: Record<string, ReactText[] | null>,
    sorter: SorterResult<any> | SorterResult<any>[],
  ): void => {
    const { columnKey: sortBy, order: sortOrder } = sorter as SorterResult<any>;

    setFilter((prevState: MainBrowseFilter) => ({
      ...prevState,
      ...(page && { page }),
      ...(size && { size }),
      sortBy: sortOrder ? sortBy : undefined,
      sortOrder,
    }));
  };
