import React from 'react';
import { Panel } from '@permitting/jurisdiction/details/models';
import { DropDownSelectorValues, ProjectType } from 'providence-types';
import { getDDVById } from '@core/utils/methods';
import {
  JurisdictionDetailsPermitting,
  PermittingPermission,
} from '@models/interfaces';
import { PermittingContent } from '../components/permitting-content';

export const mapDataToPermittingPanelData = (
  data: JurisdictionDetailsPermitting[],
  permittingLTEFields: PermittingPermission.Permitting,
  permittingNSBFields: PermittingPermission.Permitting,
): Panel[] =>
  data.map((details: JurisdictionDetailsPermitting) => {
    const { projectType, projectSubType, ...formFields } = details;

    const projectTypeDDV = getDDVById(
      DropDownSelectorValues.ProjectType,
      projectType,
    );
    const projectSubTypeDDV = getDDVById(
      DropDownSelectorValues.ProjectSubtype,
      projectSubType,
    );

    return {
      header: {
        id: formFields.id,
        projectType: projectTypeDDV,
        projectSubType: projectSubTypeDDV,
      },
      content:
        projectTypeDDV.value === ProjectType.LTE ? (
          <PermittingContent
            permissions={permittingLTEFields}
            formValue={formFields}
          />
        ) : (
          <PermittingContent
            permissions={permittingNSBFields}
            formValue={formFields}
          />
        ),
    };
  });
