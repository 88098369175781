import { RangePickerValues } from '@models/interfaces';
import { MomentNull } from '@models/types';
import { RangePickerProps } from '../interfaces';
import { reverseType } from './reverse-type';

export const changeValue =
  (
    type: keyof RangePickerValues,
    onChange: RangePickerProps['onChange'],
    value: RangePickerProps['value'],
  ) =>
  (changedValue: MomentNull): void => {
    const oppositeType = reverseType(type);

    onChange?.(
      !changedValue && !value?.[oppositeType]
        ? undefined
        : { ...value, [type]: changedValue },
    );
  };
