import { StoredRequest } from '@core/utils/store/stored-request-decorator';
import {
  ApiMethods,
  SectorEquipmentType as SectorEquipmentTypeEnum,
} from '@models/enums';
import {
  OtherEquipmentData,
  PaginatedResponse,
  RangePickerValues,
  ScopingBrowse,
  ScopingEquipmentUpdateBody,
  ScopingSummaryData,
  SectorEquipment,
  SectorEquipmentData,
  SectorEquipmentType,
} from '@models/interfaces';
import {
  MainScopingBrowseFilter,
  ProjectDetailsWithoutCategories,
} from '@models/types';
import {
  BreadcrumbsActions,
  ProjectActions,
  ScopingActions,
} from '@store/actions';
import { ProjectCategoriesEffects, ProjectsEffects } from '@store/effects';
import store from '@store/index';
import { CommonHttpService } from './common-http.service';

export class ScopingHttpService extends CommonHttpService {
  @StoredRequest(ScopingActions.getScopingBrowseDataAction)
  getScopingBrowse(
    params: MainScopingBrowseFilter,
  ): Promise<PaginatedResponse<ScopingBrowse[]>> {
    return this.http.callApi.get(`${this.apiUrl}/engineering/scoping`, null, {
      params,
    });
  }

  exportScheduledScoping(date: RangePickerValues): Promise<void> {
    return this.downloadFile(
      ApiMethods.Get,
      `${this.apiUrl}/engineering/scoping/report`,
      { params: { date } },
    );
  }

  @StoredRequest(ScopingActions.updateScopingBrowseDataAction)
  updateScopingBrowse(
    params: MainScopingBrowseFilter,
    body: { date: string },
  ): Promise<PaginatedResponse<ScopingBrowse[]>> {
    return this.http.callApi.patch(`${this.apiUrl}/engineering/scoping`, body, {
      params,
    });
  }

  @StoredRequest(
    ScopingActions.getScopingDetailsAction,
    BreadcrumbsActions.Engineering.scoping,
  )
  async getScoping(
    id: string,
  ): Promise<Omit<ProjectDetailsWithoutCategories, 'RAD'>> {
    const response = await this.http.callApi.get(
      `${this.apiUrl}/engineering/scoping/${id}`,
    );

    store.dispatch(
      ProjectActions.updateProjectPaceTaskFields.done(response.paceTasks),
    );

    return ProjectsEffects.setHoldOptions(
      ProjectsEffects.setCurrentProvidenceAgent(
        ProjectsEffects.setRAD(
          ProjectCategoriesEffects.setProjectCategories(response),
        ),
      ),
    );
  }

  @StoredRequest(ScopingActions.updateScopingSummaryAction)
  async updateScopingSummary(
    id: string,
    body: ScopingSummaryData,
  ): Promise<ScopingSummaryData> {
    await this.http.callApi.patch(
      `${this.apiUrl}/engineering/scoping/summary/${id}`,
      body,
    );

    return body;
  }

  @StoredRequest(ScopingActions.updateScopingEquipmentAction)
  async updateScopingEquipment(
    id: string,
    body: ScopingEquipmentUpdateBody,
  ): Promise<ScopingEquipmentUpdateBody> {
    const { otherEquipment, sectors, ...restData } = body;

    const processedOtherEquipment = otherEquipment.map(
      (equipment: OtherEquipmentData) => {
        const { equipmentType, generatedId, ...itemToReq } = equipment;

        return itemToReq;
      },
    );

    const processedSectors = Object.keys(sectors)
      .filter(
        (sectorName: string) =>
          sectors[sectorName as SectorEquipmentTypeEnum].length,
      )
      .reduce<SectorEquipmentData[]>(
        (
          acc: SectorEquipmentData[],
          sectorName: string,
        ): SectorEquipmentData[] => [
          ...acc,
          {
            type: sectorName,
            sectorEquipmentTypes: sectors[
              sectorName as SectorEquipmentTypeEnum
            ].map((sector: SectorEquipmentType) => ({
              ...sector,
              equipments: sector.equipments.filter(
                (equipmentItem: SectorEquipment) => !!equipmentItem.equipment,
              ),
            })),
          } as SectorEquipmentData,
        ],
        [],
      );

    await this.http.callApi.patch(
      `${this.apiUrl}/engineering/scoping/equipment/${id}`,
      {
        ...restData,
        sectors: processedSectors,
        otherEquipment: processedOtherEquipment,
      },
    );

    return { otherEquipment, sectors, ...restData };
  }
}
