import { DetailsResolver } from '@core/resolvers';
import { HttpService } from '@core/services';
import { EquipmentHttpService, LeasingHttpService } from '@core/services/http';
import { getIdParamFromUrl } from '@core/utils/methods';
import store from '@store/index';
import { LeasingState } from '@store/reducers/leasing.reducer';

export class LeasingDetailsResolver extends DetailsResolver {
  private static get _state(): LeasingState {
    return store.getState()?.leasing;
  }

  async resolve(): Promise<void> {
    await super.resolve();

    const [id] = getIdParamFromUrl();

    await HttpService.getHttpRequests(LeasingHttpService).getLeasing(id);
    await HttpService.getHttpRequests(EquipmentHttpService).getEquipmentTypes();

    if (LeasingDetailsResolver._state?.equipmentTypeNames?.length) {
      await HttpService.getHttpRequests(
        EquipmentHttpService,
      ).getEquipmentsByTypes(LeasingDetailsResolver._state?.equipmentTypeNames);
    }
  }
}
