import { Undefinable } from 'providence-types';
import { AccountingNotificationData, DashboardItem } from '@models/interfaces';

export const accountingNotificationSearcher = (
  data: DashboardItem<AccountingNotificationData>[],
  searchValue: string,
): DashboardItem<AccountingNotificationData>[] => {
  const regExp = new RegExp(searchValue, 'gi');

  return !searchValue
    ? data
    : data.filter(
        ({
          notificationData: { type },
          projectData: {
            projectBundleId,
            site: { siteNumber },
          },
        }: DashboardItem<AccountingNotificationData>) =>
          [type, projectBundleId.toString(), siteNumber].some(
            (item: Undefinable<string>) => item && regExp.test(item),
          ),
      );
};
