import { actionButtons } from '@models/constants';
import { AdminVendorsData } from '@models/interfaces';
import { ColumnsFunc } from '@models/types';
import { VirtualTableColumn } from '@shared/components';
import { VendorListTableItem } from '../interfaces';

export const getAdminVendorsColumns = (
  editFunc: ColumnsFunc<AdminVendorsData>,
  deleteFunc: ColumnsFunc<AdminVendorsData>,
): Array<VirtualTableColumn> => [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
  },
  actionButtons({ editFunc, deleteFunc }) as VirtualTableColumn,
];

export const getAdminVendorsDistributionColumns = (
  deleteFunc: ColumnsFunc<VendorListTableItem>,
): Array<VirtualTableColumn> => [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
  },
  actionButtons({ deleteFunc }) as VirtualTableColumn,
];
