import React, { Dispatch, FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { HttpService } from '@core/services';
import { EquipmentHttpService } from '@core/services/http';
import { toggleModal } from '@core/utils/methods';
import { ModalMainTypes } from '@models/enums';
import { EquipmentTypeData } from '@models/interfaces';
import { DeleteModal, VirtualTable } from '@shared/components';
import { PrimaryButton } from '@shared/modules';
import { EquipmentSelectors } from '@store/selectors';
import { EquipmentTypeModal } from './modals';
import { equipmentTypeDefaultValue, equipmentTypeTableColumns } from './models';

import './styles.scss';

export const EquipmentType: FC = () => {
  const equipmentTypes = useSelector(EquipmentSelectors.getEquipmentTypes);
  const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<EquipmentTypeData>({
    ...equipmentTypeDefaultValue,
  });
  const [isAddEditModalVisible, setAddEditModalVisible] =
    useState<boolean>(false);
  const [currentModalType, setCurrentModalType] = useState<ModalMainTypes>(
    ModalMainTypes.Add,
  );

  const toggleAddAndEditModal = (type: ModalMainTypes): void => {
    setCurrentModalType(type);
    setAddEditModalVisible(!isAddEditModalVisible);

    if (isAddEditModalVisible) {
      setSelectedItem({ ...equipmentTypeDefaultValue });
    }
  };

  const remove = async (): Promise<void> => {
    await HttpService.getHttpRequests(EquipmentHttpService).removeEquipmentType(
      selectedItem.id,
    );

    setDeleteModalVisible(false);
  };

  const selectEquipmentType = (
    equipmentType: EquipmentTypeData,
    setter: Dispatch<boolean>,
    prevState: boolean,
  ): void => {
    setSelectedItem(equipmentType);
    setter(!prevState);
  };

  return (
    <div className="prov-equipment-type">
      <DeleteModal
        onOk={remove}
        onCancel={toggleModal(setDeleteModalVisible)}
        visible={deleteModalVisible}
      />
      <EquipmentTypeModal
        visible={isAddEditModalVisible}
        modalType={currentModalType}
        toggleModal={toggleAddAndEditModal}
        value={selectedItem}
      />
      <VirtualTable
        dataSource={equipmentTypes}
        columns={equipmentTypeTableColumns(
          (equipmentType: EquipmentTypeData): void =>
            selectEquipmentType(
              equipmentType,
              () => toggleAddAndEditModal(ModalMainTypes.Edit),
              isAddEditModalVisible,
            ),
          (equipmentType: EquipmentTypeData): void =>
            selectEquipmentType(
              equipmentType,
              setDeleteModalVisible,
              deleteModalVisible,
            ),
        )}
        maxViewRow={10}
      />
      <PrimaryButton
        icon="+"
        title="Add"
        className="prov-equipment-type__add-button"
        onClick={(): void => toggleAddAndEditModal(ModalMainTypes.Add)}
      />
    </div>
  );
};
