import React, { FC, PropsWithChildren, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Form } from 'antd';
import { HttpService } from '@core/services';
import { CountyStatesHttpService } from '@core/services/http';
import { isValidForm } from '@core/utils/methods';
import { serverValidator } from '@core/utils/validators';
import { WHITESPACE_INPUT_LIMIT_RULES } from '@models/constants';
import { ModalMainTypes } from '@models/enums';
import { AdminCountyData, StateWithCounties } from '@models/interfaces';
import { AddAndEditModal } from '@shared/components';
import { Input } from '@shared/modules';
import { CountySelectors } from '@store/selectors';
import { CountyModalProps } from './models';

import './styles.scss';

const { useForm } = Form;

export const CountyModal: FC<CountyModalProps> = ({
  itemValue,
  visible,
  modalType,
  toggleModal,
  selectorValue,
}: PropsWithChildren<CountyModalProps>) => {
  const [form] = useForm();
  const states = useSelector(CountySelectors.getStatesWithCounties);

  const countyStatesService = useMemo(
    () => HttpService.getHttpRequests(CountyStatesHttpService),
    [],
  );

  const currentState = states.find(
    (state: StateWithCounties) => state.id === selectorValue,
  );

  const getInputData = (): AdminCountyData => {
    const formField = form.getFieldsValue(['county']);

    return {
      name: formField.county,
      id: itemValue.id,
    };
  };

  const add = async ({ name }: AdminCountyData): Promise<void> => {
    if ((await isValidForm(form)) && currentState) {
      await countyStatesService.addCounty(currentState, name);
      toggleModal(modalType);
    }
  };

  const edit = async (value: AdminCountyData): Promise<void> => {
    if ((await isValidForm(form)) && currentState) {
      await countyStatesService.updateCounty(currentState, value);
      toggleModal(modalType);
    }
  };

  useEffect(() => {
    if (visible) {
      form.setFieldsValue({
        county: itemValue.name,
      });
    }
  }, [visible, itemValue, form]);

  return (
    <AddAndEditModal
      onOk={
        modalType === ModalMainTypes.Add
          ? (): Promise<void> => add(getInputData())
          : (): Promise<void> => edit(getInputData())
      }
      onCancel={(): void => toggleModal(modalType)}
      visible={visible}
      type={modalType}
      title="County"
      okText="Save"
      cancelText="Delete"
      className="prov-admin-county-modal"
      formProps={{
        labelCol: { span: 5 },
        wrapperCol: { span: 15 },
        form,
        initialValues: itemValue,
      }}
    >
      <Input
        id="county"
        label="County"
        formItemProps={{
          rules: [
            serverValidator(
              {
                method: 'getCountyValidation',
                errorMessage: 'County must be unique',
              },
              CountyStatesHttpService,
              {
                countyId:
                  modalType === ModalMainTypes.Add ? undefined : itemValue.id,
                stateId: currentState ? currentState.id : undefined,
              },
            ),
            ...WHITESPACE_INPUT_LIMIT_RULES,
          ],
        }}
      />
    </AddAndEditModal>
  );
};
