import { Resolver } from '@wellyes/react-router-extended';
import {
  SubPage,
  Tab,
  TabPermissionsType,
  Undefinable,
} from 'providence-types';
import { HttpService } from '@core/services';
import {
  EngineeringHttpService,
  EquipmentHttpService,
  ProjectCategoryHttpService,
  SiteAuditHttpService,
  SiteHttpService,
} from '@core/services/http';
import { getIdParamFromUrl } from '@core/utils/methods';
import store from '@store/index';
import { AppState } from '@store/reducers';
import { EngineeringBrowseState } from '@store/reducers/engineering/browse.reducer';

export class EngineeringDetailsResolver implements Resolver {
  private static get _state(): AppState {
    return store.getState();
  }

  private static get _engineeringState(): EngineeringBrowseState {
    return EngineeringDetailsResolver._state.engineering.browse;
  }

  private static get _userTabPermissions(): Undefinable<TabPermissionsType> {
    return EngineeringDetailsResolver._state.user.activeUser?.permissions
      ?.engineering?.tabs;
  }

  private static get _SOFPermissions(): Undefinable<boolean> {
    return EngineeringDetailsResolver._userTabPermissions?.[
      SubPage.EngineeringBrowse
    ]?.[Tab.SOF].haveAccess;
  }

  private static get _SiteAuditPermissions(): Undefinable<boolean> {
    return EngineeringDetailsResolver._userTabPermissions?.[
      SubPage.EngineeringBrowse
    ]?.[Tab.SiteAudit].haveAccess;
  }

  async resolve(): Promise<void> {
    const [engineeringId] = getIdParamFromUrl();

    await HttpService.getHttpRequests(EngineeringHttpService).getEngineering(
      engineeringId,
    );
    await HttpService.getHttpRequests(
      ProjectCategoryHttpService,
    ).getProjectCategories();
    await HttpService.getHttpRequests(
      SiteHttpService,
    ).getFALocationNumberOptions();

    if (EngineeringDetailsResolver._SiteAuditPermissions) {
      await HttpService.getHttpRequests(SiteAuditHttpService).getSiteAudit(
        engineeringId,
      );
    }

    if (EngineeringDetailsResolver._SOFPermissions) {
      await HttpService.getHttpRequests(
        EquipmentHttpService,
      ).getEquipmentTypes();

      if (
        EngineeringDetailsResolver._engineeringState?.equipmentTypeNames?.length
      ) {
        await HttpService.getHttpRequests(
          EquipmentHttpService,
        ).getEquipmentsByTypes(
          EngineeringDetailsResolver._engineeringState?.equipmentTypeNames,
        );
      }
    }
  }
}
