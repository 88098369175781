import { Resolver } from '@wellyes/react-router-extended';
import { DriverWorkflow } from 'providence-types';
import { HttpService } from '@core/services';
import {
  DriverManagementHttpService,
  ProjectCategoryHttpService,
  ProjectManagementHttpService,
} from '@core/services/http';
import { getIdParamFromUrl } from '@core/utils/methods';

export class ProjectManagementDetailsResolver implements Resolver {
  async resolve(): Promise<void> {
    const [projectId] = getIdParamFromUrl();

    await HttpService.getHttpRequests(
      ProjectManagementHttpService,
    ).getProjectManagementDetails(projectId);

    await HttpService.getHttpRequests(
      ProjectCategoryHttpService,
    ).getProjectCategories();

    await HttpService.getHttpRequests(
      DriverManagementHttpService,
    ).getDriversListByWorkflow(DriverWorkflow.ProjectManagement);
  }
}
