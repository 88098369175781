import React, { Dispatch, FC, PropsWithChildren } from 'react';
import { SelectValue } from 'antd/lib/select';
import { UserRole, UserRoleView } from 'providence-types';
import { mapFromBackendToUserRole } from '@core/utils/methods';
import {
  ENGINEERING_TYPES_OPTIONS,
  USER_ROLE_OPTIONS,
} from '@models/constants';
import { Autocomplete } from '@shared/modules';
import { USER_STATUS_SELECT_OPTIONS } from './models/constants';

import './styles.scss';

export const UserManagementFilters: FC<{
  selectValueArray: SelectValue;
  setSelectValueArray: Dispatch<SelectValue>;
}> = ({
  selectValueArray,
  setSelectValueArray,
}: PropsWithChildren<{
  selectValueArray: SelectValue;
  setSelectValueArray: Dispatch<SelectValue>;
}>) => (
  <div className="user-management-filters">
    <Autocomplete
      label="Roles"
      id="roles"
      options={USER_ROLE_OPTIONS}
      elementProps={{
        mode: 'multiple',
        onChange: (value: SelectValue): void => setSelectValueArray(value),
      }}
    />
    {(selectValueArray as UserRole[])
      .map((role: UserRole) => mapFromBackendToUserRole(role))
      .includes(UserRoleView.EngineeringManager) && (
      <Autocomplete
        label="Engineering Type"
        id="engineeringManagerType"
        options={ENGINEERING_TYPES_OPTIONS}
        elementProps={{
          allowClear: true,
        }}
      />
    )}
    <Autocomplete
      id="isActive"
      label="Status"
      options={USER_STATUS_SELECT_OPTIONS}
      elementProps={{
        allowClear: true,
      }}
    />
  </div>
);
