import { DropDownSelectorValues } from 'providence-types';
import { arraySort } from '@core/utils/methods';
import { createReducer, on } from '@core/utils/store';
import {
  AddAndUpdateDropdownValueResponse,
  AdminDropdownValuesData,
  OptionProps,
  PayloadAndState,
} from '@models/interfaces';
import {
  DDVSelectorKey,
  DropdownValues,
  SelectorsOptions,
} from '@models/types';
import { DropdownValuesActions } from '@store/actions';

export interface DropdownValuesState {
  dropdownValues: DropdownValues;
  dropdownValuesOptions: OptionProps[];
  dropdownsOptions: SelectorsOptions;
  isFetching: boolean;
  errors: boolean;
}

const initialState: DropdownValuesState = {
  dropdownValues: {} as DropdownValues,
  dropdownValuesOptions: [],
  dropdownsOptions: Object.keys(DropDownSelectorValues).reduce(
    (acc: SelectorsOptions, key: string) => ({
      ...acc,
      [key]: [],
    }),
    {} as SelectorsOptions,
  ),
  isFetching: false,
  errors: false,
};

const getKey = (key: string): DDVSelectorKey => key as DDVSelectorKey;

const getObjectKeys = (data: DropdownValues): DDVSelectorKey[] =>
  Object.keys(data) as DDVSelectorKey[];

const getDropdownsOptions = (data: DropdownValues): SelectorsOptions =>
  getObjectKeys(data).reduce((acc: SelectorsOptions, key: DDVSelectorKey) => {
    const mapData = data[key] as AdminDropdownValuesData[];

    return {
      ...acc,
      [key]: arraySort(
        mapData.map((dropdownValue: AdminDropdownValuesData) => ({
          value: dropdownValue.id,
          viewValue: dropdownValue.value,
        })),
        'ASC',
        'viewValue',
      ),
    };
  }, {} as SelectorsOptions);

export const reducer = createReducer(
  initialState,
  // GET ALL DROPDOWN VALUES
  on(
    DropdownValuesActions.getAdminDropdownValuesDataAction,
    ({
      payload: dropdownValues,
    }: PayloadAndState<DropdownValues, DropdownValuesState>) => {
      dropdownValues.Frequency?.sort(
        (a: AdminDropdownValuesData, b: AdminDropdownValuesData) =>
          a.value.localeCompare(b.value, 'en', { numeric: true }),
      );

      return {
        dropdownValues,
        dropdownsOptions: getDropdownsOptions(dropdownValues),
      };
    },
  ),
  // GET ALL DROPDOWN NAMES
  on(
    DropdownValuesActions.getAdminDropdownValuesOptionsAction,
    ({
      payload: dropdownValuesOptions,
    }: PayloadAndState<OptionProps[], DropdownValuesState>) => ({
      dropdownValuesOptions,
    }),
  ),
  // ADD DROPDOWN VALUE
  on(
    DropdownValuesActions.addAdminDropdownValuesDataAction,
    ({
      payload,
      state: { dropdownValues },
    }: PayloadAndState<
      AddAndUpdateDropdownValueResponse,
      DropdownValuesState
    >) => {
      const [[key, dropdownValue]] =
        Object.entries<AdminDropdownValuesData>(payload);
      const dropdownArray = [
        ...(dropdownValues[getKey(key)] ?? []),
        dropdownValue,
      ];

      const processedPayload = {
        ...dropdownValues,
        [key]:
          key === 'Frequency'
            ? dropdownArray.sort(
                (a: AdminDropdownValuesData, b: AdminDropdownValuesData) =>
                  a.value.localeCompare(b.value, 'en', { numeric: true }),
              )
            : arraySort(dropdownArray, 'ASC', 'value'),
      };

      return {
        dropdownValues: processedPayload,
        dropdownsOptions: getDropdownsOptions(processedPayload),
      };
    },
  ),
  // UPDATE DROPDOWN VALUE
  on(
    DropdownValuesActions.updateAdminDropdownValuesDataAction,
    ({
      payload,
      state: { dropdownValues },
    }: PayloadAndState<
      AddAndUpdateDropdownValueResponse,
      DropdownValuesState
    >) => {
      const [[key, dropDownValue]] =
        Object.entries<AdminDropdownValuesData>(payload);
      const dropdownValuesKey = getKey(key);
      const index = dropdownValues[dropdownValuesKey].findIndex(
        (dropdownValue: AdminDropdownValuesData) =>
          dropdownValue.id === dropDownValue.id,
      );
      const arr = [...dropdownValues[dropdownValuesKey]];

      arr.splice(index, 1, dropDownValue);

      const processedPayload = {
        ...dropdownValues,
        [key]:
          key === 'Frequency'
            ? arr.sort(
                (a: AdminDropdownValuesData, b: AdminDropdownValuesData) =>
                  a.value.localeCompare(b.value, 'en', { numeric: true }),
              )
            : arraySort(arr, 'ASC', 'value'),
      };

      return {
        dropdownValues: processedPayload,
        dropdownsOptions: getDropdownsOptions(processedPayload),
      };
    },
  ),
  // DELETE DROPDOWN VALUE
  on(
    DropdownValuesActions.deleteAdminDropdownValuesDataAction,
    ({
      payload: id,
      state: { dropdownValues },
    }: PayloadAndState<string, DropdownValuesState>) => {
      const processedPayload = getObjectKeys(dropdownValues).reduce(
        (acc: DropdownValues, key: DDVSelectorKey) => ({
          ...acc,
          [key]: dropdownValues[key].filter(
            (dropdownValue: AdminDropdownValuesData) => dropdownValue.id !== id,
          ),
        }),
        {} as DropdownValues,
      );

      return {
        dropdownValues: processedPayload,
        dropdownsOptions: getDropdownsOptions(processedPayload),
      };
    },
  ),
);
