import { DownloadOutlined } from '@ant-design/icons/lib';
import { RedirectGuard } from '@core/guards';
import { Route, RouteGroup } from '@models/classes';
import { MenuPlacement } from '@models/enums';
import { ExportsBrowse } from './components';
import { ExportsBrowseResolver } from './resolvers';

export const exportsRoutes = new RouteGroup({
  name: 'Exports',
  path: '/exports',
})
  .addResolver(new ExportsBrowseResolver())
  .breadcrumb()
  .addGuard(new RedirectGuard('exports'), '/')
  .setMenu(MenuPlacement.Main, DownloadOutlined)
  .addRoute(
    new Route({
      name: 'Browse',
      path: '/browse',
      component: ExportsBrowse,
    })
      .breadcrumb()
      .addGuard(new RedirectGuard('exports'), '/')
      .setMenu(MenuPlacement.Main),
  );
