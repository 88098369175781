import React, {
  FC,
  PropsWithChildren,
  ReactText,
  useEffect,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Form, Row } from 'antd';
import classNames from 'classnames';
import { HttpService, ObjectComparatorService } from '@core/services';
import { EquipmentHttpService, LeasingHttpService } from '@core/services/http';
import { useDidUpdateEffect, useSaveChanged } from '@core/utils/hooks';
import { wrappedTextRender } from '@core/utils/methods';
import {
  ActionRes,
  EditableComponentProps,
  EquipmentModal,
} from '@models/interfaces';
import { NotificationsLoader } from '@shared/components';
import { PrimaryButton } from '@shared/modules';
import { CommonActions, LeasingActions } from '@store/actions';
import { LeasingSelectors, ProjectCategoriesSelectors } from '@store/selectors';
import {
  AssociatedProjectsModal,
  LeasingEquipmentNotes,
  LeasingEquipmentSection,
} from './components';

import './styles.scss';

const { useForm } = Form;

export const LeasingEquipment: FC<EditableComponentProps> = ({
  isEditing,
  toggleEditing,
  data: {
    equipmentTypeNames,
    id,
    curPriorTableData,
    curFinalTableData,
    priorTableData,
    finalTableData,
    scopingTableData,
    scopingNotes,
    priorLeasingNotes,
    finalLeasingNotes,
  },
}: PropsWithChildren<EditableComponentProps>) => {
  const projectCategoriesTableVisible = useSelector(
    ProjectCategoriesSelectors.getProjectCategoriesTableVisible,
  );
  const leasingFetching = useSelector(LeasingSelectors.isFetching);

  const dispatch = useDispatch();

  const [priorForm] = useForm();
  const [scopingForm] = useForm();
  const [finalForm] = useForm();

  const [selectedRows, setSelectedRows] = useState<ReactText[]>([]);
  const [priorData, setPriorData] = useState<EquipmentModal[]>();
  const [scopingData, setScopingData] = useState<EquipmentModal[]>();
  const [finalData, setFinalData] = useState<EquipmentModal[]>();
  const [isAssociatedProjectsModalVisible, setAssociatedProjectsModalVisible] =
    useState<boolean>(false);

  const toggleAssociatedProjectsModal = (): void => {
    setAssociatedProjectsModalVisible(!isAssociatedProjectsModalVisible);
  };

  useEffect(() => {
    if (equipmentTypeNames.length) {
      HttpService.getHttpRequests(EquipmentHttpService).getEquipmentsByTypes(
        equipmentTypeNames,
      );
    }
  }, [equipmentTypeNames]);

  useEffect(() => {
    setPriorData(curPriorTableData);
    setFinalData(curFinalTableData);
    setScopingData(scopingTableData);
  }, [curPriorTableData, curFinalTableData, scopingTableData]);

  useEffect(() => {
    priorForm.setFieldsValue({
      priorLeasingNotes,
    });
  }, [finalLeasingNotes]);

  useEffect(() => {
    finalForm.setFieldsValue({
      finalLeasingNotes,
    });
  }, [finalLeasingNotes]);

  useEffect(() => {
    scopingForm.setFieldsValue({ scopingTowerNotes: scopingNotes });
  }, [scopingNotes]);

  const deleteGeneratedIds = (data: EquipmentModal[]): EquipmentModal[] =>
    data.map((equipment: EquipmentModal) => {
      const clonedEquipment = { ...equipment };

      delete clonedEquipment.modelNumber;
      delete clonedEquipment.equipmentTypeName;
      delete clonedEquipment.generatedId;
      delete clonedEquipment.manufacturer;

      return clonedEquipment;
    });

  useDidUpdateEffect(() => {
    dispatch(
      CommonActions.setHasUnsubmittedData.done(
        !(
          ObjectComparatorService.arraysCompare(
            deleteGeneratedIds(curPriorTableData),
            priorTableData,
          ) &&
          ObjectComparatorService.arraysCompare(
            deleteGeneratedIds(curFinalTableData),
            finalTableData,
          )
        ),
      ),
    );
  }, [curPriorTableData, curFinalTableData, priorTableData, finalTableData]);

  useEffect(
    () => (): void => {
      dispatch(CommonActions.setHasUnsubmittedData.done(false));
    },

    [],
  );

  const onSubmit = async (): Promise<void> => {
    try {
      await HttpService.getHttpRequests(
        LeasingHttpService,
      ).updateLeasingEquipment(id, {
        priorTableData: deleteGeneratedIds(
          curPriorTableData.map((equipment: EquipmentModal) => ({
            ...equipment,
            leaseRights: selectedRows.includes(equipment.generatedId || ''),
          })),
        ),
        finalTableData: deleteGeneratedIds([
          ...curFinalTableData,
          ...curPriorTableData
            .map((priorEquipment: EquipmentModal) => ({
              ...priorEquipment,
              leaseRights: selectedRows.includes(
                priorEquipment.generatedId || '',
              ),
            }))
            .filter(
              (priorEquipment: EquipmentModal) =>
                !curFinalTableData.find(
                  (finalEquipment: EquipmentModal) =>
                    priorEquipment.equipment === finalEquipment.equipment,
                ) && priorEquipment.leaseRights,
            ),
        ]),
        priorLeasingNotes: priorForm.getFieldsValue().priorLeasingNotes,
        finalLeasingNotes: finalForm.getFieldsValue().finalLeasingNotes,
      });

      NotificationsLoader.notificationSuccess(
        'Leasing Equipment Information has been updated!',
      );

      toggleEditing?.();
    } catch (e) {
      console.error(e);
    }
  };

  const onCancel = (): void => {
    dispatch(LeasingActions.resetTablesDataAction.done());
    priorForm.setFieldsValue({
      priorLeasingNotes,
    });
    finalForm.setFieldsValue({
      finalLeasingNotes,
    });
    toggleEditing?.();
  };

  useSaveChanged(isEditing, onSubmit, onCancel);

  const rowSelection = {
    selectedRowKeys: selectedRows,
    onChange: (rows: ReactText[]): void => setSelectedRows(rows),
  };

  useEffect(() => {
    setSelectedRows(
      curPriorTableData
        .filter((equipment: EquipmentModal) => equipment?.leaseRights)
        .map((equipment: EquipmentModal) => equipment?.generatedId || ''),
    );
  }, [curPriorTableData, isEditing]);

  return (
    <div className="prov-leasing-equipment">
      <div
        className={classNames('tabs-wrap', {
          'tabs-wrap_with-actions': isEditing,
          'categories-table-open': projectCategoriesTableVisible,
        })}
      >
        {isEditing && (
          <Row className="prov-leasing-equipment__handler">
            <PrimaryButton
              title="Set prior project"
              onClick={toggleAssociatedProjectsModal}
            />
            <PrimaryButton
              title="Generate final loading"
              onClick={(): ActionRes<ReactText[]> =>
                dispatch(
                  LeasingActions.generateFinalLoadingAction.done(selectedRows),
                )
              }
              disabled={!priorData?.length && !scopingData?.length}
            />
            <AssociatedProjectsModal
              visible={isAssociatedProjectsModalVisible}
              toggleModal={toggleAssociatedProjectsModal}
              projectId={id}
            />
          </Row>
        )}
        <Row className="bottom-row">
          <Col className="bottom-row__col">
            <LeasingEquipmentSection
              header="Final Project Loading"
              isEditing={isEditing}
              data={finalData}
              className="final-project-loading-section"
              sectionStorePath="curFinalTableData"
            />
            <LeasingEquipmentNotes
              label="Final Notes"
              id="finalLeasingNotes"
              isEditing={isEditing}
              form={finalForm}
            />
          </Col>
        </Row>
        <Row className="top-row">
          <LeasingEquipmentSection
            header="Prior Lease Right Totals"
            isEditing={isEditing}
            data={priorData}
            sectionStorePath="curPriorTableData"
            tableProps={{
              rowSelection: isEditing ? rowSelection : undefined,
            }}
          />
          <LeasingEquipmentSection
            header="Scoping Equipment Totals"
            isEditing={false}
            data={scopingData}
            sectionStorePath="scopingTableData"
          />
        </Row>
        <Row className="notes-row">
          <LeasingEquipmentNotes
            label="Prior Leasing Notes"
            id="priorLeasingNotes"
            isEditing={isEditing}
            form={priorForm}
          />
          <LeasingEquipmentNotes
            label="Scoping Notes"
            id="scopingTowerNotes"
            isEditing={false}
            form={scopingForm}
            readOnlyElementRender={wrappedTextRender}
          />
        </Row>
      </div>
      {isEditing && (
        <Row className="prov-site-information__btn-wrap">
          <div>
            <PrimaryButton
              htmlType="submit"
              title="Submit"
              disabled={leasingFetching}
              onClick={onSubmit}
            />
            <PrimaryButton title="Cancel" type="default" onClick={onCancel} />
          </div>
        </Row>
      )}
    </div>
  );
};
