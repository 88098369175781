import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { SubPage, Tab } from 'providence-types';
import { canUserEditByProjectStatusAndRoles } from '@core/utils/methods';
import { EditablePage } from '@shared/components';
import {
  EngineeringSelectors,
  ProjectSelectors,
  UserSelectors,
} from '@store/selectors';
import { Workflow } from './components';

import './styles.scss';

export const EngineeringWorkflow: FC = () => {
  const projectType = useSelector(EngineeringSelectors.getProjectType);
  const projectTypePermission = useSelector(
    UserSelectors.canEditProjectByProjectType(projectType),
  );
  const canEdit = useSelector(
    UserSelectors.canEdit(
      'engineering',
      SubPage.EngineeringBrowse,
      Tab.Workflow,
    ),
  );

  const userRoles = useSelector(UserSelectors.getUserRoles);
  const projectStatus = useSelector(ProjectSelectors.getProjectStatus);

  return (
    <EditablePage
      editableComponent={Workflow}
      canUserEdit={
        canEdit &&
        projectTypePermission &&
        canUserEditByProjectStatusAndRoles(projectStatus, userRoles)
      }
    />
  );
};
