import React, { ReactNode } from 'react';
import { Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { HttpService } from '@core/services';
import { ProjectCategoryHttpService } from '@core/services/http';
import { actionButtons } from '@models/constants';
import { AdminProjectCategoryData } from '@models/interfaces';
import { ColumnsFunc } from '@models/types';
import { VirtualTableColumn } from '@shared/components';

export const getAdminProjectCategoryColumns = (
  editFunc: ColumnsFunc<AdminProjectCategoryData>,
  deleteFunc: ColumnsFunc<AdminProjectCategoryData>,
): Array<VirtualTableColumn<AdminProjectCategoryData>> => [
  {
    title: 'Can be duplicated',
    key: 'canBeDuplicated',
    dataIndex: 'canBeDuplicated',
    width: 200,
    render: (
      checked: boolean,
      rowData: AdminProjectCategoryData,
    ): ReactNode => (
      <Checkbox
        checked={checked}
        onChange={(e: CheckboxChangeEvent): Promise<AdminProjectCategoryData> =>
          HttpService.getHttpRequests(
            ProjectCategoryHttpService,
          ).updateProjectCategoryData({
            ...rowData,
            canBeDuplicated: e.target.checked,
          })
        }
      />
    ),
  },
  {
    title: 'Value',
    dataIndex: 'value',
    key: 'value',
  },
  actionButtons({ editFunc, deleteFunc }) as VirtualTableColumn,
];
