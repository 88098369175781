import { FormInstance } from 'antd/lib/form';
import { RuleOperator, RuleValueType } from 'providence-types';
import { RuleObject, StoreValue } from 'rc-field-form/lib/interface';

export const isCurrentValueType = ({
  getFieldValue,
}: FormInstance): RuleObject => ({
  validator: (rule: RuleObject, value: StoreValue): Promise<void> | void => {
    const operatorValue = getFieldValue('operator');

    let validationResult = Promise.resolve();

    if (operatorValue) {
      const incorrectOperatorsForPreset = [
        RuleOperator.GreaterThan,
        RuleOperator.LessThan,
      ];
      const incorrectOperatorsForDaysCount = [
        RuleOperator.Equal,
        RuleOperator.NotEqual,
      ];

      const conditionForPreset =
        value === RuleValueType.Preset &&
        incorrectOperatorsForPreset.includes(operatorValue);
      const conditionForDaysCount =
        value === RuleValueType.DayCount &&
        incorrectOperatorsForDaysCount.includes(operatorValue);

      if (conditionForPreset || conditionForDaysCount) {
        validationResult = Promise.reject(
          new Error('Chosen Value Type incorrect for chosen Operator!'),
        );
      }
    }

    return validationResult;
  },
});
