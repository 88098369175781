import { MainPaceTask } from 'providence-types';
import { OptionProps } from '../interfaces/option-props';

export const ALLOW_OOM_INFORMATION_PACE_TASK_OPTIONS: OptionProps[] = [
  {
    value: MainPaceTask.LeasingComplete,
    viewValue: 'RE015 - Leasing Complete',
  },
  {
    value: MainPaceTask.SiteAcqComplete,
    viewValue: 'RE020 - Site Acq Complete',
  },
];
