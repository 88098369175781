import React, { ReactElement, ReactNode } from 'react';
import nextId from 'react-id-generator';
import { Checkbox, Select, Tooltip } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { OptionProps, SOFToolOrderInfoData } from '@models/interfaces';
import { VirtualTableColumn } from '@shared/components';

type SOFTableColumnsArgs = {
  onSelectChange: (selectedValue: string, index: number) => void;
  onCheckboxChange: (
    checked: boolean,
    selectedRow: SOFToolOrderInfoData,
    index: number,
  ) => void;
  vendorDistributionList?: OptionProps[];
  isFetching: boolean;
  projectTypePermission: boolean;
};

export const getSOFTableColumns = ({
  onSelectChange,
  onCheckboxChange,
  vendorDistributionList = [],
  isFetching = false,
  projectTypePermission,
}: SOFTableColumnsArgs): Array<VirtualTableColumn<SOFToolOrderInfoData>> => [
  {
    title: 'Services Required',
    key: 'servicesRequired',
    dataIndex: 'servicesRequired',
    width: 200,
    render: (
      checked: boolean,
      rowData: SOFToolOrderInfoData,
      index: number,
    ): ReactNode => {
      const disabled = !rowData.distributionList || isFetching;

      const checkBox = (
        <Checkbox
          disabled={disabled}
          checked={checked}
          onChange={(e: CheckboxChangeEvent): void =>
            onCheckboxChange(e.target.checked, rowData, index)
          }
        />
      );

      const withTooltip = (
        <Tooltip title="Please choose Distribution list">
          {checkBox as ReactElement}
        </Tooltip>
      );

      return disabled ? withTooltip : checkBox;
    },
  },
  {
    title: 'Driver ID',
    key: 'driverId',
    dataIndex: 'driverId',
  },
  {
    title: 'Driver Name',
    key: 'driverName',
    dataIndex: 'driverName',
  },
  {
    title: 'Distribution list',
    key: 'distributionList',
    dataIndex: 'distributionList',
    render: (
      data: string,
      rowData: SOFToolOrderInfoData,
      index: number,
    ): ReactNode => (
      <Select
        id={nextId('distributionList')}
        className="prov-sof-tool-table__selector"
        value={data}
        disabled={isFetching || !projectTypePermission}
        onChange={(selectedValue: string): void =>
          onSelectChange(selectedValue, index)
        }
      >
        {vendorDistributionList.map(
          (vendorDistributionListItem: OptionProps) => (
            <Select.Option
              key={vendorDistributionListItem.value}
              value={vendorDistributionListItem.value}
            >
              {vendorDistributionListItem.viewValue}
            </Select.Option>
          ),
        )}
      </Select>
    ),
  },
];
