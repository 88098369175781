import { EngineeringManagerType } from 'providence-types';

export const getCurrentEngineeringManagerType = (
  value: EngineeringManagerType,
): string => {
  switch (value) {
    case EngineeringManagerType.All:
      return 'All';
    case EngineeringManagerType.LTE:
      return 'LTE';
    case EngineeringManagerType.NSB:
      return 'NSB';
    default:
      throw new Error(`Incorrect value - ${value}`);
  }
};
