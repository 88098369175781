import React, { FC, PropsWithChildren, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Form, Row } from 'antd';
import classNames from 'classnames';
import { DropDownSelectorValues, MainPaceTask } from 'providence-types';
import { Store } from 'rc-field-form/es/interface';
import { HttpService, ObjectComparatorService } from '@core/services';
import { LeasingHttpService } from '@core/services/http';
import { useDidUpdateEffect, useSaveChanged } from '@core/utils/hooks';
import {
  formDiffs,
  isValidForm,
  momentizeObjectDates,
  readOnlyPercentRender,
  readOnlyPriceRender,
  transformFields,
} from '@core/utils/methods';
import { DOLLAR_PREFIX, PERCENT_PREFIX } from '@models/constants';
import {
  EditableComponentProps,
  IdAndValue,
  LeaseAuditData,
  OptionProps,
} from '@models/interfaces';
import { NotificationsLoader, Panel, RADTable } from '@shared/components';
import { ACTUAL_DATE } from '@shared/components/pace-task-table/validators';
import {
  Autocomplete,
  Checkbox,
  Coordinate,
  DatePicker,
  Input,
  InputNumber,
  PrimaryButton,
  TextArea,
} from '@shared/modules';
import { CommonActions } from '@store/actions';
import { ProjectsEffects } from '@store/effects';
import {
  DDVSelectors,
  LeasingSelectors,
  PaceTasksSelectors,
  ProjectCategoriesSelectors,
} from '@store/selectors';
import { LEASE_AUDIT_DATES } from './models/constants';

import './styles.scss';

const { useForm } = Form;

export const LeaseAudit: FC<EditableComponentProps> = ({
  isEditing,
  isActive,
  toggleEditing,
  permissions: { leaseAuditInformationFields, leaseAuditTableFields },
}: PropsWithChildren<EditableComponentProps>) => {
  const dispatch = useDispatch();

  const [form] = useForm();

  const projectCategoriesTableVisible = useSelector(
    ProjectCategoriesSelectors.getProjectCategoriesTableVisible,
  );
  const { leasingInformation, leasingSite, currentProject, leasingAgents } =
    useSelector(LeasingSelectors.getLeasingSummary);
  const structuralTIARevCode = useSelector(
    DDVSelectors.getDDVValuesByType(
      DropDownSelectorValues.StructuralTIARevCode,
    ),
  ) as OptionProps[];
  const towerOwnerDDV = useSelector(
    DDVSelectors.getDDVValuesByType(DropDownSelectorValues.TowerOwner),
  ) as OptionProps[];
  const { LeaseAuditComplete } = useSelector(
    PaceTasksSelectors.getMainPaceTaskDates('actualDate', 'LeaseAuditComplete'),
  );
  const agreementType = useSelector(
    DDVSelectors.getDDVValuesByType(DropDownSelectorValues.AgreementType),
  ) as OptionProps[];
  const leasingFetching = useSelector(LeasingSelectors.isFetching);

  const [auditForm, setAuditForm] = useState<Store>({});
  const [initialAudit, setInitialAudit] = useState<Store>({});

  const objectsCompare = ObjectComparatorService.objectsCompare(
    initialAudit,
    auditForm,
  );

  useEffect(
    () => (): void => {
      dispatch(CommonActions.setHasUnsubmittedData.done(false));
    },

    [],
  );

  useDidUpdateEffect(() => {
    dispatch(CommonActions.setHasUnsubmittedData.done(!objectsCompare));
  }, [objectsCompare]);

  const setFieldsValue = (): void => {
    const {
      state,
      county,
      jurisdiction,
      towerType,
      equipmentType,
      ...restLeasingSiteInfo
    } = leasingSite;

    if (leasingInformation?.leaseAudit) {
      const {
        leaseAudit: {
          documentDate,
          currentTermStartDate,
          currentTermEndDate,
          finalTermEndDate,
          mostRecentSADate,
          ...restData
        },
      } = leasingInformation;

      form.setFieldsValue({
        ...transformFields(restData, [
          'agreementType',
          'mostRecentSATIARevCode',
        ]),
        ...momentizeObjectDates<Partial<LeaseAuditData>>(
          {
            auditDate: LeaseAuditComplete as any as Date,
            documentDate,
            currentTermStartDate,
            currentTermEndDate,
            finalTermEndDate,
            mostRecentSADate,
          },
          LEASE_AUDIT_DATES,
        ),
      });
    }

    form.setFieldsValue({
      ...restLeasingSiteInfo,
      state: state?.name,
      county: county?.name,
      jurisdiction: jurisdiction?.jurisdictionName,
      towerType: towerType?.value,
      equipmentType: (equipmentType as IdAndValue)?.value,
      leasingNotes: leasingInformation?.leasingNotes,
    });
  };

  useEffect(
    () => setFieldsValue(),

    [leasingSite, currentProject, leasingInformation, LeaseAuditComplete],
  );

  const onSubmit = async (): Promise<void> => {
    if (await isValidForm(form)) {
      const { leasingNotes: leasingNotesInitial, ...restInitialData } =
        momentizeObjectDates(initialAudit, LEASE_AUDIT_DATES, true);
      const { leasingNotes, ...restData } = momentizeObjectDates(
        auditForm,
        LEASE_AUDIT_DATES,
        true,
      );

      try {
        await HttpService.getHttpRequests(LeasingHttpService).updateLeaseAudit(
          currentProject?.id,
          {
            agreementType: restData.agreementType,
            ...formDiffs(
              {
                ...restInitialData,
                leasing: {
                  leasingNotes: leasingNotesInitial,
                },
              },
              {
                ...restData,
                leasing: {
                  leasingNotes,
                },
              },
            ),
          } as LeaseAuditData,
        );

        NotificationsLoader.notificationSuccess(
          `Lease Audit has been updated!`,
        );

        ProjectsEffects.updatePaceTaskFieldsByConfig(restData, [
          {
            dateType: 'actualDate',
            task: MainPaceTask.LeaseAuditComplete,
            field: 'auditDate',
          },
        ]);

        setInitialAudit(auditForm);

        if (isEditing) {
          toggleEditing?.();
        }
      } catch (e) {
        console.error(e);
      }
    }
  };

  const onCancel = (): void => {
    form.setFieldsValue({
      ...transformFields(initialAudit, [
        'agreementType',
        'mostRecentSATIARevCode',
      ]),
      ...momentizeObjectDates<Partial<LeaseAuditData>>(
        initialAudit,
        LEASE_AUDIT_DATES,
      ),
    });

    setAuditForm(initialAudit);

    if (isEditing) {
      toggleEditing?.();
    }
  };

  const onValuesChange = (): void => {
    if (Object.keys(form.getFieldsValue()).length) {
      setAuditForm(form.getFieldsValue());

      if (!Object.keys(initialAudit).length) {
        setInitialAudit(form.getFieldsValue());
      }
    }
  };

  useDidUpdateEffect(() => {
    onValuesChange();
  }, [isActive, isEditing]);

  useSaveChanged(isEditing, onSubmit, onCancel);

  return (
    <div className="prov-lease-audit">
      <div
        className={classNames('tabs-wrap', {
          'tabs-wrap_with-actions': isEditing,
          'categories-table-open': projectCategoriesTableVisible,
        })}
      >
        <Panel header="Lease Audit Information" className="lease-audit-panel">
          <Form
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 14 }}
            onValuesChange={onValuesChange}
            form={form}
          >
            <Row>
              <Col span="12">
                <DatePicker
                  id="auditDate"
                  label="Audit Date"
                  isEditing={
                    isEditing && leaseAuditInformationFields?.auditDate
                  }
                  formItemProps={{
                    rules: ACTUAL_DATE,
                  }}
                />
                <Autocomplete
                  id="auditBy"
                  label="Audit By"
                  isEditing={isEditing && leaseAuditInformationFields?.auditBy}
                  options={leasingAgents}
                />
                <Input
                  id="documentAudited"
                  label="Document Audited"
                  isEditing={
                    isEditing && leaseAuditInformationFields?.documentAudited
                  }
                />
                <DatePicker
                  id="documentDate"
                  label="Document Date"
                  isEditing={
                    isEditing && leaseAuditInformationFields?.documentDate
                  }
                />
                <Autocomplete
                  id="agreementType"
                  label="Agreement Type"
                  isEditing={
                    isEditing && leaseAuditInformationFields?.agreementType
                  }
                  options={agreementType}
                />
                <Autocomplete
                  id="towerOwner"
                  label="Tower Owner"
                  isEditing={false}
                  options={towerOwnerDDV}
                />
                <Input
                  id="towerOwnerSiteId"
                  label="Tower Owner Site ID"
                  isEditing={false}
                />
                <Input
                  id="streetAddress"
                  label="Street Address"
                  isEditing={false}
                />
                <Input id="city" label="City" isEditing={false} />
                <Input id="state" label="State" isEditing={false} />
                <Input id="zip" label="Zip" isEditing={false} />
                <Input id="county" label="County" isEditing={false} />
                <Input
                  id="jurisdiction"
                  label="Jurisdiction"
                  isEditing={false}
                />
                <Coordinate id="latitude" label="Latitude" isEditing={false} />
                <Coordinate
                  id="longitude"
                  label="Longitude"
                  isEditing={false}
                />
                <InputNumber
                  id="currentRentAmount"
                  label="Current Rent Amount"
                  isEditing={
                    isEditing && leaseAuditInformationFields?.currentRentAmount
                  }
                  elementProps={{ formatter: DOLLAR_PREFIX }}
                  readOnlyElementRender={readOnlyPriceRender}
                />
                <Input
                  id="escalator"
                  label="Escalator"
                  isEditing={
                    isEditing && leaseAuditInformationFields?.escalator
                  }
                />
              </Col>
              <Col span="12">
                <Input
                  id="termLengthOrExtensions"
                  label="Term Length/Extensions"
                  isEditing={
                    isEditing &&
                    leaseAuditInformationFields?.termLengthOrExtensions
                  }
                />
                <DatePicker
                  id="currentTermStartDate"
                  label="Current Term Start Date"
                  isEditing={
                    isEditing &&
                    leaseAuditInformationFields?.currentTermStartDate
                  }
                />
                <DatePicker
                  id="currentTermEndDate"
                  label="Current Term End Date"
                  isEditing={
                    isEditing && leaseAuditInformationFields?.currentTermEndDate
                  }
                />
                <DatePicker
                  id="finalTermEndDate"
                  label="Final Term End Date"
                  isEditing={
                    isEditing && leaseAuditInformationFields?.finalTermEndDate
                  }
                />
                <DatePicker
                  id="mostRecentSADate"
                  label="Most Recent SA Date"
                  isEditing={
                    isEditing && leaseAuditInformationFields?.mostRecentSADate
                  }
                />
                <div className="lease-audit-panel__row-wrapper">
                  <InputNumber
                    id="mostRecentTowerSAPercentage"
                    label="Most Recent Tower SA Percentage"
                    isEditing={
                      isEditing &&
                      leaseAuditInformationFields?.mostRecentTowerSAPercentage
                    }
                    elementProps={{ formatter: PERCENT_PREFIX }}
                    className={classNames(
                      'most-recent-tower-sa-percentage',
                      'ant-col-17',
                    )}
                    readOnlyElementRender={readOnlyPercentRender}
                  />
                  <Checkbox
                    id="adequate"
                    className={classNames('adequate', 'ant-col-5')}
                    label="Adequate"
                    formItemProps={{
                      wrapperCol: { span: 5 },
                      labelCol: { span: 16 },
                    }}
                    isEditing={
                      isEditing && leaseAuditInformationFields?.adequate
                    }
                  />
                </div>
                <InputNumber
                  id="mostRecentFoundationSAPercentage"
                  label="Most Recent Foundation SA Percentage"
                  isEditing={
                    isEditing &&
                    leaseAuditInformationFields?.mostRecentFoundationSAPercentage
                  }
                  elementProps={{ formatter: PERCENT_PREFIX }}
                  readOnlyElementRender={readOnlyPercentRender}
                />
                <Autocomplete
                  id="mostRecentSATIARevCode"
                  label="SA TIA Rev Code"
                  isEditing={
                    isEditing &&
                    leaseAuditInformationFields?.mostRecentSATIARevCode
                  }
                  options={structuralTIARevCode}
                />
                <TextArea
                  id="leasingNotes"
                  label="Leasing Notes"
                  isEditing={
                    isEditing && leaseAuditInformationFields?.leasingNotes
                  }
                />
                <Input id="towerType" label="Tower Type" isEditing={false} />
                <Input
                  id="towerHeight"
                  label="Tower Height"
                  isEditing={false}
                />
                <Input
                  id="leasedGroundSpace"
                  label="Leased Ground Space"
                  isEditing={false}
                />
                <Input
                  id="equipmentType"
                  label="Equipment Type"
                  isEditing={false}
                />
                <Input
                  id="separateGeneratorSpace"
                  label="Separate Generator Space"
                  isEditing={
                    isEditing &&
                    leaseAuditInformationFields?.separateGeneratorSpace
                  }
                />
                <Input
                  id="generatorDetails"
                  label="Generator Details"
                  isEditing={
                    isEditing && leaseAuditInformationFields?.generatorDetails
                  }
                />
                <Input
                  id="sqInchesEquipmentAllowed"
                  label="Sq. Inches Equipment Allowed"
                  isEditing={false}
                />
                <Input
                  id="sqInchesEquipmentUsed"
                  label="Sq. Inches Equipment Used"
                  isEditing={false}
                />
                <Input
                  id="sqInchesCablesAllowed"
                  label="Sq. Inches Cables Allowed"
                  isEditing={false}
                />
                <Input
                  id="sqInchesCablesUsed"
                  label="Sq. Inches Cables Used"
                  isEditing={false}
                />
              </Col>
            </Row>
          </Form>
        </Panel>
        <Panel header="Azimuth Information" className="lease-audit-panel">
          <Form onValuesChange={onValuesChange} form={form}>
            <Row>
              <Col span="6">
                <InputNumber
                  id="azimuthA"
                  label="Azimuth A"
                  isEditing={isEditing && leaseAuditInformationFields?.azimuthA}
                />
              </Col>
              <Col span="6">
                <InputNumber
                  id="azimuthB"
                  label="Azimuth B"
                  isEditing={isEditing && leaseAuditInformationFields?.azimuthB}
                />
              </Col>
              <Col span="6">
                <InputNumber
                  id="azimuthG"
                  label="Azimuth G"
                  isEditing={isEditing && leaseAuditInformationFields?.azimuthG}
                />
              </Col>
              <Col span="6">
                <InputNumber
                  id="azimuthD"
                  label="Azimuth D"
                  isEditing={isEditing && leaseAuditInformationFields?.azimuthD}
                />
              </Col>
            </Row>
          </Form>
        </Panel>
        <RADTable
          isEditing={isEditing}
          permissions={leaseAuditTableFields}
          projectId={currentProject?.id}
        />
      </div>
      {isEditing && (
        <div className="prov-leasing-handle__btns">
          <div className="action-wrap">
            <PrimaryButton
              title="Submit"
              type="primary"
              disabled={leasingFetching}
              onClick={onSubmit}
            />
            <PrimaryButton title="Cancel" type="default" onClick={onCancel} />
          </div>
        </div>
      )}
    </div>
  );
};
