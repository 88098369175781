import React, { FC, PropsWithChildren, useEffect, useMemo } from 'react';
import { Form } from 'antd';
import { HttpService } from '@core/services';
import { VendorHttpService } from '@core/services/http';
import { getFormData, isValidForm } from '@core/utils/methods';
import {
  emailValidator,
  serverValidator,
  validationRequestParams,
} from '@core/utils/validators';
import { WHITESPACE_INPUT_LIMIT_RULES } from '@models/constants';
import { ModalMainTypes } from '@models/enums';
import { AdminVendorsData } from '@models/interfaces';
import { AddAndEditModal } from '@shared/components';
import { Input } from '@shared/modules';
import { VendorsModalProps } from './models';

import './styles.scss';

const { useForm } = Form;

export const VendorsModal: FC<VendorsModalProps> = ({
  itemValue,
  visible,
  modalType,
  toggleModal,
}: PropsWithChildren<VendorsModalProps>) => {
  const [form] = useForm();

  const vendorService = useMemo(
    () => HttpService.getHttpRequests(VendorHttpService),
    [],
  );

  const add = async ({ name, email }: AdminVendorsData): Promise<void> => {
    if (await isValidForm(form)) {
      await vendorService.addVendor({
        name,
        email,
      });
      toggleModal(modalType);
    }
  };

  const edit = async (value: AdminVendorsData): Promise<void> => {
    if (await isValidForm(form)) {
      await vendorService.editVendor(value);
      toggleModal(modalType);
    }
  };

  useEffect(() => {
    if (visible) {
      form.setFieldsValue(itemValue);
    }
  }, [visible, itemValue, form]);

  return (
    <AddAndEditModal
      onOk={
        modalType === ModalMainTypes.Add
          ? (): Promise<void> => add(getFormData(itemValue, form))
          : (): Promise<void> => edit(getFormData(itemValue, form))
      }
      onCancel={(): void => toggleModal(modalType)}
      visible={visible}
      type={modalType}
      title="Vendor"
      okText="Save"
      cancelText="Cancel"
      className="prov-admin-vendors-modal"
      formProps={{
        wrapperCol: { span: 15 },
        form,
        initialValues: itemValue,
      }}
    >
      <Input
        id="name"
        label="Name"
        formItemProps={{
          rules: [
            { required: true, message: "Vendor's name is required!" },
            serverValidator(
              {
                method: 'getVendorValidation',
                errorMessage: "Vendor's name must be unique!",
              },
              VendorHttpService,
              validationRequestParams(modalType, itemValue, 'name'),
            ),
            ...WHITESPACE_INPUT_LIMIT_RULES,
          ],
        }}
      />
      <Input
        id="email"
        label="Email"
        formItemProps={{
          rules: [
            ...emailValidator(true, {
              requiredMessage: "Vendor's email is required!",
            }),
            serverValidator(
              {
                method: 'getVendorValidation',
                errorMessage: "Vendor's email must be unique!",
              },
              VendorHttpService,
              validationRequestParams(modalType, itemValue, 'email'),
            ),
          ],
        }}
      />
    </AddAndEditModal>
  );
};
