import React, { FC, PropsWithChildren, useEffect } from 'react';
import { Col, Form, Row } from 'antd';
import { InformationProps } from '@models/interfaces';
import { Panel } from '@shared/components';
import { Coordinate, Input } from '@shared/modules';

const { useForm } = Form;

export const SiteInformation: FC<InformationProps> = ({
  className,
  data,
}: PropsWithChildren<InformationProps>) => {
  const [form] = useForm();

  const isEditing = false;

  useEffect(() => {
    const {
      FALocationNumber,
      siteNumber,
      longitude,
      towerType,
      towerHeight,
      streetAddress,
      city,
      state,
      zip,
      county,
    } = data;

    form.setFieldsValue({
      FALocationNumber,
      siteNumber,
      longitude,
      towerType: towerType?.value,
      towerHeight,
      streetAddress,
      city,
      state: state?.name,
      zip,
      county: county?.name,
    });
  }, []);

  return (
    <Panel header="Site Information" className={className}>
      <Form labelCol={{ span: 14 }} wrapperCol={{ span: 10 }} form={form}>
        <Row>
          <Col span="12">
            <Input
              id="FALocationNumber"
              label="FA Location Number"
              isEditing={isEditing}
            />
            <Input id="siteNumber" label="Site Number" isEditing={isEditing} />
            <Coordinate
              id="longitude"
              label="Longitude"
              isEditing={isEditing}
            />
            <Input id="towerType" label="Tower Type" isEditing={isEditing} />
            <Input
              id="towerHeight"
              label="Tower Height"
              isEditing={isEditing}
            />
          </Col>
          <Col span="12">
            <Input
              id="streetAddress"
              label="Street Address"
              isEditing={isEditing}
            />
            <Input id="city" label="City" isEditing={isEditing} />
            <Input id="state" label="State" isEditing={isEditing} />
            <Input id="zip" label="Zip" isEditing={isEditing} />
            <Input id="county" label="County" isEditing={isEditing} />
          </Col>
        </Row>
      </Form>
    </Panel>
  );
};
