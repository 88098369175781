import React, { ReactNode } from 'react';
import { ColumnsType } from 'antd/es/table';
import { ISelectorOption, IToDo } from 'providence-types';
import { projectCategoryRender, tableSorter } from '@core/utils/methods';
import { actionButtons } from '@models/constants';
import { DashboardItem, DashboardProjectData } from '@models/interfaces';
import { ColumnsFunc } from '@models/types';
import { ReadOnlyDate } from '@shared/modules';
import { assigneesRenderer } from '../../../helpers';

export const TO_DO_COLUMNS = (
  adminsList: ISelectorOption<string>[],
  editFunc: ColumnsFunc<DashboardItem<IToDo>>,
): ColumnsType => [
  {
    title: 'Date',
    dataIndex: 'notificationData',
    key: 'date',
    sorter: tableSorter(['notificationData', 'date'], true),
    render: ({ date }: IToDo): ReactNode => <ReadOnlyDate date={date} utc />,
  },
  {
    title: 'Site Number',
    dataIndex: 'projectData',
    key: 'siteNumber',
    sorter: tableSorter(['projectData', 'site', 'siteNumber']),
    render: ({ site }: DashboardProjectData): ReactNode => site?.siteNumber,
  },
  {
    title: 'Project Bundle ID',
    dataIndex: 'projectData',
    key: 'projectBundleId',
    sorter: tableSorter(['projectData', 'projectBundleId']),
    render: ({ projectBundleId }: DashboardProjectData): ReactNode =>
      projectBundleId,
  },
  {
    title: 'PACE Project',
    dataIndex: 'projectData',
    key: 'PACEProjectNumber',
    width: 150,
    render: ({ categories }: DashboardProjectData): ReactNode =>
      projectCategoryRender({
        field: 'PACEProjectNumber',
      })(categories),
  },
  {
    title: 'Oracle Project',
    dataIndex: 'projectData',
    key: 'oracleProjectNumber',
    width: 150,
    render: ({ categories }: DashboardProjectData): ReactNode =>
      projectCategoryRender({
        field: 'oracleProjectNumber',
      })(categories),
  },
  {
    title: 'Action Required',
    dataIndex: 'notificationData',
    key: 'message',
    sorter: tableSorter(['notificationData', 'message']),
    render: ({ message }: IToDo): ReactNode => message,
  },
  {
    title: 'Action Field',
    dataIndex: 'notificationData',
    key: 'actionField',
    sorter: tableSorter(['notificationData', 'actionField']),
    render: ({ actionField }: IToDo): ReactNode => actionField,
  },
  {
    title: 'Task Note',
    dataIndex: 'notificationData',
    key: 'taskNote',
    sorter: tableSorter(['notificationData', 'taskNote']),
    render: ({ taskNote }: IToDo): ReactNode => taskNote,
  },
  {
    title: 'Assignee',
    dataIndex: 'notificationData',
    key: 'assignee',
    render: assigneesRenderer(adminsList),
  },
  {
    title: 'Project Type',
    dataIndex: 'projectData',
    key: 'projectType',
    width: 140,
    sorter: tableSorter(['projectData', 'projectType', 'value']),
    render: ({ projectType }: DashboardProjectData): ReactNode =>
      projectType?.value,
  },
  actionButtons({ editFunc, width: 86 }),
];
