import React, { ReactElement, ReactNode } from 'react';
import { actionButtons } from '@models/constants';
import { AdminDriverManagementData } from '@models/interfaces';
import { ColumnsFunc } from '@models/types';
import { VirtualTableColumn } from '@shared/components';

export const getAdminDriverManagementColumns = (
  editFunc: ColumnsFunc<AdminDriverManagementData>,
  deleteFunc: ColumnsFunc<AdminDriverManagementData>,
): Array<VirtualTableColumn> => [
  {
    title: 'Driver ID',
    dataIndex: 'driverId',
    key: 'driverId',
  },
  {
    title: 'Driver Name',
    dataIndex: 'driverName',
    key: 'driverName',
  },
  {
    title: 'Driver Notes',
    dataIndex: 'driverNotes',
    key: 'driverNotes',
    render: (driverNotes: string): ReactNode => (
      <span className="ellipsis-6">{driverNotes}</span>
    ),
  },
  {
    title: 'Workflow',
    dataIndex: 'workflow',
    key: 'workflow',
    render: (workflowList: string[]): ReactElement => (
      <div className="list">
        {workflowList?.map(
          (workflow: string): ReactNode => (
            <span key={workflow} className="list__item">
              {workflow.replace('_', ' ')}
            </span>
          ),
        )}
      </div>
    ),
  },
  {
    title: 'Price to AT&T',
    dataIndex: 'priceToATandT',
    key: 'priceToATandT',
    render: (priceToATandT: number): ReactNode =>
      priceToATandT && `$${priceToATandT}`,
  },
  {
    title: 'Included in Bundle Pricing',
    dataIndex: 'includedInBundlePricing',
    key: 'includedInBundlePricing',
    render: (includedInBundlePricing: boolean): ReactNode =>
      includedInBundlePricing ? 'Yes' : 'No',
  },
  actionButtons({ editFunc, deleteFunc }) as VirtualTableColumn,
];
