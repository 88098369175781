import React, { FC, PropsWithChildren, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouterProps } from 'react-router';
import { useRouteMatch } from 'react-router-dom';
import { Form } from 'antd';
import { HttpService, ObjectComparatorService } from '@core/services';
import { ProjectHttpService } from '@core/services/http';
import { useDidUpdateEffect, useSaveChanged } from '@core/utils/hooks';
import { isValidForm, removeEmptyFields } from '@core/utils/methods';
import {
  ActionRes,
  ProjectCategory as ProjectCategoryInterface,
  ProjectCreateRequestData,
  ProjectInformationData,
} from '@models/interfaces';
import { IdResponse } from '@models/types';
import { NotificationsLoader, ProjectCategory } from '@shared/components';
import { PrimaryButton } from '@shared/modules';
import {
  CommonActions,
  ProjectActions,
  ProjectCategoriesActions,
  SiteActions,
} from '@store/actions';
import {
  ProjectCategoriesSelectors,
  ProjectSelectors,
  UserSelectors,
} from '@store/selectors';
import { ProjectInformation } from '../information';

import './styles.scss';

const { useForm } = Form;

export const ProjectCreate: FC<RouterProps> = ({
  history,
}: PropsWithChildren<RouterProps>) => {
  const [form] = useForm();
  const { params } = useRouteMatch();

  const currentProjectCategories = useSelector(
    ProjectCategoriesSelectors.getProjectCategories,
  );
  const providenceAgent = useSelector(ProjectSelectors.getProvidenceAgent);
  const projectInformationFields = useSelector(
    UserSelectors.getProjectInformationPermissions,
  );

  const dispatch = useDispatch();

  const clearProjectCategories = (): ActionRes<unknown> =>
    dispatch(ProjectCategoriesActions.setProjectCategoriesAction.done([]));

  useEffect(() => {
    clearProjectCategories();
    dispatch(SiteActions.clearCreatedSite.done());

    if (!(params as IdResponse)?.id) {
      dispatch(ProjectActions.getProvidenceAgent.done(undefined));
    }
  }, []);

  const [categoriesError, setCategoriesError] = useState<boolean>();
  const [objectsCompare, setObjectsCompare] = useState<boolean>(true);
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(false);

  const getPageData = (): ProjectCreateRequestData => {
    const { FALocationNumber, ...values } = form.getFieldsValue();

    return {
      ...(values as ProjectInformationData),
      site: FALocationNumber,
      categories: currentProjectCategories.map(
        (projectCategory: ProjectCategoryInterface) => {
          const clonedItem = projectCategory;

          delete clonedItem.generatedId;

          return clonedItem;
        },
      ),
    };
  };

  const onSubmit = async (): Promise<void> => {
    if (currentProjectCategories?.length === 0) {
      setCategoriesError(true);

      return;
    }

    if (await isValidForm(form))
      try {
        setSubmitDisabled(true);

        dispatch(CommonActions.setHasUnsubmittedData.done(false));
        await HttpService.getHttpRequests(ProjectHttpService).createProject(
          removeEmptyFields(getPageData()),
        );

        NotificationsLoader.notificationSuccess('Project has been created!');

        history?.push('/project/browse');
      } catch (e) {
        console.error(e);
      }

    setSubmitDisabled(false);
  };

  const onCancel = (): void => {
    form.resetFields();
    clearProjectCategories();
    setCategoriesError(false);
    setObjectsCompare(true);
  };

  useDidUpdateEffect(
    () =>
      setCategoriesError(
        categoriesError && currentProjectCategories?.length === 0,
      ),
    [categoriesError, currentProjectCategories?.length],
  );

  const data = {
    providenceAgent,
    ...((params as IdResponse)?.id
      ? { FALocationNumber: (params as IdResponse)?.id }
      : {}),
  };

  useEffect(
    () => (): void => {
      dispatch(CommonActions.setHasUnsubmittedData.done(false));
    },

    [],
  );

  useDidUpdateEffect(() => {
    dispatch(
      CommonActions.setHasUnsubmittedData.done(
        !(
          objectsCompare &&
          ObjectComparatorService.arraysCompare(currentProjectCategories, [])
        ),
      ),
    );
  }, [currentProjectCategories, objectsCompare]);

  const onProjectInfoChange = (): void =>
    setObjectsCompare(
      ObjectComparatorService.objectsCompare(form.getFieldsValue(), data),
    );

  useSaveChanged(true, onSubmit, onCancel);

  return (
    <div className="prov-project-create">
      <div className="tabs-wrap tabs-wrap_with-actions">
        <ProjectInformation
          isEditing
          isNew
          permissions={projectInformationFields}
          className="prov-project-create__form"
          form={form}
          data={data}
          onChange={onProjectInfoChange}
        />
        <ProjectCategory projectCreate />
        {categoriesError && (
          <span className="categories-error">
            Project Category is required!
          </span>
        )}
      </div>
      <div className="prov-project-create__action-buttons">
        <PrimaryButton
          htmlType="submit"
          title="Submit"
          disabled={submitDisabled}
          onClick={onSubmit}
        />
        <PrimaryButton title="Clear" type="default" onClick={onCancel} />
      </div>
    </div>
  );
};
