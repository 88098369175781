import React, {
  forwardRef,
  PropsWithChildren,
  Ref,
  useImperativeHandle,
  useState,
} from 'react';
import { SelectValue } from 'antd/lib/select';
import { EngineeringManagerType, NotifyType, UserRole } from 'providence-types';
import {
  arraySort,
  getCurrentOptions,
  getEnumKeyByEnumValue,
  mapFromBackendToUserRole,
} from '@core/utils/methods';
import { Autocomplete, Select } from '@shared/modules';
import {
  actualizeControl,
  getCurrentEngineeringManagerType,
  getCurrentNotifyType,
} from '../helpers';
import { NotifyTypeControlsProps, NotifyTypeControlsRefType } from '../models';

export const NotifyTypeControls = forwardRef(
  (
    { form, userList, towerOwners }: PropsWithChildren<NotifyTypeControlsProps>,
    forwardedRef: Ref<NotifyTypeControlsRefType>,
  ) => {
    const [notifyType, setNotifyType] = useState<string | null>(null);
    const [roles, setRoles] = useState<string[] | null>(null);

    const isRoleType =
      notifyType === getEnumKeyByEnumValue(NotifyType, NotifyType.Role);

    useImperativeHandle(forwardedRef, () => ({
      changeNotifyType(value: string | null): void {
        setNotifyType(value);
      },
      changeRoles(value: string[] | null): void {
        setRoles(value);
      },
    }));

    const recipientsByNotifyType =
      notifyType === getEnumKeyByEnumValue(NotifyType, NotifyType.Role) ? (
        <Select
          id="roles"
          label="Roles"
          formItemProps={{
            rules: [{ required: true, message: 'Roles are required!' }],
          }}
          elementProps={{
            mode: 'multiple',
            onChange: (value: SelectValue): void => {
              const selectedValues = value as string[];

              setRoles((prevState: string[] | null) => {
                if (
                  prevState &&
                  prevState.includes(UserRole.EngineeringManager) &&
                  !selectedValues.includes(UserRole.EngineeringManager)
                ) {
                  form.resetFields(['engineeringManagerType']);
                }

                if (
                  prevState &&
                  prevState.includes(UserRole.LeasingAgent) &&
                  !selectedValues.includes(UserRole.LeasingAgent)
                ) {
                  form.resetFields(['towerOwners']);
                }

                return selectedValues;
              });
            },
          }}
          options={arraySort(
            getCurrentOptions(UserRole, mapFromBackendToUserRole),
            'ASC',
            'viewValue',
          )}
        />
      ) : (
        <Select
          id="userIds"
          label="Users"
          formItemProps={{
            rules: [{ required: true, message: 'Users are required!' }],
          }}
          elementProps={{
            mode: 'multiple',
          }}
          options={userList}
        />
      );

    return (
      <>
        <Select
          id="notifyType"
          label="Notify Type"
          formItemProps={{
            rules: [{ required: true, message: 'Notify Type is required!' }],
          }}
          elementProps={{
            onChange: (value: SelectValue): void => {
              actualizeControl(setNotifyType, form, NotifyType, value, [
                'roles',
                'userIds',
                'engineeringManagerType',
                'towerOwners',
              ]);

              if (value === NotifyType.User) {
                setRoles(null);
              }
            },
          }}
          options={getCurrentOptions(NotifyType, getCurrentNotifyType)}
        />
        {notifyType && recipientsByNotifyType}
        {notifyType &&
          isRoleType &&
          roles?.includes(UserRole.EngineeringManager) && (
            <Select
              id="engineeringManagerType"
              label="Engineering Manager Type"
              formItemProps={{
                rules: [
                  {
                    required: true,
                    message: 'Engineering Manager Type is required!',
                  },
                ],
              }}
              options={arraySort(
                getCurrentOptions(
                  EngineeringManagerType,
                  getCurrentEngineeringManagerType,
                  true,
                ),
                'ASC',
                'viewValue',
              )}
            />
          )}
        {notifyType && isRoleType && roles?.includes(UserRole.LeasingAgent) && (
          <Autocomplete
            id="towerOwners"
            label="Tower Owners"
            elementProps={{
              mode: 'multiple',
            }}
            options={towerOwners}
          />
        )}
      </>
    );
  },
);
