import moment from 'moment';
import { FRONTEND_DATE_FORMAT, Undefinable } from 'providence-types';
import { DateString } from '@models/types';

export const mainDateFormat = (
  date?: DateString,
  utc: boolean = false,
): Undefinable<string> => {
  let result;

  if (date) {
    result = utc
      ? moment(date).local().format(FRONTEND_DATE_FORMAT)
      : moment(date).format(FRONTEND_DATE_FORMAT);
  }

  return result;
};
