export const removeEmptyFields = <T>(object: T): T =>
  Object.entries(object).reduce(
    (acc: any, [key, value]: [string, any]) =>
      value == null || value === ''
        ? acc
        : {
            ...acc,
            [key]: value,
          },
    {} as T,
  );
