import React, { FC, PropsWithChildren, useEffect, useState } from 'react';
import { Form } from 'antd';
import classNames from 'classnames';
import { HttpService } from '@core/services';
import { PaceTaskHttpService } from '@core/services/http';
import { isValidForm } from '@core/utils/methods';
import { PACE_TASK_PERMISSIONS } from '@models/constants';
import { ModalMainTypes } from '@models/enums';
import { PaceTask } from '@models/interfaces';
import { AddAndEditModal } from '@shared/components';
import { Steps } from '@shared/modules';
import { getSteps } from './models/constants';
import { AddAndEditPaceTaskModalProps } from './models/interfaces';

import './styles.scss';

const { useForm } = Form;

export const AddAndEditPaceTaskModal: FC<AddAndEditPaceTaskModalProps> = ({
  onOk,
  className,
  visible,
  paceTask,
  ...props
}: PropsWithChildren<AddAndEditPaceTaskModalProps>) => {
  const [form] = useForm();
  const [resetCount, setResetCount] = useState<number>(0);

  const type: ModalMainTypes = paceTask
    ? ModalMainTypes.Edit
    : ModalMainTypes.Add;

  const resetForm = (): void => {
    form.resetFields();

    if (paceTask) {
      form.setFieldsValue(paceTask);
    }

    setResetCount(resetCount + 1);
  };
  const save = async (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
  ): Promise<void> => {
    if (await isValidForm(form)) {
      const formFields = form.getFieldsValue();

      await HttpService.getHttpRequests(PaceTaskHttpService)[
        type === ModalMainTypes.Add ? 'addPaceTask' : 'updatePaceTask'
      ]({
        ...paceTask,
        ...formFields,
        permissions: {
          ...paceTask?.permissions,
          ...formFields.permissions,
        },
      } as PaceTask);

      if (onOk) {
        onOk(e);
      }
    }
  };

  const nextArrowClick = (): Promise<boolean> => isValidForm(form);

  useEffect(() => {
    if (visible) {
      resetForm();
    }
  }, [visible]);

  return (
    <AddAndEditModal
      title="Task"
      type={type}
      visible={visible}
      className={classNames(className, 'prov-pace-task-modal')}
      onOk={save}
      cancelButtonProps={{
        onClick: resetForm,
      }}
      formProps={{
        wrapperCol: { span: 10 },
        labelCol: { span: 10 },
        form,
        initialValues: PACE_TASK_PERMISSIONS,
      }}
      {...props}
    >
      <Steps
        steps={getSteps(paceTask?.id)}
        nextArrowClick={nextArrowClick}
        resetCount={resetCount}
      />
    </AddAndEditModal>
  );
};
