import React, { FC, PropsWithChildren } from 'react';
import { Form } from 'antd';
import { isValidForm } from '@core/utils/methods';
import { AddAndEditModal } from '@shared/components';
import { TextArea } from '@shared/modules';
import { DisablingModalProps } from './models';

import './styles.scss';

const { useForm } = Form;

export const DisablingModal: FC<DisablingModalProps> = ({
  fieldSetter,
  visible,
  modalType,
  toggleModal,
}: PropsWithChildren<DisablingModalProps>) => {
  const [form] = useForm();

  const add = async (): Promise<void> => {
    if (await isValidForm(form)) {
      fieldSetter(form.getFieldValue('disablingNotes'));
      form.resetFields();
    }
  };

  return (
    <AddAndEditModal
      onOk={add}
      onCancel={(): void => {
        toggleModal(modalType);
        form.resetFields();
      }}
      visible={visible}
      type={modalType}
      title="Disabling Notes"
      okText="Add"
      cancelText="Cancel"
      className="prov-site-information-disabling-modal"
      formProps={{
        labelCol: { span: 6 },
        wrapperCol: { span: 15 },
        form,
      }}
      forceRender={false}
    >
      <TextArea
        id="disablingNotes"
        label="Disabling Notes"
        formItemProps={{
          rules: [{ required: true, message: 'Disabling Notes is required!' }],
        }}
      />
    </AddAndEditModal>
  );
};
