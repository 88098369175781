import { Guard } from '@wellyes/react-router-extended';
import { SubPage } from 'providence-types';
import { HttpService } from '@core/services';
import { UserPermissionKeys } from '../../models/types';
import store from '../../store';
import { UserState } from '../../store/reducers/user.reducer';
import { UserHttpService } from '../services/http';
import { requestWithStoreWaiting } from '../utils/methods';

export class RedirectGuard implements Guard {
  private readonly _page: UserPermissionKeys;
  private readonly _subpage: SubPage;

  constructor(page: UserPermissionKeys, subpage: SubPage = '' as SubPage) {
    this._page = page;
    this._subpage = subpage;
  }

  private static get _state(): UserState {
    return store.getState().user;
  }

  async canActivate(): Promise<boolean> {
    if (!Object.keys(RedirectGuard._state.activeUser).length) {
      await requestWithStoreWaiting(
        () => HttpService.getHttpRequests(UserHttpService).getSelf(),
        () => RedirectGuard._state,
      );
    }

    return this._subpage
      ? RedirectGuard._state.activeUser.permissions[
          this._page
        ].subPageAccess.includes(this._subpage)
      : RedirectGuard._state.activeUser.permissions[this._page].haveAccess;
  }
}
