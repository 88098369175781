import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { notification } from 'antd';
import { ArgsProps, NotificationInstance } from 'antd/lib/notification';
import SuccessIcon from 'assets/icons/success-icon.svg';
import { Notifications } from '@models/interfaces';

export class NotificationsLoader {
  private key: string = '1';

  constructor(private config: Omit<Notifications, 'type'>) {
    this.open();
  }

  static openNotification({
    message,
    description,
    type = 'error',
  }: Omit<Notifications, 'duration' | 'minTimeBeforeClose'>): void {
    notification[type]({
      message,
      description,
    });
  }

  static openNotificationWithArgs(
    type: keyof NotificationInstance,
    args: ArgsProps,
  ): void {
    notification[type](args);
  }

  static notificationError({
    message,
    description,
  }: Omit<Notifications, 'duration' | 'minTimeBeforeClose' | 'type'>): void {
    notification.error({
      message,
      description,
    });
  }

  static notificationSuccess(description: string): void {
    const succeeded: boolean = !!document.querySelector(
      '.notification-success',
    );

    if (!succeeded) {
      notification.success({
        icon: <img src={SuccessIcon} alt="Success Icon" />,
        className: 'notification-success',
        message: 'Success',
        description,
      });
    }
  }

  open(): void {
    const { message, description, duration = 0 } = this.config;

    notification.info({
      message,
      description: (
        <div>
          {description} <LoadingOutlined />
        </div>
      ),
      key: this.key,
      duration,
    });
  }

  close(): void {
    notification.close(this.key);
  }
}
