import {
  PaceTaskScreen as PaceTaskScreenEnum,
  Undefinable,
} from 'providence-types';
import { DeepPartial } from 'redux';
import { StoredRequest } from '@core/utils/store/stored-request-decorator';
import { PaceTaskTabScreens, ValidationType } from '@models/enums';
import {
  PaceTask,
  PaceTaskPermissions,
  PaceTaskScreen,
  PaceTaskScreenResponse,
} from '@models/interfaces';
import { PaceTaskActions, ProjectManagementActions } from '@store/actions';
import { CommonHttpService } from './common-http.service';

export class PaceTaskHttpService extends CommonHttpService {
  // Admin - Pace Tasks - All Pace Tasks
  @StoredRequest(PaceTaskActions.getAllPaceTasks)
  getAllPaceTasks(search?: string): Promise<PaceTask[]> {
    return this._getPaceTasks(search);
  }

  // Project Management -  Get All Pace Tasks
  @StoredRequest(ProjectManagementActions.getPMScreenPaceTasks)
  getPMPaceTasks(search?: string): Promise<PaceTask[]> {
    return this._getPaceTasks(search);
  }

  // Admin - Pace Tasks - Add Pace Task
  @StoredRequest(PaceTaskActions.addPaceTask)
  addPaceTask(paceTask: DeepPartial<PaceTask>): Promise<PaceTask> {
    return this.http.callApi.post(`${this.apiUrl}/admin/pace-task`, paceTask);
  }

  // Admin - Pace Tasks - Update Pace Task
  @StoredRequest(PaceTaskActions.updatePaceTask)
  updatePaceTask({ id, screens, ...paceTask }: PaceTask): Promise<PaceTask> {
    return this.http.callApi.patch(`${this.apiUrl}/admin/pace-task`, paceTask, {
      params: {
        paceTaskId: id,
      },
    });
  }

  // Admin - Pace Tasks - {tab exclude All Pace Tasks}
  @StoredRequest(PaceTaskActions.getScreenPaceTasks)
  async getScreenPaceTasks(
    screen: PaceTaskTabScreens,
    archived: boolean = false,
  ): Promise<PaceTaskScreenResponse> {
    const paceTasks = await this.http.callApi.get(
      `${this.apiUrl}/admin/pace-task-screens/${screen}`,
      null,
      { params: { archived } },
    );

    return {
      screen,
      paceTasks,
    };
  }

  // Admin - Pace Tasks(archived: true)
  @StoredRequest(PaceTaskActions.getArchivedPaceTasks)
  async getArchivedPaceTasks(
    screen: PaceTaskTabScreens,
    archived: boolean = true,
  ): Promise<PaceTaskScreenResponse> {
    const paceTasks = await this.http.callApi.get(
      `${this.apiUrl}/admin/pace-task-screens/${screen}`,
      null,
      { params: { archived } },
    );

    return {
      screen,
      paceTasks,
    };
  }

  // Admin - Pace Tasks - validate
  async paceTaskValidation(
    id: Undefinable<string>,
    field: 'paceTaskID' | 'taskName',
    value: string,
  ): Promise<boolean> {
    return this.http.callApi.get(
      `${this.apiUrl}/admin/pace-task/validation`,
      null,
      {
        params: {
          id,
          field,
          value,
        },
      },
    );
  }

  // Admin - Pace Task - {tab} - validate
  async paceTaskScreenValidation(
    screen: PaceTaskScreenEnum,
    method: ValidationType,
    value: number,
  ): Promise<boolean> {
    return this.http.callApi.get(
      `${this.apiUrl}/admin/pace-task-screens/validate`,
      null,
      {
        params: { screen, method, value },
      },
    );
  }

  // Admin - All Pace Tasks - Update Permissions
  @StoredRequest(PaceTaskActions.updatePermissions)
  async updatePaceTasksPermissions(
    paceTaskId: string[],
    permissions: Omit<PaceTaskPermissions, 'id' | 'canBeDeleted'>,
  ): Promise<PaceTask[]> {
    return this.http.callApi.patch(
      `${this.apiUrl}/admin/pace-task/reassign-permission`,
      permissions,
      { params: { paceTaskId } },
    );
  }

  // Admin - Pace Tasks - {tab} - Move to archive
  @StoredRequest(PaceTaskActions.moveToArchive)
  async movePaceTaskToArchive(
    screen: PaceTaskTabScreens,
    paceTaskScreen: PaceTaskScreen,
  ): Promise<PaceTaskScreenResponse> {
    const paceTasks: PaceTaskScreen[] = await this.http.callApi.patch(
      `${this.apiUrl}/admin/pace-task-screens/${screen}/into-archive`,
      null,
      { params: { paceTaskScreenId: paceTaskScreen.id } },
    );

    return {
      screen,
      paceTasks,
    };
  }

  // Admin - Pace Tasks - {tab} - Move to archive
  @StoredRequest(PaceTaskActions.deletePaceTask)
  async deletePaceTask(paceTaskId: string): Promise<string> {
    await this.http.callApi.delete(`${this.apiUrl}/admin/pace-task`, null, {
      params: { paceTaskId },
    });

    return paceTaskId;
  }

  // Admin - Pace Tasks - {tab} - Move from archive
  @StoredRequest(PaceTaskActions.moveFromArchive)
  async movePaceTaskFromArchive(
    screen: PaceTaskTabScreens,
    paceTaskScreenIds: string[],
  ): Promise<PaceTaskScreenResponse> {
    const paceTasks: PaceTaskScreen[] = await this.http.callApi.patch(
      `${this.apiUrl}/admin/pace-task-screens/${screen}/from-archive`,
      null,
      { params: { paceTaskScreenId: paceTaskScreenIds } },
    );

    return {
      screen,
      paceTasks,
    };
  }

  // Admin - Pace Tasks - {tab} - Get Pace Tasks for add
  @StoredRequest(PaceTaskActions.getExcludedPaceTasks)
  async getExcludedPaceTasks(
    screen: PaceTaskTabScreens,
  ): Promise<Omit<PaceTask, 'screens' | 'permissions'>[]> {
    return this.http.callApi.get(
      `${this.apiUrl}/admin/pace-task/${screen}`,
      null,
    );
  }

  // Admin - Pace Tasks - {tab} - Add Pace Task
  @StoredRequest(PaceTaskActions.addPaceTaskScreen)
  async addPaceTaskScreen(
    screen: PaceTaskTabScreens,
    paceTask: Omit<PaceTaskScreen, 'paceTask' | 'screen' | 'archive'>,
  ): Promise<PaceTaskScreenResponse> {
    const paceTasks = await this.http.callApi.post(
      `${this.apiUrl}/admin/pace-task-screens/${screen}`,
      { order: paceTask.order },
      {
        params: {
          paceTaskId: paceTask.id,
        },
      },
    );

    return {
      screen,
      paceTasks,
    };
  }

  // Admin - Pace Tasks - {tab} - Add Pace Task
  @StoredRequest(PaceTaskActions.updatePaceTaskScreen)
  async updatePaceTaskScreen(
    screen: PaceTaskTabScreens,
    paceTask: PaceTaskScreen,
  ): Promise<PaceTaskScreenResponse> {
    const paceTasks = await this.http.callApi.patch(
      `${this.apiUrl}/admin/pace-task-screens/${screen}`,
      { order: paceTask.order },
      {
        params: {
          paceTaskScreenId: paceTask.id,
        },
      },
    );

    return {
      screen,
      paceTasks,
    };
  }

  private _getPaceTasks(search?: string): Promise<PaceTask[]> {
    return this.http.callApi.get(`${this.apiUrl}/admin/pace-task`, null, {
      ...(search && { params: { search } }),
    });
  }
}
