export const transformFields = <T, R = T>(
  data: T,
  keys: Array<keyof T>,
): R | T =>
  data
    ? Object.keys(data).reduce((acc: T, curKey: string) => {
        const key = curKey as keyof T;

        return {
          ...acc,
          [key]:
            keys.includes(key) && typeof data[key] !== 'string'
              ? (data[key] as any)?.id
              : data[key] ?? undefined,
        };
      }, data)
    : ({} as R);
