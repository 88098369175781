import { Undefinable } from 'providence-types';
import { AdminCountyData, StateWithCounties } from '@models/interfaces';
import store from '@store/index';
import { AppState } from '@store/reducers';

export const getCountyById = (id: string): AdminCountyData => {
  const state: AppState = store.getState();
  const { states } = state.admin.countyValues;

  const counties = states.reduce(
    (acc: AdminCountyData[], v: StateWithCounties) => [...acc, ...v.counties],
    [],
  );

  const result = counties.find((value: AdminCountyData) => value.id === id);

  if (!result) {
    throw new Error(`Counties do not have value with id ${id}`);
  }

  return result;
};

export const getCountyByIdOrUndefined = (
  id?: string | null,
): Undefinable<AdminCountyData> => (id ? getCountyById(id) : undefined);
