import React, { FC, PropsWithChildren, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Filter from 'assets/icons/filter.png';
import classNames from 'classnames';
import { useDidUpdateEffect } from '@core/utils/hooks';
import { isValidForm, replacePath } from '@core/utils/methods';
import { BrowseItem } from '@models/interfaces';
import { CustomTable } from '../../components/custom-table';
import { Search } from '../../components/search';
import { PrimaryButton, SwitchBox } from '../../modules/ui-kit';
import { BROWSE_PATTERN, TEMPLATE_DETAILS_PATH } from './models/constants';
import { BrowsePageProps } from './models/interfaces';
import { FiltersModal } from './components';

import './styles.scss';

export const BrowsePage: FC<BrowsePageProps> = ({
  legend,
  onSwitch,
  switchInfo,
  additionalSwitch,
  initialSwitchStatus = false,
  columns,
  data,
  additional,
  className,
  filters,
  onSearchChanges,
  onChange,
  onChangeFilterModalVisibility,
  tableProps,
  place,
  buttonProps = {
    title: 'Configure filter',
    icon: <img src={Filter} alt="filter icon" />,
  },
  onApplyFilter,
  filterFormProps,
  validation = false,
  redirectToDetails = true,
  ...props
}: PropsWithChildren<BrowsePageProps>) => {
  const [isFilterOpen, setModal] = useState(false);
  const [switchStatus, setSwitchStatus] =
    useState<boolean>(initialSwitchStatus);

  const handleFilterModal = (): void => {
    const visible = !isFilterOpen;

    if (!visible && props.destroyOnClose) {
      filterFormProps?.form?.resetFields();
    }

    setModal(visible);
    onChangeFilterModalVisibility?.(isFilterOpen);
  };

  const withoutValidation = (): void => {
    if (onApplyFilter) {
      onApplyFilter();
    }

    handleFilterModal();
  };

  const withValidation = async (): Promise<void> => {
    if (filterFormProps?.form && (await isValidForm(filterFormProps.form))) {
      withoutValidation();
    }
  };

  const onOK = (): void => {
    const cb = validation ? withValidation : withoutValidation;

    cb();
  };

  const changeSwitchStatus = (status: boolean): void => setSwitchStatus(status);

  useDidUpdateEffect(() => {
    if (onSwitch) {
      onSwitch(switchStatus);
    }
  }, [switchStatus]);

  const history = useHistory();
  const { pathname } = useLocation();

  const listItems = legend?.map(({ title, status }: BrowseItem) => (
    <li key={title + status} className="list__item">
      <i className={`circle ${status} legend-icon`} />
      <span className="text">{title}</span>
    </li>
  ));

  return (
    <>
      <div className={classNames('prov-browse-page', className)}>
        <div className="prov-browse-page__content">
          <div className="prov-browse-page__search-box">
            <Search onChange={onSearchChanges} />
            {legend && (
              <div className="prov-browse-page__wrap">
                <span className="title">Legend</span>
                <ul className="list">{listItems}</ul>
              </div>
            )}
            {additional && (
              <div className="prov-browse-page__wrap">{additional}</div>
            )}
          </div>
          <div className="prov-browse-page__filter">
            {filters && (
              <PrimaryButton
                className="configure-btn"
                onClick={handleFilterModal}
                icon={buttonProps.icon}
                title={buttonProps.title}
                disabled={buttonProps?.disabled}
              />
            )}
            {switchInfo && (
              <div className="switch-wrap">
                <SwitchBox
                  className="switch-wrap__btn"
                  checked={switchStatus}
                  onChange={changeSwitchStatus}
                />
                <span className="switch-wrap__info">{switchInfo}</span>
              </div>
            )}
            {additionalSwitch && (
              <div className="switch-wrap">{additionalSwitch}</div>
            )}
          </div>
        </div>
        <CustomTable
          columns={columns}
          dataSource={data}
          onChange={onChange}
          onRowClick={(row: any): void => {
            const [, section] = pathname.match(
              BROWSE_PATTERN,
            ) as RegExpMatchArray;

            if (redirectToDetails) {
              history.push(
                replacePath(TEMPLATE_DETAILS_PATH, {
                  id: row.id,
                  section,
                }),
              );
            }
          }}
          place={place}
          {...tableProps}
        />
      </div>
      {filters && (
        <FiltersModal
          className={`${className}__modal`}
          visible={isFilterOpen}
          onCancel={handleFilterModal}
          onOk={onOK}
          title={
            buttonProps.isNew
              ? buttonProps.title.replace('Add', 'New')
              : 'Filters'
          }
          formProps={filterFormProps}
          {...props}
        >
          {filters}
        </FiltersModal>
      )}
    </>
  );
};
