import React, {
  FC,
  PropsWithChildren,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { Form } from 'antd';
import { SelectValue } from 'antd/lib/select';
import classNames from 'classnames';
import { PaymentType, UserRole } from 'providence-types';
import { HttpService } from '@core/services';
import { FinancialHttpService } from '@core/services/http';
import {
  getFormData,
  isValidForm,
  momentizeObjectDates,
} from '@core/utils/methods';
import {
  DOLLAR_PREFIX,
  INPUT_POSITIVE_NUMBER_VALIDATOR,
} from '@models/constants';
import { ModalMainTypes } from '@models/enums';
import {
  AddAndEditModalProps,
  FinancialSiteFeesData,
} from '@models/interfaces';
import { AddAndEditModal } from '@shared/components';
import {
  Autocomplete,
  DatePicker,
  Input,
  InputNumber,
  TextArea,
} from '@shared/modules';
import {
  DDVSelectors,
  FinancialSelectors,
  UserSelectors,
} from '@store/selectors';
import { PAYMENT_TYPE_OPTIONS } from './models/constants';

import './styles.scss';

const { useForm } = Form;

export const AddAndEditSiteFee: FC<
  AddAndEditModalProps<FinancialSiteFeesData>
> = ({
  className,
  visible,
  projectId,
  formValue,
  toggleModal,
  modalType,
  entity,
  permissions,
  ...props
}: PropsWithChildren<AddAndEditModalProps<FinancialSiteFeesData>>) => {
  const [form] = useForm();

  const financialService = useMemo(
    () => HttpService.getHttpRequests(FinancialHttpService),
    [],
  );

  const [paymentType, setPaymentType] = useState<SelectValue | null>();

  const dropdownsOptions = useSelector(DDVSelectors.getDDVOptions);
  const disabled = useSelector(FinancialSelectors.isFetching);
  const userId = useSelector(UserSelectors.getUserId);
  const roles = useSelector(UserSelectors.getUserRoles);

  const isAccounting = roles.includes(UserRole.Accounting);
  const isAdmin = roles.includes(UserRole.Admin);

  const overrideField = (): Record<string, null> => {
    let field = null;

    if (
      formValue.id &&
      formValue.paymentType !== form.getFieldValue('paymentType')
    ) {
      // eslint-disable-next-line default-case
      switch (formValue.paymentType) {
        case PaymentType.PurchaseOrder:
          field = 'PONumber';

          break;
        case PaymentType.CreditCard:
          field = 'creditCard';

          break;
        case PaymentType.Check:
          field = 'checkNumber';

          break;
      }
    }

    return field ? { [field]: null } : {};
  };

  const getFormDataFunc = (): FinancialSiteFeesData =>
    getFormData(formValue, form, overrideField());

  const canEdit =
    (modalType === ModalMainTypes.Edit && formValue.creator?.id === userId) ||
    modalType === ModalMainTypes.Add;
  const canEditAccounting = modalType === ModalMainTypes.Edit && isAccounting;
  const canEditAdmin = modalType === ModalMainTypes.Edit && isAdmin;

  const add = async ({
    id,
    ...siteFeeBody
  }: FinancialSiteFeesData): Promise<void> => {
    if (await isValidForm(form)) {
      await financialService.addFinancialSiteFee(
        momentizeObjectDates(siteFeeBody, ['date', 'dateIssued'], true),
        projectId!,
        entity,
        userId,
      );
      toggleModal(modalType);
    }
  };

  const edit = async (siteFeeBody: FinancialSiteFeesData): Promise<void> => {
    const siteFeeBodyCopy = { ...siteFeeBody };

    delete siteFeeBodyCopy.creator;

    if (await isValidForm(form)) {
      await financialService.updateFinancialSiteFee(
        momentizeObjectDates(
          { ...siteFeeBodyCopy, amount: Number(siteFeeBodyCopy?.amount) },
          ['date', 'dateIssued'],
          true,
        ),
        projectId!,
        entity,
      );
      toggleModal(modalType);
    }
  };

  useEffect(() => {
    if (visible) {
      form?.resetFields();
      form.setFieldsValue(
        momentizeObjectDates(formValue, ['date', 'dateIssued']),
      );
      setPaymentType(formValue.paymentType);
    }
  }, [visible, formValue]);

  const paymentTypeRelationControl = (): ReactNode => {
    switch (paymentType) {
      case PaymentType.PurchaseOrder:
        return (
          <InputNumber
            id="PONumber"
            label="PO Number"
            isEditing={canEdit || canEditAdmin || canEditAccounting}
          />
        );
      case PaymentType.Check:
        return (
          <InputNumber
            id="checkNumber"
            label="Check Number"
            isEditing={canEdit || canEditAdmin}
          />
        );
      case PaymentType.CreditCard:
        return (
          <Autocomplete
            id="creditCard"
            label="Credit Card"
            options={dropdownsOptions?.CreditType}
            formItemProps={{
              rules: [
                {
                  required: paymentType === PaymentType.CreditCard,
                  message: 'Credit Card is required!',
                },
              ],
            }}
            isEditing={canEdit || canEditAdmin}
          />
        );
      default:
        break;
    }
  };

  return (
    <AddAndEditModal
      title={modalType === ModalMainTypes.Add ? 'Add Fee' : 'Change Fee'}
      type={modalType}
      visible={visible}
      className={classNames(className, 'site-fee-modal')}
      onOk={
        modalType === ModalMainTypes.Add
          ? (): Promise<void> => add(getFormDataFunc())
          : (): Promise<void> => edit(getFormDataFunc())
      }
      okText={modalType === ModalMainTypes.Add ? 'Add Fee' : 'Change Fee'}
      onCancel={(): void => {
        toggleModal(modalType);
      }}
      formProps={{
        labelCol: { span: 7 },
        form,
        initialValues: formValue,
      }}
      okButtonProps={{
        disabled,
      }}
      maskClosable={!disabled}
      {...props}
    >
      <div>
        <DatePicker
          id="date"
          label="Date"
          formItemProps={{
            rules: [{ required: true, message: 'Date is required!' }],
          }}
          isEditing={canEdit || canEditAdmin}
        />
        <DatePicker
          id="dateIssued"
          label="Date Issued"
          isEditing={
            // TODO: clarify, when reworking the accounting module, do we need to display this field in the Add modal
            modalType === ModalMainTypes.Edit &&
            entity === 'accounting' &&
            (canEdit || canEditAdmin || canEditAccounting)
          }
        />
        <InputNumber
          id="amount"
          label="Amount"
          formItemProps={{
            rules: [
              { required: true, message: 'Amount is required!' },
              INPUT_POSITIVE_NUMBER_VALIDATOR,
            ],
          }}
          elementProps={{ formatter: DOLLAR_PREFIX }}
          isEditing={canEdit || canEditAdmin}
        />
        <Autocomplete
          id="siteFeeType"
          label="Site Fee Type"
          options={dropdownsOptions?.SiteFeeType}
          formItemProps={{
            rules: [{ required: true, message: 'Site Fee Type is required!' }],
          }}
          isEditing={canEdit || canEditAdmin}
        />
        <Input
          id="payee"
          label="Payee"
          formItemProps={{
            rules: [{ required: true, message: 'Payee is required!' }],
          }}
          isEditing={canEdit || canEditAdmin}
        />
        <Autocomplete
          id="paymentType"
          label="Payment Type"
          options={PAYMENT_TYPE_OPTIONS}
          formItemProps={{
            rules: [{ required: true, message: 'Payment Type is required!' }],
          }}
          elementProps={{
            onChange: (value: SelectValue): void => {
              form.setFieldsValue({
                PONumber: null,
                checkNumber: null,
                creditCard: null,
              });
              setPaymentType(value);
            },
          }}
          isEditing={canEdit || canEditAdmin}
        />
        {paymentTypeRelationControl()}
        <TextArea
          id="notes"
          label="Notes"
          isEditing={canEdit || canEditAdmin || canEditAccounting}
        />
      </div>
    </AddAndEditModal>
  );
};
