import React, { FC, PropsWithChildren, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Col, Form, Row } from 'antd';
import { arrsToSelectableObject } from '@core/utils/methods';
import { AdminDropdownValuesData, InformationProps } from '@models/interfaces';
import { Panel } from '@shared/components';
import {
  Autocomplete,
  Coordinate,
  Input,
  PrimaryButton,
  TextArea,
} from '@shared/modules';
import { DDVSelectors } from '@store/selectors';

const { useForm } = Form;

export const SiteInformation: FC<InformationProps> = ({
  className,
  data,
  expectedId,
}: PropsWithChildren<InformationProps>) => {
  const [form] = useForm();
  const dropdownValues = useSelector(DDVSelectors.getDDValues);

  useEffect(() => {
    const {
      state,
      county,
      jurisdiction,
      towerType,
      equipmentType,
      ...restData
    } = data;

    form.setFieldsValue({
      ...restData,
      state: state?.name,
      county: county?.name,
      jurisdiction: jurisdiction?.jurisdictionName,
      towerType: towerType?.value,
      equipmentType: equipmentType?.value,
    });
  }, [form, data]);

  return (
    <Panel header="Site Information" className={className}>
      <Form labelCol={{ span: 12 }} wrapperCol={{ span: 12 }} form={form}>
        <Row>
          <Col span="12">
            <Input
              id="FALocationNumber"
              label="FA Location Number"
              isEditing={false}
            />
            <Input id="siteNumber" label="Site Number" isEditing={false} />
            <Input id="legacySiteId" label="Legacy Site ID" isEditing={false} />
            <Autocomplete
              id="towerOwner"
              label="Tower Owner"
              options={arrsToSelectableObject<AdminDropdownValuesData>(
                dropdownValues.TowerOwner,
                'id',
                'value',
              )}
              isEditing={false}
            />
            <Input
              id="towerOwnerSiteId"
              label="Tower Owner Site ID"
              isEditing={false}
            />
            <Input
              id="streetAddress"
              label="Street Address"
              isEditing={false}
            />
            <Input id="city" label="City" isEditing={false} />
            <Input id="state" label="State" isEditing={false} />
            <Input id="zip" label="Zip" isEditing={false} />
          </Col>
          <Col span="12">
            <Input id="county" label="County" isEditing={false} />
            <Input id="jurisdiction" label="Jurisdiction" isEditing={false} />
            <Coordinate id="latitude" label="Latitude" isEditing={false} />
            <Coordinate id="longitude" label="Longitude" isEditing={false} />
            <Input id="towerType" label="Tower Type" isEditing={false} />
            <Input id="towerHeight" label="Tower Height" isEditing={false} />
            <Input
              id="leasedGroundSpace"
              label="Leased Ground Space"
              isEditing={false}
            />
            <Input
              id="equipmentType"
              label="Equipment Type"
              isEditing={false}
            />
            <TextArea id="notes" label="Site Notes" isEditing={false} />
          </Col>
        </Row>
        <Row className="action-buttons">
          <PrimaryButton
            title="View details"
            target="_blank"
            href={`/site/${expectedId}/details`}
            type="primary"
          />
        </Row>
      </Form>
    </Panel>
  );
};
