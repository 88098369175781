import React, {
  forwardRef,
  PropsWithChildren,
  Ref,
  useImperativeHandle,
  useState,
} from 'react';
import classNames from 'classnames';
import { WHITESPACE_INPUT_LIMIT_RULES, YES_NO_GROUP } from '@models/constants';
import {
  Autocomplete,
  Input,
  InputNumber,
  RadioGroup,
  SwitchBox,
} from '@shared/modules';
import { SiteInformationLTEControlsProps } from '../../models/interfaces';
import { LTEControlsRefType } from '../../models/types';

export const LTEControls = forwardRef(
  (
    {
      isEditing,
      dropdownsOptions,
      permissions: {
        open48VBreakPositions,
        existingRectifiers,
        icebridgeLength,
        fiberAndDCTrunkInInnerduct,
        isFiberRunWithDCTrunkInSameDuct,
        powerPlantType,
      },
    }: PropsWithChildren<SiteInformationLTEControlsProps>,
    forwardedRef: Ref<LTEControlsRefType>,
  ) => {
    const [unknownSwitchState, setUnknownSwitchState] =
      useState<boolean>(false);

    useImperativeHandle(forwardedRef, () => ({
      // getter and setter unknownSwitchState
      unknownSwitchState,
      changeUnknownSwitchState(value: boolean): void {
        setUnknownSwitchState(value);
      },
      //
      setUnknownSwitch(value: boolean): void {
        setUnknownSwitchState(value);
      },
    }));

    return (
      <>
        <div
          className={classNames('power-plant__wrapper', {
            'power-plant__wrapper-active': isEditing,
          })}
        >
          {unknownSwitchState ? (
            <Input
              id="unknownPowerPlantType"
              label="Power Plant Type"
              formItemProps={{
                className: 'power-plant-type',
                rules: WHITESPACE_INPUT_LIMIT_RULES,
              }}
              isEditing={isEditing && !!powerPlantType}
            />
          ) : (
            <Autocomplete
              id="powerPlantType"
              label="Power Plant Type"
              options={dropdownsOptions?.PowerPlantTypes}
              formItemProps={{ className: 'power-plant-type' }}
              isEditing={isEditing && !!powerPlantType}
            />
          )}
          {isEditing && (
            <SwitchBox
              title="Unknown"
              checked={unknownSwitchState}
              onChange={(): void =>
                setUnknownSwitchState((prevValue: boolean) => !prevValue)
              }
              disabled={isEditing && !powerPlantType}
            />
          )}
        </div>
        <InputNumber
          id="open48VBreakerPositions"
          label="Open -48V Breaker Positions"
          isEditing={isEditing && !!open48VBreakPositions}
        />
        <InputNumber
          id="existingRectifiers"
          label="Existing Rectifiers"
          isEditing={isEditing && !!existingRectifiers}
        />
        <InputNumber
          id="icebridgeLength"
          label="Icebridge Length"
          isEditing={isEditing && !!icebridgeLength}
        />
        <RadioGroup
          id="fiberDCTrunkInInnerduct"
          label="Fiber & DC Trunk in Innerduct"
          buttons={YES_NO_GROUP}
          isEditing={isEditing && !!fiberAndDCTrunkInInnerduct}
        />
        <RadioGroup
          id="isFiberRunWithDCTrunkInSameDuct"
          label="Is Fiber run with DC Trunk in Same Duct"
          buttons={YES_NO_GROUP}
          isEditing={isEditing && !!isFiberRunWithDCTrunkInSameDuct}
        />
      </>
    );
  },
);
