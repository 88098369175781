import React, { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Form } from 'antd';
import { SelectValue } from 'antd/es/select';
import { DirectMessageAreaKey, ISentDirectMessage } from 'providence-types';
import { ModalMainTypes } from '@models/enums';
import { CustomTable, DeleteModal } from '@shared/components';
import { Autocomplete, PrimaryButton, SwitchBox } from '@shared/modules';
import { DirectMessageActions } from '@store/actions';
import { DirectMessageSelectors } from '@store/selectors';
import { AddAndEditDirectMessage } from './modals';
import {
  AREA_OPTIONS,
  DIRECT_MESSAGE_MANAGER_COLUMNS,
  SendDirectMessagesProps,
} from './models';

export const SendDirectMessages: FC<SendDirectMessagesProps> = ({
  service,
  visible,
  isPMArea,
  userList,
  isFetching,
  areaKey,
}: SendDirectMessagesProps) => {
  const [form] = Form.useForm();

  const dispatch = useDispatch();

  const data = useSelector(DirectMessageSelectors.getDirectMessages);

  const [tableData, setTableData] = useState<ISentDirectMessage[]>(data);
  const [currentItem, setCurrentItem] = useState<ISentDirectMessage | null>(
    null,
  );
  const [switchValue, setSwitchValue] = useState<boolean>(false);
  const [selectedArea, setSelectedArea] = useState<DirectMessageAreaKey | null>(
    null,
  );
  const [addAndEditVisible, setAddAndEditVisible] = useState<boolean>(false);
  const [addAndEditModalType, setAddAndEditModalType] =
    useState<ModalMainTypes | null>(null);
  const [deleteVisible, setDeleteVisible] = useState<boolean>(false);

  useEffect(() => {
    return () => {
      if (isPMArea) {
        setSelectedArea(null);
        form.resetFields();
      }

      setSwitchValue(false);

      dispatch(DirectMessageActions.getDirectMessages.done([]));
      dispatch(DirectMessageActions.getUsersByArea.done([]));
    };
  }, [isPMArea, visible]);

  useEffect(() => {
    setTableData(
      switchValue
        ? data
        : data.filter(
            (directMessage: ISentDirectMessage) => !directMessage.completed,
          ),
    );
  }, [data, switchValue]);

  useEffect(() => {
    if (visible && selectedArea !== null) {
      service.getDirectMessages(selectedArea);
      service.getUsersByArea(selectedArea);
    }
  }, [visible, selectedArea]);

  const addDirectMessageHandler = useCallback((): void => {
    setCurrentItem(null);
    setAddAndEditModalType(ModalMainTypes.Add);
    setAddAndEditVisible(true);
  }, []);

  const editDirectMessageHandler = useCallback(
    (directMessage: ISentDirectMessage): void => {
      setCurrentItem(directMessage);
      setAddAndEditModalType(ModalMainTypes.Edit);
      setAddAndEditVisible(true);
    },
    [],
  );

  const removeDirectMessageHandler = useCallback(
    (directMessage: ISentDirectMessage): void => {
      setCurrentItem(directMessage);
      setDeleteVisible(true);
    },
    [],
  );

  const removeDirectMessage = useCallback(async (): Promise<void> => {
    await service.removeDirectMessages(currentItem!._id);

    setDeleteVisible(false);
  }, [currentItem]);

  const onCloseAddAndEditModal = useCallback(
    (): void => setAddAndEditVisible(false),
    [],
  );

  const onCloseDeleteModal = useCallback(
    (): void => setDeleteVisible(false),
    [],
  );

  const changeSelectedArea = useCallback(
    (value: SelectValue): void =>
      setSelectedArea(value as DirectMessageAreaKey),
    [],
  );

  const changeSwitchValue = useCallback(
    (checked: boolean) => setSwitchValue(checked),
    [],
  );

  return (
    <>
      {isPMArea && (
        <Form className="prov-direct-message-manager__area-form" form={form}>
          <Autocomplete
            id="area"
            label="Area"
            key="area"
            options={AREA_OPTIONS}
            elementProps={{
              onChange: changeSelectedArea,
            }}
          />
        </Form>
      )}
      <CustomTable
        dataSource={tableData}
        columns={DIRECT_MESSAGE_MANAGER_COLUMNS(
          userList,
          editDirectMessageHandler,
          removeDirectMessageHandler,
        )}
        loading={isFetching}
        pagination={false}
        hoverable={false}
        legendProps={{
          items: [
            { value: 'Completed', color: 'gray' },
            { value: 'Not completed', color: 'green' },
          ],
          additionElement: (
            <div className="legend-addition-elements">
              <PrimaryButton
                title="Create"
                onClick={addDirectMessageHandler}
                disabled={isPMArea && selectedArea === null}
              />
              <SwitchBox
                tooltip="Show Completed"
                checked={switchValue}
                onChange={changeSwitchValue}
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                disabled={isPMArea && selectedArea === null}
              />
            </div>
          ),
        }}
      />
      <AddAndEditDirectMessage
        visible={addAndEditVisible}
        modalType={addAndEditModalType}
        currentItem={currentItem}
        areaKey={isPMArea ? selectedArea : areaKey}
        onClose={onCloseAddAndEditModal}
        userList={userList}
      />
      <DeleteModal
        visible={deleteVisible}
        onOk={removeDirectMessage}
        onCancel={onCloseDeleteModal}
        centered={false}
      />
    </>
  );
};
