import React, {
  FC,
  PropsWithChildren,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Col, Form, Row } from 'antd';
import { SelectValue } from 'antd/lib/select';
import { UserRole, UserRoleView } from 'providence-types';
import { HttpService } from '@core/services';
import { UserManagementHttpService } from '@core/services/http';
import {
  getFormData,
  isValidForm,
  mapFromBackendToUserRole,
} from '@core/utils/methods';
import { emailValidator, serverValidator } from '@core/utils/validators';
import {
  ENGINEERING_TYPES_OPTIONS,
  USER_ROLE_OPTIONS,
  WHITESPACE_INPUT_LIMIT_RULES,
} from '@models/constants';
import { ModalMainTypes } from '@models/enums';
import { UserManagementData } from '@models/interfaces';
import { NotificationsLoader } from '@shared/components';
import { Autocomplete, Button, Input, Modal, Switch } from '@shared/modules';
import { UserManagementModalProps } from './models';

import './styles.scss';

const { useForm } = Form;

export const UserModal: FC<UserManagementModalProps> = ({
  formValues,
  visible,
  modalType,
  toggleModal,
  currentOptions,
  ...props
}: PropsWithChildren<UserManagementModalProps>) => {
  const [form] = useForm();

  const userManagementService = useMemo(
    () => HttpService.getHttpRequests(UserManagementHttpService),
    [],
  );

  const [switchState, setSwitchState] = useState<boolean>(false);
  const [selectValueArray, setSelectValueArray] = useState<SelectValue>([]);

  const doRolesIncludeEM = (selectValueArray as UserRole[])
    .map(mapFromBackendToUserRole)
    .includes(UserRoleView.EngineeringManager);

  const getUserData = (): UserManagementData =>
    getFormData(formValues, form, {
      isActive: switchState,
      engineeringManagerType: !doRolesIncludeEM
        ? undefined
        : form.getFieldsValue().engineeringManagerType,
    });

  const add = async (reqValue: UserManagementData): Promise<void> => {
    if (await isValidForm(form)) {
      await userManagementService.createUsers(reqValue, currentOptions);
      toggleModal(modalType);
    }
  };

  const edit = async (reqValue: UserManagementData): Promise<void> => {
    if (await isValidForm(form)) {
      await userManagementService.editUser(formValues.id, reqValue);
      toggleModal(modalType);
    }
  };

  const onResetButton = async (): Promise<void> => {
    try {
      await userManagementService.resetUserPassword(formValues.id);

      NotificationsLoader.notificationSuccess('Password has been changed!');
    } catch (e) {
      console.error(e);
    }
  };

  const resetForm = (): void => {
    form.setFieldsValue(formValues);
    setSwitchState(formValues.isActive);
    setSelectValueArray(formValues.roles);
  };

  useEffect(() => {
    if (visible) {
      resetForm();
    }
  }, [visible, formValues, form]);

  return (
    <Modal
      visible={visible}
      type={modalType}
      title={`${modalType === ModalMainTypes.Add ? 'Create' : 'Edit'} User`}
      okText="Save"
      cancelText="Clear"
      className="prov-create-user-modal"
      onCancel={(): void => {
        form.resetFields();
        toggleModal(modalType);
        setSelectValueArray([]);
      }}
      onOk={
        modalType === ModalMainTypes.Add
          ? (): Promise<void> => add(getUserData())
          : (): Promise<void> => edit(getUserData())
      }
      cancelButtonProps={{
        onClick: resetForm,
      }}
      {...props}
    >
      <Form
        wrapperCol={{ span: 14 }}
        labelCol={{ span: 7 }}
        form={form}
        initialValues={formValues}
      >
        <Row>
          <Col span="24">
            <Input
              label="First Name"
              id="firstName"
              formItemProps={{
                rules: [
                  { required: true, message: 'First Name is required!' },
                  ...WHITESPACE_INPUT_LIMIT_RULES,
                ],
              }}
            />
            <Input
              label="Last Name"
              id="lastName"
              formItemProps={{
                rules: [
                  { required: true, message: 'Last Name is required!' },
                  ...WHITESPACE_INPUT_LIMIT_RULES,
                ],
              }}
            />
            <Input
              label="Email"
              id="email"
              formItemProps={{
                rules: [
                  ...emailValidator(true),
                  serverValidator(
                    {
                      method: 'getUserValidation',
                      errorMessage: 'Email address should be Unique!',
                    },
                    UserManagementHttpService,
                    modalType === ModalMainTypes.Edit
                      ? formValues.id
                      : undefined,
                  ),
                  ...WHITESPACE_INPUT_LIMIT_RULES,
                ],
              }}
            />
            {modalType === ModalMainTypes.Edit && (
              <Button
                label="Password"
                id="password"
                elementProps={{
                  title: 'Send Temp Password',
                  onClick: onResetButton,
                }}
              />
            )}
            <Autocomplete
              label="Roles"
              id="roles"
              options={USER_ROLE_OPTIONS}
              elementProps={{
                mode: 'multiple',
                onChange: (value: SelectValue): void =>
                  setSelectValueArray(value),
              }}
              formItemProps={{
                rules: [{ required: true, message: 'Role is required!' }],
              }}
            />
            {doRolesIncludeEM && (
              <Autocomplete
                label="Engineering Type"
                id="engineeringManagerType"
                options={ENGINEERING_TYPES_OPTIONS}
                formItemProps={{
                  rules: [
                    {
                      required: true,
                      message: 'Engineering Type is required!',
                    },
                  ],
                }}
              />
            )}
            <Switch
              id="isActive"
              label="Status"
              switchState={switchState}
              elementProps={{
                title: switchState ? 'Active' : 'Inactive',
                checked: switchState,
                onChange: (value: boolean): void => {
                  setSwitchState(value);
                },
              }}
            />
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
