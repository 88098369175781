import { ReactText } from 'react';
import nextId from 'react-id-generator';
import {
  generatedIdMapper,
  getEquipmentTotalsFromScoping,
  getProcessedEquipments,
  sortEquipments,
  transformFields,
} from '../../core/utils/methods';
import { browseReducer } from '../../core/utils/methods/browse-sorter-reducer';
import { createReducer, on } from '../../core/utils/store';
import {
  LEASING_ENGINEERING_TRANSFORM_FIELDS,
  LEASING_INFO_TRANSFORM_FIELDS,
  STRUCTURAL_INFO_TRANSFORM_FIELDS,
} from '../../models/constants/transform-fields';
import {
  AssociatedProject,
  EquipmentModal,
  IdAndValue,
  LeaseAuditData,
  LeasingDetails,
  LeasingEquipmentResp,
  LeasingInformation,
  LeasingProject,
  LeasingWorkflowUpdateData,
  OptionProps,
  PaginatedResponse,
  PayloadAndState,
  ProjectDetails,
  ScopingDetails,
  ScopingInfo,
  SiteResponse,
  StructuralInformation,
  UpdateLeasingEquipmentData,
} from '../../models/interfaces';
import { CuttedProjectDetails, LeasingEquipmentData } from '../../models/types';
import { ScopingActions } from '../actions/engineering/scoping.actions';
import { LeasingActions, LeasingActionType } from '../actions/leasing.actions';

type StoreLeasingInformation = LeasingInformation & {
  leasingNotes: string;
  agreementType: IdAndValue;
  mostRecentSATIARevCode?: IdAndValue;
};

export interface LeasingState {
  associatedProjects: AssociatedProject[];
  leasingBrowseData: PaginatedResponse<LeasingProject>;
  currentProject: CuttedProjectDetails;
  leasingSite: SiteResponse;
  leasingInformation: StoreLeasingInformation;
  leasingAgents: OptionProps[];
  leasingEquipmentData: LeasingEquipmentData;
  equipmentTypeNames: string[];
  isFetching: boolean;
  errors: boolean;
}

const initialState: LeasingState = {
  associatedProjects: [],
  leasingBrowseData: {
    items: [],
    total: 0,
  },
  currentProject: {} as Omit<
    ProjectDetails,
    'categories' | 'paceTasks' | 'site'
  >,
  leasingSite: {} as SiteResponse,
  leasingInformation: {} as StoreLeasingInformation,
  leasingAgents: [],
  leasingEquipmentData: {
    priorTableData: [],
    curPriorTableData: [],
    scopingTableData: [],
    finalTableData: [],
    curFinalTableData: [],
    scopingNotes: null,
    priorLeasingNotes: null,
    finalLeasingNotes: null,
  },
  equipmentTypeNames: [],
  isFetching: false,
  errors: false,
};

const equipmentTypeNames = new Set<string>();

export const reducer = createReducer(
  initialState,
  // GET LEASING BROWSE
  on(
    LeasingActions.getLeasingBrowseDataAction,
    browseReducer('leasingBrowseData'),
  ),
  // GET LEASING DETAILS
  on(
    LeasingActions.getLeasingDetailsAction,
    ({
      payload: {
        site,
        leasing: leasingPayload,
        leasingAgents,
        scoping: leasingScoping,
        ...currentProject
      },
      state: { leasingInformation },
    }: PayloadAndState<LeasingDetails, LeasingState>) => {
      const leasing = leasingPayload ?? {};
      const { structural, ...restLeasingData } = leasing;
      const scopingData = leasingScoping ?? {};

      return {
        currentProject: {
          ...currentProject,
          scoping: scopingData
            ? transformFields<ScopingInfo>(
                scopingData,
                LEASING_ENGINEERING_TRANSFORM_FIELDS,
              )
            : undefined,
        },
        leasingAgents,
        leasingSite: transformFields<SiteResponse>(site, [
          'towerOwner',
          'riskCategory',
        ]),
        leasingInformation: {
          ...leasingInformation,
          ...transformFields<LeasingInformation>(
            restLeasingData,
            LEASING_INFO_TRANSFORM_FIELDS,
          ),
          structural: structural
            ? transformFields<StructuralInformation>(
                structural,
                STRUCTURAL_INFO_TRANSFORM_FIELDS,
              )
            : undefined,
        },
        leasingEquipmentData: {
          priorTableData: getProcessedEquipments(
            equipmentTypeNames,
            restLeasingData?.leasingEquipment,
            'PriorEquipment',
          ),
          curPriorTableData: getProcessedEquipments(
            equipmentTypeNames,
            restLeasingData?.leasingEquipment,
            'PriorEquipment',
          ),
          finalTableData: getProcessedEquipments(
            equipmentTypeNames,
            restLeasingData?.leasingEquipmentFinal,
            'FinalEquipment',
          ),
          curFinalTableData: getProcessedEquipments(
            equipmentTypeNames,
            restLeasingData?.leasingEquipmentFinal,
            'FinalEquipment',
          ),
          scopingTableData: sortEquipments(
            getEquipmentTotalsFromScoping(
              equipmentTypeNames,
              scopingData,
              'ScopingEquipment',
            ),
          ),
          scopingNotes: scopingData?.scopingTowerNotes,
          priorLeasingNotes: restLeasingData?.priorLeasingNotes,
          finalLeasingNotes: restLeasingData?.finalLeasingNotes,
        },
        equipmentTypeNames: [...equipmentTypeNames],
      };
    },
  ),
  // GET LEASING EQUIPMENT PRIOR PROJECT
  on(
    LeasingActions.getLeasingEquipmentPriorProjectAction,
    ({
      payload: { scoping: priorScopingData, leasing },
      state: { leasingEquipmentData },
    }: PayloadAndState<LeasingEquipmentResp, LeasingState>) => {
      const scopingData = priorScopingData ?? {};

      const curPriorTableData = getEquipmentTotalsFromScoping(
        equipmentTypeNames,
        scopingData,
        'PriorEquipment',
      );

      return {
        leasingEquipmentData: {
          ...leasingEquipmentData,
          curPriorTableData,
          curFinalTableData: sortEquipments(
            leasingEquipmentData.scopingTableData.map(
              (equipment: EquipmentModal) => ({
                ...equipment,
                generatedId: nextId(),
              }),
            ),
          ),
          priorLeasingNotes: leasing?.priorLeasingNotes,
          finalLeasingNotes: null,
        },
        equipmentTypeNames: [...equipmentTypeNames],
      };
    },
  ),
  // GET SCOPING DETAILS
  on(
    ScopingActions.getScopingDetailsAction,
    ({
      payload: {
        site,
        leasing: leasingPayload,
        leasingAgents,
        ...currentProject
      },
      state: { leasingInformation },
    }: PayloadAndState<
      Omit<ScopingDetails, 'categories' | 'RAD'>,
      LeasingState
    >) => {
      const leasing = leasingPayload ?? {};
      const { structural, ...restLeasingData } = leasing;

      return {
        currentProject,
        leasingAgents,
        leasingSite: transformFields<SiteResponse>(site, [
          'towerOwner',
          'riskCategory',
        ]),
        leasingInformation: {
          ...leasingInformation,
          ...transformFields<LeasingInformation>(
            restLeasingData,
            LEASING_INFO_TRANSFORM_FIELDS,
          ),
          structural: structural
            ? transformFields<StructuralInformation>(
                structural,
                STRUCTURAL_INFO_TRANSFORM_FIELDS,
              )
            : undefined,
        },
      };
    },
  ),
  // UPDATE LEASING WORKFLOW
  on(
    LeasingActions.updateLeasingWorkflowAction,
    ({
      payload: {
        leasing: { auditDate, ...leasing },
        structural,
      },
      state: { leasingInformation },
    }: PayloadAndState<LeasingWorkflowUpdateData, LeasingState>) => ({
      leasingInformation: {
        ...leasingInformation,
        ...leasing,
        leaseAudit: {
          ...leasingInformation.leaseAudit,
          auditDate,
        },
        structural,
      },
    }),
  ),
  // UPDATE LEASE AUDIT
  on(
    LeasingActions.updateLeaseAuditAction,
    ({
      payload: { leasing, mostRecentSATIARevCode, ...leaseAudit },
      state: { leasingInformation },
    }: PayloadAndState<LeaseAuditData, LeasingState>) => ({
      leasingInformation: {
        ...leasingInformation,
        leasingNotes: leasing?.leasingNotes || '',
        mostRecentSATIARevCode: mostRecentSATIARevCode
          ? ({
              id: mostRecentSATIARevCode,
            } as IdAndValue)
          : undefined,
        leaseAudit,
      },
    }),
  ),
  // ADD LEASING EQUIPMENT TABLE ITEM
  on(
    LeasingActions.addTableItemAction,
    ({
      payload: { data, key },
      state: { leasingEquipmentData },
    }: PayloadAndState<LeasingActionType, LeasingState>) => ({
      leasingEquipmentData: {
        ...leasingEquipmentData,
        [key]: sortEquipments([...leasingEquipmentData[key], data]),
      },
    }),
  ),
  // UPDATE LEASING EQUIPMENT TABLE ITEM
  on(
    LeasingActions.editTableItemAction,
    ({
      payload: { data, key },
      state: { leasingEquipmentData },
    }: PayloadAndState<LeasingActionType, LeasingState>) => {
      const dataToEdit = leasingEquipmentData[key];

      const index = dataToEdit.findIndex(
        (equipment: EquipmentModal) =>
          equipment.generatedId === data.generatedId,
      );

      const editedData = [...dataToEdit];

      editedData.splice(index, 1, data);

      return {
        leasingEquipmentData: {
          ...leasingEquipmentData,
          [key]: sortEquipments(editedData),
        },
      };
    },
  ),
  // REMOVE LEASING EQUIPMENT TABLE ITEM
  on(
    LeasingActions.deleteTableItemAction,
    ({
      payload: { data, key },
      state: { leasingEquipmentData },
    }: PayloadAndState<LeasingActionType, LeasingState>) => {
      const revertKey =
        key === 'curPriorTableData' ? 'curFinalTableData' : 'curPriorTableData';
      const dataToEdit = leasingEquipmentData[revertKey];

      const index = dataToEdit.findIndex(
        (equipmentModalItem: EquipmentModal) =>
          equipmentModalItem.generatedId === data.generatedId ||
          equipmentModalItem.equipment === data.equipment,
      );

      const editedData = [...dataToEdit];

      if (index >= 0) {
        editedData.splice(index, 1, {
          ...editedData[index],
          leaseRights: false,
        });
      }

      return {
        leasingEquipmentData: {
          ...leasingEquipmentData,
          [key]: [
            ...leasingEquipmentData[key].filter(
              (equipment: EquipmentModal) =>
                equipment.generatedId !== data.generatedId,
            ),
          ],
          [revertKey]: index < 0 ? leasingEquipmentData[revertKey] : editedData,
        },
      };
    },
  ),
  // UPDATE LEASING EQUIPMENT
  on(
    LeasingActions.updateLeasingEquipmentAction,
    ({
      payload,
      state: { leasingEquipmentData },
    }: PayloadAndState<UpdateLeasingEquipmentData, LeasingState>) => ({
      leasingEquipmentData: {
        ...leasingEquipmentData,
        ...payload,
        curPriorTableData: generatedIdMapper(
          payload.priorTableData,
          'PriorEquipment',
        ),
        curFinalTableData: generatedIdMapper(
          payload.finalTableData,
          'FinalEquipment',
        ),
      },
    }),
  ),
  // EMPTY A PRIOR TABLE
  on(
    LeasingActions.emptyPriorTableAction,
    ({
      state: { leasingEquipmentData },
    }: PayloadAndState<null, LeasingState>) => ({
      leasingEquipmentData: {
        ...leasingEquipmentData,
        curPriorTableData: [],
      },
    }),
  ),
  // GENERATE LEASING EQUIPMENT FINAL TABLE DATA
  on(
    LeasingActions.generateFinalLoadingAction,
    ({
      payload,
      state: { leasingEquipmentData },
    }: PayloadAndState<ReactText[], LeasingState>) => ({
      leasingEquipmentData: {
        ...leasingEquipmentData,
        curPriorTableData: sortEquipments(
          leasingEquipmentData.curPriorTableData.map(
            (priorEquipment: EquipmentModal) => ({
              ...priorEquipment,
              ...{
                leaseRights: payload.includes(
                  priorEquipment?.generatedId || '',
                ),
              },
            }),
          ),
        ),
        curFinalTableData: sortEquipments([
          ...leasingEquipmentData.scopingTableData,
          ...leasingEquipmentData.curPriorTableData
            .filter((priorEquipment: EquipmentModal) =>
              payload.includes(priorEquipment?.generatedId || ''),
            )
            .map((priorEquipment: EquipmentModal) => ({
              ...priorEquipment,
              leaseRights: true,
            })),
        ]),
      },
    }),
  ),
  // RESET LEASING EQUIPMENT TABLES DATA
  on(
    LeasingActions.resetTablesDataAction,
    ({
      state: { leasingEquipmentData },
    }: PayloadAndState<null, LeasingState>) => ({
      leasingEquipmentData: {
        ...leasingEquipmentData,
        curPriorTableData: [...leasingEquipmentData.priorTableData],
        curFinalTableData: [...leasingEquipmentData.finalTableData],
      },
    }),
  ),
);
