import { Rule } from 'antd/es/form';
import { Moment } from 'moment';
import { RuleObject } from 'rc-field-form/lib/interface';

export const DATE_INPUT_FORMAT_VALIDATOR: Rule[] = [
  {
    validator: (_: RuleObject, inputValue: Moment): Promise<void> =>
      inputValue == null || inputValue.isValid()
        ? Promise.resolve()
        : Promise.reject(new Error('Should be a date with correct format')),
  },
];
