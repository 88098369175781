import { PermittingAdditionFilterType } from 'providence-types';
import { AdditionalFilter, Filter } from '@models/interfaces';

export const PERMITTING_BROWSE_RANGE_FILTERS: Filter[] = [
  {
    id: 'zoningSubmittedForecastedDate',
    name: 'Zoning Submitted Forecasted Date',
  },
  {
    id: 'zoningSubmittedActualDate',
    name: 'Zoning Submitted Actual Date',
  },
  {
    id: 'projectAssignedDate',
    name: 'Assigned Date',
  },
];

export const ADDITIONAL_FILTERS: AdditionalFilter[] = [
  {
    key: PermittingAdditionFilterType.AuditNotComplete,
    name: 'Audit Not Complete',
  },
  {
    key: PermittingAdditionFilterType.ZoningSubmitRequired,
    name: 'Zoning Submit Required',
  },
  {
    key: PermittingAdditionFilterType.BRReceivedRequired,
    name: 'BP Received Required',
  },
];
