import { Resolver } from '@wellyes/react-router-extended';
import { HttpService } from '@core/services';
import { NotificationsHttpService } from '@core/services/http';

export class NotificationsResolver implements Resolver {
  async resolve(): Promise<void> {
    const httpService = HttpService.getHttpRequests(NotificationsHttpService);

    await httpService.getPaceTaskList();
    await httpService.getUsersList();
    await httpService.getRuleFields();
    await httpService.getSchedule();
    await httpService.getRules();
    await httpService.getEmailCertificationDate();
    await httpService.getDirectMessages();
  }
}
