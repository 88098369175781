import { OptionProps } from '@models/interfaces';

export const arrsToSelectableObject = <T>(
  arr: T[],
  valueKey: keyof T,
  viewValueKey: keyof T,
  withAllVariant: boolean = false,
): OptionProps[] => {
  const result = arr.map((property: T) => ({
    value: Number.isNaN(+property[valueKey])
      ? `${property[valueKey]}`
      : +property[valueKey],
    viewValue: Number.isNaN(+property[viewValueKey])
      ? `${property[viewValueKey]}`
      : +property[viewValueKey],
  }));

  if (withAllVariant) {
    result.unshift({ value: '', viewValue: 'All' });
  }

  return result;
};
