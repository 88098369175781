import React, {
  FC,
  PropsWithChildren,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { Form } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import classNames from 'classnames';
import { HttpService } from '@core/services';
import { PaceTaskHttpService } from '@core/services/http';
import { arrsToSelectableObject, isValidForm } from '@core/utils/methods';
import { serverValidator } from '@core/utils/validators';
import { ModalMainTypes, ValidationType } from '@models/enums';
import { PaceTask, PaceTaskScreen } from '@models/interfaces';
import { AddAndEditModal } from '@shared/components';
import { Autocomplete, InputNumber, RadioGroup } from '@shared/modules';
import { PaceTaskSelectors } from '@store/selectors';
import { paceTaskTabToScreen, TASK_IDENTIFIER } from './models/constants';
import { AddExistsProps } from './models/interfaces';

import './styles.scss';

const { useForm } = Form;

export const AddExistsPaceTaskModal: FC<AddExistsProps> = ({
  screen,
  visible,
  onOk,
  className,
  ...props
}: PropsWithChildren<AddExistsProps>) => {
  const paceTaskService = useMemo(
    () => HttpService.getHttpRequests(PaceTaskHttpService),
    [],
  );

  const [taskIdentifier, setTaskIdentifier] = useState<
    'paceTaskID' | 'taskName'
  >('paceTaskID');
  const [form] = useForm();
  const paceTasks: Omit<PaceTask, 'screens' | 'permissions'>[] = useSelector(
    PaceTaskSelectors.getExcludedPaceTsks,
  );

  const label: string =
    taskIdentifier === 'paceTaskID' ? 'PACE ID' : 'Task Name';
  const message: string = `${label} is required`;
  const options = arrsToSelectableObject<
    Omit<PaceTask, 'screens' | 'permissions'>
  >(
    (taskIdentifier === 'paceTaskID'
      ? paceTasks.filter(
          (paceTask: Omit<PaceTask, 'screens' | 'permissions'>) =>
            paceTask.paceTaskID,
        )
      : paceTasks
    ).sort(
      (
        a: Omit<PaceTask, 'screens' | 'permissions'>,
        b: Omit<PaceTask, 'screens' | 'permissions'>,
      ) => a[taskIdentifier]!.localeCompare(b[taskIdentifier]!),
    ),
    'id',
    taskIdentifier,
  );
  const resetForm = (): void => {
    setTaskIdentifier('paceTaskID');
    form.resetFields();
  };
  const save = async (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
  ): Promise<void> => {
    if (await isValidForm(form)) {
      const formFields = form.getFieldsValue(['id', 'order']);

      await paceTaskService.addPaceTaskScreen(
        screen,
        formFields as Omit<PaceTaskScreen, 'paceTask' | 'screen' | 'archive'>,
      );

      if (onOk) {
        onOk(e);
      }
    }
  };

  useEffect(() => {
    if (visible) {
      paceTaskService.getExcludedPaceTasks(screen);
      resetForm();
    }
  }, [visible, screen]);

  return (
    <AddAndEditModal
      title="PACE Task"
      visible={visible}
      type={ModalMainTypes.Add}
      okText="Submit"
      onOk={save}
      cancelButtonProps={{
        onClick: resetForm,
      }}
      className={classNames(className, 'prov-add-exist-pace-task')}
      formProps={{
        labelCol: { span: 9 },
        wrapperCol: { span: 10 },
        initialValues: {
          taskIdentifier: 'paceTaskID',
        },
        form,
      }}
      {...props}
    >
      <RadioGroup
        buttons={TASK_IDENTIFIER}
        formItemProps={{
          className: 'prov-add-exist-pace-task__task-identifier',
        }}
        elementProps={{
          value: taskIdentifier,
          onChange: ({ target: { value } }: RadioChangeEvent): void => {
            setTaskIdentifier(value);
            form.setFieldsValue({ id: null });
          },
        }}
        id="taskIdentifier"
        label="Identify Task to Be Added By"
      />
      <Autocomplete
        options={options}
        id="id"
        label={label}
        formItemProps={{
          rules: [{ required: true, message }],
        }}
      />
      <InputNumber
        id="order"
        label="Date Grid Order"
        formItemProps={{
          rules: [
            { required: true, message: 'Date Grid Order is required' },
            serverValidator(
              {
                method: 'paceTaskScreenValidation',
                errorMessage: 'Invalid value',
              },
              PaceTaskHttpService,
              paceTaskTabToScreen(screen),
              ValidationType.Create,
            ),
          ],
        }}
        elementProps={{
          min: 1,
        }}
      />
    </AddAndEditModal>
  );
};
