import React, { FC, PropsWithChildren, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Col, Form, Row } from 'antd';
import classNames from 'classnames';
import {
  momentizeObjectDates,
  readOnlyPercentRender,
  readOnlyPriceRender,
} from '@core/utils/methods';
import { DOLLAR_PREFIX, PERCENT_PREFIX, YES_NO_GROUP } from '@models/constants';
import { FormProps, StructuralInformation } from '@models/interfaces';
import { Panel } from '@shared/components';
import {
  Autocomplete,
  Checkbox,
  DatePicker,
  Input,
  InputNumber,
  RadioGroup,
  TextArea,
} from '@shared/modules';
import { DDVSelectors } from '@store/selectors';
import { STRUCTURAL_INFO_DATES } from '../../models/constants';

export const StructuralPanel: FC<FormProps> = ({
  form,
  isEditing,
  data,
  onValuesChange,
  permissions: {
    ATTModApproved,
    IBMApproved,
    IBMEligible,
    PMIModTurnover,
    PMSAFoundationPercent,
    PMSAReceived,
    PMSATowerPercent,
    failingStructuralReceived,
    modConstructionComplete,
    modConstructionStart,
    modCost,
    modDesignComplete,
    orderModDesign,
    passingStructuralReceived,
    structuralComments,
    structuralFoundationPercent,
    structuralOrdered,
    structuralRequired,
    structuralResult,
    structuralService,
    structuralTIARevCode,
    structuralTowerPercent,
    adequate,
    structuralVendor,
  },
}: PropsWithChildren<FormProps>) => {
  const dropdownsOptions = useSelector(DDVSelectors.getDDVOptions);

  useEffect(() => {
    if (data) {
      form?.setFieldsValue(
        momentizeObjectDates<StructuralInformation>(
          data as StructuralInformation,
          STRUCTURAL_INFO_DATES,
        ),
      );
    }
  }, [data, isEditing]);

  return (
    <Panel header="Structural" className="structural-panel" collapsible>
      <Form
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 14 }}
        onValuesChange={onValuesChange}
        form={form}
      >
        <Row>
          <Col span="12">
            <RadioGroup
              id="structuralRequired"
              label="Structural Required"
              isEditing={isEditing && structuralRequired}
              buttons={YES_NO_GROUP}
            />
            <DatePicker
              id="structuralOrdered"
              label="Structural Ordered"
              isEditing={isEditing && structuralOrdered}
            />
            <Autocomplete
              id="structuralVendor"
              label="Structural Vendor"
              isEditing={isEditing && structuralVendor}
              options={dropdownsOptions.StructuralVendor}
            />
            <Input
              id="structuralPOIssued"
              label="Structural PO Issued"
              isEditing={false}
            />
            <Autocomplete
              id="structuralService"
              label="Structural Service"
              isEditing={isEditing && structuralService}
              options={dropdownsOptions.StructuralService}
            />
            <Autocomplete
              id="structuralResult"
              label="Structural Result"
              isEditing={isEditing && structuralResult}
              options={dropdownsOptions.StructuralResult}
            />
            <DatePicker
              id="passingStructuralReceived"
              label="Passing Structural Received"
              isEditing={isEditing && passingStructuralReceived}
            />
            <TextArea
              id="structuralComments"
              label="Structural Comments"
              isEditing={isEditing && structuralComments}
            />
            <div className="structural-panel__row-wrapper">
              <InputNumber
                id="structuralTowerPercent"
                label="Structural Tower Percent"
                isEditing={isEditing && structuralTowerPercent}
                elementProps={{ formatter: PERCENT_PREFIX }}
                className={classNames('structural-tower-percent', 'ant-col-17')}
                readOnlyElementRender={readOnlyPercentRender}
              />
              <Checkbox
                id="adequate"
                className={classNames('adequate', 'ant-col-5')}
                label="Adequate"
                formItemProps={{
                  wrapperCol: { span: 5 },
                  labelCol: { span: 16 },
                }}
                isEditing={isEditing && adequate}
              />
            </div>
            <InputNumber
              id="structuralFoundationPercent"
              label="Structural Foundation Percent"
              isEditing={isEditing && structuralFoundationPercent}
              elementProps={{ formatter: PERCENT_PREFIX }}
              readOnlyElementRender={readOnlyPercentRender}
            />
            <Autocomplete
              id="structuralTIARevCode"
              label="Structural TIA Rev Code"
              isEditing={isEditing && structuralTIARevCode}
              options={dropdownsOptions.StructuralTIARevCode}
            />
            <DatePicker
              id="failingStructuralReceived"
              label="Failing Structural Received"
              isEditing={isEditing && failingStructuralReceived}
            />
          </Col>
          <Col span="12">
            <InputNumber
              id="modCost"
              label="Mod Cost"
              isEditing={isEditing && modCost}
              elementProps={{ formatter: DOLLAR_PREFIX }}
              readOnlyElementRender={readOnlyPriceRender}
            />
            <DatePicker
              id="orderModDesign"
              label="Order Mod Design"
              isEditing={isEditing && orderModDesign}
            />
            <DatePicker
              id="modDesignComplete"
              label="Mod Design Complete"
              isEditing={isEditing && modDesignComplete}
            />
            <DatePicker
              id="PMSAReceived"
              label="PMSA Received"
              isEditing={isEditing && PMSAReceived}
            />
            <InputNumber
              id="PMSATowerPercent"
              label="PMSA Tower Percent"
              isEditing={isEditing && PMSATowerPercent}
              elementProps={{ formatter: PERCENT_PREFIX }}
              readOnlyElementRender={readOnlyPercentRender}
            />
            <InputNumber
              id="PMSAFoundationPercent"
              label="PMSA Foundation Percent"
              isEditing={isEditing && PMSAFoundationPercent}
              elementProps={{ formatter: PERCENT_PREFIX }}
              readOnlyElementRender={readOnlyPercentRender}
            />
            <DatePicker
              id="ATTModApproved"
              label="ATT Mod Approved"
              isEditing={isEditing && ATTModApproved}
            />
            <Autocomplete
              id="IBMEligible"
              label="IBM Eligible"
              isEditing={isEditing && IBMEligible}
              options={dropdownsOptions.IBMEligible}
            />
            <DatePicker
              id="IBMApproved"
              label="IBM Approved"
              isEditing={isEditing && IBMApproved}
            />
            <DatePicker
              id="modConstructionStart"
              label="Mod Construction Start"
              isEditing={isEditing && modConstructionStart}
            />
            <DatePicker
              id="modConstructionComplete"
              label="Mod Construction Complete"
              isEditing={isEditing && modConstructionComplete}
            />
            <DatePicker
              id="PMIModTurnover"
              label="PMI Mod Turnover"
              isEditing={isEditing && PMIModTurnover}
            />
          </Col>
        </Row>
      </Form>
    </Panel>
  );
};
