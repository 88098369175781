import { ReactText } from 'react';

export const getEnumKeyByEnumValue = <TEnum>(
  someEnum: TEnum,
  enumValue: ReactText,
): keyof TEnum | string => {
  const [key] =
    Object.entries(someEnum).find(
      ([, value]: [string, string]) => value === enumValue,
    ) ?? '';

  return key;
};
