import { Dispatch, SetStateAction } from 'react';
import { FormInstance } from 'antd/lib/form';
import { SelectValue } from 'antd/lib/select';
import { getEnumKeyByEnumValue } from '@core/utils/methods';

export const actualizeControl = <T>(
  setter: Dispatch<SetStateAction<keyof T | string | null>>,
  form: FormInstance,
  currentEnum: T,
  value: SelectValue,
  resetFields: string[],
): void => {
  setter(getEnumKeyByEnumValue(currentEnum, value as string));
  form.resetFields([...resetFields]);
};
