import React, { FC, PropsWithChildren, useEffect, useMemo } from 'react';
import { Form } from 'antd';
import { HttpService } from '@core/services';
import { EquipmentHttpService } from '@core/services/http';
import { isValidForm } from '@core/utils/methods';
import {
  INPUT_NUMBER_VALIDATION_RULES,
  WHITESPACE_INPUT_LIMIT_RULES,
} from '@models/constants';
import { ModalMainTypes } from '@models/enums';
import { EquipmentTabData } from '@models/interfaces';
import { AddAndEditModal } from '@shared/components';
import { Input, InputNumber, TextArea } from '@shared/modules';
import { EQUIPMENT_DEFAULT_VALUE } from '../../models';
import { EquipmentModalProps } from './models';

import './styles.scss';

const { useForm } = Form;

export const EquipmentModal: FC<EquipmentModalProps> = ({
  value = EQUIPMENT_DEFAULT_VALUE,
  visible,
  modalType,
  toggleModal,
  equipmentTypeSelectorValue,
  equipmentManufacturerSelectorValue,
}: PropsWithChildren<EquipmentModalProps>) => {
  const [form] = useForm();

  const equipmentService = useMemo(
    () => HttpService.getHttpRequests(EquipmentHttpService),
    [],
  );

  const getEquipmentData = (): EquipmentTabData => ({
    ...value,
    ...form.getFieldsValue(),
  });

  useEffect(() => {
    if (visible) {
      form.setFieldsValue(value);
    }
  }, [visible, value, form]);

  const add = async ({ id, ...equipment }: EquipmentTabData): Promise<void> => {
    if (await isValidForm(form)) {
      if (equipmentTypeSelectorValue && equipmentManufacturerSelectorValue) {
        await equipmentService.addEquipment(
          equipmentTypeSelectorValue,
          equipmentManufacturerSelectorValue,
          equipment,
        );
      }

      toggleModal(modalType);
    }
  };

  const edit = async (equipment: EquipmentTabData): Promise<void> => {
    if (await isValidForm(form)) {
      await equipmentService.editEquipment(equipment);
      toggleModal(modalType);
    }
  };

  return (
    <AddAndEditModal
      visible={visible}
      type={modalType}
      okText="Save"
      cancelText="Cancel"
      className="prov-equipment-modal"
      formProps={{
        labelCol: { span: 5 },
        wrapperCol: { span: 15 },
        form,
        initialValues: value,
      }}
      title={`${modalType === ModalMainTypes.Add ? 'New' : ''} Item`}
      onCancel={(): void => toggleModal(modalType)}
      onOk={
        modalType === ModalMainTypes.Add
          ? (): Promise<void> => add(getEquipmentData())
          : (): Promise<void> => edit(getEquipmentData())
      }
    >
      <Input
        id="modelNumber"
        label="Model Number"
        elementProps={{
          autoFocus: true,
        }}
        formItemProps={{
          rules: [
            { required: true, message: 'Model Number is required!' },
            ...WHITESPACE_INPUT_LIMIT_RULES,
          ],
        }}
      />
      <InputNumber
        id="length"
        label="Length"
        formItemProps={{
          rules: INPUT_NUMBER_VALIDATION_RULES,
        }}
      />
      <InputNumber
        id="width"
        label="Width"
        formItemProps={{
          rules: INPUT_NUMBER_VALIDATION_RULES,
        }}
      />
      <InputNumber
        id="depth"
        label="Depth"
        formItemProps={{
          rules: INPUT_NUMBER_VALIDATION_RULES,
        }}
      />
      <InputNumber
        id="weight"
        label="Weight"
        formItemProps={{
          rules: INPUT_NUMBER_VALIDATION_RULES,
        }}
      />
      <TextArea
        id="description"
        label="Description"
        formItemProps={{
          rules: [...WHITESPACE_INPUT_LIMIT_RULES],
        }}
      />
    </AddAndEditModal>
  );
};
