import React, { FC, PropsWithChildren, useMemo, useState } from 'react';
import { ConfigurablePanel } from '@permitting/jurisdiction/details/components';
import { JurisdictionModal } from '@permitting/jurisdiction/details/modals';
import {
  DEFAULT_JURISDICTION_MODAL_VALUES,
  JURISDICTION_CAN_USER_EDIT,
} from '@permitting/jurisdiction/details/models';
import { HttpService } from '@core/services';
import { PermittingHttpService } from '@core/services/http';
import { useDidUpdateEffect } from '@core/utils/hooks';
import { getIdParamFromUrl, removeEmptyFields } from '@core/utils/methods';
import { ModalMainTypes } from '@models/enums';
import { JurisdictionModalValues } from '@models/interfaces';
import { DeleteModal, Panel } from '@shared/components';
import { PrimaryButton } from '@shared/modules';
import { mapDataToZoningPanelData } from '../../helpers';
import { ZoningZoningSectionProps } from '../../models';

import './styles.scss';

export const JurisdictionZoningSection: FC<ZoningZoningSectionProps> = ({
  isEditing,
  zoningLTEFields,
  zoningNSBFields,
  data: zoningData,
  updateZoningSection,
  reset,
}: PropsWithChildren<ZoningZoningSectionProps>) => {
  const permittingService = useMemo(
    () => HttpService.getHttpRequests(PermittingHttpService),
    [],
  );

  const [data, setData] = useState(() =>
    mapDataToZoningPanelData(zoningData, zoningLTEFields, zoningNSBFields),
  );
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false);
  const [removedId, setRemovedId] = useState<string>('');
  const [modalValues, setModalValues] = useState<JurisdictionModalValues>(
    DEFAULT_JURISDICTION_MODAL_VALUES,
  );
  const [modalActionType, setModalActionType] = useState<ModalMainTypes | null>(
    null,
  );

  useDidUpdateEffect(() => {
    setData(
      mapDataToZoningPanelData(zoningData, zoningLTEFields, zoningNSBFields),
    );
  }, [zoningData, reset]);

  const openModal = (
    type: ModalMainTypes,
    formValues?: JurisdictionModalValues,
  ): void => {
    setModalValues(
      type === ModalMainTypes.Edit && formValues
        ? formValues
        : DEFAULT_JURISDICTION_MODAL_VALUES,
    );
    setModalActionType(type);

    setModalVisible(!modalVisible);
  };

  const add = async (formValues: JurisdictionModalValues): Promise<void> => {
    const [jurisdictionId] = getIdParamFromUrl();

    await permittingService.addJurisdictionZoningPanel(
      jurisdictionId,
      removeEmptyFields(formValues),
    );
  };

  const update = async (formValues: JurisdictionModalValues): Promise<void> => {
    await permittingService.updateJurisdictionZoningPanel(formValues);
  };

  const onOk = async (formValues: JurisdictionModalValues): Promise<void> => {
    const cb = modalActionType === ModalMainTypes.Add ? add : update;

    await cb(formValues);

    setModalVisible(!modalVisible);
  };

  const openDeleteModel = (id: string): void => {
    setRemovedId(id);
    setDeleteModalVisible(!deleteModalVisible);
  };

  const closeDeleteModel = (): void => {
    setRemovedId('');
    setDeleteModalVisible(!deleteModalVisible);
  };

  const onDelete = async (): Promise<void> => {
    await permittingService.removeJurisdictionZoningPanel(removedId);
    setDeleteModalVisible(!deleteModalVisible);
  };

  return (
    <>
      <Panel header="Zoning" className="prov-zoning-zoning-section" collapsible>
        {JURISDICTION_CAN_USER_EDIT() && (
          <PrimaryButton
            className="prov-zoning-zoning-section__add-button"
            title="Add Zoning"
            icon="+"
            disabled={!isEditing}
            onClick={(): void => openModal(ModalMainTypes.Add)}
          />
        )}
        <ConfigurablePanel
          panels={data}
          openModal={openModal}
          openDeleteModal={openDeleteModel}
          formUpdating={updateZoningSection}
          isEditing={isEditing}
        />
      </Panel>
      <JurisdictionModal
        title={modalValues.id ? 'Edit Zoning' : 'Add Zoning'}
        visible={modalVisible}
        initialFormValues={modalValues}
        onOk={onOk}
        onCancel={(): void => setModalVisible(!modalVisible)}
      />
      <DeleteModal
        onOk={onDelete}
        onCancel={closeDeleteModel}
        visible={deleteModalVisible}
      />
    </>
  );
};
