import moment from 'moment';
import { Undefinable } from 'providence-types';
import {
  FiltersSetDataFilter,
  FiltersSetDataPaceTaskFilter,
  RangePickerValues,
} from '@models/interfaces';
import { FiltersSetDateValue, MomentString } from '@models/types';

const revertMoment = (
  value: Undefinable<MomentString>,
  revert: boolean,
): MomentString => (revert ? moment(value).format() : moment(value));

export const momentValue = (
  v: FiltersSetDateValue,
  revert: boolean = false,
): FiltersSetDateValue => {
  switch (true) {
    case revert ? v instanceof moment : typeof v === 'string':
      return revertMoment(v as MomentString, revert);
    case typeof v === 'object' && !!(v?.from && v?.to): {
      const value = v as Required<RangePickerValues<string>>;

      return {
        from: revertMoment(value.from, revert),
        to: revertMoment(value.to, revert),
      };
    }
    default:
      return undefined;
  }
};

export const convertPaceTaskFilterToObj = (
  paceTaskFilter: FiltersSetDataPaceTaskFilter[],
): FiltersSetDataFilter =>
  paceTaskFilter.reduce(
    (
      acc: FiltersSetDataFilter,
      { id, value, operator, visible }: FiltersSetDataPaceTaskFilter,
    ) => ({
      ...acc,
      [id]: {
        value: momentValue(value),
        operator,
        visible,
      },
    }),
    {},
  );
