import { DriverWorkflow } from 'providence-types';
import { DetailsResolver } from '@core/resolvers';
import { HttpService } from '@core/services';
import {
  DriverManagementHttpService,
  PermittingHttpService,
  ProjectCategoryHttpService,
} from '@core/services/http';
import { getIdParamFromUrl } from '@core/utils/methods';

export class PermittingDetailsResolver extends DetailsResolver {
  async resolve(): Promise<void> {
    const [projectId] = getIdParamFromUrl();

    await HttpService.getHttpRequests(
      PermittingHttpService,
    ).getPermittingDetailsData(projectId);

    await HttpService.getHttpRequests(
      ProjectCategoryHttpService,
    ).getProjectCategories();

    await HttpService.getHttpRequests(
      DriverManagementHttpService,
    ).getDriversListByWorkflow(DriverWorkflow.Permitting);
  }
}
