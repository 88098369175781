import { MainPaceTask } from 'providence-types';
import { PaceTasksToObjectMapper } from './pace-task-to-object-mapper.interface';

export const PERMITTING_SECTION_MAPPER_OPTIONS: PaceTasksToObjectMapper.Options[] =
  [
    {
      task: MainPaceTask.JurisdictionAuditComplete,
      fieldKey: 'jurisdictionAuditComplete',
    },
    {
      task: MainPaceTask.E911AddressValidationDate,
      fieldKey: 'e911AddressValidationDate',
    },
    {
      task: MainPaceTask.PrelimCDsReviewedPermitting,
      fieldKey: 'prelimCDsReviewedPermitting',
    },
  ];
