import { SubPage, Tab } from 'providence-types';
import { TabAccessGuard } from '../../core/guards/tab-access.guard';
import {
  AllPaceTasks,
  DirectMessages,
  Mailer,
  PagePaceTask,
  Rules,
  Schedule,
} from '../../modules/client/pages/admin/components';
import {
  EngineeringSummaryTab,
  EngineeringWorkflow,
  EquipmentTab,
  ScopingAssociatedProjects,
  ScopingLeasingEquipmentTab,
  SiteAuditTab,
  SummaryTab,
} from '../../modules/client/pages/engineering/components';
import {
  Equipment,
  EquipmentManufacturer,
  EquipmentType,
} from '../../modules/client/pages/equipment/components';
import {
  LeasingEquipmentTab,
  LeasingSummaryTab,
  LeasingWorkflow,
} from '../../modules/client/pages/leasing/components';
import {
  SiteAssociatedProjects,
  SiteContacts,
  SiteInformationTab,
} from '../../modules/client/pages/site/components';
import { Financial } from '../../shared/components/financial';
import { LeaseAuditTab } from '../../shared/components/lease-audit';
import { ScopingSelectors } from '../../store/selectors/engineering/scoping.selectors';
import { LeasingSelectors } from '../../store/selectors/leasing.selectors';
import { TabProps } from '../classes/models';

export const ENGINEERING_TABS_PROPS: TabProps[] = [
  {
    path: '/summary',
    name: 'Summary',
    component: EngineeringSummaryTab,
    guards: [
      new TabAccessGuard('engineering', SubPage.EngineeringBrowse, Tab.Summary),
    ],
  },
  {
    path: '/workflow',
    name: 'Workflow',
    component: EngineeringWorkflow,
    guards: [
      new TabAccessGuard(
        'engineering',
        SubPage.EngineeringBrowse,
        Tab.Workflow,
      ),
    ],
  },
];

export const SCOPING_TABS_PROPS: TabProps[] = [
  {
    name: 'Summary',
    component: SummaryTab,
    path: '/summary',
    guards: [
      new TabAccessGuard(
        'engineering',
        SubPage.EngineeringScoping,
        Tab.Summary,
      ),
    ],
  },
  {
    name: 'Equipment',
    component: EquipmentTab,
    path: '/equipment',
    guards: [
      new TabAccessGuard(
        'engineering',
        SubPage.EngineeringScoping,
        Tab.Equipment,
      ),
    ],
  },
  {
    name: 'Site Audit',
    component: SiteAuditTab,
    path: '/site-audit',
    guards: [
      new TabAccessGuard(
        'engineering',
        SubPage.EngineeringScoping,
        Tab.SiteAudit,
      ),
    ],
  },
  {
    name: 'Lease Audit',
    component: LeaseAuditTab,
    path: '/lease-audit',
    componentProps: {
      projectTypeSelector: ScopingSelectors.getProjectType,
      area: 'engineering',
      subPage: SubPage.EngineeringScoping,
    },
    guards: [
      new TabAccessGuard(
        'engineering',
        SubPage.EngineeringScoping,
        Tab.LeaseAudit,
      ),
    ],
  },
  {
    name: 'Lease Equipment',
    component: ScopingLeasingEquipmentTab,
    path: '/lease-equipment',
    guards: [
      new TabAccessGuard(
        'engineering',
        SubPage.EngineeringScoping,
        Tab.LeaseEquipment,
      ),
    ],
  },
  {
    name: 'Associated Projects',
    component: ScopingAssociatedProjects,
    path: '/associated-projects',
    guards: [
      new TabAccessGuard(
        'engineering',
        SubPage.EngineeringScoping,
        Tab.AssociatedProjects,
      ),
    ],
  },
];

export const SITE_TABS_PROPS: TabProps[] = [
  {
    path: '/site-information',
    name: 'Site Information',
    component: SiteInformationTab,
    guards: [
      new TabAccessGuard('site', SubPage.SiteBrowse, Tab.SiteInformation),
    ],
  },
  {
    path: '/contacts',
    name: 'Contacts',
    component: SiteContacts,
    guards: [new TabAccessGuard('site', SubPage.SiteBrowse, Tab.Contacts)],
  },
  {
    path: '/associated-projects',
    name: 'Associated Projects',
    component: SiteAssociatedProjects,
    guards: [
      new TabAccessGuard('site', SubPage.SiteBrowse, Tab.AssociatedProjects),
    ],
  },
];

export const LEASING_TABS_PROPS: TabProps[] = [
  {
    path: '/summary',
    component: LeasingSummaryTab,
    name: 'Summary',
    guards: [new TabAccessGuard('leasing', SubPage.LeasingBrowse, Tab.Summary)],
  },
  {
    path: '/workflow',
    component: LeasingWorkflow,
    name: 'Workflow',
    guards: [
      new TabAccessGuard('leasing', SubPage.LeasingBrowse, Tab.Workflow),
    ],
  },
  {
    path: '/lease-audit',
    component: LeaseAuditTab,
    name: 'Lease Audit',
    componentProps: {
      projectTypeSelector: LeasingSelectors.getProjectType,
      area: 'leasing',
      subPage: SubPage.LeasingBrowse,
    },
    guards: [
      new TabAccessGuard('leasing', SubPage.LeasingBrowse, Tab.LeaseAudit),
    ],
  },
  {
    path: '/equipment',
    component: LeasingEquipmentTab,
    name: 'Equipment',
    guards: [
      new TabAccessGuard('leasing', SubPage.LeasingBrowse, Tab.Equipment),
    ],
  },
  {
    path: '/financial',
    component: Financial,
    name: 'Financial',
    componentProps: {
      feeAccessPath: 'leasing',
      siteDataRootPath: ['leasing', 'leasingSite'],
      projectTypeSelector: LeasingSelectors.getProjectType,
    },
    guards: [
      new TabAccessGuard('leasing', SubPage.LeasingBrowse, Tab.Financial),
    ],
  },
];

export const EQUIPMENT_TABS_PROPS: TabProps[] = [
  {
    path: '/equipment-type',
    component: EquipmentType,
    name: 'Equipment Type',
  },
  {
    path: '/manufacturer',
    component: EquipmentManufacturer,
    name: 'Manufacturer',
  },
  {
    path: '/equipment',
    component: Equipment,
    name: 'Equipment',
  },
];

export const PACE_TASKS_TABS: TabProps[] = [
  {
    name: 'All PACE Tasks',
    component: AllPaceTasks,
    path: '/all-pace-tasks',
  },
  {
    name: 'Accounting',
    component: PagePaceTask,
    path: '/accounting',
  },
  {
    name: 'Project',
    component: PagePaceTask,
    path: '/project',
  },
  {
    name: 'Engineering',
    component: PagePaceTask,
    path: '/engineering',
  },
  {
    name: 'Leasing',
    component: PagePaceTask,
    path: '/leasing',
  },
  {
    name: 'Permitting',
    component: PagePaceTask,
    path: '/permitting',
  },
  {
    name: 'Project Management',
    component: PagePaceTask,
    path: '/project-management',
  },
  {
    name: 'Forecasting',
    component: PagePaceTask,
    path: '/forecasting',
  },
];

export const NOTIFICATIONS_TABS: TabProps[] = [
  {
    name: 'Rules',
    component: Rules,
    path: '/rules',
  },
  {
    name: 'Schedule',
    component: Schedule,
    path: '/schedule',
  },
  {
    name: 'Mailer',
    component: Mailer,
    path: '/mailer',
  },
  {
    name: 'Direct Messages',
    component: DirectMessages,
    path: '/direct-messages',
  },
];
