import React, { FC, ReactText, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Col, Form, Row } from 'antd';
import { Store } from 'antd/es/form/interface';
import { SelectValue } from 'antd/es/select';
import { RadioChangeEvent } from 'antd/lib/radio';
import { AxiosError } from 'axios';
import moment from 'moment';
import { HttpService } from '@core/services';
import { ExportsHttpService } from '@core/services/http';
import { useDidUpdateEffect } from '@core/utils/hooks';
import {
  createOnChangeModalVisibility,
  momentizeRangePickerValues,
} from '@core/utils/methods';
import {
  browseFiltersChange,
  tableCount,
  YES_NO_GROUP,
} from '@models/constants';
import {
  ExportsBrowse as ExportsBrowseInterface,
  ExportsBrowseFilter,
  ExportsDownloadReportsParams,
  RangePickerValues,
} from '@models/interfaces';
import { MainExportsBrowseFilter, SwitchType } from '@models/types';
import { NotificationsLoader } from '@shared/components';
import {
  PrimaryButton,
  RadioGroup,
  RangePicker,
  Select,
  SwitchBox,
} from '@shared/modules';
import { BrowsePage } from '@shared/modules/browse-page';
import { ExportsSelectors } from '@store/selectors';
import {
  EXPORT_BROWSE_DEFAULT_CONFIGURE_FILTER,
  EXPORTS_BROWSE_COLUMNS,
  EXPORTS_BROWSE_DEF_FILTER_VALUE,
  REPORT_DATE_TYPE_OPTIONS,
} from './models/constants';
import { Filters } from './filters';

import './styles.scss';

const { useForm } = Form;

export const ExportsBrowse: FC = () => {
  const [form] = useForm();
  const [exportForm] = useForm();

  const exportsService = useMemo(
    () => HttpService.getHttpRequests(ExportsHttpService),
    [],
  );

  const exportsBrowseData = useSelector(ExportsSelectors.getExportsBrowseData);

  const [filterValue, setFilterValue] = useState<MainExportsBrowseFilter>(
    EXPORTS_BROWSE_DEF_FILTER_VALUE,
  );
  const [configureFilter, setConfigureFilter] = useState<ExportsBrowseFilter>(
    EXPORT_BROWSE_DEFAULT_CONFIGURE_FILTER,
  );
  const [selectedRows, setSelectedRows] = useState<ReactText[]>([]);
  const [exportFormData, setExportFormData] = useState<Store>({
    todaysDate: EXPORTS_BROWSE_DEF_FILTER_VALUE.todaysDate,
    reportDateType: EXPORTS_BROWSE_DEF_FILTER_VALUE.reportDateType,
  });
  const [excludedRows, setExcludedRows] = useState<string[]>([]);
  const [showExported, setShowExported] = useState<boolean>(
    EXPORTS_BROWSE_DEF_FILTER_VALUE.showExportedRecords,
  );

  useDidUpdateEffect(() => {
    exportsService.getExportsBrowse(filterValue);
  }, [filterValue]);

  useDidUpdateEffect(() => {
    setFilterValue((prevState: MainExportsBrowseFilter) => ({
      ...prevState,
      showExportedRecords: showExported,
    }));
  }, [showExported]);

  const onSearchChanges = (search: string): void =>
    setFilterValue((prevState: MainExportsBrowseFilter) => ({
      ...prevState,
      search,
    }));

  const onApplyConfigureFilter = (): void =>
    setFilterValue((prevState: MainExportsBrowseFilter) => ({
      ...prevState,
      ...configureFilter,
    }));

  const onOnlyCompletedSwitch = (value: boolean): void =>
    setFilterValue((prevState: MainExportsBrowseFilter) => ({
      ...prevState,
      showCompletedProjects: value as SwitchType,
    }));

  const onSubmitExport = async (): Promise<void> => {
    const notification = new NotificationsLoader({
      message: 'Loading',
      description: 'Please wait',
    });

    try {
      await exportsService.dowloadReports({
        ...momentizeRangePickerValues(
          exportForm.getFieldsValue(),
          ['reportDate'],
          true,
        ),
        showExportedRecords: showExported,
        excludedRows: excludedRows.map((joinedIds: string) => {
          const [categoryId, projectPaceTaskId] = joinedIds.split('/');

          return { categoryId, projectPaceTaskId };
        }),
      } as ExportsDownloadReportsParams);

      exportForm.resetFields();
      setExportFormData({
        todaysDate: EXPORTS_BROWSE_DEF_FILTER_VALUE.todaysDate,
        reportDateType: EXPORTS_BROWSE_DEF_FILTER_VALUE.reportDateType,
      });
      setShowExported(EXPORTS_BROWSE_DEF_FILTER_VALUE.showExportedRecords);
      setSelectedRows([]);
      setExcludedRows([]);
      setFilterValue({
        ...EXPORTS_BROWSE_DEF_FILTER_VALUE,
        showCompletedProjects: filterValue?.showCompletedProjects,
      });
    } catch (e) {
      const error = e as AxiosError<{ statusText: string }>;

      NotificationsLoader.notificationError({
        message: 'Error',
        description: error?.response?.statusText || 'Something went wrong',
      });
    }
    notification.close();
  };

  const rowSelection = {
    selectedRowKeys: selectedRows,
    onChange: (rows: ReactText[]): void => {
      setExcludedRows((prevValue: string[]) =>
        Array.from(
          new Set([
            ...prevValue.filter(
              (prevExcludedRow: string) => !rows.includes(prevExcludedRow),
            ),
            ...selectedRows.filter(
              (selectedRow: ReactText) => !rows.includes(selectedRow),
            ),
          ] as string[]),
        ),
      );
      setSelectedRows(rows);
    },
  };

  useDidUpdateEffect(() => {
    const { todaysDate, reportDate, reportDateType } = exportFormData;

    const getSelectionIds = (
      exportsBrowseItems: ExportsBrowseInterface[],
    ): string[] =>
      exportsBrowseItems
        .filter(
          (exportsBrowseItem: ExportsBrowseInterface) =>
            !excludedRows.includes(exportsBrowseItem.id),
        )
        .map(
          (exportsBrowseItem: ExportsBrowseInterface) => exportsBrowseItem.id,
        );

    if (todaysDate) {
      setSelectedRows(getSelectionIds(exportsBrowseData?.items));
    }

    if (reportDate) {
      const filteredItems = exportsBrowseData?.items.filter(
        (exportsBrowseItem: ExportsBrowseInterface) => {
          if (reportDateType === 'Both') {
            return reportDate?.from && reportDate?.to
              ? moment(exportsBrowseItem.actualDateUpdated).isBetween(
                  reportDate?.from || 0,
                  reportDate?.to || undefined,
                  undefined,
                  '[]',
                ) ||
                  moment(exportsBrowseItem.PACEForecastedDateUpdated).isBetween(
                    reportDate?.from || 0,
                    reportDate?.to || undefined,
                    undefined,
                    '[]',
                  )
              : false;
          }

          return reportDate?.from && reportDate?.to
            ? moment(
                exportsBrowseItem[
                  `${reportDateType}Updated` as keyof ExportsBrowseInterface
                ],
              ).isBetween(
                reportDate?.from || 0,
                reportDate?.to || undefined,
                undefined,
                '[]',
              )
            : false;
        },
      );

      setSelectedRows(getSelectionIds(filteredItems));
    }
  }, [exportFormData, exportsBrowseData?.items]);

  useEffect(() => exportForm.setFieldsValue(exportFormData), [exportFormData]);

  const isButtonDisabled = (): boolean => {
    switch (true) {
      case !exportFormData?.reportDateType:
        return true;
      case !selectedRows.length:
        return true;
      case exportFormData.todaysDate ||
        (exportFormData?.reportDate?.from && exportFormData?.reportDate?.to):
      default:
        return false;
    }
  };

  const onChangeConfigureFilter = (filter: ExportsBrowseFilter): void =>
    setConfigureFilter((prevState: ExportsBrowseFilter) => ({
      ...prevState,
      ...filter,
    }));

  return (
    <BrowsePage
      redirectToDetails={false}
      columns={EXPORTS_BROWSE_COLUMNS}
      data={exportsBrowseData?.items}
      switchInfo="Show Completed Projects"
      additionalSwitch={
        <>
          <SwitchBox
            className="switch-wrap__btn"
            checked={showExported}
            onChange={(value: boolean): void => setShowExported(value)}
          />
          <span className="switch-wrap__info">Show Exported Records</span>
        </>
      }
      filters={
        <Filters onChanges={onChangeConfigureFilter} value={configureFilter} />
      }
      onChangeFilterModalVisibility={createOnChangeModalVisibility(
        form,
        configureFilter,
        filterValue,
        setConfigureFilter,
      )}
      className="prov-exports-browse"
      filterFormProps={{ labelCol: { span: 8 }, form }}
      cancelButtonProps={{
        onClick: (): void => {
          form?.resetFields();
          setConfigureFilter(EXPORT_BROWSE_DEFAULT_CONFIGURE_FILTER);
        },
      }}
      onChange={browseFiltersChange(setFilterValue)}
      onSearchChanges={onSearchChanges}
      onApplyFilter={onApplyConfigureFilter}
      onSwitch={onOnlyCompletedSwitch}
      additional={
        <Col className="prov-exports-browse__handler">
          <Row>
            <Form
              onValuesChange={(value: Store): void =>
                setExportFormData((prevValue: Store) => ({
                  ...prevValue,
                  ...value,
                }))
              }
              form={exportForm}
            >
              <Col>
                <Row>
                  <RadioGroup
                    id="todaysDate"
                    label="Need today's date report"
                    buttons={YES_NO_GROUP}
                    elementProps={{
                      onChange: ({
                        target: { value: todaysDate },
                      }: RadioChangeEvent): void => {
                        setSelectedRows([]);
                        setExcludedRows([]);
                        setFilterValue(
                          (prevValue: MainExportsBrowseFilter) => ({
                            ...prevValue,
                            todaysDate,
                          }),
                        );
                      },
                      disabled:
                        exportForm.getFieldsValue().reportDate?.from ||
                        exportForm.getFieldsValue().reportDate?.to,
                    }}
                  />
                  <RangePicker
                    label="Report dates for selection"
                    id="reportDate"
                    onChange={(value?: RangePickerValues): void => {
                      const { reportDate } = momentizeRangePickerValues(
                        { reportDate: value },
                        ['reportDate'],
                        true,
                      );

                      // if dates, or we reset range picker
                      if (
                        reportDate &&
                        ((reportDate.from && reportDate.to) ||
                          (reportDate.from === null && reportDate.to === null))
                      ) {
                        setFilterValue(
                          (prevValue: MainExportsBrowseFilter) => ({
                            ...prevValue,
                            reportDate:
                              reportDate.from && reportDate.to
                                ? reportDate
                                : undefined,
                          }),
                        );
                      }
                    }}
                    disabled={exportForm.getFieldsValue().todaysDate}
                  />
                </Row>
                <Row>
                  <Select
                    id="reportDateType"
                    label="Report Date Type"
                    options={REPORT_DATE_TYPE_OPTIONS}
                    elementProps={{
                      value: 'Both',
                      disabled: !(
                        (exportForm.getFieldsValue().reportDate?.from &&
                          exportForm.getFieldsValue().reportDate?.to) ||
                        exportForm.getFieldsValue().todaysDate
                      ),
                      onChange: (reportDateType: SelectValue): void => {
                        setFilterValue(
                          (prevValue: MainExportsBrowseFilter) => ({
                            ...prevValue,
                            reportDateType: reportDateType as string,
                          }),
                        );
                      },
                    }}
                  />
                </Row>
                <Row>
                  <PrimaryButton
                    onClick={onSubmitExport}
                    title="Download"
                    className="download-btn"
                    disabled={isButtonDisabled()}
                  />
                </Row>
              </Col>
            </Form>
          </Row>
        </Col>
      }
      place={tableCount(true)}
      tableProps={{
        rowSelection,
        pagination: {
          total: exportsBrowseData?.total,
        },
        module: 'exports',
      }}
    />
  );
};
