import React, { ReactNode } from 'react';
import { MainPaceTask } from 'providence-types';
import {
  projectCategoryRender,
  projectPaceTaskDataRender,
} from '@core/utils/methods';
import { AdminDropdownValuesData } from '@models/interfaces';
import { ProjectStatus } from '@shared/modules';

export const ASSOCIATED_PROJECTS_COLUMNS = [
  {
    title: 'PACE Project Number',
    key: 'PACEProjectNumber',
    dataIndex: 'categories',
    sorter: true,
    width: 195,
    render: projectCategoryRender({
      field: 'PACEProjectNumber',
    }),
  },
  {
    title: 'Oracle Project Number',
    key: 'oracleProjectNumber',
    dataIndex: 'categories',
    sorter: true,
    width: 210,
    render: projectCategoryRender({
      field: 'oracleProjectNumber',
    }),
  },
  {
    title: 'Project Category',
    key: 'projectCategory',
    dataIndex: 'categories',
    sorter: true,
    width: 200,
    render: projectCategoryRender({
      field: 'projectCategory',
    }),
  },
  {
    title: 'Project Ranking',
    key: 'projectRanking',
    dataIndex: 'categories',
    editable: true,
    sorter: true,
    render: projectCategoryRender({
      field: 'projectRanking',
    }),
  },
  {
    title: 'Project Type',
    key: 'projectType',
    dataIndex: 'projectType',
    sorter: true,
    render: (projectType: AdminDropdownValuesData): ReactNode =>
      projectType?.value,
  },
  {
    title: 'Date Assigned',
    key: 'projectAssignedDate',
    dataIndex: 'categories',
    sorter: true,
    render: projectCategoryRender({
      field: 'projectAssignedDate',
      dateField: true,
    }),
  },
  {
    title: 'Project Work Type',
    key: 'projectWorkType',
    dataIndex: 'projectWorkType',
    sorter: true,
    width: 155,
    render: (projectWorkType: AdminDropdownValuesData): ReactNode =>
      projectWorkType?.value,
  },
  {
    title: 'Project Status',
    key: 'projectStatus',
    dataIndex: 'projectStatus',
    sorter: true,
    render: (status: { value: string }): ReactNode => (
      <ProjectStatus status={status?.value} />
    ),
  },
  {
    title: 'Site ACQ Vendor',
    key: 'siteAcqVendor',
    dataIndex: 'siteAcqVendor',
    sorter: true,
    render: (siteAcqVendor: AdminDropdownValuesData): ReactNode =>
      siteAcqVendor?.value,
  },
  {
    title: 'Site Acq Comp RE020',
    key: 'siteAcqCompleteRE020',
    dataIndex: 'projectPaceTaskData',
    sorter: true,
    width: 200,
    render: projectPaceTaskDataRender({
      field: 'actualDate',
      task: MainPaceTask.SiteAcqComplete,
    }),
  },
];
