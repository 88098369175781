import { SiteOptions } from '@models/interfaces';

export const SOF_TOOL_HEADER_SITE_OPTIONS: SiteOptions[] = [
  {
    key: 'FALocationNumber',
    label: 'FA Location Number',
  },
  {
    key: 'siteNumber',
    label: 'Site Number',
  },
  {
    key: 'legacySiteId',
    label: 'Legacy Site ID',
  },
];
