import React, { FC, PropsWithChildren } from 'react';
import { Form } from 'antd';
import classNames from 'classnames';
import { TextArea } from '@shared/modules';
import { LeasingEquipmentNotesProps } from './models';

export const LeasingEquipmentNotes: FC<LeasingEquipmentNotesProps> = ({
  form,
  isEditing,
  className,
  ...props
}: PropsWithChildren<LeasingEquipmentNotesProps>) => (
  <Form
    form={form}
    className={classNames(className, 'prov-leasing-equipment__notes')}
  >
    <TextArea
      {...props}
      isEditing={isEditing}
      className="prov-leasing-equipment__notes-textarea"
      elementProps={{ autoSize: { minRows: 3 } }}
    />
  </Form>
);
