import {
  IReceivedDirectMessage,
  IRequiresAttention,
  ISelectorOption,
  IToDo,
} from 'providence-types';
import { actionCreatorFactory } from '@core/utils/factories';
import {
  AccountingNotificationData,
  DashboardItem,
  DashboardUpdatableItem,
  MultiSetCompletedDirectMessage,
  MultiUpdateAssignees,
  SetCompletedDirectMessage,
} from '@models/interfaces';

const actionCreator = actionCreatorFactory('DASHBOARD');

export namespace DashboardActions {
  export const getAdminList =
    actionCreator.createAsyncAction<ISelectorOption<string>[]>(
      'GET_ADMIN_LIST',
    );

  export const getUserList =
    actionCreator.createAsyncAction<ISelectorOption<string>[]>('GET_USER_LIST');
  // To Do Item
  export const getAssignedToDoItems = actionCreator.createAsyncAction<
    DashboardItem<IToDo>[]
  >('GET_ASSIGNED_TO_DO_ITEMS');

  export const getAllToDoItems = actionCreator.createAsyncAction<
    DashboardItem<IToDo>[]
  >('GET_ALL_TO_DO_ITEMS');

  export const updateToDoItem =
    actionCreator.createAsyncAction<DashboardUpdatableItem>(
      'UPDATE_TO_DO_ITEM',
    );

  export const multiUpdateToDoAssignees =
    actionCreator.createAsyncAction<MultiUpdateAssignees>(
      'MULTI_UPDATE_TO_DO_ASSIGNEES',
    );

  // Requires Attention Item
  export const getAssignedRequiresAttentionItems =
    actionCreator.createAsyncAction<DashboardItem<IRequiresAttention>[]>(
      'GET_ASSIGNED_REQUIRES_ATTENTION_ITEMS',
    );

  export const getAllRequiresAttentionItems = actionCreator.createAsyncAction<
    DashboardItem<IRequiresAttention>[]
  >('GET_ALL_REQUIRES_ATTENTION_ITEMS');

  export const updateRequiresAttentionItem =
    actionCreator.createAsyncAction<DashboardUpdatableItem>(
      'UPDATE_REQUIRES_ATTENTION_ITEM',
    );

  export const multiUpdateRequiresAttentionAssignees =
    actionCreator.createAsyncAction<MultiUpdateAssignees>(
      'MULTI_UPDATE_REQUIRES_ATTENTION_ASSIGNEES',
    );
  // Accounting Item
  export const getAccountingItems = actionCreator.createAsyncAction<
    DashboardItem<AccountingNotificationData>[]
  >('GET_ACCOUNTING_ITEMS');
  // Direct Message Item
  export const getDirectMessage =
    actionCreator.createAsyncAction<DashboardItem<IReceivedDirectMessage>[]>(
      'GET_DIRECT_MESSAGE',
    );

  export const setCompletedDirectMessage =
    actionCreator.createAsyncAction<SetCompletedDirectMessage>(
      'SET_COMPLETED_DIRECT_MESSAGE',
    );

  export const multiSetCompletedDirectMessage =
    actionCreator.createAsyncAction<MultiSetCompletedDirectMessage>(
      'MULTI_SET_COMPLETED_DIRECT_MESSAGE',
    );
}
