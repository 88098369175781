import React, { FC, PropsWithChildren } from 'react';
import { InputNumberProps } from 'antd/lib/input-number';
import { FilterNumberOperator } from 'providence-types';
import { arraySort, getCurrentOptions } from '@core/utils/methods';
import { InputNumber } from '@shared/modules';
import { ItemWithOperator } from '../item-with-operator';
import { ComponentWithOperatorProps, getFilterOperator } from '../models';

import './styles.scss';

type InputNumberWithOperatorProps =
  ComponentWithOperatorProps<InputNumberProps>;

export const InputNumberWithOperator: FC<InputNumberWithOperatorProps> = (
  props: PropsWithChildren<InputNumberWithOperatorProps>,
) => (
  <ItemWithOperator
    component={InputNumber}
    className="prov-input-number-with-operator"
    selectProps={{
      options: arraySort(
        getCurrentOptions(FilterNumberOperator, getFilterOperator, false, true),
        'ASC',
        'viewValue',
      ),
      label: '',
    }}
    {...props}
  />
);
