import React, { FC, PropsWithChildren, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Col, Form, Row } from 'antd';
import { momentizeObjectDates } from '@core/utils/methods';
import {
  FormProps,
  LeaseAuditData,
  LeasingInformation,
} from '@models/interfaces';
import { Panel } from '@shared/components';
import { Autocomplete, DatePicker, Input, TextArea } from '@shared/modules';
import { DDVSelectors } from '@store/selectors';
import { LEASING_INFO_DATES } from '../../models/constants';

export const LeasingPanel: FC<FormProps> = ({
  form,
  data,
  isEditing,
  onValuesChange,
  permissions: {
    leaseAuditComplete,
    SOFIssuedDate,
    landlordRequiresMA,
    coloAppDrafted,
    coloAppSubmitted,
    coloAppApproved,
    PALOrNPARequired,
    PALOrNPAReceived,
    leaseDraftReceived,
    leaseDocumentName,
    leaseType,
    leaseRevisionRequested,
    LMTSubmitted,
    LMTApproved,
    legalAssignedDate,
    legalSubmitted,
    legalCompany,
    legalApproved,
    outForSignature,
    eSignature,
    leaseOrAmendmentFullyExecuted,
    NTPRequirements,
    NTPRequested,
    NTPApproved,
    leasingNotes,
  },
}: PropsWithChildren<FormProps>) => {
  const dropdownsOptions = useSelector(DDVSelectors.getDDVOptions);

  useEffect(() => {
    if (data) {
      if (data.leaseAudit) {
        const {
          leaseAudit: { auditDate },
        } = data;

        form?.setFieldsValue(
          momentizeObjectDates<Partial<LeaseAuditData>>(
            {
              auditDate,
            },
            ['auditDate'],
          ),
        );
      }

      form?.setFieldsValue(
        momentizeObjectDates<LeasingInformation>(data, LEASING_INFO_DATES),
      );
    }
  }, [data]);

  return (
    <Panel header="Leasing" className="leasing-panel" collapsible>
      <Form
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 14 }}
        onValuesChange={onValuesChange}
        form={form}
      >
        <Row>
          <Col span="11">
            <DatePicker
              id="leaseAuditComplete"
              label="Lease Audit Complete"
              isEditing={isEditing && leaseAuditComplete}
            />
            <DatePicker
              id="SOFIssuedDate"
              label="SOF Issued Date"
              isEditing={isEditing && SOFIssuedDate}
            />
            <Autocomplete
              id="landlordRequiresMA"
              label="Landlord Requires MA"
              isEditing={isEditing && landlordRequiresMA}
              options={dropdownsOptions.LandlordRequiresMA}
            />
            <DatePicker
              id="coloAppDrafted"
              label="Colo App Drafted"
              isEditing={isEditing && coloAppDrafted}
            />
            <DatePicker
              id="coloAppSubmitted"
              label="Colo App Submitted"
              isEditing={isEditing && coloAppSubmitted}
            />
            <DatePicker
              id="coloAppApproved"
              label="Colo App Approved"
              isEditing={isEditing && coloAppApproved}
            />
            <Autocomplete
              id="PALOrNPARequired"
              label="PAL/NPA Required"
              isEditing={isEditing && PALOrNPARequired}
              options={dropdownsOptions.PALNPARequired}
            />
            <DatePicker
              id="PALOrNPAReceived"
              label="PAL/NPA Received"
              isEditing={isEditing && PALOrNPAReceived}
            />
            <DatePicker
              id="leaseDraftReceived"
              label="Lease Draft Received"
              isEditing={isEditing && leaseDraftReceived}
            />
            <Input
              id="leaseDocumentName"
              label="Lease Document Name"
              isEditing={isEditing && leaseDocumentName}
            />
            <Autocomplete
              id="leaseType"
              label="Lease Type"
              isEditing={isEditing && leaseType}
              options={dropdownsOptions.LeaseType}
            />
            <DatePicker
              id="leaseRevisionRequested"
              label="Lease Revision Requested"
              isEditing={isEditing && leaseRevisionRequested}
            />
            <DatePicker
              id="LMTSubmitted"
              label="LMT Submitted"
              isEditing={isEditing && LMTSubmitted}
            />
          </Col>
          <Col span="11">
            <DatePicker
              id="LMTApproved"
              label="LMT Approved"
              isEditing={isEditing && LMTApproved}
            />
            <DatePicker
              id="legalSubmitted"
              label="Legal Submitted"
              isEditing={isEditing && legalSubmitted}
            />
            <DatePicker
              id="legalAssignedDate"
              label="Legal Assigned Date"
              isEditing={isEditing && legalAssignedDate}
            />
            <Autocomplete
              id="legalCompany"
              label="Legal Company"
              isEditing={isEditing && legalCompany}
              options={dropdownsOptions.LegalCompany}
            />
            <DatePicker
              id="legalApproved"
              label="Legal Approved"
              isEditing={isEditing && legalApproved}
            />
            <DatePicker
              id="outForSignature"
              label="Out for Signature"
              isEditing={isEditing && outForSignature}
            />
            <Autocomplete
              id="eSignature"
              label="eSignature"
              isEditing={isEditing && eSignature}
              options={dropdownsOptions.ESignature}
            />
            <DatePicker
              id="leaseOrAmendmentFullyExecuted"
              label="Lease/Amendment Fully Executed"
              isEditing={isEditing && leaseOrAmendmentFullyExecuted}
            />
            <Input
              id="NTPRequirements"
              label="NTP Requirements"
              isEditing={isEditing && NTPRequirements}
            />
            <DatePicker
              id="NTPRequested"
              label="NTP Requested"
              isEditing={isEditing && NTPRequested}
            />
            <DatePicker
              id="NTPApproved"
              label="NTP Approved"
              isEditing={isEditing && NTPApproved}
            />
            <TextArea
              id="leasingNotes"
              label="Leasing Notes"
              isEditing={isEditing && leasingNotes}
            />
          </Col>
        </Row>
      </Form>
    </Panel>
  );
};
