import { MainPaceTask } from 'providence-types';
import { PaceTasksToObjectMapper } from './pace-task-to-object-mapper.interface';

export const ZONING_SECTION_MAPPER_OPTIONS: PaceTasksToObjectMapper.Options[] =
  [
    {
      task: MainPaceTask.ZoningSubmitted,
      fieldKey: 'zoningSubmitted',
    },
    {
      task: MainPaceTask.ZoningAppDeemedComplete,
      fieldKey: 'zoningAppDeemedComplete',
    },
    {
      task: MainPaceTask.ZoningApproved,
      fieldKey: 'zoningApproved',
    },
    {
      task: MainPaceTask.BPSubmitted,
      fieldKey: 'BPSubmitted',
    },
    {
      task: MainPaceTask.BPReceived,
      fieldKey: 'BPReceived',
    },
  ];
