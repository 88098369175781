import React, { FC, PropsWithChildren, useEffect, useMemo } from 'react';
import { Form } from 'antd';
import { HttpService } from '@core/services';
import { ProjectCategoryHttpService } from '@core/services/http';
import { isValidForm } from '@core/utils/methods';
import { serverValidator } from '@core/utils/validators';
import { WHITESPACE_INPUT_LIMIT_RULES } from '@models/constants';
import { ModalMainTypes } from '@models/enums';
import { AdminProjectCategoryData } from '@models/interfaces';
import { AddAndEditModal } from '@shared/components';
import { Input } from '@shared/modules';
import { ProjectCategoryModalProps } from './models/interfaces';

import './styles.scss';

const { useForm } = Form;

export const ProjectCategoryModal: FC<ProjectCategoryModalProps> = ({
  itemValue,
  visible,
  modalType,
  toggleModal,
}: PropsWithChildren<ProjectCategoryModalProps>) => {
  const [form] = useForm();

  const projectCategoryService = useMemo(
    () => HttpService.getHttpRequests(ProjectCategoryHttpService),
    [],
  );

  const getInputData = (): AdminProjectCategoryData => {
    const formField = form.getFieldsValue(['projectCategory']);

    return {
      ...(itemValue as AdminProjectCategoryData),
      value: formField.projectCategory,
    };
  };

  const add = async ({ value }: AdminProjectCategoryData): Promise<void> => {
    if (await isValidForm(form)) {
      await projectCategoryService.addProjectCategoryData({
        value,
        canBeDuplicated: false,
      });
      toggleModal(modalType);
    }
  };

  const edit = async ({ value }: AdminProjectCategoryData): Promise<void> => {
    if (await isValidForm(form)) {
      await projectCategoryService.updateProjectCategoryData({
        ...itemValue,
        value,
      });
      toggleModal(modalType);
    }
  };

  useEffect(() => {
    if (visible) {
      form.setFieldsValue({
        projectCategory: itemValue.value,
      });
    } else {
      form.resetFields();
    }
  }, [visible, itemValue, form]);

  return (
    <AddAndEditModal
      onOk={
        modalType === ModalMainTypes.Add
          ? (): Promise<void> => add(getInputData())
          : (): Promise<void> => edit(getInputData())
      }
      onCancel={(): void => toggleModal(modalType)}
      visible={visible}
      type={modalType}
      title="Project Category"
      okText="Save"
      cancelText="Cancel"
      className="prov-admin-project-category-modal"
      formProps={{ wrapperCol: { span: 15 }, form, initialValues: itemValue }}
    >
      <Input
        id="projectCategory"
        label="Project Category"
        formItemProps={{
          rules: [
            { required: true, message: 'Project Category is required!' },
            serverValidator(
              {
                method: 'getProjectCategoryValidation',
                errorMessage: 'Project Category must be unique!',
              },
              ProjectCategoryHttpService,
              modalType === ModalMainTypes.Edit ? itemValue.id : undefined,
            ),
            ...WHITESPACE_INPUT_LIMIT_RULES,
          ],
        }}
      />
    </AddAndEditModal>
  );
};
