import React, {
  FC,
  PropsWithChildren,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { Form } from 'antd';
import classNames from 'classnames';
import { HttpService } from '@core/services';
import { VendorHttpService } from '@core/services/http';
import { isValidForm } from '@core/utils/methods';
import { serverValidator } from '@core/utils/validators';
import { WHITESPACE_INPUT_LIMIT_RULES } from '@models/constants';
import { ModalMainTypes } from '@models/enums';
import { AdminVendorDistributionListData } from '@models/interfaces';
import { AddAndEditModal } from '@shared/components';
import { Input } from '@shared/modules';
import { VendorsSelectors } from '@store/selectors';
import { VendorDistributionListModalProps } from './models';

import './styles.scss';

const { useForm } = Form;

export const VendorDistributionListModal: FC<
  VendorDistributionListModalProps
> = ({
  visible,
  modalType,
  toggleModal,
  selectorValue,
  setSelector,
}: PropsWithChildren<VendorDistributionListModalProps>) => {
  const [form] = useForm();

  const vendorService = useMemo(
    () => HttpService.getHttpRequests(VendorHttpService),
    [],
  );

  const vendorsLists = useSelector(VendorsSelectors.getVendorsLists);

  const [currentVendorListId, setCurrentVendorListId] = useState<string>('');

  useEffect(() => {
    if (visible) {
      setCurrentVendorListId(
        vendorsLists.find(
          (vendorList: AdminVendorDistributionListData) =>
            vendorList.name === selectorValue,
        )?.id || '',
      );
    }
  }, [visible, selectorValue, vendorsLists]);

  const getInputData = (): { name: string } => ({
    name: form.getFieldsValue().name,
  });

  const add = async ({
    name,
  }: Pick<AdminVendorDistributionListData, 'name'>): Promise<void> => {
    if (await isValidForm(form)) {
      await vendorService.addVendorList({
        name,
      });

      setSelector(name);
      toggleModal(modalType);
    }
  };

  const edit = async ({
    name,
  }: Pick<AdminVendorDistributionListData, 'name'>): Promise<void> => {
    if (await isValidForm(form)) {
      await vendorService.editVendorList({
        name,
        id: currentVendorListId,
      });

      setSelector(name);
      toggleModal(modalType);
    }
  };

  const deleteVendorList = async (): Promise<void> => {
    await vendorService.deleteVendorList(currentVendorListId);
    setSelector('');
    toggleModal(modalType);
  };

  useEffect(() => {
    if (visible) {
      form.setFieldsValue({
        name: modalType === ModalMainTypes.Edit ? selectorValue : '',
      });
    }
  }, [visible, selectorValue, modalType, form]);

  return (
    <AddAndEditModal
      onOk={
        modalType === ModalMainTypes.Add
          ? (): Promise<void> => add(getInputData())
          : (): Promise<void> => edit(getInputData())
      }
      onCancel={(): void => toggleModal(modalType)}
      visible={visible}
      type={modalType}
      title="Vendor List"
      okText="Save"
      cancelText="Delete"
      className={classNames('prov-admin-vendors-distribution-modal', {
        'add-modal': modalType === ModalMainTypes.Add,
      })}
      formProps={{
        labelCol: { span: 5 },
        wrapperCol: { span: 15 },
        form,
      }}
      cancelButtonProps={{
        onClick: deleteVendorList,
      }}
    >
      <Input
        id="name"
        label="List Name"
        formItemProps={{
          rules: [
            serverValidator(
              {
                method: 'getVendorListValidation',
                errorMessage: 'List Name must be unique!',
              },
              VendorHttpService,
              modalType === ModalMainTypes.Add
                ? undefined
                : currentVendorListId,
            ),
            ...WHITESPACE_INPUT_LIMIT_RULES,
          ],
        }}
      />
    </AddAndEditModal>
  );
};
