import React, { FC, PropsWithChildren } from 'react';
import { Card, Form } from 'antd';
import { Input } from '../../modules/ui-kit/form/input';
import { RiskPopupProps } from './models/interfaces';

import './styles.scss';

export const RiskPopup: FC<RiskPopupProps> = ({
  risk,
  riskCategory = '',
  disablingNotes = '',
}: PropsWithChildren<RiskPopupProps>) => (
  <Card bordered={false} className="risk-popup">
    <div className={`risk-popup__card high ${risk}`}>
      <span className="risk-popup__card-category">
        {risk === 'high' ? 'High Risk Site' : 'Site Off'}
      </span>
    </div>
    <Form
      className="risk-popup__info"
      labelCol={{ span: 11 }}
      wrapperCol={{ span: 14 }}
      initialValues={{ riskCategory, disablingNotes }}
    >
      {risk === 'high' ? (
        <Input label="Risk Category" isEditing={false} id="riskCategory" />
      ) : (
        <Input label="Disabling Notes" isEditing={false} id="disablingNotes" />
      )}
    </Form>
  </Card>
);
