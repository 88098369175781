import { Undefinable } from 'providence-types';
import { StoredRequest } from '@core/utils/store/stored-request-decorator';
import {
  DEFAULT_JURISDICTION_PERMITTING_PANEL_FIELDS,
  DEFAULT_JURISDICTION_ZONING_PANEL_FIELDS,
} from '@models/constants/default-jurisdiction-panel-fields';
import {
  AddPermitTypeData,
  JurisdictionBody,
  JurisdictionBrowse,
  JurisdictionDetails,
  JurisdictionDetailsContacts,
  JurisdictionDetailsPermitting,
  JurisdictionDetailsZoning,
  JurisdictionModalValues,
  JurisdictionOption,
  JurisdictionPermittingData,
  JurisdictionZoningData,
  MainBrowseFilter,
  PaginatedResponse,
  PermittingBrowse,
  PermittingBrowseFilter,
  PermittingJurisdictionAuditBody,
  PermittingWorkflowBody,
  ProjectDetails,
} from '@models/interfaces';
import { IdResponse, RemovePermitTypeData } from '@models/types';
import { BreadcrumbsActions, PermittingActions } from '@store/actions';
import {
  PermittingEffects,
  ProjectCategoriesEffects,
  ProjectsEffects,
} from '@store/effects';
import { CommonHttpService } from './common-http.service';

export class PermittingHttpService extends CommonHttpService {
  @StoredRequest(PermittingActions.getPermittingBrowseData)
  getPermittingBrowseData(
    params: PermittingBrowseFilter,
  ): Promise<PaginatedResponse<PermittingBrowse>> {
    return this.http.callApi.get(`${this.apiUrl}/permitting`, null, { params });
  }

  @StoredRequest(PermittingActions.getJurisdictionList)
  getJurisdictionList(): Promise<JurisdictionOption[]> {
    return this.http.callApi.get(
      `${this.apiUrl}/permitting/jurisdictions/list-options`,
    );
  }

  @StoredRequest(
    PermittingActions.getPermittingDetailsData,
    BreadcrumbsActions.Permitting.permitting,
  )
  async getPermittingDetailsData(projectId: string): Promise<ProjectDetails> {
    const response = await this.http.callApi.get(
      `${this.apiUrl}/permitting/${projectId}`,
    );

    ProjectsEffects.setHoldOptions({
      holdReason: response.holdReason,
      projectStatus: { id: response.projectStatus, value: '' },
    } as ProjectDetails);

    return PermittingEffects.setFinancial(
      ProjectsEffects.setCurrentProvidenceAgent(
        ProjectsEffects.setPaceTaskFields(
          ProjectsEffects.setPaceTasks(
            ProjectCategoriesEffects.setProjectCategories(response),
          ),
        ),
      ),
    );
  }

  @StoredRequest(PermittingActions.permittingUpdateWorkflowData)
  async updatePermittingWorkflowData(
    projectId: string,
    body: PermittingWorkflowBody,
  ): Promise<PermittingWorkflowBody> {
    await this.http.callApi.patch(
      `${this.apiUrl}/permitting/${projectId}/workflow`,
      body,
    );

    return body;
  }

  @StoredRequest(PermittingActions.permittingUpdateJurisdictionAuditData)
  async updatePermittingJurisdictionAuditData(
    projectId: string,
    body: PermittingJurisdictionAuditBody,
  ): Promise<PermittingJurisdictionAuditBody> {
    await this.http.callApi.patch(
      `${this.apiUrl}/permitting/${projectId}/jurisdiction-audit`,
      body,
    );

    return body;
  }

  // PERMITTING - JURISDICTION
  @StoredRequest(PermittingActions.getJurisdictionBrowseData)
  getJurisdictionBrowseData(
    params: MainBrowseFilter,
  ): Promise<PaginatedResponse<JurisdictionBrowse>> {
    return this.http.callApi.get(
      `${this.apiUrl}/permitting/jurisdictions`,
      null,
      { params },
    );
  }

  @StoredRequest(
    PermittingActions.getJurisdictionDetailsData,
    BreadcrumbsActions.Permitting.jurisdiction,
  )
  getJurisdictionDetailsData(
    jurisdictionId: string,
  ): Promise<JurisdictionDetails> {
    return this.http.callApi.get(
      `${this.apiUrl}/permitting/jurisdictions/${jurisdictionId}`,
    );
  }

  getJurisdictionNameValidation(
    id: Undefinable<string>,
    stateId: Undefinable<string>,
    jurisdictionName: string,
  ): Promise<boolean> {
    return this.http.callApi.get(
      `${this.apiUrl}/permitting/jurisdictions/validation`,
      null,
      {
        params: { jurisdictionName: jurisdictionName.trim(), id, stateId },
      },
    );
  }

  @StoredRequest(PermittingActions.addJurisdiction)
  addJurisdiction(
    params: MainBrowseFilter,
    body: JurisdictionBody,
  ): Promise<PaginatedResponse<JurisdictionBrowse>> {
    return this.http.callApi.post(
      `${this.apiUrl}/permitting/jurisdictions`,
      body,
      { params },
    );
  }

  @StoredRequest(PermittingActions.addJurisdictionZoningPanel)
  async addJurisdictionZoningPanel(
    jurisdictionId: string,
    body: Omit<JurisdictionModalValues, 'id'>,
  ): Promise<JurisdictionDetailsZoning> {
    const id = await this.http.callApi.post(
      `${this.apiUrl}/permitting/jurisdictions/${jurisdictionId}/zoning-panel`,
      body,
    );

    return {
      id,
      ...body,
      ...DEFAULT_JURISDICTION_ZONING_PANEL_FIELDS,
    };
  }

  @StoredRequest(PermittingActions.updateJurisdictionZoning)
  async updateJurisdictionZoning(
    jurisdictionId: string,
    body: JurisdictionZoningData,
  ): Promise<JurisdictionZoningData> {
    await this.http.callApi.patch(
      `${this.apiUrl}/permitting/jurisdictions/${jurisdictionId}/zoning`,
      body,
    );

    return body;
  }

  @StoredRequest(PermittingActions.updateJurisdictionZoningPanel)
  async updateJurisdictionZoningPanel(
    values: JurisdictionModalValues,
  ): Promise<JurisdictionModalValues> {
    const { id, ...body } = values;

    await this.http.callApi.patch(
      `${this.apiUrl}/permitting/jurisdictions/zoning-panel`,
      body,
      { params: { zoningPanelId: id } },
    );

    return values;
  }

  @StoredRequest(PermittingActions.removeJurisdictionZoningPanel)
  async removeJurisdictionZoningPanel(zoningPanelId: string): Promise<string> {
    await this.http.callApi.delete(
      `${this.apiUrl}/permitting/jurisdictions/zoning-panel`,
      null,
      { params: { zoningPanelId } },
    );

    return zoningPanelId;
  }

  @StoredRequest(PermittingActions.permittingAddPermittingType)
  permittingAddPermittingType(
    data: AddPermitTypeData,
  ): Promise<AddPermitTypeData> {
    return this._addPermittingType(data);
  }

  @StoredRequest(PermittingActions.jurisdictionAddPermittingType)
  jurisdictionAddPermittingType(
    data: AddPermitTypeData,
  ): Promise<AddPermitTypeData> {
    return this._addPermittingType(data);
  }

  @StoredRequest(PermittingActions.permittingUpdatePermittingType)
  permittingUpdatePermittingType(
    data: AddPermitTypeData,
  ): Promise<AddPermitTypeData> {
    return this._updatePermittingType(data);
  }

  @StoredRequest(PermittingActions.jurisdictionUpdatePermittingType)
  jurisdictionUpdatePermittingType(
    data: AddPermitTypeData,
  ): Promise<AddPermitTypeData> {
    return this._updatePermittingType(data);
  }

  @StoredRequest(PermittingActions.permittingRemovePermittingType)
  permittingRemovePermittingType(
    data: RemovePermitTypeData,
  ): Promise<RemovePermitTypeData> {
    return this._removePermitType(data);
  }

  @StoredRequest(PermittingActions.jurisdictionRemovePermittingType)
  jurisdictionRemovePermittingType(
    data: RemovePermitTypeData,
  ): Promise<RemovePermitTypeData> {
    return this._removePermitType(data);
  }

  @StoredRequest(PermittingActions.addJurisdictionPermittingPanel)
  async addJurisdictionPermittingPanel(
    jurisdictionId: string,
    body: Omit<JurisdictionModalValues, 'id'>,
  ): Promise<JurisdictionDetailsPermitting> {
    const id = await this.http.callApi.post(
      `${this.apiUrl}/permitting/jurisdictions/${jurisdictionId}/permitting-panel`,
      body,
    );

    return {
      id,
      ...body,
      ...DEFAULT_JURISDICTION_PERMITTING_PANEL_FIELDS,
    };
  }

  @StoredRequest(PermittingActions.updateJurisdictionPermittingPanel)
  async updateJurisdictionPermittingPanel(
    values: JurisdictionModalValues,
  ): Promise<JurisdictionModalValues> {
    const { id, ...body } = values;

    await this.http.callApi.patch(
      `${this.apiUrl}/permitting/jurisdictions/permitting-panel`,
      body,
      { params: { permittingPanelId: id } },
    );

    return values;
  }

  @StoredRequest(PermittingActions.removeJurisdictionPermittingPanel)
  async removeJurisdictionPermittingPanel(
    permittingPanelId: string,
  ): Promise<string> {
    await this.http.callApi.delete(
      `${this.apiUrl}/permitting/jurisdictions/permitting-panel`,
      null,
      { params: { permittingPanelId } },
    );

    return permittingPanelId;
  }

  @StoredRequest(PermittingActions.updateJurisdictionPermitting)
  async updateJurisdictionPermitting(
    jurisdictionId: string,
    body: JurisdictionPermittingData,
  ): Promise<JurisdictionPermittingData> {
    await this.http.callApi.patch(
      `${this.apiUrl}/permitting/jurisdictions/${jurisdictionId}/permitting`,
      body,
    );

    return body;
  }

  @StoredRequest(PermittingActions.addJurisdictionContact)
  async addJurisdictionContact(
    jurisdictionId: string,
    body: Omit<JurisdictionDetailsContacts, 'id'>,
  ): Promise<JurisdictionDetailsContacts> {
    const id = await this.http.callApi.post(
      `${this.apiUrl}/permitting/jurisdictions/contacts/${jurisdictionId}`,
      body,
    );

    return {
      id,
      ...body,
    };
  }

  @StoredRequest(PermittingActions.updateJurisdictionContact)
  async updateJurisdictionContact(
    contactId: string,
    body: Omit<JurisdictionDetailsContacts, 'id'>,
  ): Promise<JurisdictionDetailsContacts> {
    await this.http.callApi.patch(
      `${this.apiUrl}/permitting/jurisdictions/contacts`,
      body,
      { params: { contactId } },
    );

    return {
      id: contactId,
      ...body,
    };
  }

  @StoredRequest(PermittingActions.removeJurisdictionContact)
  async removeJurisdictionContact(contactId: string): Promise<string> {
    await this.http.callApi.delete(
      `${this.apiUrl}/permitting/jurisdictions/contacts`,
      null,
      { params: { contactId } },
    );

    return contactId;
  }

  private async _addPermittingType(
    data: AddPermitTypeData,
  ): Promise<AddPermitTypeData> {
    const { permittingId, data: body } = data;
    const { id } = (await this.http.callApi.post(
      `${this.apiUrl}/permitting/jurisdictions/permitting-type`,
      body,
      { params: { permittingId } },
    )) as IdResponse;

    return {
      permittingId,
      data: { id, ...body },
    };
  }

  private async _updatePermittingType(
    data: AddPermitTypeData,
  ): Promise<AddPermitTypeData> {
    const {
      data: { id, ...body },
    } = data;

    await this.http.callApi.patch(
      `${this.apiUrl}/permitting/jurisdictions/permitting-type`,
      body,
      { params: { permittingTypeId: id } },
    );

    return data;
  }

  private async _removePermitType({
    permittingId,
    permittingTypeId,
  }: RemovePermitTypeData): Promise<RemovePermitTypeData> {
    await this.http.callApi.delete(
      `${this.apiUrl}/permitting/jurisdictions/permitting-type`,
      null,
      { params: { permittingTypeId } },
    );

    return { permittingId, permittingTypeId };
  }
}
