/* eslint-disable default-case */
import { isMobile } from 'react-device-detect';
import { FirebaseApp, initializeApp } from 'firebase/app';
import {
  deleteToken,
  getMessaging,
  getToken,
  isSupported,
  MessagePayload,
  Messaging,
  onMessage,
  Unsubscribe,
} from 'firebase/messaging';
import {
  IDeviceToken,
  INotificationData,
  NotificationStatus,
  NotificationType,
  Undefinable,
} from 'providence-types';
import { NotificationsLoader } from '../../shared/components/notifications'; // TODO: fix import from index
import store from '../../store';
import { UserActions } from '../../store/actions';
import { AppState } from '../../store/reducers';
import { Config } from '../config/config';
import { NotificationsHttpService } from './http';
import { HttpService } from './http.service';

export class FirebaseService {
  private static _instance: FirebaseService;

  private _firebaseApp!: FirebaseApp;
  private _firebaseMessaging: Undefinable<Messaging>;
  private _currentDeviceToken: Undefinable<string>;
  private _currentDeviceTokenKey: Undefinable<keyof IDeviceToken>;
  private _messageUnsubscribe: Unsubscribe | null = null;
  private _http: NotificationsHttpService = HttpService.getHttpRequests(
    NotificationsHttpService,
  );

  static get get(): FirebaseService {
    if (!this._instance) {
      this._instance = new FirebaseService();
    }

    return this._instance;
  }

  // eslint-disable-next-line no-useless-constructor
  private constructor() {}

  /* ############################# Common methods ##################################### */
  init(): void {
    this._firebaseApp = initializeApp(Config.get().firebaseConfig);

    isSupported().then(() => {
      if (Config.get().isDevelopment) {
        console.log('FirebaseApp and FirebaseMessaging initialized');
      }

      this._currentDeviceTokenKey = isMobile ? 'mobileDevice' : 'desktop';

      this._firebaseMessaging = getMessaging(this._firebaseApp);
    });
  }

  async destroy(): Promise<void> {
    this._unsubscriptionToForegroundMessage();
    await this._unsubscriptionToBackgroundMessage();
  }

  // to monitor progress issues, as with the unsubscription and subscription we can see error in devTool console https://github.com/firebase/firebase-js-sdk/issues/2364
  async requestPermission(): Promise<void> {
    if (this._firebaseMessaging && this._currentDeviceTokenKey) {
      const state: AppState = store.getState();
      const { deviceTokens } = state?.user?.activeUser || {};

      const deviceToken = deviceTokens?.[this._currentDeviceTokenKey];

      try {
        this._currentDeviceToken = await getToken(this._firebaseMessaging, {
          vapidKey: Config.get().fireBaseVapidKey,
        });

        if (deviceToken !== this._currentDeviceToken) {
          await this._changeSubscriptionToken(this._currentDeviceToken);
        }

        this._subscriptionToForegroundMessage();
        this._subscriptionToBackgroundMessage();
      } catch (err) {
        // reset sub token if user block notification
        if (deviceToken) {
          await this._changeSubscriptionToken(null);
        }

        if (Config.get().isDevelopment) {
          console.error(
            'FIREBASE ERROR: An error occurred while retrieving token.',
            err,
          );
        }
      }
    }
  }

  changeSubscriptionStatus(status: NotificationStatus): void {
    this._http.changeSubscriptionStatus(status).then(() => {
      store.dispatch(UserActions.changeSubscriptionStatus.done(status));
    });
  }
  /* ########################### End Common methods ################################### */

  /* ############################# Helper methods ##################################### */
  private async _changeSubscriptionToken(token: string | null): Promise<void> {
    if (this._currentDeviceTokenKey) {
      await this._http.changeSubscriptionToken({
        [this._currentDeviceTokenKey]: token,
      });
    }
  }

  private static _listenToBackgroundMessages(evt: MessageEvent): void {
    if (evt.data && evt.data.event === 'self-background') {
      const { event, ...otherData } = evt.data;

      store.dispatch(UserActions.addNotification.done(otherData));
    }
  }
  /* ########################### End Helper methods ################################### */

  /* ######################### Sub and Unsub methods ################################## */
  private _subscriptionToForegroundMessage(): void {
    if (this._messageUnsubscribe === null && this._firebaseMessaging) {
      if (Config.get().isDevelopment) {
        console.log('sub foreground message');
      }

      this._messageUnsubscribe = onMessage(
        this._firebaseMessaging,
        (payload: MessagePayload) => {
          if (Config.get().isDevelopment) {
            console.log(payload, 'payload');
          }

          const currentPayload = payload.data as unknown as INotificationData;

          const { title, body, type, clickRedirectUrl } = currentPayload;

          NotificationsLoader.openNotificationWithArgs(
            type === NotificationType.Info ? 'info' : 'warning',
            {
              message: title,
              description: body,
              onClose: () =>
                store.dispatch(
                  UserActions.addNotification.done(currentPayload),
                ),
              onClick: () =>
                clickRedirectUrl
                  ? window.location.replace(clickRedirectUrl)
                  : null,
            },
          );
        },
      );
    }
  }

  private _subscriptionToBackgroundMessage(): void {
    if ('serviceWorker' in navigator) {
      if (Config.get().isDevelopment) {
        console.log('add background message listener');
      }

      navigator.serviceWorker.addEventListener(
        'message',
        FirebaseService._listenToBackgroundMessages,
      );
    }
  }

  private _unsubscriptionToForegroundMessage(): void {
    if (this._messageUnsubscribe !== null) {
      if (Config.get().isDevelopment) {
        console.log('unsub foreground message');
      }

      this._messageUnsubscribe();
      this._messageUnsubscribe = null;
    }
  }

  private async _unsubscriptionToBackgroundMessage(): Promise<void> {
    const state: AppState = store.getState();
    const { subscriptionStatus } = state?.user?.activeUser || {};

    if ('serviceWorker' in navigator) {
      if (Config.get().isDevelopment) {
        console.log('remove background message listener');
      }

      navigator.serviceWorker.removeEventListener(
        'message',
        FirebaseService._listenToBackgroundMessages,
      );
    }

    if (
      this._firebaseMessaging &&
      this._currentDeviceToken &&
      subscriptionStatus === NotificationStatus.Unsubscription
    ) {
      const removed = await deleteToken(this._firebaseMessaging);

      if (removed) {
        if (Config.get().isDevelopment) {
          console.log('unsub background message');
        }

        this._currentDeviceToken = undefined;
        await this._changeSubscriptionToken(null);
      }
    }
  }
  /* ######################## End Sub and Unsub methods ################################ */
}
