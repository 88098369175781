import React, {
  ChangeEvent,
  FC,
  PropsWithChildren,
  ReactElement,
  useState,
} from 'react';
import { Button } from 'antd';
import RemoveIcon from 'assets/icons/delete-icon.svg';
import DocIcon from 'assets/icons/doc.png';
import UploadIcon from 'assets/icons/upload-icon.svg';
import { useDidUpdateEffect } from '@core/utils/hooks';
import { NotificationsLoader } from '@shared/components';
import { PrimaryButton } from '@shared/modules';
import { ACCEPT_TYPES, SOFToolUploadButtonProps } from './models';

import './styles.scss';

export const SOFUploadButton: FC<SOFToolUploadButtonProps> = ({
  updateAttachment,
  isDisabled,
}: PropsWithChildren<SOFToolUploadButtonProps>) => {
  const hiddenFileInput = React.useRef<HTMLInputElement>(null);

  const [file, setFile] = useState<File | null>(null);
  const [inputValue, setInputValue] = useState<string>('');

  useDidUpdateEffect(() => {
    updateAttachment(file);
  }, [file]);

  const addAttachment = (): void => {
    hiddenFileInput.current?.click();
  };

  const removeAttachment = (): void => {
    setFile(null);
    setInputValue('');
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    if (event.target.files) {
      const currentFile = event.target.files[0];

      if (!ACCEPT_TYPES.includes(currentFile.type)) {
        NotificationsLoader.notificationError({
          message: 'Error',
          description: 'Incorrect file type!',
        });

        return;
      }

      setFile(currentFile);
    }
  };

  const UploadIconElement = (): ReactElement => (
    <img src={UploadIcon} alt="upload icon" />
  );

  return (
    <div className="prov-sof-tool-upload-button">
      <PrimaryButton
        title="Add attachment"
        type="primary"
        icon={<UploadIconElement />}
        onClick={addAttachment}
        disabled={isDisabled}
      />
      <input
        type="file"
        ref={hiddenFileInput}
        value={inputValue}
        onChange={handleChange}
        accept={ACCEPT_TYPES.join(', ')}
        hidden
      />
      {file && (
        <>
          <div className="prov-sof-tool-upload-button__file-info">
            <img src={DocIcon} alt="doc icon" className="document-icon" />
            <span className="file-name">{file.name}</span>
          </div>
          <Button onClick={removeAttachment} disabled={isDisabled}>
            <img src={RemoveIcon} alt="remove icon" />
          </Button>
        </>
      )}
    </div>
  );
};
