import React, { FC, PropsWithChildren, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Divider, Form } from 'antd';
import { DropDownSelectorValues } from 'providence-types';
import {
  arraySort,
  canBeEditable,
  momentizeObjectDates,
} from '@core/utils/methods';
import {
  JURISDICTION_DATES_FOR_MOMENTIZE,
  YES_NO_GROUP,
} from '@models/constants';
import { OptionProps } from '@models/interfaces';
import { PermitTypeTable } from '@shared/components';
import {
  Autocomplete,
  DatePicker,
  Input,
  RadioGroup,
  TextArea,
} from '@shared/modules';
import { DDVSelectors } from '@store/selectors';
import { PermittingFormProps } from '../../models';

export const PermittingContent: FC<PermittingFormProps> = ({
  isEditing,
  permissions: {
    permittingRequired,
    specialLicensingOrBondingConditions,
    expeditingOrResubmissionApprovalTimeline,
    writtenDocumentationFromJurisdiction,
    notes,
    tableAccess,
    jurisdictionReviewDate,
  },
  formValue: { permittingTypes, id, ...values },
  formUpdating,
}: PropsWithChildren<PermittingFormProps>) => {
  const [form] = Form.useForm();

  const permittingRequiredDDV = useSelector(
    DDVSelectors.getDDVValuesByType(DropDownSelectorValues.PermittingRequired),
  ) as OptionProps[];

  const expeditingOrResubmissionApprovalTimelineDDV = useSelector(
    DDVSelectors.getDDVValuesByType(
      DropDownSelectorValues.ExpeditingResubmissionApprovalTimeline,
    ),
  ) as OptionProps[];

  useEffect(() => {
    form.setFieldsValue(
      momentizeObjectDates(values, JURISDICTION_DATES_FOR_MOMENTIZE),
    );
  }, [isEditing]);

  const update = (): void => {
    if (formUpdating) {
      formUpdating(id, form.getFieldsValue());
    }
  };

  return (
    <>
      <Form
        className="prov-jurisdiction-configurable-panel__form"
        labelCol={{ span: 10 }}
        form={form}
        onValuesChange={update}
      >
        <Autocomplete
          id="permittingRequired"
          className="left"
          label="Permitting Required"
          options={arraySort(permittingRequiredDDV, 'DESC', 'viewValue')}
          isEditing={canBeEditable(permittingRequired, isEditing)}
        />
        <Input
          id="specialLicensingOrBondingConditions"
          className="right"
          label="Special Licensing/Bonding Conditions"
          isEditing={canBeEditable(
            specialLicensingOrBondingConditions,
            isEditing,
          )}
        />
        <RadioGroup
          id="writtenDocumentationFromJurisdiction"
          className="left"
          label="Written Documentation from Jurisdiction"
          isEditing={canBeEditable(
            writtenDocumentationFromJurisdiction,
            isEditing,
          )}
          buttons={YES_NO_GROUP}
        />
        <TextArea
          id="notes"
          className="right"
          label="Permitting Notes"
          isEditing={canBeEditable(notes, isEditing)}
        />
        <Autocomplete
          id="expeditingOrResubmissionApprovalTimeline"
          className="left"
          label="Expediting/Resubmission Approval Timeline"
          options={expeditingOrResubmissionApprovalTimelineDDV}
          isEditing={canBeEditable(
            expeditingOrResubmissionApprovalTimeline,
            isEditing,
          )}
        />
        <DatePicker
          id="jurisdictionReviewDate"
          className="right"
          label="Jurisdiction Review Date"
          isEditing={canBeEditable(jurisdictionReviewDate, isEditing)}
        />
      </Form>
      <Divider />
      <PermitTypeTable
        permittingId={id}
        isEditing={isEditing}
        tableAccess={tableAccess}
        data={permittingTypes}
        reqType="jurisdiction"
      />
    </>
  );
};
