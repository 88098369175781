import React, { FC, useState } from 'react';
import { Undefinable } from 'providence-types';
import { ImportError, ImportFormProps } from '@models/interfaces';
import { ErrorArea } from './error-area';
import { ImportForm } from './import-form';

import './styles.scss';

export const Import: FC<Omit<ImportFormProps, 'onError'>> = ({
  template,
  sendFile,
}: Omit<ImportFormProps, 'onError'>) => {
  const [errors, setErrors] = useState<Undefinable<ImportError[]>>(undefined);

  return (
    <div className="prov-import-form">
      <ImportForm template={template} onError={setErrors} sendFile={sendFile} />
      <ErrorArea errors={errors} />
    </div>
  );
};
