import {
  LeasingEngineeringWorkflow,
  LeasingInformation,
  StructuralInformation,
} from '@models/interfaces';

export const LEASING_INFO_DATES: Array<keyof LeasingInformation> = [
  'auditDate',
  'SOFIssuedDate',
  'coloAppApproved',
  'coloAppDrafted',
  'coloAppSubmitted',
  'PALOrNPAReceived',
  'leaseDraftReceived',
  'leaseRevisionRequested',
  'LMTApproved',
  'LMTSubmitted',
  'legalApproved',
  'legalAssignedDate',
  'legalSubmitted',
  'outForSignature',
  'leaseAmendmentFullyExecuted',
  'NTPApproved',
  'NTPRequested',
];

export const STRUCTURAL_INFO_DATES: Array<keyof StructuralInformation> = [
  'structuralOrdered',
  'passingStructuralReceived',
  'failingStructuralReceived',
  'orderModDesign',
  'modDesignComplete',
  'PMSAReceived',
  'ATTModApproved',
  'IBMApproved',
  'modConstructionStart',
  'modConstructionComplete',
  'PMIModTurnover',
];

export const LEASING_ENGINEERING_INFO_DATES: Array<
  keyof LeasingEngineeringWorkflow
> = [
  'failingMountAnalysisReviewed',
  'passingMountAnalysisReviewed',
  'CDsSentToLandlord',
  'CDsReceivedFromLandlord',
  'prelimCDsApprovedLeasing',
  'finalCDsReviewedLeasing',
];
