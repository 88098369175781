import React, {
  FC,
  PropsWithChildren,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { Form } from 'antd';
import { Store } from 'antd/es/form/interface';
import { SelectValue } from 'antd/lib/select';
import { HttpService } from '@core/services';
import { ProjectManagementHttpService } from '@core/services/http';
import {
  arrsToSelectableObject,
  getFormData,
  isValidForm,
} from '@core/utils/methods';
import {
  serverValidator,
  validationRequestParams,
} from '@core/utils/validators';
import { DEFAULT_ITEM_WITH_OPERATOR_VALUE } from '@models/constants';
import { ModalMainTypes } from '@models/enums';
import {
  AdminCountyData,
  FiltersSetData,
  FiltersSetDataExtended,
  FiltersSetDataPaceTaskFilter,
  StateWithCounties,
} from '@models/interfaces';
import { AddAndEditModal } from '@shared/components';
import { Autocomplete, Input } from '@shared/modules';
import { ProjectManagementSelectors } from '@store/selectors';
import {
  convertPaceTaskFilterToObj,
  DEFAULT_FILTERS_SET_VALUE,
  FiltersSetModalProps,
  getSetFieldsOptions,
  momentValue,
} from '../../models';
import { FiltersList } from '../filters-list';

import './styles.scss';

const { useForm } = Form;

export const FiltersSetModal: FC<FiltersSetModalProps> = ({
  selectorValue,
  visible,
  modalType,
  toggleModal,
  setSelector,
}: PropsWithChildren<FiltersSetModalProps>) => {
  const [form] = useForm();
  const [paceTaskForm] = useForm();

  const projectManagementService = useMemo(
    () => HttpService.getHttpRequests(ProjectManagementHttpService),
    [],
  );

  const {
    filterSets,
    pmScreenPaceTasksOptions,
    filtersOptions,
    jurisdictionList,
    states,
  } = useSelector(ProjectManagementSelectors.getPMBrowseFiltersInfo);

  const [filtersSet, setFiltersSet] = useState<FiltersSetDataExtended>(
    DEFAULT_FILTERS_SET_VALUE,
  );

  const findFiltersSet = (value?: string): FiltersSetDataExtended =>
    filterSets.find((set: FiltersSetData) => set.id === value) ||
    DEFAULT_FILTERS_SET_VALUE;

  const updateForms = (
    formValue: Store,
    paceTaskFormValue: FiltersSetDataPaceTaskFilter[],
  ): void => {
    form.setFieldsValue(formValue);
    paceTaskForm.resetFields();
    paceTaskForm.setFieldsValue(convertPaceTaskFilterToObj(paceTaskFormValue));
  };

  const onClose = (): void => {
    toggleModal(modalType);
    form.resetFields();
    paceTaskForm.resetFields();
  };

  const add = async (value: FiltersSetData): Promise<void> => {
    if ((await isValidForm(form)) && (await isValidForm(paceTaskForm))) {
      await projectManagementService.createPMBrowseFiltersSet(value);

      onClose();
      setSelector(undefined);
    }
  };

  const edit = async (value: Store): Promise<void> => {
    if (await isValidForm(form)) {
      await projectManagementService.updatePMBrowseFiltersSet({
        ...value,
        id: selectorValue,
      } as FiltersSetData);

      onClose();
    }
  };

  const onOk = (): void => {
    const generateData = (): FiltersSetData => {
      const { name, setFields, paceTaskFields, templateSet, ...filter } =
        getFormData(form.getFieldsValue(), form);

      const paceTasksPickerValues = getFormData(
        paceTaskForm.getFieldsValue(),
        paceTaskForm,
      );

      const paceTaskFilter = Object.keys(paceTasksPickerValues).reduce(
        (acc: FiltersSetDataPaceTaskFilter[], key: string) => {
          const { operator, value } = paceTasksPickerValues[key];
          const [id, field] = key.split('/');

          return [
            ...acc,
            {
              value: momentValue(value, true),
              operator,
              id,
              field,
            },
          ];
        },
        [],
      );

      return {
        name,
        filter,
        paceTaskFilter,
      };
    };
    const action = modalType === ModalMainTypes.Add ? add : edit;

    action(generateData());
  };

  useEffect(() => {
    setFiltersSet(
      modalType === ModalMainTypes.Add
        ? DEFAULT_FILTERS_SET_VALUE
        : findFiltersSet(selectorValue),
    );
  }, [visible, filterSets, selectorValue]);

  useEffect(() => {
    if (visible) {
      const { paceTaskFilter, filter, ...rest } = findFiltersSet(selectorValue);

      updateForms({ ...rest, ...filter }, paceTaskFilter);
    }
  }, [visible]);

  const onMultiSelectChange = (
    fields: SelectValue,
    key: 'setFields' | 'paceTaskFields',
  ): void => {
    setFiltersSet((prevValue: FiltersSetDataExtended) => {
      const [name] = (fields as string[]).filter(
        (x: string) => !prevValue[key].includes(x),
      );

      if (name) {
        (key === 'setFields' ? form : paceTaskForm).setFields([
          {
            name,
            value: DEFAULT_ITEM_WITH_OPERATOR_VALUE,
          },
        ]);
      }

      return {
        ...prevValue,
        [key]: fields as string[],
      };
    });
  };

  return (
    <AddAndEditModal
      onOk={onOk}
      onCancel={onClose}
      visible={visible}
      type={modalType}
      title="Filters Set"
      okText="Save"
      cancelText="Cancel"
      className="prov-filters-set-modal"
      formProps={{
        wrapperCol: { span: 15 },
        labelCol: { span: 8 },
        form,
      }}
      width={900}
    >
      <Input
        id="name"
        key="name"
        label="Filters Set Name"
        formItemProps={{
          rules: [
            { required: true, message: 'Filters Set Name is required!' },
            serverValidator(
              {
                method: 'getFilterNameValidation',
                errorMessage: 'Filters Set Name must be unique!',
              },
              ProjectManagementHttpService,
              validationRequestParams(
                modalType,
                findFiltersSet(selectorValue),
                'name',
              ),
            ),
          ],
        }}
      />
      {modalType === ModalMainTypes.Add && (
        <Autocomplete
          id="templateSet"
          key="templateSet"
          label="Template Set"
          options={arrsToSelectableObject(filterSets, 'id', 'name')}
          elementProps={{
            onChange: (value: SelectValue): void => {
              const curSet = findFiltersSet(value as string);
              const { paceTaskFilter, filter, name, ...rest } = curSet;

              setFiltersSet(curSet);
              updateForms({ ...rest, ...filter }, paceTaskFilter);
            },
          }}
        />
      )}
      <Autocomplete
        id="setFields"
        key="setFields"
        label="Filter's Set Fields"
        elementProps={{
          mode: 'multiple',
          onChange: (fields: SelectValue): void =>
            onMultiSelectChange(fields, 'setFields'),
        }}
        options={getSetFieldsOptions()}
        formItemProps={{
          rules: [
            {
              required: !filtersSet?.paceTaskFields.length,
              message: "Filter's Set Fields are required!",
            },
          ],
        }}
      />
      <Autocomplete
        id="paceTaskFields"
        key="paceTaskFields"
        label="PACE Task Fields"
        elementProps={{
          mode: 'multiple',
          onChange: (fields: SelectValue): void =>
            onMultiSelectChange(fields, 'paceTaskFields'),
        }}
        options={pmScreenPaceTasksOptions}
        formItemProps={{
          rules: [
            {
              required: !filtersSet?.setFields.length,
              message: 'Pace Task Fields are required!',
            },
          ],
        }}
      />
      <FiltersList
        form={form}
        fieldsList={filtersSet?.setFields}
        data={filtersSet?.filter}
        options={{
          jurisdictionOptions: arrsToSelectableObject(
            jurisdictionList,
            'id',
            'jurisdictionName',
          ),
          countyOptions: arrsToSelectableObject<AdminCountyData>(
            states.flatMap(
              (stateItem: StateWithCounties) => stateItem.counties,
            ),
            'id',
            'name',
          ),
          ...filtersOptions,
        }}
      />
      <Form
        form={paceTaskForm}
        wrapperCol={{ span: 15 }}
        labelCol={{ span: 8 }}
      >
        <FiltersList
          form={paceTaskForm}
          fieldsList={filtersSet?.paceTaskFields}
          data={convertPaceTaskFilterToObj(filtersSet?.paceTaskFilter)}
          pmScreenPaceTasksOptions={pmScreenPaceTasksOptions}
        />
      </Form>
    </AddAndEditModal>
  );
};
