import { ReactNode } from 'react';
import { ColumnsType } from 'antd/lib/table/interface';
import { MainPaceTask } from 'providence-types';
import {
  getJurisdictionByIdOrUndefined,
  projectCategoryRender,
  projectPaceTaskDataRender,
  providenceAgentRender,
  readOnlyInputNumberRender,
} from '@core/utils/methods';
import { IdAndValue, SiteBrowse } from '@models/interfaces';
import { Browse } from '@models/types';

export const ENGINEERING_BROWSE_COLUMNS: ColumnsType<Browse> = [
  {
    title: 'A/E CD Vendor',
    dataIndex: 'scoping',
    key: 'AEVendor',
    width: 160,
    render: ({ AEVendor }: { AEVendor: IdAndValue }): ReactNode =>
      AEVendor?.value,
  },
  {
    title: 'A/E Mount Vendor',
    dataIndex: 'scoping',
    key: 'AEMountVendor',
    width: 180,
    sorter: true,
    render: ({ AEMountVendor }: { AEMountVendor: IdAndValue }): ReactNode =>
      AEMountVendor?.value,
  },
  {
    title: 'Alternate Site Info',
    width: 170,
    key: 'alternateSiteInfo',
    sorter: true,
    dataIndex: 'site',
    render: (site: SiteBrowse): ReactNode => site.alternateSiteInfo,
  },
  {
    title: 'Civil Vendor',
    dataIndex: 'civilVendor',
    key: 'civilVendor',
    width: 140,
    sorter: true,
    render: (civilVendor: IdAndValue): ReactNode => civilVendor?.value,
  },
  {
    title: 'FA Location',
    dataIndex: 'site',
    key: 'FALocationNumber',
    width: 140,
    sorter: true,
    render: (site: SiteBrowse): ReactNode =>
      readOnlyInputNumberRender(site?.FALocationNumber),
  },
  {
    title: 'Jurisdiction',
    dataIndex: 'site',
    key: 'jurisdiction',
    width: 180,
    sorter: true,
    render: (site: SiteBrowse): ReactNode =>
      getJurisdictionByIdOrUndefined(site?.jurisdiction?.id)?.jurisdictionName,
  },
  {
    title: 'Legacy Site ID',
    width: 150,
    key: 'legacySiteId',
    sorter: true,
    dataIndex: 'site',
    render: (site: SiteBrowse): ReactNode => site.legacySiteId,
  },
  {
    title: 'Oracle Project',
    dataIndex: 'categories',
    key: 'oracleProjectNumber',
    width: 150,
    sorter: true,
    render: projectCategoryRender({
      field: 'oracleProjectNumber',
    }),
  },
  {
    title: 'PACE Project',
    dataIndex: 'categories',
    key: 'PACEProjectNumber',
    width: 150,
    sorter: true,
    render: projectCategoryRender({
      field: 'PACEProjectNumber',
    }),
  },
  {
    title: 'Project Category',
    dataIndex: 'categories',
    key: 'projectCategory',
    width: 210,
    sorter: true,
    render: projectCategoryRender({
      field: 'projectCategory',
    }),
  },
  {
    title: 'Project Type',
    dataIndex: 'projectType',
    key: 'projectType',
    width: 140,
    render: (projectType: IdAndValue): ReactNode => projectType?.value,
  },
  {
    title: 'Project Work Type',
    dataIndex: 'projectWorkType',
    key: 'projectWorkType',
    width: 180,
    render: (projectWorkType: IdAndValue): ReactNode => projectWorkType?.value,
  },
  {
    title: 'Providence Agent',
    key: 'providenceAgent',
    width: 180,
    sorter: true,
    dataIndex: 'providenceAgent',
    render: providenceAgentRender,
  },
  {
    title: 'SOF Issued Date',
    dataIndex: 'projectPaceTaskData',
    key: 'SOFIssuedDate',
    width: 160,
    sorter: true,
    render: projectPaceTaskDataRender({
      field: 'actualDate',
      task: MainPaceTask.SOFIssuedDate,
    }),
  },
  {
    title: 'SS014 Scoping Complete',
    dataIndex: 'projectPaceTaskData',
    key: 'SS014ScopingComplete',
    width: 225,
    sorter: true,
    render: projectPaceTaskDataRender({
      field: 'actualDate',
      task: MainPaceTask.ScopingComplete,
    }),
  },
  {
    title: 'Site',
    dataIndex: 'site',
    key: 'siteNumber',
    width: 100,
    sorter: true,
    render: (site: SiteBrowse): ReactNode => site?.siteNumber,
  },
  {
    title: 'State',
    dataIndex: 'site',
    key: 'state',
    width: 120,
    sorter: true,
    render: (site: SiteBrowse): ReactNode => site?.state?.name,
  },
  {
    title: 'Submarket',
    key: 'submarket',
    width: 140,
    sorter: true,
    dataIndex: 'site',
    render: (site: SiteBrowse): ReactNode => site.submarket?.value,
  },
  {
    title: 'Tower Owner',
    dataIndex: 'site',
    key: 'towerOwner',
    width: 150,
    sorter: true,
    render: (site: SiteBrowse): ReactNode => site?.towerOwner?.value,
  },
  {
    title: 'USID',
    width: 150,
    key: 'USID',
    sorter: true,
    dataIndex: 'site',
    render: (site: SiteBrowse): ReactNode => site.USID,
  },
];
