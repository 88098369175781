import { PaceTaskScreen } from 'providence-types';
import { PaceTaskTabScreens } from '@models/enums';
import { RadioButtonProps } from '@shared/modules/ui-kit/models/interfaces';

export const TASK_IDENTIFIER: RadioButtonProps[] = [
  {
    value: 'paceTaskID',
    viewValue: 'PACE Task ID',
    defaultChecked: true,
  },
  {
    value: 'taskName',
    viewValue: 'Task Name',
  },
];

type TKey = keyof typeof PaceTaskTabScreens;

const findPaceTaskTabKey = (tab: PaceTaskTabScreens): TKey => {
  const keys = Object.keys(PaceTaskTabScreens) as TKey[];

  return keys.find((key: TKey) => PaceTaskTabScreens[key] === tab) || 'Project';
};

export const paceTaskTabToScreen = (
  tab: PaceTaskTabScreens,
): PaceTaskScreen => {
  const key = findPaceTaskTabKey(tab);

  return PaceTaskScreen[key];
};
