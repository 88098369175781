import { EngineeringAdditionFilterType } from 'providence-types';
import { AdditionalFilter } from '@models/interfaces';

export const ADDITIONAL_FILTERS: AdditionalFilter[] = [
  {
    key: EngineeringAdditionFilterType.CraneFileDateRequired,
    name: 'Crane File Date Required',
  },
  {
    key: EngineeringAdditionFilterType.SOWSubmitRequired,
    name: 'SOW Submit Required',
  },
];
