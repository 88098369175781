import { StoredRequest } from '@core/utils/store/stored-request-decorator';
import {
  LeaseAuditData,
  LeasingEquipmentResp,
  LeasingProject,
  LeasingWorkflowUpdateData,
  MainBrowseFilter,
  PaginatedResponse,
  UpdateLeasingEquipmentData,
} from '@models/interfaces';
import { ProjectDetailsWithoutCategories } from '@models/types';
import { BreadcrumbsActions, LeasingActions } from '@store/actions';
import { ProjectCategoriesEffects, ProjectsEffects } from '@store/effects';
import { CommonHttpService } from './common-http.service';

export class LeasingHttpService extends CommonHttpService {
  @StoredRequest(LeasingActions.getLeasingBrowseDataAction)
  getLeasingBrowse(
    params: MainBrowseFilter,
  ): Promise<PaginatedResponse<LeasingProject>> {
    return this.http.callApi.get(`${this.apiUrl}/leasing/browse`, null, {
      params,
    });
  }

  @StoredRequest(
    LeasingActions.getLeasingDetailsAction,
    BreadcrumbsActions.leasing,
  )
  async getLeasing(
    id: string,
  ): Promise<Omit<ProjectDetailsWithoutCategories, 'paceTasks'>> {
    const response = await this.http.callApi.get(
      `${this.apiUrl}/leasing/${id}`,
    );

    return ProjectsEffects.setHoldOptions(
      ProjectsEffects.setCurrentProvidenceAgent(
        ProjectsEffects.setRAD(
          ProjectsEffects.setPaceTaskFields(
            ProjectsEffects.setPaceTasks(
              ProjectCategoriesEffects.setProjectCategories(response),
            ),
          ),
        ),
      ),
    );
  }

  @StoredRequest(LeasingActions.getLeasingEquipmentPriorProjectAction)
  getLeasingEquipmentPriorProject(id: string): Promise<LeasingEquipmentResp> {
    return this.http.callApi.get(`${this.apiUrl}/leasing/${id}/equipment`);
  }

  @StoredRequest(LeasingActions.updateLeasingWorkflowAction)
  async updateLeasingWorkflow(
    id: string,
    body: LeasingWorkflowUpdateData,
  ): Promise<LeasingWorkflowUpdateData> {
    await this.http.callApi.patch(`${this.apiUrl}/leasing`, body, {
      params: { id },
    });

    return body;
  }

  @StoredRequest(LeasingActions.updateLeasingEquipmentAction)
  async updateLeasingEquipment(
    id: string,
    body: UpdateLeasingEquipmentData,
  ): Promise<UpdateLeasingEquipmentData> {
    await this.http.callApi.patch(
      `${this.apiUrl}/leasing/equipment/${id}`,
      body,
    );

    return body;
  }

  @StoredRequest(LeasingActions.updateLeaseAuditAction)
  async updateLeaseAudit(
    id: string,
    body: LeaseAuditData,
  ): Promise<LeaseAuditData> {
    await this.http.callApi.patch(`${this.apiUrl}/leasing/lease-audit`, body, {
      params: { id },
    });

    return body;
  }
}
