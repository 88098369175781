import React, {
  FC,
  PropsWithChildren,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Form, Popover, Row } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import { SelectValue } from 'antd/lib/select';
import classNames from 'classnames';
import { Undefinable } from 'providence-types';
import { RuleObject } from 'rc-field-form/es/interface';
import { HttpService, ObjectComparatorService } from '@core/services';
import { SiteHttpService } from '@core/services/http';
import {
  useDidUpdateEffect,
  useFindCountyAndJurisdictions,
  useSaveChanged,
} from '@core/utils/hooks';
import {
  arrsToSelectableObject,
  formDiffs,
  isValidForm,
  normalize,
  toggleModal,
} from '@core/utils/methods';
import {
  INPUT_POSITIVE_NUMBER_VALIDATOR,
  YES_NO_GROUP,
} from '@models/constants';
import { ModalMainTypes } from '@models/enums';
import {
  AssociatedProject,
  OptionProps,
  SiteBrowse,
  StateWithCounties,
} from '@models/interfaces';
import {
  ConfirmationPopup,
  NotificationsLoader,
  Panel,
} from '@shared/components';
import {
  Autocomplete,
  Coordinate,
  Input,
  PhoneNumber,
  PrimaryButton,
  RadioGroup,
  TextArea,
} from '@shared/modules';
import { CommonActions, SiteActions } from '@store/actions';
import { SharedSelectors, SiteSelectors } from '@store/selectors';
import { transformPhoneNumber } from './models/helpers';
import { SiteFormProps } from './models/interfaces';
import { DisablingModal, SiteOnSwitch } from './components';

import './styles.scss';

const { useForm } = Form;

export const SiteInformation: FC<SiteFormProps> = ({
  isEditing,
  toggleEditing,
  isNew = false,
  className,
  permissions: {
    canRemoveSite,
    turnOnOffSite,
    siteInformationFields: {
      DNE,
      FALocationNumber,
      NTPRequirements,
      USID,
      accessDetails,
      alternateSiteInfo,
      city,
      county,
      equipmentType,
      gateOrDoorCodes,
      generatorInstalled,
      sqInchesEquipmentAllowed,
      sqInchesEquipmentUsed,
      sqInchesCablesAllowed,
      sqInchesCablesUsed,
      groundElevation,
      highRiskSite,
      jurisdiction,
      landlordLegalEntityName,
      latitude,
      leaseAccessRestrictions,
      leasedGroundSpace,
      longitude,
      notes,
      noticeComments,
      noticeNeeded,
      parcelId,
      parcelOwner,
      primaryTech,
      primaryTechMobile,
      providenceAccessNotes,
      riskCategory,
      riskNote,
      legacySiteId,
      siteNumber,
      state,
      streetAddress,
      submarket,
      towerHeight,
      towerOwner,
      towerOwnerSiteId,
      towerType,
      zip,
    },
  },
  data,
  history,
}: PropsWithChildren<SiteFormProps>) => {
  const dispatch = useDispatch();

  const [form] = useForm();

  const siteService = useMemo(
    () => HttpService.getHttpRequests(SiteHttpService),
    [],
  );

  const { dropdownsOptions, states } = useSelector(
    SharedSelectors.getDropdownOptionsAndStates,
  );

  const siteFetching = useSelector(SiteSelectors.isFetching);

  const [stateId, setStateId] = useState<Undefinable<string>>('');
  const [countyOptions, setCountyOptions] = useState<OptionProps[]>([]);
  const [jurisdictionOptions, setJurisdictionOptions] = useState<OptionProps[]>(
    [],
  );
  const [disablingModalVisible, setDisablingModalVisible] =
    useState<boolean>(false);
  const [siteOnSwitch, setSiteOnSwitch] = useState<boolean>();
  const [siteOnPopconfirm, setSiteOnPopconfirm] = useState<boolean>();
  const [areDynamicFieldsRequired, setDynamicFieldsRequirements] =
    useState<boolean>(false);
  const [objectsCompare, setObjectsCompare] = useState<boolean>();

  const resetForm = (): void => {
    setStateId(data?.state || '');

    if (isNew) {
      form.resetFields();
    } else {
      form.setFieldsValue({ ...data, ...form.getFieldsValue() });
    }
  };

  useFindCountyAndJurisdictions({
    stateId,
    setCountyOptions,
    setJurisdictionOptions,
    effectDepArray: [stateId, states],
  });

  useEffect(() => {
    if (data) {
      if (isNew) {
        history?.push(`/project/create/${data}`);
        dispatch(SiteActions.clearCreatedSite.done());

        return;
      }

      resetForm();
    }
  }, [data, isEditing]);

  useEffect(() => {
    if (data) {
      setSiteOnSwitch(data.siteOn);
    }
  }, [data]);

  useEffect(
    () => (): void => {
      dispatch(CommonActions.setHasUnsubmittedData.done(false));
    },

    [],
  );

  useDidUpdateEffect(() => {
    dispatch(CommonActions.setHasUnsubmittedData.done(!objectsCompare));
  }, [objectsCompare]);

  const formatSiteForm = (): SiteBrowse => {
    const values = form.getFieldsValue();

    return formDiffs<SiteBrowse>(data, {
      ...data,
      ...values,
      primaryTechMobile: values.primaryTechMobile
        ? transformPhoneNumber(values.primaryTechMobile)
        : undefined,
      longitude:
        values.longitude !== '-' ? (values.longitude as number) : undefined,
    });
  };

  const onSubmit = async (): Promise<void> => {
    if (await isValidForm(form)) {
      setObjectsCompare(true);

      try {
        if (isNew) {
          await siteService.createSite(formatSiteForm());
        } else {
          await siteService.updateSite(data?.id, formatSiteForm());

          toggleEditing?.();
        }

        NotificationsLoader.notificationSuccess(
          `Site has been ${isNew ? 'created' : 'updated'}!`,
        );
      } catch (e) {
        setObjectsCompare(false);
        console.error(e);
      }
    }
  };

  const onCancel = (): void => {
    resetForm();

    setObjectsCompare(true);

    toggleEditing?.();
  };

  const onDelete = async (): Promise<void> => {
    try {
      await siteService.deleteSite(data?.id);

      NotificationsLoader.notificationSuccess('Site has been deleted!');
    } catch (e) {
      console.error(e);
    }

    history?.push('/site/browse');
  };

  const changeSiteState = (
    checked: boolean,
    disablingNotes?: string,
  ): Promise<SiteBrowse> =>
    siteService.updateSite(data?.id, {
      siteOn: checked,
      disablingNotes,
    } as SiteBrowse);

  const onToggleSite = (): void => {
    setDisablingModalVisible(!disablingModalVisible);
  };

  const setDisablingNotesValue = async (value: string): Promise<void> => {
    await changeSiteState(false, value);
    onToggleSite();
  };

  const isSwitchDisabled =
    data.siteOn &&
    data?.associatedProjects.length &&
    data?.associatedProjects.some((project: AssociatedProject) =>
      ['Active', 'Authorized'].includes(project?.projectStatus?.value),
    );

  useSaveChanged(isEditing, onSubmit, onCancel);

  return (
    <Form
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 14 }}
      className={classNames('prov-site-information', className)}
      form={form}
      initialValues={{ longitude: '-' }}
      onChange={(): void =>
        setObjectsCompare(
          ObjectComparatorService.objectsCompare(form.getFieldsValue(), data),
        )
      }
    >
      <DisablingModal
        visible={disablingModalVisible}
        modalType={ModalMainTypes.Add}
        toggleModal={toggleModal(setDisablingModalVisible)}
        fieldSetter={setDisablingNotesValue}
      />
      <div
        className={classNames('tabs-wrap', {
          'tabs-wrap_with-actions': isNew || isEditing,
        })}
      >
        <Panel
          header={
            <Row
              justify="space-between"
              className="prov-site-information__header"
            >
              <Col className="header-title">Site Information</Col>
              {!isNew && turnOnOffSite && (
                <Col
                  span={6}
                  className={classNames('prov-site-information__info', {
                    'disabled-mode': !isSwitchDisabled,
                  })}
                >
                  <Popover
                    content={
                      <ConfirmationPopup
                        onOk={(): void => {
                          changeSiteState(true);
                          setSiteOnPopconfirm(false);
                        }}
                        onCancel={(): void => setSiteOnPopconfirm(false)}
                        text="Are you sure you want to reactivate this site?"
                      />
                    }
                    placement="top"
                    visible={!disablingModalVisible && siteOnPopconfirm}
                  >
                    <SiteOnSwitch
                      onChange={(checked: boolean): void => {
                        const callback = checked
                          ? setSiteOnPopconfirm
                          : onToggleSite;

                        callback(checked);
                      }}
                      switchState={siteOnSwitch}
                      disabled={isSwitchDisabled}
                    />
                  </Popover>
                </Col>
              )}
            </Row>
          }
          className="prov-site-information__panel"
        >
          <Row>
            <Col span="8">
              <Input
                id="FALocationNumber"
                label="FA Location Number"
                elementProps={{
                  maxLength: 8,
                  minLength: 8,
                }}
                isEditing={isEditing && !!FALocationNumber}
                formItemProps={{
                  normalize,
                  rules: [
                    {
                      required: true,
                      message: 'FA Location Number is required!',
                    },
                    INPUT_POSITIVE_NUMBER_VALIDATOR,
                    {
                      validator: async (
                        _: RuleObject,
                        value: Undefinable<number>,
                      ): Promise<void> => {
                        if (value) {
                          if (`${value}`.length < 8) {
                            throw new Error(
                              'FA Location Number must be 8 characters!',
                            );
                          }

                          if (
                            !(await siteService.validateFALocationNumber(
                              value,
                              data?.id,
                            ))
                          ) {
                            throw new Error(
                              'FA Location Number must be unique!',
                            );
                          }
                        }
                      },
                    },
                  ],
                }}
              />
              <Input
                id="siteNumber"
                label="Site Number"
                isEditing={isEditing && !!siteNumber}
              />
              <Input
                id="legacySiteId"
                label="Legacy Site ID"
                isEditing={isEditing && !!legacySiteId}
              />
              <Input
                id="alternateSiteInfo"
                label="Alternate Site Info"
                isEditing={isEditing && !!alternateSiteInfo}
              />
              <Input
                id="USID"
                label="USID"
                isEditing={isEditing && !!USID}
                formItemProps={{
                  normalize,
                }}
              />
              <Autocomplete
                id="towerOwner"
                label="Tower Owner"
                isEditing={isEditing && !!towerOwner}
                options={dropdownsOptions?.TowerOwner || []}
              />
              <Input
                id="towerOwnerSiteId"
                label="Tower Owner Site ID"
                isEditing={isEditing && !!towerOwnerSiteId}
              />
              <Input
                id="parcelId"
                label="Parcel ID"
                isEditing={isEditing && !!parcelId}
              />
              <Input
                id="parcelOwner"
                label="Parcel Owner"
                isEditing={isEditing && !!parcelOwner}
              />
              <Input
                id="landlordLegalEntityName"
                label="Landlord Legal Entity Name"
                isEditing={isEditing && !!landlordLegalEntityName}
              />
              <Input
                id="streetAddress"
                label="Street Address"
                isEditing={isEditing && !!streetAddress}
              />
              <Input id="city" label="City" isEditing={isEditing && !!city} />
              <Autocomplete
                id="state"
                label="State"
                options={arrsToSelectableObject<StateWithCounties>(
                  states,
                  'id',
                  'name',
                )}
                elementProps={{
                  onChange: (value: SelectValue): void => {
                    form.setFieldsValue({
                      county: null,
                      jurisdiction: null,
                    });
                    setStateId(value as string);
                  },
                }}
                isEditing={isEditing && !!state}
              />
              <Input
                id="zip"
                label="Zip"
                isEditing={isEditing && !!zip}
                formItemProps={{
                  normalize,
                }}
              />
              <Autocomplete
                id="county"
                label="County"
                isEditing={isEditing && !!county}
                options={stateId ? countyOptions : []}
              />
              <Autocomplete
                id="jurisdiction"
                label="Jurisdiction"
                isEditing={isEditing && !!jurisdiction}
                options={stateId ? jurisdictionOptions : []}
              />
              <Coordinate
                id="latitude"
                label="Latitude"
                isEditing={isEditing && !!latitude}
              />
              <Coordinate
                id="longitude"
                label="Longitude"
                isEditing={isEditing && !!longitude}
              />
              <Autocomplete
                id="submarket"
                label="Submarket"
                options={dropdownsOptions?.Submarket || []}
                isEditing={isEditing && !!submarket}
              />
            </Col>
            <Col span="8">
              <Autocomplete
                id="towerType"
                label="Tower Type"
                isEditing={isEditing && !!towerType}
                options={dropdownsOptions?.TowerType || []}
              />
              <Input
                id="towerHeight"
                label="Tower Height"
                isEditing={isEditing && !!towerHeight}
                formItemProps={{
                  normalize,
                  rules: [INPUT_POSITIVE_NUMBER_VALIDATOR],
                }}
              />
              <Input
                id="DNE"
                label="DNE"
                isEditing={isEditing && !!DNE}
                formItemProps={{
                  normalize,
                  rules: [INPUT_POSITIVE_NUMBER_VALIDATOR],
                }}
              />
              <Input
                id="groundElevation"
                label="Ground Elevation"
                isEditing={isEditing && !!groundElevation}
                formItemProps={{
                  normalize,
                }}
              />
              <Input
                id="leasedGroundSpace"
                label="Leased Ground Space"
                isEditing={isEditing && !!leasedGroundSpace}
              />
              <Autocomplete
                id="equipmentType"
                label="Equipment Type"
                isEditing={isEditing && !!equipmentType}
                options={dropdownsOptions?.EquipmentType || []}
              />
              <Autocomplete
                id="generatorInstalled"
                label="Generator Installed"
                isEditing={isEditing && !!generatorInstalled}
                options={dropdownsOptions?.GeneratorInstalled || []}
              />
              <Input
                id="sqInchesEquipmentAllowed"
                label="Sq. Inches Equipment Allowed"
                isEditing={isEditing && !!sqInchesEquipmentAllowed}
              />
              <Input
                id="sqInchesEquipmentUsed"
                label="Sq. Inches Equipment Used"
                isEditing={isEditing && !!sqInchesEquipmentUsed}
              />
              <Input
                id="sqInchesCablesAllowed"
                label="Sq. Inches Cables Allowed"
                isEditing={isEditing && !!sqInchesCablesAllowed}
              />
              <Input
                id="sqInchesCablesUsed"
                label="Sq. Inches Cables Used"
                isEditing={isEditing && !!sqInchesCablesUsed}
              />
              <Input
                id="NTPRequirements"
                label="NTP Requirements"
                isEditing={isEditing && !!NTPRequirements}
              />
              <TextArea
                id="notes"
                label="Site Notes"
                isEditing={isEditing && !!notes}
              />
              {!isNew && !data.siteOn && (
                <TextArea
                  id="disablingNotes"
                  label="Disabling notes"
                  isEditing={false}
                />
              )}
            </Col>
            <Col span="8">
              <RadioGroup
                id="highRiskSite"
                label="High Risk"
                buttons={YES_NO_GROUP}
                isEditing={isEditing && !!highRiskSite}
                elementProps={{
                  onChange: (event: RadioChangeEvent): void =>
                    setDynamicFieldsRequirements(event.target.value),
                }}
              />
              <Autocomplete
                id="riskCategory"
                label="Risk Category"
                options={dropdownsOptions?.RiskCategory || []}
                isEditing={isEditing && !!riskCategory}
                formItemProps={{
                  rules: [
                    {
                      required: areDynamicFieldsRequired,
                      message: 'Risk Category is required!',
                    },
                  ],
                }}
              />
              <TextArea
                id="riskNote"
                label="Risk Note"
                isEditing={isEditing && !!riskNote}
                formItemProps={{
                  rules: [
                    {
                      required: areDynamicFieldsRequired,
                      message: 'Risk Note is required!',
                    },
                  ],
                }}
              />
              <TextArea
                id="providenceAccessNotes"
                label="Providence Access Notes"
                isEditing={isEditing && !!providenceAccessNotes}
              />
              <TextArea
                id="accessDetails"
                label="Access Details"
                isEditing={isEditing && !!accessDetails}
              />
              <TextArea
                id="gateOrDoorCodes"
                label="Gate/Door Codes"
                isEditing={isEditing && !!gateOrDoorCodes}
              />
              <RadioGroup
                id="noticeNeeded"
                label="Notice Needed"
                buttons={YES_NO_GROUP}
                isEditing={isEditing && !!noticeNeeded}
              />
              <TextArea
                id="noticeComments"
                label="Notice Comments"
                isEditing={isEditing && !!noticeComments}
              />
              <TextArea
                id="leaseAccessRestrictions"
                label="Lease Access Restrictions"
                isEditing={isEditing && !!leaseAccessRestrictions}
              />
              <Input
                id="primaryTech"
                label="Primary Tech"
                isEditing={isEditing && !!primaryTech}
              />
              <PhoneNumber
                id="primaryTechMobile"
                label="Primary Tech Mobile"
                isEditing={isEditing && !!primaryTechMobile}
              />
            </Col>
          </Row>
        </Panel>
      </div>
      {isEditing && (
        <Row className="prov-site-information__btn-wrap">
          <div>
            <PrimaryButton
              htmlType="submit"
              title="Submit"
              disabled={siteFetching}
              onClick={onSubmit}
            />
            <PrimaryButton title="Cancel" type="default" onClick={onCancel} />
          </div>
          {!isNew && canRemoveSite && (
            <PrimaryButton
              className="site-delete"
              title="Delete this site"
              type="danger"
              onClick={onDelete}
            />
          )}
        </Row>
      )}
    </Form>
  );
};
