import React, { FC, ReactText, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RouterProps } from 'react-router';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { IReceivedDirectMessage } from 'providence-types';
import { HttpService } from '@core/services';
import { DashboardHttpService } from '@core/services/http';
import { tableCount } from '@models/constants';
import { DashboardItem } from '@models/interfaces';
import { CustomTable, Search, TableContainer } from '@shared/components';
import { PrimaryButton, SwitchBox } from '@shared/modules';
import { DashboardSelectors } from '@store/selectors';
import { directMessagesSearcher } from './helpers';
import { DirectMessageViewer } from './modals';
import { DIRECT_MESSAGE_COLUMNS } from './models';

export const DirectMessageTable: FC<RouterProps> = ({
  history,
}: RouterProps) => {
  const dashboardService = HttpService.getHttpRequests(DashboardHttpService);

  const items = useSelector(DashboardSelectors.getDirectMessagesData);
  const isDirectMessagesFetching = useSelector(
    DashboardSelectors.isDirectMessagesFetching,
  );
  const usersList = useSelector(DashboardSelectors.getUserList);

  const [searchValue, setSearchValue] = useState<string>('');
  const [switchValue, setSwitchValue] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState<ReactText[]>([]);
  const [tableData, setTableData] =
    useState<DashboardItem<IReceivedDirectMessage>[]>(items);
  const [visible, setVisible] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] =
    useState<DashboardItem<IReceivedDirectMessage> | null>(null);

  const getData = useCallback(async (checked: boolean): Promise<void> => {
    await dashboardService.getDirectMessages(checked);
  }, []);

  useEffect(() => {
    getData(switchValue);
  }, []);

  useEffect(() => {
    setTableData(directMessagesSearcher(items, usersList, searchValue));
    setSelectedRows([]);
  }, [searchValue, items]);

  const changeSwitchHandler = useCallback(
    async (checked: boolean): Promise<void> => {
      setSwitchValue(checked);

      await getData(checked);
    },
    [],
  );

  const showMessageHandler = useCallback(
    (dashboardItem: DashboardItem<IReceivedDirectMessage>): void => {
      setSelectedRows([]);
      setSelectedItem(dashboardItem);
      setVisible(true);
    },
    [],
  );

  const changeRowSelected = useCallback(
    (rows: ReactText[]): void => setSelectedRows(rows),
    [],
  );

  const setCheckboxDisabled = useCallback(
    (dashbaoardItem: DashboardItem<IReceivedDirectMessage>) => ({
      disabled: dashbaoardItem.notificationData.completed,
    }),
    [],
  );

  const closeMessage = useCallback((): void => {
    setVisible(false);
  }, []);

  const setCompleted = useCallback(
    async (_id: string): Promise<void> => {
      await dashboardService.setCompletedDirectMessage(_id, switchValue);

      closeMessage();
    },
    [switchValue],
  );

  const multiSetCompleted = useCallback(async (): Promise<void> => {
    await dashboardService.multiSetCompletedDirectMessage(
      selectedRows as string[],
      switchValue,
    );

    setSelectedRows([]);
  }, [selectedRows, switchValue]);

  return (
    <>
      <TableContainer header="Direct Messages" className="prov-dashboard-panel">
        <div className="prov-dashboard-panel__controls">
          <Search className="search" onChange={setSearchValue} allowClear />
          <div className="right">
            <PrimaryButton
              title="Set Сompleted"
              onClick={multiSetCompleted}
              disabled={!selectedRows.length}
            />
            <SwitchBox
              tooltip="Show Completed"
              checked={switchValue}
              onChange={changeSwitchHandler}
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
            />
          </div>
        </div>
        <CustomTable
          dataSource={tableData}
          columns={DIRECT_MESSAGE_COLUMNS(usersList, showMessageHandler)}
          place={tableCount(true)}
          pagination={{
            total: tableData?.length,
          }}
          rowSelection={{
            selectedRowKeys: selectedRows,
            onChange: changeRowSelected,
            getCheckboxProps: setCheckboxDisabled,
          }}
          module="home"
          subModule="directMessage"
          loading={isDirectMessagesFetching}
          legendProps={{
            items: [
              { value: 'Completed', color: 'gray' },
              { value: 'Not completed', color: 'green' },
            ],
          }}
          hoverable={false}
        />
      </TableContainer>
      <DirectMessageViewer
        history={history}
        visible={visible}
        item={selectedItem}
        usersList={usersList}
        onCancel={closeMessage}
        onCompleted={setCompleted}
      />
    </>
  );
};
