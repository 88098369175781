import { ReactNode } from 'react';
import { ColumnsType } from 'antd/lib/table/interface';
import { MainPaceTask } from 'providence-types';
import {
  projectCategoryRender,
  projectPaceTaskDataRender,
  readOnlyInputNumberRender,
} from '@core/utils/methods';
import { SiteBrowse } from '@models/interfaces';
import { Browse } from '@models/types';

export const SOF_TOOL_BROWSE_COLUMNS: ColumnsType<Browse> = [
  {
    title: 'FA Location Number',
    dataIndex: 'site',
    key: 'FALocationNumber',
    sorter: true,
    render: (site: SiteBrowse): ReactNode =>
      readOnlyInputNumberRender(site?.FALocationNumber),
  },
  {
    title: 'Legacy Site ID',
    dataIndex: 'site',
    key: 'legacySiteId',
    sorter: true,
    render: (site: SiteBrowse): ReactNode => site?.legacySiteId,
  },
  {
    title: 'Oracle Project Number',
    dataIndex: 'categories',
    key: 'oracleProjectNumber',
    sorter: true,
    render: projectCategoryRender({
      field: 'oracleProjectNumber',
    }),
  },
  {
    title: 'PACE Project Number',
    dataIndex: 'categories',
    key: 'PACEProjectNumber',
    sorter: true,
    render: projectCategoryRender({
      field: 'PACEProjectNumber',
    }),
  },
  {
    title: 'SS014 Actual Date',
    key: 'SS014ActualDate',
    dataIndex: 'projectPaceTaskData',
    sorter: true,
    width: 200,
    render: projectPaceTaskDataRender({
      field: 'actualDate',
      task: MainPaceTask.ScopingComplete,
    }),
  },
  {
    title: 'Site Number',
    dataIndex: 'site',
    key: 'siteNumber',
    sorter: true,
    render: (site: SiteBrowse): ReactNode => site?.siteNumber,
  },
];
