import React, { FC, PropsWithChildren, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { Badge, Card, Empty, Popover } from 'antd';
import bellIcon from 'assets/icons/bell.svg';
import closeIcon from 'assets/icons/close-icon.svg';
import { INotificationData } from 'providence-types';
import { HttpService } from '@core/services';
import { NotificationsHttpService } from '@core/services/http';
import { showIfAuthorized } from '@core/utils/HOC';
import { mainDateFormat } from '@core/utils/methods';
import { Modal, PrimaryButton } from '@shared/modules';
import { UserSelectors } from '@store/selectors';
import {
  NotificationBoxProps,
  NotificationCardProps,
} from '../../models/interfaces';

import './styles.scss';

const NotificationCard: FC<NotificationCardProps> = ({
  notification,
}: PropsWithChildren<NotificationCardProps>) => {
  const { title, body, iconUrl, type, createdAt, _id, clickRedirectUrl } =
    notification;

  const clearNotificationById = async (
    evt: React.MouseEvent<HTMLElement, MouseEvent>,
  ): Promise<void> => {
    evt.preventDefault();

    await HttpService.getHttpRequests(
      NotificationsHttpService,
    ).removeCurrentNotification(_id);
  };

  const mainContent = (
    <>
      <span className="clear" onClick={clearNotificationById}>
        <img className="close-icon" src={closeIcon} alt="close-icon" />
      </span>
      <img
        className="icon"
        src={iconUrl}
        crossOrigin="use-credentials"
        alt="icon"
      />
      <div className="content">
        <span className={`title title--${type}`}>{title}</span>
        <span className="body">{body}</span>
      </div>
      <span className="date">{mainDateFormat(createdAt, true)}</span>
    </>
  );

  return (
    <Card className="notification-card" bordered={false}>
      {clickRedirectUrl ? (
        <a className="redirected" href={clickRedirectUrl}>
          {mainContent}
        </a>
      ) : (
        <div className="not-redirected">{mainContent}</div>
      )}
    </Card>
  );
};

const NotificationsBox: FC<NotificationBoxProps> = ({
  notifications,
  popoverVisibleSetter,
}: PropsWithChildren<NotificationBoxProps>) => {
  const [modalVisible, setModalVisible] = useState<boolean>(false);

  const clickToCloseAll = (): void => {
    setModalVisible(!modalVisible);
    popoverVisibleSetter(false);
  };

  const clearAll = async (): Promise<void> => {
    await HttpService.getHttpRequests(
      NotificationsHttpService,
    ).removeAllNotifications();
    setModalVisible(!modalVisible);
  };

  return (
    <>
      <Card
        className="prov-bell-notifications__notification-box"
        bordered={false}
      >
        {notifications.length ? (
          <>
            <PrimaryButton
              className="clear-btn"
              title="Clear All"
              onClick={clickToCloseAll}
            />
            {notifications.map((notification: INotificationData) => (
              <NotificationCard
                key={notification._id}
                notification={notification}
              />
            ))}
          </>
        ) : (
          <Empty />
        )}
      </Card>
      <Modal
        className="prov-bell-notifications__clear-all-modal"
        visible={modalVisible}
        okText="Yes"
        cancelText="No"
        cancelType="danger-outline"
        centered
        onOk={clearAll}
        onCancel={(): void => setModalVisible(!modalVisible)}
      >
        <span className="content">Are you sure?</span>
      </Modal>
    </>
  );
};

const BellNotificationsComponent: FC = () => {
  const notifications = useSelector(UserSelectors.getUserNotifications);
  const [boxVisible, setBoxVisible] = useState<boolean>(false);

  const openNotificationBox = useCallback((visible: boolean): void => {
    setBoxVisible(visible);
  }, []);

  return (
    <Popover
      className="prov-bell-notifications"
      content={
        <NotificationsBox
          notifications={notifications}
          popoverVisibleSetter={setBoxVisible}
        />
      }
      visible={boxVisible}
      onVisibleChange={openNotificationBox}
      trigger="click"
      placement="bottom"
    >
      <Badge count={notifications.length}>
        <img
          className="prov-bell-notifications__bell-icon"
          src={bellIcon}
          alt="bell-icon"
        />
      </Badge>
    </Popover>
  );
};

export const BellNotifications: FC = showIfAuthorized(
  BellNotificationsComponent,
);
