import { RuleValueType } from 'providence-types';

export const getCurrentValueType = (value: RuleValueType): string => {
  switch (value) {
    case RuleValueType.Preset:
      return 'Preset';
    case RuleValueType.DayCount:
      return 'Days Count';
    default:
      throw new Error('Incorrect value');
  }
};
