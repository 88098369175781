import React, { ReactNode } from 'react';
import { Popover } from 'antd';
import { ColumnsType } from 'antd/lib/table/interface';
import { MainPaceTask } from 'providence-types';
import {
  getJurisdictionByIdOrUndefined,
  projectCategoryRender,
  projectPaceTaskDataRender,
  providenceAgentRender,
  readOnlyInputNumberRender,
} from '@core/utils/methods';
import { IdAndValue, ProjectBrowse, SiteBrowse } from '@models/interfaces';
import { Browse } from '@models/types';
import { RiskPopup } from '@shared/components';
import { ProjectStatus } from '@shared/modules';
import { SiteRisk } from '@shared/modules/browse-page/models/enums';

export const PROJECT_BROWSE_COLUMNS: ColumnsType<Browse> = [
  {
    dataIndex: 'site',
    key: 'risk',
    width: 45,
    render: (site: ProjectBrowse): ReactNode =>
      site?.highRiskSite && (
        <Popover
          content={
            <RiskPopup
              risk={site?.highRiskSite && SiteRisk.High}
              riskCategory={site?.riskCategory?.value}
            />
          }
          placement="bottomLeft"
        >
          <i className="circle circle_high" />
        </Popover>
      ),
  },
  {
    title: 'AT&T PM',
    key: 'ATnTPM',
    width: 130,
    dataIndex: 'ATnTPM',
    sorter: true,
    render: (ATnTPM: IdAndValue): ReactNode => ATnTPM?.value,
  },
  {
    title: 'Alternate Site Info',
    width: 170,
    key: 'alternateSiteInfo',
    sorter: true,
    dataIndex: 'site',
    render: (site: SiteBrowse): ReactNode => site.alternateSiteInfo,
  },
  {
    title: 'CI032 OnAir Actual',
    key: 'CI032OnAirActual',
    dataIndex: 'projectPaceTaskData',
    width: 210,
    render: projectPaceTaskDataRender({
      field: 'actualDate',
      task: MainPaceTask.OnAir,
    }),
  },
  {
    title: 'CI032 OnAir Forecast',
    key: 'CI032OnAirForecast',
    dataIndex: 'projectPaceTaskData',
    width: 210,
    render: projectPaceTaskDataRender({
      field: 'forecastedDate',
      task: MainPaceTask.OnAir,
    }),
  },
  {
    title: 'Civil Vendor',
    key: 'civilVendor',
    width: 165,
    dataIndex: 'civilVendor',
    sorter: true,
    render: (civilVendor: IdAndValue): ReactNode => civilVendor?.value,
  },
  {
    title: 'FA Location Number',
    dataIndex: 'site',
    width: 195,
    key: 'FALocationNumber',
    sorter: true,
    render: (site: SiteBrowse): ReactNode =>
      readOnlyInputNumberRender(site?.FALocationNumber),
  },
  {
    title: 'Jurisdiction',
    key: 'jurisdiction',
    width: 215,
    dataIndex: 'site',
    sorter: true,
    render: (site: SiteBrowse): ReactNode =>
      getJurisdictionByIdOrUndefined(site?.jurisdiction?.id)?.jurisdictionName,
  },
  {
    title: 'Legacy Site ID',
    width: 150,
    key: 'legacySiteId',
    sorter: true,
    dataIndex: 'site',
    render: (site: SiteBrowse): ReactNode => site.legacySiteId,
  },
  {
    title: 'Oracle Project Number',
    dataIndex: 'categories',
    key: 'oracleProjectNumber',
    width: 215,
    render: projectCategoryRender({
      field: 'oracleProjectNumber',
      className: 'prov-project-browse__items prov-project-browse__items_col',
    }),
  },
  {
    title: 'PACE Project Number',
    dataIndex: 'categories',
    key: 'PACEProjectNumber',
    width: 215,
    render: projectCategoryRender({
      field: 'PACEProjectNumber',
      className: 'prov-project-browse__items prov-project-browse__items_col',
    }),
  },
  {
    title: 'Project Category',
    dataIndex: 'categories',
    key: 'projectCategory',
    width: 205,
    render: projectCategoryRender({
      field: 'projectCategory',
      className: 'prov-project-browse__items prov-project-browse__items_col',
    }),
  },
  {
    title: 'Project Status',
    dataIndex: 'projectStatus',
    key: 'projectStatus',
    width: 150,
    sorter: true,
    render: (status: IdAndValue): ReactNode => (
      <ProjectStatus status={status?.value} />
    ),
  },
  {
    title: 'Providence Agent',
    key: 'providenceAgent',
    width: 180,
    sorter: true,
    dataIndex: 'providenceAgent',
    render: providenceAgentRender,
  },
  {
    title: 'RE020 Site Acq Complete Actual',
    key: 'RE020SiteAcqCompleteActual',
    dataIndex: 'projectPaceTaskData',
    width: 280,
    render: projectPaceTaskDataRender({
      field: 'actualDate',
      task: MainPaceTask.SiteAcqComplete,
    }),
  },
  {
    title: 'Site Acq Vendor',
    key: 'siteAcqVendor',
    width: 165,
    dataIndex: 'siteAcqVendor',
    sorter: true,
    render: (siteAcqVendor: IdAndValue): ReactNode => siteAcqVendor?.value,
  },
  {
    title: 'Site Number',
    dataIndex: 'site',
    width: 140,
    key: 'siteNumber',
    sorter: true,
    render: (site: SiteBrowse): ReactNode =>
      readOnlyInputNumberRender(site?.siteNumber),
  },
  {
    title: 'Submarket',
    key: 'submarket',
    width: 140,
    sorter: true,
    dataIndex: 'site',
    render: (site: SiteBrowse): ReactNode => site.submarket?.value,
  },
  {
    title: 'Tower Owner',
    key: 'towerOwner',
    width: 145,
    dataIndex: 'site',
    sorter: true,
    render: (site: SiteBrowse): ReactNode => site?.towerOwner?.value,
  },
  {
    title: 'Tower Owner Site ID',
    key: 'towerOwnerSiteId',
    width: 195,
    dataIndex: 'site',
    sorter: true,
    render: (site: SiteBrowse): ReactNode => site?.towerOwnerSiteId,
  },
  {
    title: 'USID',
    width: 150,
    key: 'USID',
    sorter: true,
    dataIndex: 'site',
    render: (site: SiteBrowse): ReactNode => site.USID,
  },
];
