import { arraySort } from '../../core/utils/methods';
import { createReducer, on } from '../../core/utils/store';
import {
  PayloadAndState,
  ProcessEnvMap as KeyString,
  ProjectCategory,
} from '../../models/interfaces';
import { ProjectCategoriesActions } from '../actions/project-categories.actions';

export interface ProjectCategoriesState {
  projectCategoriesTableVisible: boolean;
  isCategoriesTableEditing: boolean;
  originalProjectCategories: ProjectCategory[];
  currentProjectCategories: ProjectCategory[];
  total?: number;
  isFetching: boolean;
  errors: boolean;
}

const initialState: ProjectCategoriesState = {
  projectCategoriesTableVisible: false,
  isCategoriesTableEditing: false,
  originalProjectCategories: [],
  currentProjectCategories: [],
  total: undefined,
  isFetching: false,
  errors: false,
};

export const reducer = createReducer(
  initialState,
  // set/clear
  on(
    ProjectCategoriesActions.setProjectCategoriesAction,
    ({
      payload,
    }: PayloadAndState<ProjectCategory[], ProjectCategoriesState>) => ({
      currentProjectCategories: payload,
      originalProjectCategories: payload,
    }),
  ),
  // add
  on(
    ProjectCategoriesActions.addProjectCategoriesItemAction,
    ({
      payload,
      state: { currentProjectCategories },
    }: PayloadAndState<ProjectCategory, ProjectCategoriesState>) => ({
      currentProjectCategories: arraySort<ProjectCategory>(
        [...currentProjectCategories, payload],
        'ASC',
        'projectCategory',
      ),
    }),
  ),
  // update
  on(
    ProjectCategoriesActions.updateProjectCategoriesItemAction,
    ({
      payload,
      state: { currentProjectCategories },
    }: PayloadAndState<ProjectCategory, ProjectCategoriesState>) => ({
      currentProjectCategories: arraySort<ProjectCategory>(
        [
          ...currentProjectCategories.filter(
            (projectCategory: ProjectCategory) =>
              projectCategory.generatedId !== payload.generatedId,
          ),
          payload,
        ],
        'ASC',
        'projectCategory',
      ),
    }),
  ),
  // remove
  on(
    ProjectCategoriesActions.deleteProjectCategoriesItemAction,
    ({
      payload,
      state: { currentProjectCategories },
    }: PayloadAndState<KeyString, ProjectCategoriesState>) => ({
      currentProjectCategories: currentProjectCategories.filter(
        (projectCategory: ProjectCategory) =>
          projectCategory.generatedId !== payload.generatedId,
      ),
    }),
  ),
  // toggle table visible
  on(
    ProjectCategoriesActions.toggleProjectCategoriesTableAction,
    ({
      state: { projectCategoriesTableVisible },
    }: PayloadAndState<void, ProjectCategoriesState>) => ({
      projectCategoriesTableVisible: !projectCategoriesTableVisible,
    }),
  ),
  // set table visible
  on(
    ProjectCategoriesActions.setProjectCategoriesTableAction,
    ({
      payload: projectCategoriesTableVisible,
    }: PayloadAndState<boolean, ProjectCategoriesState>) => ({
      projectCategoriesTableVisible,
    }),
  ),
  // set table visible
  on(
    ProjectCategoriesActions.setCategoriesTableEditing,
    ({
      payload: isCategoriesTableEditing,
    }: PayloadAndState<boolean, ProjectCategoriesState>) => ({
      isCategoriesTableEditing,
    }),
  ),
);
