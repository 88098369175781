import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { AssociatedProjects } from '@shared/components';
import { ScopingSelectors } from '@store/selectors';

export const ScopingAssociatedProjects: FC<RouteComponentProps> = (
  props: RouteComponentProps,
) => {
  const { id, projects, siteOn } = useSelector(
    ScopingSelectors.getScopingSiteInfo,
  );

  return (
    <AssociatedProjects
      {...props}
      linkId={id}
      data={projects ?? []}
      siteOn={siteOn || false}
    />
  );
};
