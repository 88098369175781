import { accountingRoutes } from './pages/accounting/accounting.routing';
import { adminRoutes } from './pages/admin/admin.routing';
import { engineeringRoutes } from './pages/engineering/engineering.routing';
import { equipmentRoutes } from './pages/equipment/equipment.routing';
import { exportsRoutes } from './pages/exports/exports.routing';
import { leasingRoutes } from './pages/leasing/leasing.routing';
import { permittingRoutes } from './pages/permitting/permitting.routing';
import { projectRoutes } from './pages/project/project.routing';
import { projectManagementRoutes } from './pages/project-management/project-management.routing';
import { siteRoutes } from './pages/site/site.routing';
import { userManagementRoutes } from './pages/user-management/user-management.routing';

export const CLIENT_ROUTES = [
  siteRoutes,
  projectRoutes,
  engineeringRoutes,
  leasingRoutes,
  permittingRoutes,
  equipmentRoutes,
  projectManagementRoutes,
  accountingRoutes,
  adminRoutes,
  userManagementRoutes,
  exportsRoutes,
];
