import { AdminDriverManagementData } from '@models/interfaces';

export const DRIVER_MANAGEMENT_DEFAULT_VALUE: AdminDriverManagementData = {
  id: '',
  driverId: '',
  driverName: '',
  driverNotes: '',
  workflow: [],
  includedInBundlePricing: false,
};
