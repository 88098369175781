import {
  ColumnSettings,
  FiltersSetDataFilter,
  OptionProps,
  ProcessEnvMap as KeyString,
} from '@models/interfaces';
import { getSetFieldsOptions } from '../../filters/models';

export const getNewColumnsSettings = (
  defaultColumns: ColumnSettings[],
  filterFields: FiltersSetDataFilter,
  paceTasksOptions: OptionProps[] = [],
): ColumnSettings[] => {
  const fieldsNames = [...getSetFieldsOptions(), ...paceTasksOptions].reduce(
    (acc: KeyString, { value, viewValue }: OptionProps) => ({
      ...acc,
      [value]: viewValue as string,
    }),
    {},
  );

  return [
    ...defaultColumns.filter(
      (defaultColumn: ColumnSettings) => !defaultColumn.configured,
    ),
    ...Object.keys(filterFields)
      .filter((key: string) =>
        defaultColumns.every(
          (defaultColumn: ColumnSettings) =>
            defaultColumn.title !== fieldsNames[key],
        ),
      )
      .map((key: string) => ({
        title: fieldsNames[key],
        visible: !!filterFields[key]?.visible,
        configured: true,
      })),
  ];
};
