import { DropDownSelectorValues } from 'providence-types';
import { createSelector } from 'reselect';
import { getEnumKeyByEnumValue } from '@core/utils/methods';
import { DDVSelectorKey } from '@models/types';
import { AppState } from '@store/reducers';
import { DropdownValuesState } from '@store/reducers/admin/dropdown-values.reducer';

const DDVState = (state: AppState): DropdownValuesState =>
  state.admin.dropdownValues;

export namespace DDVSelectors {
  export const getDDVValuesByType = (
    valueKey: DropDownSelectorValues,
    likeOptionsProps: boolean = true,
  ) =>
    createSelector([DDVState], (state: DropdownValuesState) => {
      const key = getEnumKeyByEnumValue(
        DropDownSelectorValues,
        valueKey,
      ) as DDVSelectorKey;

      return state[likeOptionsProps ? 'dropdownsOptions' : 'dropdownValues'][
        key
      ];
    });

  export const getDDVOptions = createSelector(
    [DDVState],
    ({ dropdownsOptions }: DropdownValuesState) => dropdownsOptions,
  );

  export const getDDValues = createSelector(
    [DDVState],
    ({ dropdownValues }: DropdownValuesState) => dropdownValues,
  );

  export const getDropdownValuesOptionsAndDropdownValues = createSelector(
    [DDVState],
    ({ dropdownValuesOptions, dropdownValues }: DropdownValuesState) => ({
      dropdownValuesOptions,
      dropdownValues,
    }),
  );
}
