import { MainPaceTask } from 'providence-types';
import {
  equipmentDuplicatesCombiner,
  getEquipmentTotalsFromScoping,
  getProcessedEquipments,
  sortEquipments,
  transformFields,
} from '@core/utils/methods';
import { browseReducer } from '@core/utils/methods/browse-sorter-reducer';
import { createReducer, on } from '@core/utils/store';
import {
  getOtherEquipmentInfo,
  getScopingSectorsInfo,
  SCOPING_INFO_TRANSFORM_FIELDS,
  SCOPING_PROJECT_INFO_TRANSFORM_FIELDS,
  SCOPING_SECTORS,
} from '@models/constants';
import { ModalMainTypes } from '@models/enums';
import {
  AssociatedProject,
  EngineeringWorkflowUpdateData,
  EquipmentModal,
  IdAndValue,
  LeasingInformation,
  OtherEquipmentData,
  PaginatedResponse,
  PayloadAndState,
  ProjectCategory,
  ProjectDetails,
  ProjectPaceTask,
  ScopingBrowse,
  ScopingDetails,
  ScopingEquipmentData,
  ScopingInfo,
  ScopingSummaryData,
  SectorEquipment,
  SectorEquipmentType,
  SiteAuditInformation,
  SiteResponse,
} from '@models/interfaces';
import {
  DateString,
  LeasingEquipmentData,
  SectorDataState,
} from '@models/types';
import { ScopingActions } from '@store/actions';

type ScopingProjectInfo = Omit<
  ProjectDetails,
  'id' | 'site' | 'paceTasks' | 'categories'
>;

export interface EngineeringScopingState {
  scopingBrowseData: PaginatedResponse<ScopingBrowse>;
  currentProjectId: string;
  currentModalType: ModalMainTypes;
  scopingSiteInfo: SiteResponse;
  projectType: IdAndValue;
  scopingProjectInfo: ScopingProjectInfo;
  scopingLeasingInfo: LeasingInformation;
  scopingInfo: Omit<ScopingInfo, 'otherEquipment' | 'scopingSectors'>;
  scopingSiteAuditData: SiteAuditInformation;
  scopingEngineeringData: EngineeringWorkflowUpdateData;
  scheduledScopingDate?: DateString;
  mountMappingOrdered?: DateString;
  mountMappingReceived?: DateString;
  mountAnalysisOrdered?: DateString;
  failingMountAnalysisReceived?: DateString;
  passingMountAnalysisReceived?: DateString;
  prelimCDsOrdered?: DateString;
  prelimCDsReceived?: DateString;
  finalCDsReceived?: DateString;
  otherEquipment: OtherEquipmentData[];
  scopingSectors: SectorDataState;
  isFetching: boolean;
  errors: boolean;
  equipmentTypeNames: string[];
  leasingEquipmentData: LeasingEquipmentData;
}

const initialState: EngineeringScopingState = {
  scopingBrowseData: { items: [], total: 0 },
  currentProjectId: '',
  currentModalType: ModalMainTypes.Add,
  scopingSiteInfo: {} as SiteResponse,
  scopingProjectInfo: {} as ScopingProjectInfo,
  projectType: {} as IdAndValue,
  scopingLeasingInfo: {} as LeasingInformation,
  scopingInfo: {} as Omit<ScopingInfo, 'otherEquipment' | 'scopingSectors'>,
  scopingSiteAuditData: {} as SiteAuditInformation,
  scopingEngineeringData: {
    id: '',
    regulatoryCompliance: {},
    RFDSPhase1: {},
  } as EngineeringWorkflowUpdateData,
  scheduledScopingDate: undefined,
  mountMappingOrdered: undefined,
  mountMappingReceived: undefined,
  mountAnalysisOrdered: undefined,
  failingMountAnalysisReceived: undefined,
  passingMountAnalysisReceived: undefined,
  prelimCDsOrdered: undefined,
  prelimCDsReceived: undefined,
  finalCDsReceived: undefined,
  otherEquipment: [],
  scopingSectors: SCOPING_SECTORS,
  isFetching: false,
  errors: false,
  equipmentTypeNames: [],
  leasingEquipmentData: {
    priorTableData: [],
    curPriorTableData: [],
    scopingTableData: [],
    finalTableData: [],
    curFinalTableData: [],
    scopingNotes: null,
    priorLeasingNotes: null,
    finalLeasingNotes: null,
  },
};

const equipmentTypeNames = new Set<string>();

export const reducer = createReducer(
  initialState,
  // GET SCOPING BROWSE DATA
  on(
    ScopingActions.getScopingBrowseDataAction,
    browseReducer('scopingBrowseData'),
  ),
  // UPDATE CURRENT MODAL TYPE
  on(
    ScopingActions.updateCurrentModalTypeAction,
    ({
      payload: currentModalType,
    }: PayloadAndState<ModalMainTypes, EngineeringScopingState>) => ({
      currentModalType,
    }),
  ),
  // UPDATE SCOPING BROWSE DATA
  on(
    ScopingActions.updateScopingBrowseDataAction,
    ({
      payload: scopingBrowseData,
    }: PayloadAndState<
      PaginatedResponse<ScopingBrowse>,
      EngineeringScopingState
    >) => ({
      scopingBrowseData,
    }),
  ),
  // GET SCOPING DETAILS
  on(
    ScopingActions.getScopingDetailsAction,
    ({
      payload: {
        site,
        id,
        leasing: scopingLeasingInfo,
        siteAudit: scopingSiteAuditData,
        paceTasks,
        scoping: scopingPayload,
        engineering: engineeringPayload,
        ...projectData
      },
    }: PayloadAndState<
      Omit<ScopingDetails, 'categories' | 'RAD'>,
      EngineeringScopingState
    >) => {
      const engineeringData = engineeringPayload ?? {};
      const leasing = scopingLeasingInfo ?? {};
      const scopingData = scopingPayload ?? {};
      const { otherEquipment, scopingSectors, ...restScopingData } =
        scopingData;
      const { RFDSPhase1, ...restEngineeringData } = engineeringData;

      // need to execute getProcessedEquipments before getOtherEquipmentInfo and getScopingSectorsInfo
      const leasingEquipmentData = {
        priorTableData: getProcessedEquipments(
          equipmentTypeNames,
          leasing?.leasingEquipment,
          'PriorEquipment',
        ),
        curPriorTableData: getProcessedEquipments(
          equipmentTypeNames,
          leasing?.leasingEquipment,
          'PriorEquipment',
        ),
        finalTableData: getProcessedEquipments(
          equipmentTypeNames,
          leasing?.leasingEquipmentFinal,
          'FinalEquipment',
        ),
        curFinalTableData: getProcessedEquipments(
          equipmentTypeNames,
          leasing?.leasingEquipmentFinal,
          'FinalEquipment',
        ),
        scopingTableData: sortEquipments(
          getEquipmentTotalsFromScoping(
            equipmentTypeNames,
            scopingData,
            'ScopingEquipment',
          ),
        ),
        scopingNotes: restScopingData?.scopingTowerNotes,
        priorLeasingNotes: leasing?.priorLeasingNotes,
        finalLeasingNotes: leasing?.finalLeasingNotes,
      };

      const otherEquipmentInfo = getOtherEquipmentInfo(otherEquipment ?? []);

      const scopingSectorsInfo = getScopingSectorsInfo(scopingSectors ?? []);

      const associatedProjectsSorter = (
        payload: SiteResponse,
      ): SiteResponse => ({
        ...payload,
        projects: payload.projects.map((project: AssociatedProject) => {
          project.categories?.sort(
            (a: ProjectCategory, b: ProjectCategory) =>
              a?.projectRanking?.value?.localeCompare(
                b?.projectRanking?.value ?? 'Secondary',
              ) ?? 0,
          );

          return project;
        }),
      });

      return {
        scopingSiteInfo: transformFields<SiteResponse>(
          associatedProjectsSorter(site),
          ['towerOwner', 'riskCategory'],
        ),
        scopingProjectInfo: transformFields<ScopingProjectInfo>(
          projectData,
          SCOPING_PROJECT_INFO_TRANSFORM_FIELDS,
        ),
        projectType: projectData.projectType,
        scopingInfo: transformFields<
          Omit<ScopingInfo, 'otherEquipment' | 'scopingSectors'>
        >(restScopingData, SCOPING_INFO_TRANSFORM_FIELDS),
        scheduledScopingDate: paceTasks.find(
          (paceTask: ProjectPaceTask) =>
            paceTask?.id === MainPaceTask.ScopingComplete,
        )?.projectPaceTaskData?.forecastedDate,
        mountMappingOrdered: paceTasks.find(
          (paceTask: ProjectPaceTask) =>
            paceTask?.id === MainPaceTask.MountMappingOrdered,
        )?.projectPaceTaskData?.actualDate,
        mountMappingReceived: paceTasks.find(
          (paceTask: ProjectPaceTask) =>
            paceTask?.id === MainPaceTask.MountMappingReceived,
        )?.projectPaceTaskData?.actualDate,
        mountAnalysisOrdered: paceTasks.find(
          (paceTask: ProjectPaceTask) =>
            paceTask?.id === MainPaceTask.MountAnalysisOrdered,
        )?.projectPaceTaskData?.actualDate,
        failingMountAnalysisReceived: paceTasks.find(
          (paceTask: ProjectPaceTask) =>
            paceTask?.id === MainPaceTask.FailingMountAnalysisReceived,
        )?.projectPaceTaskData?.actualDate,
        passingMountAnalysisReceived: paceTasks.find(
          (paceTask: ProjectPaceTask) =>
            paceTask?.id === MainPaceTask.PassingMountAnalysisReceived,
        )?.projectPaceTaskData?.actualDate,
        prelimCDsOrdered: paceTasks.find(
          (paceTask: ProjectPaceTask) =>
            paceTask?.id === MainPaceTask.PrelimCDsOrdered,
        )?.projectPaceTaskData?.actualDate,
        prelimCDsReceived: paceTasks.find(
          (paceTask: ProjectPaceTask) =>
            paceTask?.id === MainPaceTask.PrelimCDsReceived,
        )?.projectPaceTaskData?.actualDate,
        finalCDsReceived: paceTasks.find(
          (paceTask: ProjectPaceTask) =>
            paceTask?.id === MainPaceTask.FinalCDsReceived,
        )?.projectPaceTaskData?.actualDate,
        scopingEngineeringData: {
          ...restEngineeringData,
          RFDSPhase1: transformFields(RFDSPhase1, ['RFDSStatus']),
        },
        scopingSiteAuditData,
        scopingLeasingInfo,
        currentProjectId: id,
        otherEquipment: otherEquipmentInfo?.otherEquipment || [],
        equipmentTypeNames: [
          ...(otherEquipmentInfo?.equipmentTypeNames || []),
          ...(scopingSectorsInfo?.equipmentTypeNames || []),
          ...equipmentTypeNames,
        ],
        scopingSectors: scopingSectorsInfo?.scopingSectors,
        leasingEquipmentData,
      };
    },
  ),
  // UPDATE SCOPING SUMMARY
  on(
    ScopingActions.updateScopingSummaryAction,
    ({
      payload: {
        projectInformation: projectInfoPayload,
        preScopingAssessment: preScopingPayload,
      },
      state,
    }: PayloadAndState<ScopingSummaryData, EngineeringScopingState>) => {
      const projectData = projectInfoPayload ?? {};
      const scopingData = preScopingPayload ?? {};
      const { AEVendor, AEMountVendor, ...restProjectData } = projectData;
      const {
        scheduledScopingDate,
        mountMappingOrdered,
        mountMappingReceived,
        mountAnalysisOrdered,
        failingMountAnalysisReceived,
        passingMountAnalysisReceived,
        prelimCDsOrdered,
        prelimCDsReceived,
        finalCDsReceived,
        ...restScopingData
      } = scopingData;

      return {
        scopingProjectInfo: {
          ...state.scopingProjectInfo,
          ...restProjectData,
        },
        scopingInfo: {
          ...state.scopingInfo,
          ...restScopingData,
          AEVendor,
          AEMountVendor,
        },
        scheduledScopingDate,
        mountMappingOrdered,
        mountMappingReceived,
        mountAnalysisOrdered,
        failingMountAnalysisReceived,
        passingMountAnalysisReceived,
        prelimCDsOrdered,
        prelimCDsReceived,
        finalCDsReceived,
      };
    },
  ),
  // UPDATE SCOPING EQUIPMENT
  on(
    ScopingActions.updateScopingEquipmentAction,
    ({
      payload: {
        scopingNotes: scopingNotesPayload,
        scopingInformation: scopingInfoPayload,
        otherEquipment,
        sectors: scopingSectors,
      },
      state,
    }: PayloadAndState<ScopingEquipmentData, EngineeringScopingState>) => {
      const scopingInfoData = scopingInfoPayload ?? {};
      const scopingNotesInfo = scopingNotesPayload ?? {};
      const {
        prelimRFDSReceived,
        microwaveToBeRemoved,
        craneRequired,
        craneHeight,
        mountModelReplacement,
      } = scopingInfoData;
      const { RFDSStatus, ...scopingNotes } = scopingNotesInfo;

      const sectorsEquipments = (
        Object.keys(scopingSectors) as Array<keyof SectorDataState>
      ).flatMap((sectorKey: keyof SectorDataState) =>
        scopingSectors[sectorKey].flatMap(
          (equipmentTypeItem: SectorEquipmentType) =>
            equipmentTypeItem.equipments
              .filter(
                (equipmentItem: SectorEquipment) => !!equipmentItem.equipment,
              )
              .map((equipmentItem: SectorEquipment) => {
                const { position, ...equipment } = equipmentItem;

                return {
                  ...equipment,
                  equipmentType: equipmentTypeItem.equipmentType,
                } as EquipmentModal;
              }),
        ),
      );

      return {
        scopingInfo: {
          ...state.scopingInfo,
          ...scopingNotes,
          ...(microwaveToBeRemoved !== undefined
            ? { microwaveToBeRemoved }
            : {}),
          ...(mountModelReplacement ? { mountModelReplacement } : {}),
        },
        scopingEngineeringData: {
          RFDSPhase1: {
            ...state.scopingEngineeringData.RFDSPhase1,
            ...(prelimRFDSReceived !== undefined ? { prelimRFDSReceived } : {}),
            ...(RFDSStatus ? { RFDSStatus } : {}),
          },
          regulatoryCompliance: {
            ...state.scopingEngineeringData.regulatoryCompliance,
            ...(craneRequired !== undefined ? { craneRequired } : {}),
            ...(craneHeight ? { craneHeight } : {}),
          },
        },
        leasingEquipmentData: {
          ...state.leasingEquipmentData,
          scopingTableData: equipmentDuplicatesCombiner([
            ...sectorsEquipments,
            ...(otherEquipment as EquipmentModal[]),
          ]),
        },
        otherEquipment,
        scopingSectors,
      };
    },
  ),
  // UPDATE SCOPING SECTORS
  on(
    ScopingActions.updateScopingSectorsAction,
    ({
      payload: scopingSectors,
    }: PayloadAndState<SectorDataState, EngineeringScopingState>) => ({
      scopingSectors,
    }),
  ),
);
