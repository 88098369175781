import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { FirebaseService } from './app/core/services';
import { Router } from './app/router';
import store from './app/store';

import 'styles/global/main.scss';

FirebaseService.get.init();

ReactDOM.render(
  <Provider store={store}>
    <Router />
  </Provider>,
  document.getElementById('root'),
);
