import nextId from 'react-id-generator';
import { distructAndDispatch } from '../../core/utils/methods/destruct-and-dispatch';
import { transformFields } from '../../core/utils/methods/transform-fields';
import { PROJECT_CATEGORY_TRANSFORM_FIELDS } from '../../models/constants/transform-fields';
import { ProjectCategory, ProjectDetails } from '../../models/interfaces';
import { ProjectCategoriesActions } from '../actions/project-categories.actions';

export namespace ProjectCategoriesEffects {
  export const setProjectCategories = distructAndDispatch<
    ProjectDetails,
    ProjectCategory[]
  >(
    'categories',
    ProjectCategoriesActions.setProjectCategoriesAction,
    (categories: ProjectCategory[]) =>
      categories?.map((category: ProjectCategory) =>
        transformFields<ProjectCategory>(
          { ...category, generatedId: nextId('PCCategory') },
          PROJECT_CATEGORY_TRANSFORM_FIELDS,
        ),
      ),
  );
}
