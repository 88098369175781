import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Tabs } from 'antd';
import { DirectMessageAreaKey } from 'providence-types';
import { HttpService } from '@core/services';
import { DirectMessageHttpService } from '@core/services/http';
import { Modal } from '@shared/modules';
import { DirectMessageSelectors } from '@store/selectors';
import { ReceivedDirectMessages, SendDirectMessages } from './components';
import { DirectMessageManagerProps } from './models';

import './styles.scss';

export const DirectMessageManager: FC<DirectMessageManagerProps> = ({
  visible,
  areaKey,
  onClose,
}: DirectMessageManagerProps) => {
  const directMessageService = HttpService.getHttpRequests(
    DirectMessageHttpService,
  );

  const isPMArea = useMemo(
    () =>
      [
        DirectMessageAreaKey.ProjectManagementSummary,
        DirectMessageAreaKey.ProjectManagementFinancial,
      ].includes(areaKey),
    [areaKey],
  );

  const isFetching = useSelector(DirectMessageSelectors.isFetching);
  const userList = useSelector(DirectMessageSelectors.getUserList);

  const [tab, setTab] = useState<string>('1');

  useEffect(() => {
    if (visible) {
      if (!isPMArea) {
        directMessageService.getDirectMessages(areaKey);
        directMessageService.getReceivedDirectMessages(areaKey);
        directMessageService.getUsersByArea(areaKey);

        return () => {
          setTab('1');
        };
      }
    }
  }, [areaKey, visible, isPMArea]);

  const changeTab = useCallback((activeKey: string) => setTab(activeKey), []);

  return (
    <Modal
      title="Direct Message Manager"
      className="prov-direct-message-manager"
      visible={visible}
      footer={[]}
      width="85vw"
      onCancel={onClose}
      maskClosable={false}
    >
      {isPMArea ? (
        <SendDirectMessages
          areaKey={areaKey}
          userList={userList}
          isPMArea={isPMArea}
          visible={visible}
          isFetching={isFetching}
          service={directMessageService}
        />
      ) : (
        <Tabs activeKey={tab} onChange={changeTab}>
          <Tabs.TabPane key="1" tab="Send">
            <SendDirectMessages
              areaKey={areaKey}
              userList={userList}
              isPMArea={isPMArea}
              visible={visible}
              isFetching={isFetching}
              service={directMessageService}
            />
          </Tabs.TabPane>
          <Tabs.TabPane key="2" tab="Received">
            <ReceivedDirectMessages
              isFetching={isFetching}
              userList={userList}
              service={directMessageService}
              visible={visible}
            />
          </Tabs.TabPane>
        </Tabs>
      )}
    </Modal>
  );
};
