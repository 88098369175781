import React, { FC, PropsWithChildren, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Form } from 'antd';
import { HttpService } from '@core/services';
import { DefaultTowerAgentHttpService } from '@core/services/http';
import { getFormData, isValidForm } from '@core/utils/methods';
import { ModalMainTypes } from '@models/enums';
import {
  AdminDefaultTowerAgentData,
  IdAndValue,
  TowerOwnersOptions,
} from '@models/interfaces';
import { AddAndEditModal } from '@shared/components';
import { Autocomplete } from '@shared/modules';
import { DefaultTowerAgentSelectors } from '@store/selectors';
import {
  DefaultTowerAgentEditData,
  DefaultTowerAgentModalProps,
} from './models';

import './styles.scss';

const { useForm } = Form;

export const DefaultTowerAgentModal: FC<DefaultTowerAgentModalProps> = ({
  formValue,
  visible,
  modalType,
  toggleModal,
}: PropsWithChildren<DefaultTowerAgentModalProps>) => {
  const [form] = useForm();

  const defaultTowerAgentService = useMemo(
    () => HttpService.getHttpRequests(DefaultTowerAgentHttpService),
    [],
  );

  const agentsOptions = useSelector(DefaultTowerAgentSelectors.getAgentOptions);
  const ownersOptions = useSelector(
    DefaultTowerAgentSelectors.getOwnersOptions,
  );

  const add = async (value: AdminDefaultTowerAgentData): Promise<void> => {
    if (await isValidForm(form)) {
      await defaultTowerAgentService.addDefaultTowerAgentData(value);
      toggleModal(modalType);
    }
  };

  const edit = async (value: DefaultTowerAgentEditData): Promise<void> => {
    if (await isValidForm(form)) {
      await defaultTowerAgentService.updateDefaultTowerAgentData({
        defaultTowerAgentId: value.id,
        towerOwners: value.towerOwners,
      });
      toggleModal(modalType);
    }
  };

  useEffect(() => {
    if (visible) {
      form.setFieldsValue({
        providenceAgent: formValue.providenceAgent.id,
        towerOwners: formValue.towerOwners.map(
          (towerOwner: IdAndValue) => towerOwner.id,
        ),
      });
    }
  }, [visible, formValue, form]);

  const getProcessedOwnersOptions = (): TowerOwnersOptions[] => {
    const recordTowerOwnersIds = formValue.towerOwners.map(
      (towerOwner: IdAndValue) => towerOwner.id,
    );

    const notAlreadySelectedOptions = ownersOptions.filter(
      (option: TowerOwnersOptions) =>
        recordTowerOwnersIds.indexOf(option.value as string) < 0,
    );

    return ownersOptions.map((option: TowerOwnersOptions) => ({
      ...option,
      disabled:
        notAlreadySelectedOptions.some(
          (misItem: TowerOwnersOptions) => misItem.value === option.value,
        ) && option.isAssigned,
    }));
  };

  return (
    <AddAndEditModal
      onOk={
        modalType === ModalMainTypes.Add
          ? (): Promise<void> => add(getFormData(formValue, form))
          : (): Promise<void> =>
              edit(
                getFormData<unknown>(
                  formValue,
                  form,
                ) as DefaultTowerAgentEditData,
              )
      }
      onCancel={(): void => {
        form.resetFields();
        toggleModal(modalType);
      }}
      visible={visible}
      type={modalType}
      title={
        modalType === ModalMainTypes.Add
          ? 'Assign Providence Agent to Tower Owner'
          : 'Edit Assignment'
      }
      okText="Save"
      cancelText="Cancel"
      className="prov-default-tower-agent-modal"
      formProps={{
        wrapperCol: { span: 15 },
        labelCol: { span: 8 },
        form,
        initialValues: {
          providenceAgent: formValue.providenceAgent.id,
          towerOwners: formValue.towerOwners.map(
            (towerOwner: IdAndValue) => towerOwner.id,
          ),
        },
      }}
    >
      <Autocomplete
        id="providenceAgent"
        label="Providence Agent"
        options={agentsOptions}
        formItemProps={{
          rules: [{ required: true, message: 'Providence Agent is required!' }],
        }}
      />
      <Autocomplete
        id="towerOwners"
        label="Tower Owner"
        options={getProcessedOwnersOptions()}
        elementProps={{
          mode: 'multiple',
        }}
        formItemProps={{
          rules: [{ required: true, message: 'Tower Owner is required!' }],
        }}
      />
    </AddAndEditModal>
  );
};
