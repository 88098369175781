import React, { Dispatch, FC, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Col, Form, Row } from 'antd';
import { SelectValue } from 'antd/lib/select';
import Info from 'assets/icons/info.png';
import { HttpService } from '@core/services';
import { DropdownHttpService } from '@core/services/http';
import { toggleModal } from '@core/utils/methods';
import { ModalMainTypes } from '@models/enums';
import { AdminDropdownValuesData, OptionProps } from '@models/interfaces';
import { DDVSelectorKey, ScrollToInitRef } from '@models/types';
import { DeleteModal, VirtualTable } from '@shared/components';
import { Autocomplete, PrimaryButton } from '@shared/modules';
import { DDVSelectors } from '@store/selectors';
import {
  DROPDOWN_VALUES_DEFAULT_VALUE,
  getAdminDropdownValuesColumns,
} from './models/constants';
import { DropdownValuesModal } from './components';

import './styles.scss';

export const DropdownValues: FC = () => {
  const virtualTableRef = useRef<ScrollToInitRef>(null);

  const { dropdownValuesOptions, dropdownValues } = useSelector(
    DDVSelectors.getDropdownValuesOptionsAndDropdownValues,
  );

  const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false);
  const [isAddEditModalVisible, setAddEditModalVisible] =
    useState<boolean>(false);
  const [currentModalType, setCurrentModalType] = useState<ModalMainTypes>(
    ModalMainTypes.Add,
  );
  const [selectorValue, setSelectorValue] = useState<SelectValue>('');
  const [selectedItem, setSelectedItem] = useState<AdminDropdownValuesData>(
    DROPDOWN_VALUES_DEFAULT_VALUE,
  );
  const [tableValues, setTableValues] = useState<AdminDropdownValuesData[]>([]);

  const toggleAddAndEditModal = (type: ModalMainTypes): void => {
    setCurrentModalType(type);
    setAddEditModalVisible(!isAddEditModalVisible);

    if (isAddEditModalVisible) {
      setSelectedItem({ ...DROPDOWN_VALUES_DEFAULT_VALUE });
    }
  };

  useEffect(() => {
    if (selectorValue) {
      setTableValues(dropdownValues[selectorValue as DDVSelectorKey]);
    }
  }, [selectorValue, dropdownValues]);

  const selectCurrentDropdownValues = (
    currentDropdownValues: AdminDropdownValuesData,
    setter: Dispatch<boolean>,
    prevState: boolean,
  ): void => {
    setSelectedItem(currentDropdownValues);
    setter(!prevState);
  };

  const remove = async (): Promise<void> => {
    await HttpService.getHttpRequests(DropdownHttpService).deleteDropdownValue(
      selectedItem.id,
      selectorValue as string,
    );

    setSelectedItem({ ...DROPDOWN_VALUES_DEFAULT_VALUE });
    setDeleteModalVisible(false);
  };

  return (
    <div className="prov-admin-dropdown-values">
      <Row className="prov-admin-dropdown-values__table-handler">
        <Col span="7">
          <Form>
            <Autocomplete
              label={
                <span className="select-text">
                  <img src={Info} alt="Edit row" className="select-img" />
                  Please select a drop-down value to edit
                </span>
              }
              id="dropdown-values-type"
              options={dropdownValuesOptions.filter(
                (option: OptionProps) => option.value !== 'Jurisdiction',
              )}
              elementProps={{
                value: selectorValue,
                onChange: (value: SelectValue): void => {
                  setSelectorValue(value);
                  virtualTableRef?.current?.scrollToInit();
                },
              }}
            />
          </Form>
        </Col>
      </Row>
      <VirtualTable
        ref={virtualTableRef}
        dataSource={tableValues}
        columns={getAdminDropdownValuesColumns(
          selectorValue as string,
          (adminDropdownValuesReacord: AdminDropdownValuesData): void =>
            selectCurrentDropdownValues(
              adminDropdownValuesReacord,
              () => toggleAddAndEditModal(ModalMainTypes.Edit),
              isAddEditModalVisible,
            ),
          (adminDropdownValuesReacord: AdminDropdownValuesData): void =>
            selectCurrentDropdownValues(
              adminDropdownValuesReacord,
              setDeleteModalVisible,
              deleteModalVisible,
            ),
        )}
        maxViewRow={10}
      />
      <PrimaryButton
        icon="+"
        title="Add"
        className="prov-admin-dropdown-values__add-button"
        onClick={(): void => toggleAddAndEditModal(ModalMainTypes.Add)}
        disabled={!selectorValue}
      />
      <DropdownValuesModal
        visible={isAddEditModalVisible}
        modalType={currentModalType}
        toggleModal={toggleAddAndEditModal}
        itemValue={selectedItem}
        selectorValue={selectorValue}
      />
      <DeleteModal
        onOk={remove}
        onCancel={toggleModal(setDeleteModalVisible)}
        visible={deleteModalVisible}
      />
    </div>
  );
};
