import React, { ReactNode } from 'react';
import { IRuleDateFields, RuleOperator, RuleValueType } from 'providence-types';
import { getCurrentDateType } from '@core/utils/methods';
import { actionButtons } from '@models/constants';
import { OptionProps, Rule } from '@models/interfaces';
import { ColumnsFunc } from '@models/types';
import {
  getCurrentNotifyType,
  getValueForUsersOrRolesColumn,
} from '@notifications/helpers';
import { VirtualTableColumn } from '@shared/components';

export const getRulesTableColumns = (
  ruleFiles: IRuleDateFields[],
  usersList: OptionProps[],
  towerOwners: OptionProps[],
  editFunc: ColumnsFunc<Rule>,
  deleteFunc: ColumnsFunc<Rule>,
): Array<VirtualTableColumn<Rule>> => {
  const fields = ruleFiles.reduce(
    (acc: OptionProps[], v: IRuleDateFields) => [
      ...new Set([...acc, ...v.options]),
    ],
    [],
  );

  return [
    {
      title: 'Field',
      key: 'fieldName',
      width: 200,
      render: (rule: Rule): ReactNode => {
        let result = '';
        const found = fields.find(
          ({ value }: OptionProps) => value === rule.fieldName,
        );

        if (found) {
          result = rule.dateType
            ? `${found.viewValue} - ${getCurrentDateType(rule.dateType)}`
            : (found.viewValue as string);
        }

        return result;
      },
    },
    {
      title: 'Operator',
      key: 'operator',
      width: 150,
      dataIndex: 'operator',
      render: (operator: RuleOperator): ReactNode => (
        <span className="cell cell--capitalize">{operator}</span>
      ),
    },
    {
      title: 'Value Type',
      key: 'valueType',
      width: 150,
      dataIndex: 'valueType',
      render: (valueType: RuleValueType): ReactNode => (
        <span className="cell cell--capitalize">{valueType}</span>
      ),
    },
    {
      title: 'Value',
      key: 'value',
      width: 150,
      render: ({ value, daysCount, valueType }: Rule): ReactNode =>
        valueType === RuleValueType.DayCount ? (
          `${daysCount} days`
        ) : (
          <span className="cell cell--capitalize">{value}</span>
        ),
    },
    {
      title: 'Notify Type',
      key: 'notifyType',
      width: 150,
      dataIndex: 'notifyType',
      render: getCurrentNotifyType,
    },
    {
      title: 'Users/Roles',
      key: 'usersOrRoles',
      width: 250,
      render: (rule: Rule): ReactNode => (
        <span className="cell cell--pre-wrap">
          {getValueForUsersOrRolesColumn(rule, usersList, towerOwners)}
        </span>
      ),
    },
    {
      title: 'Message',
      key: 'message',
      dataIndex: 'message',
      width: 150,
    },
    {
      title: 'Redirect link',
      key: 'redirectLink',
      dataIndex: 'redirectLinkKey',
      width: 200,
    },
    actionButtons({ editFunc, deleteFunc }) as VirtualTableColumn,
  ];
};
