import { createSelector } from 'reselect';
import { AppState } from '../reducers';
import { DirectMessageState } from '../reducers/direct-message.reducer';

const directMessageState = (state: AppState): DirectMessageState =>
  state.directMessage;

export namespace DirectMessageSelectors {
  export const getUserList = createSelector(
    [directMessageState],
    (state: DirectMessageState) => state.userList,
  );

  export const getDirectMessages = createSelector(
    [directMessageState],
    (state: DirectMessageState) => state.directMessages,
  );

  export const getReceivedDirectMessagesForArea = createSelector(
    [directMessageState],
    (state: DirectMessageState) => state.receivedDirectMessages,
  );

  export const isFetching = createSelector(
    [directMessageState],
    (state: DirectMessageState) => state.isFetching,
  );
}
