import React, { FC, PropsWithChildren, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Form, Row } from 'antd';
import { Store } from 'antd/es/form/interface';
import classNames from 'classnames';
import { HttpService, ObjectComparatorService } from '@core/services';
import { ScopingHttpService } from '@core/services/http';
import { useDidUpdateEffect, useSaveChanged } from '@core/utils/hooks';
import {
  formDiffs,
  isValidForm,
  momentizeObjectDates,
} from '@core/utils/methods';
import { EditableComponentProps, ScopingInfo } from '@models/interfaces';
import { ScopingSummaryProjectInfo } from '@models/types';
import { NotificationsLoader } from '@shared/components';
import { PrimaryButton } from '@shared/modules';
import { CommonActions } from '@store/actions';
import { ProjectCategoriesSelectors, ScopingSelectors } from '@store/selectors';
import {
  AzimuthInformation,
  LeasingInformation,
  PreScopingAssessment,
  ProjectInformation,
  RADInformation,
  SiteInformation,
} from './components';
import { TRANSFORM_SCOPING_DATES } from './models';

import './styles.scss';

const { useForm } = Form;

export const Summary: FC<EditableComponentProps> = ({
  isEditing,
  toggleEditing,
  permissions: { projectInformationFields, preScopingAssessmentFields },
  isActive,
}: PropsWithChildren<EditableComponentProps>) => {
  const dispatch = useDispatch();

  const [projectInfoForm] = useForm();
  const [preScopingForm] = useForm();

  const [workflowForm, setWorkflowForm] = useState<Array<Store>>([]);
  const [initialForm, setInitialForm] = useState<Array<Store>>([]);

  const arraysCompare = ObjectComparatorService.arraysCompare(
    initialForm,
    workflowForm,
  );

  useEffect(
    () => (): void => {
      dispatch(CommonActions.setHasUnsubmittedData.done(false));
    },

    [],
  );

  useDidUpdateEffect(() => {
    dispatch(CommonActions.setHasUnsubmittedData.done(!arraysCompare));
  }, [arraysCompare]);

  const {
    scopingSiteInfo,
    scopingProjectInfo,
    scopingLeasingInfo,
    currentProjectId,
    scopingInfo,
    scopingSiteAuditData,
    scheduledScopingDate,
  } = useSelector(ScopingSelectors.getScopingSummaryData);
  const projectCategoriesTableVisible = useSelector(
    ProjectCategoriesSelectors.getProjectCategoriesTableVisible,
  );
  const scopingFetching = useSelector(ScopingSelectors.isFetching);

  const onSubmit = async (): Promise<void> => {
    if (
      (await isValidForm(projectInfoForm)) &&
      (await isValidForm(preScopingForm))
    ) {
      try {
        const [projectInformationInitial, preScopingInitial] = initialForm;
        const [projectInformation, preScoping] = workflowForm;

        await HttpService.getHttpRequests(
          ScopingHttpService,
        ).updateScopingSummary(currentProjectId, {
          projectInformation: formDiffs(
            projectInformationInitial,
            projectInformation,
          ) as ScopingSummaryProjectInfo,
          preScopingAssessment: formDiffs<ScopingInfo>(
            momentizeObjectDates(
              preScopingInitial,
              TRANSFORM_SCOPING_DATES,
              true,
            ) as ScopingInfo,
            momentizeObjectDates<ScopingInfo>(
              preScoping as ScopingInfo,
              TRANSFORM_SCOPING_DATES,
              true,
            ),
          ),
        });

        setInitialForm(workflowForm);

        toggleEditing?.();

        NotificationsLoader.notificationSuccess(
          `Information has been updated!`,
        );
      } catch (e) {
        console.error(e);
      }
    }
  };

  const onCancel = (): void => {
    projectInfoForm.setFieldsValue({ ...scopingProjectInfo, ...scopingInfo });
    preScopingForm.setFieldsValue(
      momentizeObjectDates(
        {
          ...scopingInfo,
          ...scopingSiteAuditData,
          scheduledScopingDate,
        },
        TRANSFORM_SCOPING_DATES,
      ),
    );

    setWorkflowForm(initialForm);

    toggleEditing?.();
  };

  const onValuesChange = (): void => {
    if (Object.keys(projectInfoForm.getFieldsValue()).length) {
      const data = [
        projectInfoForm.getFieldsValue(),
        preScopingForm.getFieldsValue(),
      ];

      setWorkflowForm(data);

      if (!Object.keys(initialForm).length) {
        setInitialForm(data);
      }
    }
  };

  useDidUpdateEffect(() => {
    onValuesChange();
  }, [isActive, isEditing]);

  useSaveChanged(isEditing, onSubmit, onCancel);

  return (
    <div className="prov-scoping-summary">
      <Row
        justify="space-between"
        className={classNames('tabs-wrap', {
          'tabs-wrap_with-actions': isEditing,
          'categories-table-open': projectCategoriesTableVisible,
        })}
      >
        <Col span="10">
          <SiteInformation
            className="prov-scoping-summary__information"
            data={scopingSiteInfo}
            expectedId={scopingSiteInfo?.id}
          />
          <ProjectInformation
            className="prov-scoping-summary__information"
            data={{ ...scopingProjectInfo, ...scopingInfo }}
            expectedId={currentProjectId}
            isEditing={isEditing}
            permissions={projectInformationFields}
            form={projectInfoForm}
            onValuesChange={onValuesChange}
          />
          <LeasingInformation
            className="prov-scoping-summary__information"
            data={scopingLeasingInfo}
            expectedId={currentProjectId}
          />
        </Col>
        <Col span="13">
          <PreScopingAssessment
            className="prov-scoping-summary__information"
            isEditing={isEditing}
            permissions={preScopingAssessmentFields}
            form={preScopingForm}
            data={{
              ...scopingInfo,
              ...scopingSiteAuditData,
              scheduledScopingDate,
            }}
            onValuesChange={onValuesChange}
          />
          <RADInformation className="prov-scoping-summary__information rad-information" />
          <AzimuthInformation
            className="prov-scoping-summary__information"
            data={scopingLeasingInfo?.leaseAudit}
          />
        </Col>
      </Row>
      {isEditing && (
        <Row className="prov-site-information__btn-wrap">
          <div>
            <PrimaryButton
              htmlType="submit"
              title="Submit"
              disabled={scopingFetching}
              onClick={onSubmit}
            />
            <PrimaryButton title="Cancel" type="default" onClick={onCancel} />
          </div>
        </Row>
      )}
    </div>
  );
};
