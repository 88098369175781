import { StoredRequest } from '@core/utils/store/stored-request-decorator';
import { UserManagementData } from '@models/interfaces';
import { UserManagementAllOptions } from '@models/types';
import { UserManagementActions } from '@store/actions';
import { CommonHttpService } from './common-http.service';

export class UserManagementHttpService extends CommonHttpService {
  @StoredRequest(UserManagementActions.getAllUsersAction)
  getUsers(params: UserManagementAllOptions): Promise<UserManagementData[]> {
    return this.http.callApi.get(
      `${this.apiUrl}/user-management/browse`,
      null,
      { params },
    );
  }

  @StoredRequest(UserManagementActions.createUserAction)
  createUsers(
    {
      email,
      firstName,
      isActive,
      lastName,
      roles,
      engineeringManagerType,
    }: UserManagementData,
    currentOptions: UserManagementAllOptions,
  ): Promise<UserManagementData> {
    return this.http.callApi.post(
      `${this.apiUrl}/user-management`,
      { email, firstName, isActive, lastName, roles, engineeringManagerType },
      { params: currentOptions },
    );
  }

  @StoredRequest(UserManagementActions.deleteUserAction)
  deleteUser(
    id: string,
    currentOptions: UserManagementAllOptions,
  ): Promise<UserManagementData> {
    return this.http.callApi.delete(`${this.apiUrl}/user-management`, null, {
      params: { ...currentOptions, id },
    });
  }

  getUserValidation(id: string, email: string): Promise<boolean> {
    return this.http.callApi.get(
      `${this.apiUrl}/user-management/validation`,
      null,
      {
        params: { id, email },
      },
    );
  }

  resetUserPassword(id: string): Promise<null> {
    return this.http.callApi.patch(
      `${this.apiUrl}/user-management/reset-password`,
      null,
      {
        params: { id },
      },
    );
  }

  @StoredRequest(UserManagementActions.updateUserAction)
  editUser(
    id: string,
    {
      firstName,
      isActive,
      lastName,
      email,
      roles,
      engineeringManagerType,
    }: UserManagementData,
  ): Promise<UserManagementData> {
    return this.http.callApi.patch(
      `${this.apiUrl}/user-management`,
      { firstName, isActive, lastName, roles, engineeringManagerType, email },
      {
        params: { id },
      },
    );
  }
}
