import React, { FC, PropsWithChildren } from 'react';
import { DropDownSelectorValues } from 'providence-types';
import {
  getDDVByIdOrUndefined,
  getStateByIdOrUndefined,
} from '@core/utils/methods';
import {
  TableContainer as ContentContainer,
  ViewElement,
} from '@shared/components';
import { Coordinate, PrimaryButton } from '@shared/modules';
import { SiteInfoProps } from '../../models';
import { getCountyByIdOrUndefined } from './models';

export const SiteInformation: FC<SiteInfoProps> = ({
  siteData,
}: PropsWithChildren<SiteInfoProps>) => (
  <ContentContainer header="Site Information">
    <div className="information">
      <ViewElement
        additionClassName="left"
        label="FA Location Number"
        value={siteData.FALocationNumber}
      />
      <ViewElement
        additionClassName="right"
        label="Street Address"
        value={siteData.streetAddress}
      />
      <ViewElement
        additionClassName="left"
        label="Site Number"
        value={siteData.siteNumber}
      />
      <ViewElement
        additionClassName="right"
        label="City"
        value={siteData.city}
      />
      <ViewElement
        additionClassName="left"
        label="Legacy Site ID"
        value={siteData.legacySiteId}
      />
      <ViewElement
        additionClassName="right"
        label="State"
        value={getStateByIdOrUndefined(siteData.state)?.name}
      />
      <ViewElement
        additionClassName="left"
        label="Tower Owner Site ID"
        value={siteData.towerOwnerSiteId}
      />
      <ViewElement additionClassName="right" label="Zip" value={siteData.zip} />
      <ViewElement
        additionClassName="left"
        label="Parcel ID"
        value={siteData.parcelId}
      />
      <ViewElement
        additionClassName="right"
        label="County"
        value={getCountyByIdOrUndefined(siteData.county)?.name}
      />
      <ViewElement
        additionClassName="left"
        label="Parcel Owner"
        value={siteData.parcelOwner}
      />
      <ViewElement
        additionClassName="right"
        label="Jurisdiction"
        value={siteData.jurisdiction?.jurisdictionName}
      />
      <ViewElement
        additionClassName="left"
        label="Tower Type"
        value={
          getDDVByIdOrUndefined(
            DropDownSelectorValues.TowerType,
            siteData.towerType,
          )?.value
        }
      />
      <Coordinate
        id="latitude"
        className="view-coordinate"
        label="Latitude"
        value={siteData.latitude}
        isEditing={false}
      />
      <ViewElement
        additionClassName="left"
        label="Tower Height"
        value={siteData.towerHeight}
      />
      <Coordinate
        id="longitude"
        className="view-coordinate"
        label="Longitude"
        value={siteData.longitude}
        isEditing={false}
      />
      <ViewElement
        additionClassName="left"
        label="Leased Ground Space"
        value={siteData.leasedGroundSpace}
      />
      <ViewElement
        additionClassName="left"
        label="Equipment Type"
        value={
          getDDVByIdOrUndefined(
            DropDownSelectorValues.EquipmentType,
            siteData.equipmentType,
          )?.value
        }
      />
      <ViewElement
        additionClassName="left"
        label="Site Notes"
        value={siteData.notes}
      />
    </div>
    <PrimaryButton
      className="action-button"
      title="View Details"
      target="_blank"
      href={`/site/${siteData.id}/details`}
      type="primary"
    />
  </ContentContainer>
);
