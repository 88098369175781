import { MainPaceTask } from 'providence-types';
import { ProjectsEffects } from '@store/effects';
import { PermittingSection, ZoningSection } from './../models';

export const updatePermittingPaceTaskFields = (
  permittingSection: PermittingSection,
  zoningSection: ZoningSection,
): void => {
  ProjectsEffects.updatePaceTaskFieldsByConfig(permittingSection, [
    {
      dateType: 'actualDate',
      task: MainPaceTask.JurisdictionAuditComplete,
      field: 'jurisdictionAuditComplete',
    },
    {
      dateType: 'actualDate',
      task: MainPaceTask.E911AddressValidationDate,
      field: 'e911AddressValidationDate',
    },
    {
      dateType: 'actualDate',
      task: MainPaceTask.PrelimCDsReviewedPermitting,
      field: 'prelimCDsReviewedPermitting',
    },
  ]);

  ProjectsEffects.updatePaceTaskFieldsByConfig(zoningSection, [
    {
      dateType: 'actualDate',
      task: MainPaceTask.ZoningSubmitted,
      field: 'zoningSubmitted',
    },
    {
      dateType: 'actualDate',
      task: MainPaceTask.ZoningAppDeemedComplete,
      field: 'zoningAppDeemedComplete',
    },
    {
      dateType: 'actualDate',
      task: MainPaceTask.ZoningApproved,
      field: 'zoningApproved',
    },
    {
      dateType: 'actualDate',
      task: MainPaceTask.BPSubmitted,
      field: 'BPSubmitted',
    },
    {
      dateType: 'actualDate',
      task: MainPaceTask.BPReceived,
      field: 'BPReceived',
    },
  ]);
};
