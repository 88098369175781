import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { Form, Row } from 'antd';
import { PermittingAdditionFilterType } from 'providence-types';
import { HttpService } from '@core/services';
import { PermittingHttpService } from '@core/services/http';
import { useDidUpdateEffect } from '@core/utils/hooks';
import {
  createOnChangeModalVisibility,
  momentizeRangePickerValues,
  removeEmptyFields,
} from '@core/utils/methods';
import {
  BROWSE_DEFAULT_FILTER_VALUE,
  browseFiltersChange,
  tableCount,
} from '@models/constants';
import { Filter, PermittingBrowseFilter } from '@models/interfaces';
import { MainPermittingBrowseFilter } from '@models/types';
import { AdditionalFilters } from '@shared/components';
import { BrowsePage } from '@shared/modules';
import { PermittingSelectors } from '@store/selectors';
import { PermittingBrowseFilters } from './modals';
import {
  ADDITIONAL_FILTERS,
  PERMITTING_BROWSE_RANGE_FILTERS,
  PERMITTING_COLUMNS,
  PERMITTING_DEFAULT_BROWSE_CONFIGURE_FILTER,
} from './models';

import './styles.scss';

const { useForm } = Form;

export const PermittingBrowse: FC = () => {
  const { items, total } = useSelector(
    PermittingSelectors.getPermittingBrowseData,
  );

  const [form] = useForm();
  const [filters, setFilters] = useState<MainPermittingBrowseFilter>({
    ...BROWSE_DEFAULT_FILTER_VALUE,
    showCompletedProjects: false,
  });
  const [configureFilter, setConfigureFilter] =
    useState<PermittingBrowseFilter>(
      PERMITTING_DEFAULT_BROWSE_CONFIGURE_FILTER,
    );

  useDidUpdateEffect(() => {
    HttpService.getHttpRequests(PermittingHttpService).getPermittingBrowseData(
      momentizeRangePickerValues(
        removeEmptyFields({ ...filters }),
        PERMITTING_BROWSE_RANGE_FILTERS.map(({ id }: Filter) => id) as Array<
          keyof PermittingBrowseFilter
        >,
        true,
      ),
    );
  }, [filters]);

  const onChangeAdditionFilter = (filter: string | string[]): void => {
    setFilters((prevState: MainPermittingBrowseFilter) => ({
      ...prevState,
      additionFilter: filter as PermittingAdditionFilterType,
    }));
  };

  const onSearchChanges = (search: string): void => {
    setFilters((prevState: MainPermittingBrowseFilter) => ({
      ...prevState,
      search,
    }));
  };

  const changeToggle = (status: boolean): void => {
    setFilters((prevState: MainPermittingBrowseFilter) => ({
      ...prevState,
      showCompletedProjects: status,
    }));
  };

  const onApplyConfigureFilter = (): void => {
    setFilters((prevState: MainPermittingBrowseFilter) => ({
      ...prevState,
      ...configureFilter,
    }));
  };

  const onResetFilter = (): void => {
    form?.resetFields();
    setConfigureFilter(PERMITTING_DEFAULT_BROWSE_CONFIGURE_FILTER);
  };

  const onChangeConfigureFilter = (filter: PermittingBrowseFilter): void =>
    setConfigureFilter((prevState: PermittingBrowseFilter) => ({
      ...prevState,
      ...filter,
    }));

  return (
    <BrowsePage
      className="prov-permitting-browse"
      switchInfo="Show Completed Projects"
      data={items}
      columns={PERMITTING_COLUMNS}
      filters={
        <PermittingBrowseFilters
          formProps={{ form }}
          onChanges={onChangeConfigureFilter}
          value={configureFilter}
        />
      }
      onChangeFilterModalVisibility={createOnChangeModalVisibility(
        form,
        configureFilter,
        filters,
        setConfigureFilter,
      )}
      filterFormProps={{ labelCol: { span: 12 }, form }}
      onSearchChanges={onSearchChanges}
      onSwitch={changeToggle}
      onChange={browseFiltersChange(setFilters)}
      onApplyFilter={onApplyConfigureFilter}
      validation
      cancelButtonProps={{
        onClick: onResetFilter,
      }}
      additional={
        <Row>
          <AdditionalFilters
            filters={ADDITIONAL_FILTERS}
            onChange={onChangeAdditionFilter}
            activeFilters={(filters.additionFilter as string) || ''}
            mode="switch"
          />
        </Row>
      }
      place={tableCount(true)}
      tableProps={{
        pagination: {
          total,
        },
        module: 'permitting',
      }}
    />
  );
};
