import { ForkOutlined } from '@ant-design/icons';
import { SubPage } from 'providence-types';
import { RedirectGuard } from '@core/guards/redirect.guard';
import { tabPropsToTabs } from '@core/utils/methods';
import { Route, RouteGroup } from '@models/classes';
import { EQUIPMENT_TABS_PROPS } from '@models/constants';
import { MenuPlacement } from '@models/enums';
import { EquipmentBrowse, EquipmentManagement } from './components';
import { EquipmentResolver } from './resolvers';

export const equipmentManagementTabs = tabPropsToTabs(EQUIPMENT_TABS_PROPS);

export const equipmentRoutes = new RouteGroup({
  name: 'Equipment',
  path: '/equipment',
})
  .breadcrumb()
  .addGuard(new RedirectGuard('equipment'), '/')
  .setMenu(MenuPlacement.Main, ForkOutlined)
  .addRoutes([
    new Route({
      name: 'Browse',
      path: '/browse',
      component: EquipmentBrowse,
    })
      .breadcrumb()
      .addGuard(new RedirectGuard('equipment', SubPage.EquipmentBrowse), '/')
      .addResolver(new EquipmentResolver())
      .setMenu(MenuPlacement.Main),
    new Route({
      name: 'Equipment Management',
      path: '/management',
      component: EquipmentManagement,
    })
      .breadcrumb()
      .addGuard(
        new RedirectGuard('equipment', SubPage.EquipmentEquipmentManagement),
        '/',
      )
      .setMenu(MenuPlacement.Main)
      .addResolver(new EquipmentResolver())
      .addTabs(equipmentManagementTabs),
  ]);
