import React, { FC, PropsWithChildren, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Col, Form, Row } from 'antd';
import classNames from 'classnames';
import { wrappedTextRender } from '@core/utils/methods';
import { EditableInformationProps } from '@models/interfaces';
import { DDVSelectors } from '@store/selectors';
import { Autocomplete, TextArea } from '../../modules/ui-kit';
import { Panel } from '../panel';

import './styles.scss';

export const ScopingNotes: FC<EditableInformationProps> = ({
  className,
  isEditing,
  form,
  data,
  onValuesChange,
}: PropsWithChildren<EditableInformationProps>) => {
  const dropdownsOptions = useSelector(DDVSelectors.getDDVOptions);

  useEffect(() => {
    if (data) {
      form?.setFieldsValue(data);
    }
  }, []);

  const textAreaElementProps = { autoSize: { minRows: 2 } };

  return (
    <Panel
      header="Scoping Notes"
      className={classNames('prov-scoping-notes', className)}
      collapsible
    >
      <Form
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        form={form}
        onValuesChange={onValuesChange}
      >
        <Row>
          <Col span="12">
            <Autocomplete
              id="RFDSStatus"
              label="RFDS Status"
              isEditing={isEditing}
              options={dropdownsOptions?.RFDSStatus}
            />
            <TextArea
              id="demotionNotes"
              label="Demotion Notes"
              isEditing={isEditing}
              elementProps={textAreaElementProps}
              readOnlyElementRender={wrappedTextRender}
            />
            <TextArea
              id="promotionNotes"
              label="Promotion Notes"
              isEditing={isEditing}
              elementProps={textAreaElementProps}
              readOnlyElementRender={wrappedTextRender}
            />
          </Col>
          <Col span="12">
            <TextArea
              id="scopingGroundNotes"
              label="Scoping Ground Notes"
              isEditing={isEditing}
              elementProps={textAreaElementProps}
              readOnlyElementRender={wrappedTextRender}
            />
            <TextArea
              id="scopingTowerNotes"
              label="Scoping Tower Notes"
              isEditing={isEditing}
              elementProps={textAreaElementProps}
              readOnlyElementRender={wrappedTextRender}
            />
          </Col>
        </Row>
      </Form>
    </Panel>
  );
};
