import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { Undefinable } from 'providence-types';
import { StorageItem } from '@models/enums';
import { ConnectionConfig } from '@models/interfaces';
import { Config } from '../../config/config';
import { LocalStorageService } from '../local-storage.service';

export class AuthInterceptorService {
  private static instance: AuthInterceptorService;

  private localStorageService: LocalStorageService = new LocalStorageService();
  private config: ConnectionConfig = Config.get().connectionConfig;

  private _isProcessing: boolean = false;

  static getInstance(): AuthInterceptorService {
    this.instance = this.instance ?? new AuthInterceptorService();

    return this.instance;
  }

  private constructor() {
    this._intercept();
  }

  private get _apiUrl(): string {
    return this.config.apiUrl;
  }

  private _intercept(): void {
    const http = async (
      req: AxiosRequestConfig,
    ): Promise<AxiosRequestConfig> => {
      try {
        const refreshUrl = (url: string = 'refresh'): string =>
          `${this._apiUrl}/auth/${url}`;

        const accessToken: Undefinable<string> =
          this.localStorageService.getItem(StorageItem.ACCESS_TOKEN);
        const refreshToken: Undefinable<string> =
          this.localStorageService.getItem(StorageItem.REFRESH_TOKEN);

        if (
          accessToken &&
          refreshToken &&
          req.url !== refreshUrl() &&
          req.url !== refreshUrl('sign-in')
        ) {
          const isTokenExpired: boolean =
            AuthInterceptorService._isTokenExpired(accessToken);

          if (isTokenExpired && !this._isProcessing) {
            this._isProcessing = true;

            const {
              data: {
                accessToken: newAccessToken,
                refreshToken: newRefreshToken,
              },
            } = await axios({
              method: 'get',
              url: refreshUrl(),
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${refreshToken}`,
              },
            });

            if (newAccessToken && newRefreshToken) {
              const config = { ...req };

              config!.headers!.Authorization = `Bearer ${newAccessToken}`;

              this.localStorageService
                .setItem(StorageItem.ACCESS_TOKEN, newAccessToken)
                .setItem(StorageItem.REFRESH_TOKEN, newRefreshToken);
            }

            this._isProcessing = false;
          }
        }

        return req;
      } catch (err) {
        this._isProcessing = false;

        return this._errorResponse(err as AxiosError);
      }
    };

    axios.interceptors.request.use(http);

    axios.interceptors.response.use(
      (res: AxiosResponse) => res,
      (err: AxiosError) => {
        if (err.response?.status === 401) {
          return this._errorResponse(err);
        }

        throw err;
      },
    );
  }

  private static _isTokenExpired(jwt: string): boolean {
    const [, token] = jwt.split('.');
    const { exp = null } = JSON.parse(atob(token));

    return exp ? Date.now() > exp * 1000 : false;
  }

  private _errorResponse(err: AxiosError): Promise<AxiosRequestConfig> {
    this.localStorageService
      .removeItem(StorageItem.ACCESS_TOKEN)
      .removeItem(StorageItem.REFRESH_TOKEN);

    if (window.location.pathname !== '/login') {
      window.location.replace('/login');
    }

    return Promise.reject(err);
  }
}
