import React, { ReactNode } from 'react';
import { ColumnsType } from 'antd/lib/table';
import { UserRole } from 'providence-types';
import {
  mapFromBackendToUserRole,
  readOnlyDatePickerRenderer,
} from '@core/utils/methods';
import { actionButtons } from '@models/constants';
import { UserManagementData } from '@models/interfaces';
import { ColumnsFunc } from '@models/types';
import { ProjectStatus } from '@shared/modules';

export const getUserManagementColumns = (
  editFunc: ColumnsFunc<UserManagementData>,
  deleteFunc: ColumnsFunc<UserManagementData>,
): ColumnsType => [
  {
    title: 'First Name',
    dataIndex: 'firstName',
    key: 'firstName',
    sorter: true,
    defaultSortOrder: 'ascend',
  },
  {
    title: 'Last Name',
    dataIndex: 'lastName',
    key: 'lastName',
    sorter: true,
  },
  {
    title: 'Role',
    dataIndex: 'roles',
    key: 'roles',
    render: (roles: UserRole[]): ReactNode => (
      <div className="project-category-cell">
        {roles?.map((role: UserRole) => (
          <span key={role}>{mapFromBackendToUserRole(role)}</span>
        ))}
      </div>
    ),
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    sorter: true,
    width: 310,
  },
  {
    title: 'Status',
    dataIndex: 'isActive',
    key: 'isActive',
    render: (isActive: boolean): ReactNode => (
      <ProjectStatus status={isActive ? 'Active' : 'Inactive'} />
    ),
  },
  {
    title: 'Last Logged In',
    dataIndex: 'lastLogin',
    key: 'lastLogin',
    sorter: true,
    render: readOnlyDatePickerRenderer,
  },
  actionButtons({ editFunc, deleteFunc }),
];
