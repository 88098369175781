import React, {
  Dispatch,
  FC,
  ReactText,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { Col, Form, Row } from 'antd';
import { Store } from 'antd/es/form/interface';
import { SelectValue } from 'antd/es/select';
import { ColumnsType, ColumnType } from 'antd/lib/table';
import { AxiosError } from 'axios';
import moment from 'moment';
import { PermissionAccess, ProjectType } from 'providence-types';
import { HttpService } from '@core/services';
import { AccountingHttpService } from '@core/services/http';
import { useDidUpdateEffect } from '@core/utils/hooks';
import {
  createOnChangeModalVisibility,
  formDiffs,
  isValidForm,
  momentizeRangePickerValues,
} from '@core/utils/methods';
import {
  BROWSE_DEFAULT_FILTER_VALUE,
  browseFiltersChange,
  LTE_NSB_RADIO_BUTTON_GROUP,
  tableCount,
} from '@models/constants';
import {
  AccountingBrowse as AccountingBrowseInterface,
  AccountingBrowseFilter,
  AccountingExportParams,
  RangePickerValues,
} from '@models/interfaces';
import {
  Browse,
  ColumnsSettingsSubmodulesKeys,
  MainAccountingBrowseFilter,
  ProjectPaceTaskDataType,
} from '@models/types';
import { NotificationsLoader, RadioButtonGroup } from '@shared/components';
import {
  BrowsePage,
  PrimaryButton,
  RadioGroup,
  RangePicker,
  Select,
} from '@shared/modules';
import { AccountingSelectors, UserSelectors } from '@store/selectors';
import {
  ACCOUNTING_BROWSE_DEFAULT_VALUE,
  ACCOUNTING_BROWSE_EMPTY_INVOICE_DATES,
  ACCOUNTING_DEFAULT_BROWSE_CONFIGURE_FILTER,
  EXPORT_TYPE_BUTTONS,
  getAccountingBrowseFormDefValues,
  getAccountingColumns,
  getInvoiceDatePaceTaskIdOptions,
  LTEModeColumnsArray,
} from './models/constants';
import { AccountingModal } from './components';
import { Filters } from './filters';

import './styles.scss';

const { useForm } = Form;

export const AccountingBrowse: FC = () => {
  const [form] = useForm();
  const [exportForm] = useForm();

  const accountingService = useMemo(
    () => HttpService.getHttpRequests(AccountingHttpService),
    [],
  );

  const accountingBrowseData = useSelector(
    AccountingSelectors.getAccountingBrowseData,
  );

  const hasEditAccess: PermissionAccess = useSelector(
    UserSelectors.hasAccountingTableAccess,
  );

  const [editModalVisible, setEditModalVisible] = useState<boolean>(false);
  const [projectTypeMode, setProjectTypeMode] = useState<ProjectType>(
    ProjectType.LTE,
  );
  const [filterValue, setFilterValue] = useState<MainAccountingBrowseFilter>({
    ...BROWSE_DEFAULT_FILTER_VALUE,
    showCompletedProjects: false,
  });
  const [configureFilter, setConfigureFilter] =
    useState<AccountingBrowseFilter>(
      ACCOUNTING_DEFAULT_BROWSE_CONFIGURE_FILTER,
    );
  const [selectedItem, setSelectedItem] = useState<AccountingBrowseInterface>(
    ACCOUNTING_BROWSE_DEFAULT_VALUE,
  );
  const [selectedRows, setSelectedRows] = useState<ReactText[]>([]);
  const [exportFormData, setExportFormData] = useState<Store>(
    getAccountingBrowseFormDefValues(ProjectType.LTE),
  );
  const [excludedRows, setExcludedRows] = useState<ReactText[]>([]);

  useDidUpdateEffect(() => {
    accountingService.getAccountingBrowse({
      ...filterValue,
      projectTypeMode,
    });
  }, [filterValue, projectTypeMode]);

  const handleColumns = (cols: ColumnsType<Browse>): ColumnsType<Browse> =>
    projectTypeMode === ProjectType.LTE
      ? cols.filter(
          (col: ColumnType<Browse>) =>
            !LTEModeColumnsArray.includes(col.key as string),
        )
      : cols;

  const onSearchChanges = (search: string): void =>
    setFilterValue((prevState: MainAccountingBrowseFilter) => ({
      ...prevState,
      search,
    }));

  const onApplyConfigureFilter = (): void =>
    setFilterValue((prevState: MainAccountingBrowseFilter) => ({
      ...prevState,
      ...momentizeRangePickerValues(configureFilter, ['RE020ActualDate'], true),
    }));

  const onOnlyCompletedSwitch = (showCompletedProjects?: boolean): void =>
    setFilterValue((prevState: MainAccountingBrowseFilter) => ({
      ...prevState,
      showCompletedProjects,
    }));

  const selectCurrentAccountingValues = (
    currentAccountingValues: AccountingBrowseInterface,
    setter: Dispatch<boolean>,
    prevState: boolean,
  ): void => {
    setSelectedItem(currentAccountingValues);
    setter(!prevState);
  };

  const onSubmitExport = async (): Promise<void> => {
    if (await isValidForm(exportForm)) {
      const notification = new NotificationsLoader({
        message: 'Loading',
        description: 'Please wait',
      });

      try {
        await accountingService.exportAccountingProjects({
          ...momentizeRangePickerValues<
            Omit<AccountingExportParams, 'excludedRows'>
          >(
            exportForm.getFieldsValue() as Omit<
              AccountingExportParams,
              'excludedRows'
            >,
            ['invoiceDate'],
            true,
          ),
          excludedRows,
        });
        exportForm.resetFields();
        setExportFormData(getAccountingBrowseFormDefValues(projectTypeMode));
        setSelectedRows([]);
        setExcludedRows([]);
        setFilterValue({
          ...BROWSE_DEFAULT_FILTER_VALUE,
          showCompletedProjects: false,
        });
      } catch (e) {
        const error = e as AxiosError<{ statusText: string }>;

        NotificationsLoader.notificationError({
          message: 'Error',
          description: error?.response?.statusText || 'Something went wrong',
        });
      }
      notification.close();
    }
  };

  const rowSelection = {
    selectedRowKeys: selectedRows,
    onChange: (rows: ReactText[]): void => {
      setExcludedRows((prevValue: ReactText[]) =>
        Array.from(
          new Set([
            ...prevValue.filter(
              (prevValueRow: ReactText) => !rows.includes(prevValueRow),
            ),
            ...selectedRows.filter(
              (selectedRow: ReactText) => !rows.includes(selectedRow),
            ),
          ]),
        ),
      );
      setSelectedRows(rows);
    },
  };

  useDidUpdateEffect(() => {
    const { invoiceDate, invoiceDatePaceTaskId } = exportFormData;

    if (invoiceDate) {
      const filteredItems = accountingBrowseData?.items.filter(
        (accountingBrowseItem: AccountingBrowseInterface) => {
          const itemInvoiceDate = accountingBrowseItem.projectPaceTaskData.find(
            (paceTaskItem: ProjectPaceTaskDataType) =>
              paceTaskItem.paceTask.id === invoiceDatePaceTaskId,
          )?.invoiceDate;

          return invoiceDate?.from && invoiceDate?.to
            ? moment(itemInvoiceDate).isBetween(
                invoiceDate?.from || 0,
                invoiceDate?.to || undefined,
                undefined,
                '[]',
              )
            : false;
        },
      );

      setSelectedRows(
        filteredItems
          .filter(
            (filteredItem: AccountingBrowseInterface) =>
              !excludedRows.includes(filteredItem.id),
          )
          .map((filteredItem: AccountingBrowseInterface) => filteredItem.id),
      );
    }
  }, [exportFormData, accountingBrowseData?.items]);

  useEffect(() => exportForm.setFieldsValue(exportFormData), [exportFormData]);

  const onChangeConfigureFilter = (filter: AccountingBrowseFilter): void =>
    setConfigureFilter((prevState: AccountingBrowseFilter) => ({
      ...prevState,
      ...filter,
    }));

  return (
    <>
      <BrowsePage
        columns={handleColumns(
          getAccountingColumns(
            hasEditAccess
              ? (accountingBrowseRecord: AccountingBrowseInterface): void =>
                  selectCurrentAccountingValues(
                    accountingBrowseRecord,
                    () => setEditModalVisible(!editModalVisible),
                    editModalVisible,
                  )
              : undefined,
          ),
        )}
        data={accountingBrowseData?.items}
        switchInfo="Show Completed Projects"
        filters={
          <Filters
            onChanges={onChangeConfigureFilter}
            value={configureFilter}
          />
        }
        onChangeFilterModalVisibility={createOnChangeModalVisibility(
          form,
          configureFilter,
          filterValue,
          setConfigureFilter,
        )}
        className="prov-accounting-browse"
        filterFormProps={{ labelCol: { span: 8 }, form }}
        cancelButtonProps={{
          onClick: (): void => {
            form?.resetFields();
            setConfigureFilter(ACCOUNTING_DEFAULT_BROWSE_CONFIGURE_FILTER);
          },
        }}
        onChange={browseFiltersChange(setFilterValue)}
        onSearchChanges={onSearchChanges}
        onApplyFilter={onApplyConfigureFilter}
        onSwitch={onOnlyCompletedSwitch}
        additional={
          <Col className="prov-accounting-browse__handler">
            <Row>
              <RadioButtonGroup
                className="mode-handler"
                buttons={LTE_NSB_RADIO_BUTTON_GROUP}
                onChange={(value: string): void => {
                  const payload = value as ProjectType;

                  setProjectTypeMode(payload);
                  exportForm.setFieldsValue(
                    getAccountingBrowseFormDefValues(payload),
                  );
                  setSelectedRows([]);
                  setExcludedRows([]);
                }}
                defaultValue={ProjectType.LTE}
              />
            </Row>
            <Row className="prov-accounting-browse__handler-bottom-row">
              <Form
                onValuesChange={(value: Store): void =>
                  setExportFormData((prevValue: Store) => ({
                    ...prevValue,
                    ...value,
                  }))
                }
                form={exportForm}
              >
                <Col>
                  <Row>
                    <Select
                      id="invoiceDatePaceTaskId"
                      label="Invoice Date Type"
                      options={getInvoiceDatePaceTaskIdOptions(projectTypeMode)}
                      elementProps={{
                        onChange: (value: SelectValue): void => {
                          const key = value as string;
                          const dates = momentizeRangePickerValues(
                            { [key]: exportFormData.invoiceDate },
                            [key],
                            true,
                          );

                          if (dates[key]?.from && dates[key]?.to) {
                            setFilterValue(
                              (prevValue: MainAccountingBrowseFilter) => ({
                                ...prevValue,
                                ...ACCOUNTING_BROWSE_EMPTY_INVOICE_DATES,
                                ...dates,
                              }),
                            );
                          }
                        },
                      }}
                    />
                    <RangePicker
                      label="Invoice dates for selection"
                      id="invoiceDate"
                      required
                      onChange={async (
                        value?: RangePickerValues,
                      ): Promise<void> => {
                        const dates = momentizeRangePickerValues(
                          { [exportFormData.invoiceDatePaceTaskId]: value },
                          [exportFormData.invoiceDatePaceTaskId],
                          true,
                          true,
                        );

                        setFilterValue(
                          (prevValue: MainAccountingBrowseFilter) => {
                            const newValue = {
                              ...prevValue,
                              ...(dates?.[exportFormData.invoiceDatePaceTaskId]
                                ?.from &&
                              dates?.[exportFormData.invoiceDatePaceTaskId]?.to
                                ? dates
                                : ACCOUNTING_BROWSE_EMPTY_INVOICE_DATES),
                            };

                            const diff = formDiffs(newValue, prevValue);

                            return Object.keys(diff).length
                              ? newValue
                              : prevValue;
                          },
                        );
                      }}
                    />
                  </Row>
                  <Row>
                    <RadioGroup
                      id="exportType"
                      label="Export Type"
                      buttons={EXPORT_TYPE_BUTTONS}
                    />
                    <PrimaryButton
                      onClick={onSubmitExport}
                      title="Download"
                      className="download-btn"
                      disabled={
                        !selectedRows.length &&
                        (!exportFormData?.invoiceDate?.from ||
                          !exportFormData?.invoiceDate?.to)
                      }
                    />
                  </Row>
                </Col>
              </Form>
            </Row>
          </Col>
        }
        place={tableCount(true)}
        tableProps={{
          rowSelection,
          pagination: {
            total: accountingBrowseData?.total,
          },
          module: 'accounting',
          subModule: projectTypeMode as ColumnsSettingsSubmodulesKeys,
        }}
      />
      <AccountingModal
        projectTypeMode={projectTypeMode}
        visible={editModalVisible}
        toggleModal={(): void => setEditModalVisible(!editModalVisible)}
        itemValue={selectedItem}
      />
    </>
  );
};
