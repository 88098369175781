import React, {
  FC,
  PropsWithChildren,
  ReactText,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { Undefinable } from 'providence-types';
import { HttpService } from '@core/services';
import { PaceTaskHttpService } from '@core/services/http';
import { PaceTask, TabComponentProps } from '@models/interfaces';
import { PaceTasks, Search } from '@shared/components';
import { PrimaryButton } from '@shared/modules';
import { PaceTaskSelectors } from '@store/selectors';
import { AccessPaceTaskModal, AddAndEditPaceTaskModal } from '../../modals';
import { getAllPaceTaskColumns } from './models/constants';

import './styles.scss';

export const AllPaceTasks: FC<TabComponentProps> = ({
  isActive,
}: PropsWithChildren<TabComponentProps>) => {
  const paceTaskService = useMemo(
    () => HttpService.getHttpRequests(PaceTaskHttpService),
    [],
  );

  const [selectedRows, setSelectedRows] = useState<ReactText[]>([]);
  const [isAddModalVisible, setAddModalVisible] = useState<boolean>(false);
  const [isAccessModalVisible, setAccessModalVisible] =
    useState<boolean>(false);
  const [search, setSearch] = useState<string>('');
  const [editablePaceTak, setEditablePaceTask] =
    useState<Undefinable<PaceTask>>();

  const rowSelection = {
    selectedRowKeys: selectedRows,
    onChange: (rows: ReactText[]): void => setSelectedRows(rows),
  };

  const toggleAddTaskModal = (): void => {
    setAddModalVisible(!isAddModalVisible);

    if (isAddModalVisible && editablePaceTak) {
      setEditablePaceTask(undefined);
    }
  };
  const toggleAccessModal = (): void =>
    setAccessModalVisible(!isAccessModalVisible);
  const editPaceTask = (paceTask: PaceTask): void => {
    setEditablePaceTask(paceTask);
    toggleAddTaskModal();
  };

  const deletePaceTask = (paceTask: PaceTask): Promise<string> =>
    paceTaskService.deletePaceTask(paceTask.id);

  const paceTaskData = useSelector(PaceTaskSelectors.getAllPaceTasks);

  useEffect(() => {
    if (isActive) {
      paceTaskService.getAllPaceTasks(search);
    }
  }, [isActive, search]);

  useEffect(() => {
    if (selectedRows.length) {
      setSelectedRows(
        selectedRows.filter(
          (id: ReactText): boolean =>
            !!paceTaskData.find((p: PaceTask) => p.id === id),
        ),
      );
    }
  }, [paceTaskData]);

  return (
    <div className="prov-all-pace-tasks">
      <AddAndEditPaceTaskModal
        paceTask={editablePaceTak}
        visible={isAddModalVisible}
        onOk={toggleAddTaskModal}
        onCancel={toggleAddTaskModal}
      />
      <AccessPaceTaskModal
        paceTaskId={selectedRows as string[]}
        visible={isAccessModalVisible}
        onOk={toggleAccessModal}
        onCancel={toggleAccessModal}
      />
      <PaceTasks
        columns={getAllPaceTaskColumns(editPaceTask, deletePaceTask)}
        data={paceTaskData}
        infoRowLeftElement={
          <Search onChange={(value: string): void => setSearch(value)} />
        }
        infoRowRightElement={
          <PrimaryButton
            title="Add Task"
            icon="+"
            onClick={toggleAddTaskModal}
          />
        }
        rowSelection={rowSelection}
      />
      <PrimaryButton
        disabled={!selectedRows.length}
        title="Assign Access"
        icon="+"
        onClick={toggleAccessModal}
        className="prov-all-pace-tasks__access-btn"
      />
    </div>
  );
};
