import { PaymentType as DataBasePaymentType } from 'providence-types';
import { arraySort } from '@core/utils/methods/array-sort';
import { PaymentType } from '@models/enums';

export const PAYMENT_TYPE_OPTIONS = arraySort(
  Object.keys(DataBasePaymentType).map((key: string) => ({
    value: DataBasePaymentType[key as keyof typeof DataBasePaymentType],
    viewValue: PaymentType[key as keyof typeof PaymentType],
  })),
  'ASC',
  'viewValue',
);
