import { Undefinable } from 'providence-types';
import { JurisdictionOption } from '@models/interfaces';
import store from '@store/index';
import { AppState } from '@store/reducers';

export const getJurisdictionById = (id: string): JurisdictionOption => {
  const state: AppState = store.getState();
  const { jurisdictionList } = state.permitting;

  const result = jurisdictionList.find(
    (listOption: JurisdictionOption) => listOption.id === id,
  );

  if (!result) {
    throw new Error(`Jurisdictions do not have value with id ${id}`);
  }

  return result;
};

export const getJurisdictionByIdOrUndefined = (
  id?: string | null,
): Undefinable<JurisdictionOption> =>
  id ? getJurisdictionById(id) : undefined;
