import { ReactText } from 'react';
import { OptionProps, ProjectCategoryTableRecord } from '@models/interfaces';

export const getRanking = (
  recordItem: ProjectCategoryTableRecord,
  options: Record<string, OptionProps[]>,
): ReactText =>
  options.ProjectRanking.find(
    (option: OptionProps) => option.value === recordItem.projectRanking,
  )?.viewValue || 'Secondary';
