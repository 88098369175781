import {
  IDirectMessageReceivers,
  IReceivedDirectMessageForArea,
  ISentDirectMessage,
} from 'providence-types';
import { actionCreatorFactory } from '@core/utils/factories';

const actionCreator = actionCreatorFactory('DIRECT_MESSAGE');

export namespace DirectMessageActions {
  export const getUsersByArea =
    actionCreator.createAsyncAction<IDirectMessageReceivers[]>(
      'GET_USERS_BY_AREA',
    );

  export const getDirectMessages =
    actionCreator.createAsyncAction<ISentDirectMessage[]>('GET');

  export const getReceivedDirectMessages =
    actionCreator.createAsyncAction<IReceivedDirectMessageForArea[]>(
      'GET_RECEIVED',
    );

  export const createDirectMessage =
    actionCreator.createAsyncAction<ISentDirectMessage>('CREATE');

  export const updateDirectMessage =
    actionCreator.createAsyncAction<Omit<ISentDirectMessage, 'completed'>>(
      'UPDATE',
    );

  export const setCompletedDirectMessage =
    actionCreator.createAsyncAction<string>('SET_COMPLETED_DIRECT_MESSAGE');

  export const multiSetCompletedDirectMessage = actionCreator.createAsyncAction<
    string[]
  >('MULTI_SET_COMPLETED_DIRECT_MESSAGE');

  export const removeDirectMessage =
    actionCreator.createAsyncAction<string>('REMOVE');
}
