import React, { FC, PropsWithChildren } from 'react';
import { useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Details } from '@shared/modules/details';
import { LeasingSelectors } from '@store/selectors';

import './styles.scss';

export const LeasingDetails: FC<RouteComponentProps> = (
  props: PropsWithChildren<RouteComponentProps>,
) => {
  const site = useSelector(LeasingSelectors.getLeasingSite);

  return (
    <Details {...props} className="prov-leasing-details" category site={site} />
  );
};
