import React, { FC, PropsWithChildren, useMemo, useState } from 'react';
import { Form } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import Filter from 'assets/icons/filter.png';
import { HttpService } from '@core/services';
import { NotificationsHttpService } from '@core/services/http';
import { Autocomplete, Modal, PrimaryButton } from '@shared/modules';
import { TowerOwnerFilterProps } from '../models';

export const TowerOwnerFilter: FC<TowerOwnerFilterProps> = ({
  towerOwners,
  place,
}: PropsWithChildren<TowerOwnerFilterProps>) => {
  const [form] = useForm();

  const notificationsService = useMemo(
    () => HttpService.getHttpRequests(NotificationsHttpService),
    [],
  );

  const [showModal, setShowModal] = useState<boolean>(false);

  const applyFilter = async (): Promise<void> => {
    const { towerOwnerIds } = form.getFieldsValue();

    place === 'rule'
      ? await notificationsService.getRules(towerOwnerIds)
      : await notificationsService.getSchedule(towerOwnerIds);
  };

  return (
    <>
      <PrimaryButton
        className="configure-btn"
        onClick={(): void => setShowModal(true)}
        icon={<img src={Filter} alt="filter icon" />}
        title="Configure filter"
      />
      <Modal
        title="Filters"
        okText="Apply"
        cancelText="Clear"
        cancelButtonProps={{
          onClick: (): void => form.resetFields(),
        }}
        onCancel={(): void => setShowModal(false)}
        onOk={applyFilter}
        visible={showModal}
      >
        <Form form={form} labelCol={{ span: 6 }} wrapperCol={{ span: 16 }}>
          <Autocomplete
            id="towerOwnerIds"
            label="Tower Owners"
            elementProps={{
              mode: 'multiple',
            }}
            options={towerOwners}
          />
        </Form>
      </Modal>
    </>
  );
};
