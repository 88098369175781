import React, { FC, PropsWithChildren, ReactText, useEffect } from 'react';
import { Form } from 'antd';
import { HttpService } from '@core/services';
import { ProjectHttpService } from '@core/services/http';
import { isValidForm, normalize } from '@core/utils/methods';
import { ModalMainTypes } from '@models/enums';
import { AddAndEditModal, NotificationsLoader } from '@shared/components';
import { RADModalProps } from '@shared/components/rad-table/models/interfaces';
import { InputNumber } from '@shared/modules';

const { useForm } = Form;

export const RADModal: FC<RADModalProps> = ({
  title,
  visible,
  toggleModal,
  permissions,
  formValue,
  projectId,
}: PropsWithChildren<RADModalProps>) => {
  const [form] = useForm();

  useEffect(() => {
    if (formValue) {
      form.setFieldsValue(formValue);
    }
  }, [formValue]);

  const onOk = async (): Promise<void> => {
    if (await isValidForm(form)) {
      try {
        const result = Object.entries<ReactText>(form.getFieldsValue()).map(
          ([field, value]: Array<ReactText>) => ({
            RADField: field,
            [title]: value,
          }),
        );

        await HttpService.getHttpRequests(ProjectHttpService).updateProjectRAD(
          projectId,
          result,
        );

        NotificationsLoader.notificationSuccess('RAD table has been updated!');

        toggleModal(ModalMainTypes.Edit);
      } catch (e) {
        console.error(e);
      }
    }
  };

  return (
    <AddAndEditModal
      onOk={onOk}
      onCancel={(): void => toggleModal(ModalMainTypes.Edit)}
      visible={visible}
      type={ModalMainTypes.Edit}
      title={title}
      formProps={{
        labelCol: { span: 5 },
        wrapperCol: { span: 14 },
        form,
      }}
      className="prov-rad-table__modal"
      cancelButtonProps={{
        onClick: (): void =>
          form.setFieldsValue({
            leased: 0,
            structural: 0,
            RFDS: 0,
            scoping: 0,
          }),
      }}
    >
      <InputNumber
        id="A"
        label="A"
        isEditing={permissions}
        formItemProps={{
          normalize,
        }}
      />
      <InputNumber
        id="B"
        label="B"
        isEditing={permissions}
        formItemProps={{
          normalize,
        }}
      />
      <InputNumber
        id="G"
        label="G"
        isEditing={permissions}
        formItemProps={{
          normalize,
        }}
      />
      <InputNumber
        id="D"
        label="D"
        isEditing={permissions}
        formItemProps={{
          normalize,
        }}
      />
    </AddAndEditModal>
  );
};
