import React, {
  FC,
  ReactElement,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { Button, Card, Form, Popover } from 'antd';
import { SelectValue } from 'antd/lib/select';
import { ProjectType, Undefinable } from 'providence-types';
import { HttpService } from '@core/services';
import {
  ProjectManagementHttpService,
  UserHttpService,
} from '@core/services/http';
import { useDidUpdateEffect } from '@core/utils/hooks';
import {
  BROWSE_DEFAULT_FILTER_VALUE,
  browseFiltersChange,
  LTE_NSB_RADIO_BUTTON_GROUP,
  tableCount,
} from '@models/constants';
import {
  FiltersSetData,
  FiltersSetDataExtended,
  FiltersSetDataFilter,
} from '@models/interfaces';
import { MainProjectManagementBrowseFilter, SwitchType } from '@models/types';
import { RadioButtonGroup } from '@shared/components';
import { BrowsePage } from '@shared/modules/browse-page';
import { UserActions } from '@store/actions';
import store from '@store/index';
import { ProjectManagementSelectors, UserSelectors } from '@store/selectors';
import {
  convertPaceTaskFilterToObj,
  DEFAULT_FILTERS_SET_VALUE,
} from './filters/models';
import {
  findFiltersSet,
  getNewColumnsSettings,
  getProjectManagementBrowseColumns,
  projectManagementBrowseFilterTransformer,
} from './models/constants';
import { Filters } from './filters';

import './styles.scss';

const { useForm } = Form;

export const ProjectManagementBrowse: FC = () => {
  const [form] = useForm();

  const projectManagementService = useMemo(
    () => HttpService.getHttpRequests(ProjectManagementHttpService),
    [],
  );

  const {
    projectManagementBrowse,
    filterSets,
    pmScreenPaceTasksOptions,
    settingsColumns,
  } = useSelector(ProjectManagementSelectors.getPMBrowseInfo);
  const currentProjectType = useSelector(UserSelectors.getDefaultPMProjectType);

  // filters states
  const [selectorValue, setSelectorValue] =
    useState<Undefinable<SelectValue>>(undefined);
  const [filtersSet, setFiltersSet] = useState<FiltersSetDataExtended>(
    DEFAULT_FILTERS_SET_VALUE,
  );
  // remove it when cancel button props func will be changed
  const [needClear, setNeedClear] = useState<boolean>(false);
  //
  const [filterSetFields, setFilterSetFields] = useState<FiltersSetDataFilter>(
    {},
  );
  const [filterValue, setFilterValue] =
    useState<MainProjectManagementBrowseFilter>({
      ...BROWSE_DEFAULT_FILTER_VALUE,
      showCompleted: false,
    });
  const [projectType, setProjectType] =
    useState<ProjectType>(currentProjectType);

  useDidUpdateEffect(() => {
    const { name, ...fieldsWithoutName } = filterSetFields;

    projectManagementService.getProjectManagementBrowse({
      ...filterValue,
      configuredFilter:
        projectManagementBrowseFilterTransformer(fieldsWithoutName),
      projectType,
    });
  }, [filterValue, filterSetFields, projectType]);

  const onSearchChanges = (search: string): void => {
    setFilterValue((prevState: MainProjectManagementBrowseFilter) => ({
      ...prevState,
      search,
    }));
  };

  const onApplyConfigureFilter = async (): Promise<void> => {
    const formValue = form.getFieldsValue();
    const { name, ...rest } = formValue;

    if (name) {
      await projectManagementService.updatePMBrowseFiltersSet({
        ...projectManagementBrowseFilterTransformer(rest),
        id: selectorValue,
        name,
      } as FiltersSetData);
    }

    setFilterSetFields({
      name,
      ...Object.keys(rest).reduce((acc: FiltersSetDataFilter, key: string) => {
        const { visible, ...otherData } = rest[key];

        return { ...acc, [key]: otherData };
      }, {}),
    });

    store.dispatch(
      UserActions.changeColumnsSettings.done({
        module: 'projectManagement',
        columns: getNewColumnsSettings(
          settingsColumns.projectManagement,
          rest,
          pmScreenPaceTasksOptions,
        ),
      }),
    );
  };

  const onOnlyCompletedSwitch = (value: boolean): void =>
    setFilterValue((prevState: MainProjectManagementBrowseFilter) => ({
      ...prevState,
      showCompleted: value as SwitchType,
    }));

  const changeDefaultProjectType = async (type: ProjectType): Promise<void> => {
    await HttpService.getHttpRequests(
      UserHttpService,
    ).changeDefaultPMProjectType(type);
  };

  const popoverContent = (type: ProjectType): ReactNode => (
    <Card className="prov-project-management__card" bordered={false}>
      {currentProjectType !== type ? (
        <Button onClick={(): Promise<void> => changeDefaultProjectType(type)}>
          Set{' '}
          {currentProjectType === ProjectType.LTE
            ? ProjectType.NSB
            : ProjectType.LTE}{' '}
          as default
        </Button>
      ) : (
        <>{type} is default</>
      )}
    </Card>
  );

  // remove it when cancel button props func will be changed
  useEffect(() => {
    const { name, filter, paceTaskFilter } = filtersSet;

    form.resetFields();
    form.setFieldsValue({
      name,
      ...filter,
      ...convertPaceTaskFilterToObj(paceTaskFilter),
    });
  }, [needClear]);

  return (
    <BrowsePage
      switchInfo="Show Completed Projects"
      columns={getProjectManagementBrowseColumns(
        Object.keys({
          ...filtersSet.filter,
          ...convertPaceTaskFilterToObj(filtersSet.paceTaskFilter),
        }),
        selectorValue,
      )}
      data={projectManagementBrowse?.items}
      filters={
        <Filters
          formProps={{ form }}
          selectorValue={selectorValue}
          setSelectorValue={setSelectorValue}
          setFiltersSet={setFiltersSet}
          filtersSet={filtersSet}
        />
      }
      place={tableCount(true, 'Project')}
      filterFormProps={{ form, labelCol: { span: 9 } }}
      validation
      additional={
        <RadioButtonGroup
          className="mode-handler"
          buttons={LTE_NSB_RADIO_BUTTON_GROUP}
          onChange={(value: string): void => {
            setProjectType(value as ProjectType);
          }}
          value={projectType}
          buttonRender={(button: ReactElement, value: string): ReactElement => (
            <Popover
              content={popoverContent(value as ProjectType)}
              placement="bottom"
              overlayClassName="prov-project-management__popover"
              key={value}
            >
              {button}
            </Popover>
          )}
        />
      }
      className="prov-project-management"
      cancelButtonProps={{
        onClick: (): void => {
          setFiltersSet(findFiltersSet(filterSets, selectorValue));
          setNeedClear((prevValue: boolean) => !prevValue);
        },
      }}
      onChange={browseFiltersChange(setFilterValue)}
      onSearchChanges={onSearchChanges}
      onApplyFilter={onApplyConfigureFilter}
      onSwitch={onOnlyCompletedSwitch}
      tableProps={{
        pagination: {
          total: projectManagementBrowse?.total,
        },
        module: 'projectManagement',
      }}
    />
  );
};
