import React, { FC, PropsWithChildren, useEffect, useState } from 'react';
import nextId from 'react-id-generator';
import { useSelector } from 'react-redux';
import { Form } from 'antd';
import { SelectValue } from 'antd/lib/select';
import { UserRole } from 'providence-types';
import {
  EquipmentAvailableSelectorValueService,
  HttpService,
} from '@core/services';
import { EquipmentHttpService } from '@core/services/http';
import { useDidUpdateEffect } from '@core/utils/hooks';
import {
  arrsToSelectableObject,
  canEdit,
  isValidForm,
  normalize,
} from '@core/utils/methods';
import { INPUT_NUMBER_VALIDATION_RULES } from '@models/constants';
import { ModalMainTypes } from '@models/enums';
import {
  EquipmentFullInfo,
  EquipmentManufacturerData,
  EquipmentModal as IEquipmentModal,
  EquipmentTypeData,
} from '@models/interfaces';
import { AddAndEditModal } from '@shared/components';
import { Autocomplete, InputNumber } from '@shared/modules';
import { EquipmentSelectors } from '@store/selectors';
import { EquipmentModalProps } from './models';

import './styles.scss';

const availableSelectorValueService =
  new EquipmentAvailableSelectorValueService();

const { useForm } = Form;

export const EquipmentModal: FC<EquipmentModalProps> = ({
  visible,
  modalType,
  toggleModal,
  setEquipmentItem,
  itemValue,
}: PropsWithChildren<EquipmentModalProps>) => {
  const [form] = useForm();

  const { equipmentTypes, currentEquipments } = useSelector(
    EquipmentSelectors.getEquipmentTypesWithCurrentEquipments,
  );

  const [equipmentTypeValue, setEquipmentTypeValue] = useState<SelectValue>();
  const [equipmentManufacturerValue, setEquipmentManufacturerValue] =
    useState<SelectValue>();
  const [equipmentManufacturerOptions, setEquipmentManufacturerOptions] =
    useState<EquipmentManufacturerData[]>([]);
  const [equipmentTypesOptions, setEquipmentTypesOptions] = useState<
    EquipmentTypeData[]
  >([]);
  const [modelNumberOptions, setModelNumberOptions] = useState<
    EquipmentFullInfo[]
  >([]);
  const [areValuesSet, setValuesSet] = useState<boolean>(false);

  useEffect(() => {
    if (visible) {
      availableSelectorValueService.equipmentTypes = equipmentTypes;
      availableSelectorValueService.init();

      setEquipmentManufacturerOptions(
        availableSelectorValueService.availableEquipmentManufacturerValue,
      );

      setEquipmentTypesOptions(
        availableSelectorValueService.availableEquipmentTypeValue,
      );

      if (itemValue) {
        setEquipmentTypeValue(itemValue.equipmentType);
        setEquipmentManufacturerValue(itemValue.manufacturer);
        setValuesSet(true);
      }
    } else {
      form.resetFields();
      setEquipmentTypeValue(undefined);
      setEquipmentManufacturerValue(undefined);
    }
  }, [equipmentTypes, form, visible, itemValue]);

  useDidUpdateEffect(() => {
    if (areValuesSet && itemValue) {
      form.setFieldsValue(itemValue);

      setValuesSet(false);
    }
  }, [areValuesSet]);

  useDidUpdateEffect(() => {
    if (equipmentTypesOptions && equipmentTypeValue) {
      HttpService.getHttpRequests(EquipmentHttpService).getEquipmentsByTypes([
        equipmentTypesOptions?.find(
          (option: EquipmentTypeData) => option.id === equipmentTypeValue,
        )!.name,
      ]);
    }
  }, [equipmentTypeValue]);

  useDidUpdateEffect(() => {
    if (equipmentTypeValue) {
      availableSelectorValueService.selectEquipmentType(equipmentTypeValue);

      setEquipmentManufacturerOptions(
        availableSelectorValueService.availableEquipmentManufacturerValue,
      );
    }
  }, [equipmentTypeValue]);

  useDidUpdateEffect(() => {
    availableSelectorValueService.selectEquipmentManufacturer(
      equipmentManufacturerValue,
    );

    setModelNumberOptions(
      currentEquipments.filter(
        (eqip: EquipmentFullInfo) =>
          eqip.equipmentType.id === equipmentTypeValue &&
          (equipmentManufacturerValue
            ? eqip.manufacturer.id === equipmentManufacturerValue
            : true),
      ),
    );
  }, [equipmentManufacturerValue]);

  useDidUpdateEffect(() => {
    if (visible) {
      setModelNumberOptions(
        currentEquipments.filter((eqip: EquipmentFullInfo) =>
          equipmentManufacturerValue
            ? eqip.equipmentType.id === equipmentTypeValue &&
              eqip.manufacturer.id === equipmentManufacturerValue
            : eqip.equipmentType.id === equipmentTypeValue,
        ),
      );
    }
  }, [currentEquipments, visible]);

  const add = async (): Promise<void> => {
    if (await isValidForm(form)) {
      const manufacturerId = currentEquipments.find((eqip: EquipmentFullInfo) =>
        equipmentManufacturerValue
          ? eqip.equipmentType.id === equipmentTypeValue &&
            eqip.manufacturer.id === equipmentManufacturerValue
          : eqip.equipmentType.id === equipmentTypeValue,
      )?.manufacturer?.id;

      setEquipmentItem({
        ...form.getFieldsValue(),
        generatedId: nextId('equipment'),
        ...(manufacturerId && {
          manufacturer: manufacturerId,
        }),
      } as IEquipmentModal);

      toggleModal(modalType);
    }
  };

  const edit = async (): Promise<void> => {
    if (await isValidForm(form)) {
      setEquipmentItem(
        itemValue?.generatedId
          ? ({
              ...form.getFieldsValue(),
              generatedId: itemValue.generatedId,
            } as IEquipmentModal)
          : (form.getFieldsValue() as IEquipmentModal),
      );

      toggleModal(modalType);
    }
  };

  return (
    <AddAndEditModal
      visible={visible}
      type={modalType}
      okText="Save"
      cancelText="Cancel"
      className="prov-equipment-modal"
      formProps={{
        labelCol: { span: 6 },
        wrapperCol: { span: 15 },
        form,
      }}
      title="New Item"
      onCancel={(): void => {
        form.resetFields();
        setModelNumberOptions([]);
        toggleModal(modalType);
      }}
      onOk={
        modalType === ModalMainTypes.Add
          ? (): Promise<void> => add()
          : (): Promise<void> => edit()
      }
    >
      <Autocomplete
        id="equipmentType"
        label="Equipment Type"
        options={arrsToSelectableObject(equipmentTypesOptions, 'id', 'name')}
        formItemProps={{
          rules: [{ required: true, message: 'Equipment Type is required!' }],
        }}
        elementProps={{
          allowClear: true,
          onChange: (value: SelectValue): void => {
            setEquipmentTypeValue(value);
            setEquipmentManufacturerValue(undefined);
            setEquipmentManufacturerOptions([]);
            setModelNumberOptions([]);
            form.setFieldsValue({ manufacturer: undefined });
            form.setFieldsValue({ equipment: undefined });
          },
        }}
      />
      <Autocomplete
        id="manufacturer"
        label="Manufacturer"
        options={arrsToSelectableObject(
          equipmentManufacturerOptions,
          'id',
          'name',
        )}
        elementProps={{
          allowClear: true,
          onChange: (value: SelectValue): void => {
            setEquipmentManufacturerValue(value);
            form.setFieldsValue({ equipment: undefined });
          },
        }}
      />
      <Autocomplete
        id="equipment"
        label="Model Number"
        options={arrsToSelectableObject(
          modelNumberOptions,
          'id',
          'modelNumber',
        )}
        formItemProps={{
          rules: [{ required: true, message: 'Model Number is required!' }],
        }}
        elementProps={{
          allowClear: true,
        }}
      />
      <InputNumber
        id="quantity"
        label="Quantity"
        formItemProps={{
          rules: [
            { required: true, message: 'Quantity is required!' },
            ...INPUT_NUMBER_VALIDATION_RULES,
          ],
        }}
      />
      <InputNumber
        id="RAD"
        label="RAD"
        isEditing={canEdit([UserRole.Admin, UserRole.LeasingAgent])}
        formItemProps={{
          normalize,
        }}
      />
    </AddAndEditModal>
  );
};
