import React, { ReactNode } from 'react';
import { ColumnsType, ColumnType } from 'antd/lib/table';
import { Undefinable } from 'providence-types';
import { arraySort } from '@core/utils/methods';
import { SectorEquipmentType } from '@models/enums';
import {
  ColumnSelectEquipment,
  EquipmentTypeData,
  SectorEquipment,
  SectorEquipmentType as SectorEquipmentTypeInterface,
} from '@models/interfaces';
import { SectorDataState } from '@models/types';
import { ColumnsSelect } from '@shared/components';
import { ScopingActions } from '@store/actions';
import store from '@store/index';

export const getSectorEquipmentsColumns = (
  prevState: SectorDataState,
  sectorType: SectorEquipmentTypeInterface[],
  type: SectorEquipmentType,
  isEditing?: boolean,
): ColumnsType => {
  const {
    equipment: { equipmentTypes, sectorsEquipmentsByTypes },
  } = store.getState();

  const getEquipmentTypeName = (equipmentType: string): Undefinable<string> =>
    equipmentTypes?.find(
      (equipment: EquipmentTypeData) => equipment.id === equipmentType,
    )?.name;

  return [
    {
      title: 'Position',
      key: 'position',
      dataIndex: 'position',
      defaultSortOrder: 'ascend',
      width: 40,
    },
    ...arraySort(
      sectorType.map((dataItem: SectorEquipmentTypeInterface) => ({
        title: getEquipmentTypeName(dataItem.equipmentType),
        key: getEquipmentTypeName(dataItem.equipmentType),
        className: 'equipment-model-number',
        width: 200,
        render: (_: unknown, __: unknown, index: number): ReactNode => {
          const equipments = dataItem?.equipments
            ?.filter(
              (equipment: SectorEquipment) =>
                equipment.position === index + 1 && equipment.equipment,
            )
            ?.map(
              ({
                equipment: equipmentId,
                quantity,
                manufacturer,
              }: SectorEquipment) => ({
                id: equipmentId,
                quantity,
                manufacturer,
              }),
            );

          const currentTypeEquipments =
            sectorsEquipmentsByTypes[dataItem.equipmentType] || [];

          return (
            <ColumnsSelect
              isEditing={isEditing}
              data={currentTypeEquipments}
              equipments={equipments}
              label="modelNumber"
              type={type}
              onChange={(value: ColumnSelectEquipment[]): void => {
                const editingType = prevState[type].find(
                  (typeItem: SectorEquipmentTypeInterface) =>
                    typeItem.equipmentType === dataItem.equipmentType,
                );

                const filteredEquipments =
                  editingType?.equipments?.filter(
                    (editingTypeItem: SectorEquipment) =>
                      editingTypeItem?.position !== index + 1,
                  ) || [];

                store.dispatch(
                  ScopingActions.updateScopingSectorsAction.done({
                    ...prevState,
                    [type]: [
                      ...prevState[type].filter(
                        (typeItem: SectorEquipmentTypeInterface) =>
                          typeItem.equipmentType !== editingType?.equipmentType,
                      ),
                      {
                        ...editingType,
                        equipments: [
                          ...filteredEquipments,
                          ...value.map(
                            ({
                              id: equipment,
                              quantity,
                              manufacturer,
                            }: ColumnSelectEquipment) => ({
                              position: index + 1,
                              equipment,
                              quantity,
                              manufacturer,
                            }),
                          ),
                        ],
                      } as SectorEquipmentTypeInterface,
                    ],
                  }),
                );
              }}
            />
          );
        },
      })) as ColumnType<unknown>[],
      'ASC',
      'title',
    ),
  ];
};
