import React, { FC, PropsWithChildren } from 'react';
import classNames from 'classnames';
import { Status as StatusProps } from './models/interfaces';

import './styles.scss';

export const Status: FC<StatusProps> = ({
  status,
  className,
  color,
}: PropsWithChildren<StatusProps>) => {
  const statusColor = status ? color[status] : '';

  return (
    <span className={classNames('status', className, `status_${statusColor}`)}>
      {status && (
        <>
          <i className={`circle circle_${statusColor}`} />
          {status}
        </>
      )}
    </span>
  );
};
