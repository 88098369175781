import React, { FC, PropsWithChildren, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'antd';
import classNames from 'classnames';
import { Store } from 'rc-field-form/lib/interface';
import {
  HttpService,
  ObjectComparatorService,
  ObjectDifferencesService,
} from '@core/services';
import { PermittingHttpService } from '@core/services/http';
import { useDidUpdateEffect, useSaveChanged } from '@core/utils/hooks';
import {
  arraySort,
  getIdParamFromUrl,
  momentizeObjectDates,
} from '@core/utils/methods';
import { JURISDICTION_DATES_FOR_MOMENTIZE } from '@models/constants';
import {
  JurisdictionDetailsSummary,
  JurisdictionDetailsZoning,
  JurisdictionZoningData,
} from '@models/interfaces';
import { EditablePage, NotificationsLoader } from '@shared/components';
import { PrimaryButton } from '@shared/modules';
import { CommonActions } from '@store/actions';
import { PermittingSelectors, UserSelectors } from '@store/selectors';
import { JurisdictionSummarySection } from '../../components';
import { JURISDICTION_CAN_USER_EDIT } from '../../models';
import { JurisdictionZoningSection } from './components';
import { getDefaultZoningData } from './helpers';
import { ZoningProps } from './models';

import './styles.scss';

const { useForm } = Form;

const Zoning: FC<ZoningProps> = ({
  isEditing,
  permissions,
  toggleEditing,
}: PropsWithChildren<ZoningProps>) => {
  const dispatch = useDispatch();

  const [summaryForm] = useForm();

  const [resetZoning, setResetZoning] = useState<boolean>(false);

  const { zoning, summary } = useSelector(
    PermittingSelectors.getJurisdictionDetailsData(['summary', 'zoning']),
  );

  const [data, setData] = useState<JurisdictionZoningData>(() =>
    getDefaultZoningData(summary, zoning),
  );
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(false);

  const isDisabled = ObjectComparatorService.objectsCompare(
    getDefaultZoningData(summary, zoning),
    data,
  );

  useEffect(
    () => (): void => {
      dispatch(CommonActions.setHasUnsubmittedData.done(false));
    },

    [],
  );

  useDidUpdateEffect(() => {
    dispatch(CommonActions.setHasUnsubmittedData.done(!isDisabled));
  }, [isDisabled]);

  useDidUpdateEffect(() => {
    setData(getDefaultZoningData(summary, zoning));
  }, [zoning]);

  const updateSummarySection = (summaryData: Store): void => {
    setData((prevState: JurisdictionZoningData) => ({
      ...prevState,
      summarySection: {
        ...prevState.summarySection,
        ...summaryData,
      },
    }));
  };

  const updateZoningSection = (id: string, formValue: Store): void => {
    setData((prevState: JurisdictionZoningData) => ({
      ...prevState,
      zoningSection: prevState.zoningSection.map(
        (zoningSection: JurisdictionDetailsZoning) => {
          const condition = zoningSection.id !== id;

          return condition
            ? zoningSection
            : {
                ...zoningSection,
                ...formValue,
              };
        },
      ),
    }));
  };

  const onSubmit = async (): Promise<void> => {
    try {
      const [jurisdictionId] = getIdParamFromUrl();

      const zoningSorted = arraySort([...zoning!], 'ASC', 'id');

      setSubmitDisabled(true);

      const zoningDataSorted = arraySort(
        data.zoningSection.map((section: JurisdictionDetailsZoning) =>
          momentizeObjectDates(section, JURISDICTION_DATES_FOR_MOMENTIZE, true),
        ),
        'ASC',
        'id',
      );

      const needUpdatedZoning = zoningDataSorted.filter(
        (z: JurisdictionDetailsZoning, index: number) =>
          !ObjectComparatorService.objectsCompare(z, zoningSorted[index]),
      );

      await HttpService.getHttpRequests(
        PermittingHttpService,
      ).updateJurisdictionZoning(jurisdictionId, {
        summarySection: ObjectDifferencesService.getObjectsDiff(
          summary || {},
          summaryForm.getFieldsValue(),
          ['jurisdictionName'],
        ) as Omit<JurisdictionDetailsSummary, 'id'>,
        zoningSection: needUpdatedZoning,
      });

      toggleEditing?.();

      NotificationsLoader.notificationSuccess('Information has been updated!');

      setSubmitDisabled(false);
    } catch (e) {
      console.error(e);
    }
  };

  const onCancel = (): void => {
    summaryForm.resetFields();
    setResetZoning(!resetZoning);
    setData(getDefaultZoningData(summary, zoning));
    toggleEditing?.();
  };

  useSaveChanged(isEditing, onSubmit, onCancel);

  return (
    <main className="prov-jurisdiction-zoning">
      <div
        className={classNames('page-wrap', {
          'page-wrap_with-actions': isEditing,
        })}
      >
        <JurisdictionSummarySection
          permissions={permissions!.zoningSummaryFields}
          isEditing={isEditing}
          updateSummarySection={updateSummarySection}
          data={summary!}
          form={summaryForm}
        />
        <JurisdictionZoningSection
          isEditing={isEditing}
          zoningLTEFields={permissions!.zoningLTEFields}
          zoningNSBFields={permissions!.zoningNSBFields}
          updateZoningSection={updateZoningSection}
          data={zoning!}
          reset={resetZoning}
        />
      </div>
      {isEditing && (
        <section className="actions">
          {JURISDICTION_CAN_USER_EDIT() && (
            <PrimaryButton
              title="Submit"
              type="primary"
              disabled={submitDisabled}
              onClick={onSubmit}
            />
          )}
          <PrimaryButton title="Cancel" type="default" onClick={onCancel} />
        </section>
      )}
    </main>
  );
};

export const JurisdictionZoning: FC = () => {
  const permissions = useSelector(
    UserSelectors.getPermittingJurisdictionPermissions,
  );

  return (
    <EditablePage
      editableComponent={Zoning}
      permissions={permissions}
      canUserEdit={JURISDICTION_CAN_USER_EDIT()}
    />
  );
};
