import { Rule } from '@models/interfaces';

export const RULE_DEFAULT_VALUES: Partial<Rule> = {
  _id: undefined,
  fieldName: undefined,
  dateType: undefined,
  valueType: undefined,
  value: undefined,
  daysCount: undefined,
  operator: undefined,
  notifyType: undefined,
  roles: undefined,
  userIds: undefined,
  engineeringManagerType: undefined,
  message: undefined,
};
