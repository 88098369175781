import { MainPaceTask } from 'providence-types';
import {
  EngineeringRegulatoryCompliance,
  EngineeringRFDSPhase1,
} from '@models/interfaces';
import { ProjectsEffects } from '@store/effects';

export const updateEmgineeringPaceTaskFields = (
  regulatoryComplianceSection: EngineeringRegulatoryCompliance,
  RFDSPhase1Section: EngineeringRFDSPhase1,
): void => {
  ProjectsEffects.updatePaceTaskFieldsByConfig(regulatoryComplianceSection, [
    {
      dateType: 'actualDate',
      task: MainPaceTask.RFSSROrderedDate,
      field: 'RFSSROrderedDate',
    },
    {
      dateType: 'actualDate',
      task: MainPaceTask.RFSSRReceivedDate,
      field: 'RFSSRReceivedDate',
    },
  ]);
  ProjectsEffects.updatePaceTaskFieldsByConfig(RFDSPhase1Section, [
    {
      dateType: 'actualDate',
      task: MainPaceTask.FinalRFDSReceived,
      field: 'finalRFDSReceived',
    },
    {
      dateType: 'PACEActualDate',
      task: MainPaceTask.RBSDataEntered,
      field: 'CI004',
    },
  ]);
};
