import React, { FC, PropsWithChildren, useEffect, useMemo } from 'react';
import { Form } from 'antd';
import { HttpService } from '@core/services';
import { DropdownHttpService } from '@core/services/http';
import { getFormData, isValidForm } from '@core/utils/methods';
import { serverValidator } from '@core/utils/validators';
import { WHITESPACE_INPUT_LIMIT_RULES } from '@models/constants';
import { ModalMainTypes } from '@models/enums';
import { AdminFrequencyDDV } from '@models/interfaces';
import { AddAndEditModal } from '@shared/components';
import { Checkbox, Input } from '@shared/modules';
import { DropdownValuesModalProps } from './models';

import './styles.scss';

const { useForm } = Form;

export const DropdownValuesModal: FC<DropdownValuesModalProps> = ({
  itemValue,
  visible,
  modalType,
  toggleModal,
  selectorValue,
}: PropsWithChildren<DropdownValuesModalProps>) => {
  const [form] = useForm();

  const dropdownService = useMemo(
    () => HttpService.getHttpRequests(DropdownHttpService),
    [],
  );

  const add = async ({
    value,
    isFirstNet,
  }: Partial<AdminFrequencyDDV>): Promise<void> => {
    if (await isValidForm(form)) {
      if (value) {
        await dropdownService.addDropdownValue(
          selectorValue as string,
          value,
          isFirstNet,
        );
      }

      toggleModal(modalType);
    }
  };

  const edit = async ({
    value,
    isFirstNet,
  }: Partial<AdminFrequencyDDV>): Promise<void> => {
    if (await isValidForm(form)) {
      if (value) {
        await dropdownService.updateDropdownValue(
          itemValue.id,
          value,
          isFirstNet,
        );
      }

      toggleModal(modalType);
    }
  };

  useEffect(() => {
    if (visible) {
      form.setFieldsValue(itemValue);
    }
  }, [visible, itemValue, form]);

  return (
    <AddAndEditModal
      onOk={
        modalType === ModalMainTypes.Add
          ? (): Promise<void> => add(getFormData(itemValue, form))
          : (): Promise<void> => edit(getFormData(itemValue, form))
      }
      onCancel={(): void => toggleModal(modalType)}
      visible={visible}
      type={modalType}
      title="Dropdown Value"
      okText="Save"
      cancelText="Cancel"
      className="prov-admin-dropdown-values-modal"
      formProps={{ wrapperCol: { span: 15 }, form, initialValues: itemValue }}
    >
      <Input
        id="value"
        label="Dropdown value"
        formItemProps={{
          rules: [
            {
              max: 255,
              message: 'Dropdown Value should be 255 characters or less.',
            },
            serverValidator(
              {
                method: 'getDropdownValueValidation',
                errorMessage: 'Dropdown value must be unique',
              },
              DropdownHttpService,
              modalType === ModalMainTypes.Add ? undefined : itemValue.id,
              selectorValue,
            ),
            ...WHITESPACE_INPUT_LIMIT_RULES,
          ],
        }}
      />
      {selectorValue === 'Frequency' && (
        <Checkbox label="Is First Net?" id="isFirstNet" />
      )}
    </AddAndEditModal>
  );
};
