import React, { FC, PropsWithChildren, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Form } from 'antd';
import { DropDownSelectorValues } from 'providence-types';
import {
  arraySort,
  canBeEditable,
  momentizeObjectDates,
} from '@core/utils/methods';
import {
  JURISDICTION_DATES_FOR_MOMENTIZE,
  YES_NO_GROUP,
} from '@models/constants';
import { OptionProps } from '@models/interfaces';
import {
  Autocomplete,
  DatePicker,
  Input,
  InputNumber,
  RadioGroup,
  TextArea,
} from '@shared/modules';
import { DDVSelectors } from '@store/selectors';
import { ZoningNSBFormProps } from '../../models';

export const ZoningNSBForm: FC<ZoningNSBFormProps> = ({
  isEditing,
  permissions: {
    applicationRequirements,
    describeOverallProcess,
    estimatedZoningApprovalTimeline,
    otherFees,
    specialLicensingOrBondingConditions,
    thirdPartyCostRequired,
    writtenDocumentationFromJurisdiction,
    notes,
    zoningRequired,
    separationRequirements,
    setbacks,
    towerHeightRestrictions,
    jurisdictionReviewDate,
  },
  formValue,
  formUpdating,
}: PropsWithChildren<ZoningNSBFormProps>) => {
  const [form] = Form.useForm();

  const zoningRequiredDDV = useSelector(
    DDVSelectors.getDDVValuesByType(DropDownSelectorValues.ZoningRequired),
  ) as OptionProps[];

  const estimatedZoningApprovalTimelineDDV = useSelector(
    DDVSelectors.getDDVValuesByType(
      DropDownSelectorValues.EstimatedZoningApprovalTimeline,
    ),
  ) as OptionProps[];

  useEffect(() => {
    form.setFieldsValue(
      momentizeObjectDates(formValue, JURISDICTION_DATES_FOR_MOMENTIZE),
    );
  }, [isEditing]);

  const update = (): void => {
    if (formUpdating) {
      formUpdating(formValue.id, form.getFieldsValue());
    }
  };

  return (
    <Form
      className="prov-jurisdiction-configurable-panel__form"
      labelCol={{ span: 10 }}
      form={form}
      onValuesChange={update}
    >
      <Autocomplete
        id="zoningRequired"
        className="left"
        label="Zoning Required"
        options={arraySort(zoningRequiredDDV, 'DESC', 'viewValue')}
        isEditing={canBeEditable(zoningRequired, isEditing)}
      />
      <Input
        id="setbacks"
        className="right"
        label="Setbacks"
        isEditing={canBeEditable(setbacks, isEditing)}
      />
      <RadioGroup
        id="writtenDocumentationFromJurisdiction"
        className="left"
        label="Written Documentation from Jurisdiction"
        isEditing={canBeEditable(
          writtenDocumentationFromJurisdiction,
          isEditing,
        )}
        buttons={YES_NO_GROUP}
      />
      <Input
        id="specialLicensingOrBondingConditions"
        className="right"
        label="Special Licensing/Bonding Conditions"
        isEditing={canBeEditable(
          specialLicensingOrBondingConditions,
          isEditing,
        )}
      />
      <Input
        id="describeOverallProcess"
        className="left"
        label="Describe Overall Process"
        isEditing={canBeEditable(describeOverallProcess, isEditing)}
      />
      <InputNumber
        id="thirdPartyCostRequired"
        className="right"
        label="Third-Party Cost Required"
        elementProps={{ min: 1 }}
        isEditing={canBeEditable(thirdPartyCostRequired, isEditing)}
      />
      <Autocomplete
        id="estimatedZoningApprovalTimeline"
        className="left"
        label="Estimated Zoning Approval Timeline"
        options={estimatedZoningApprovalTimelineDDV}
        isEditing={canBeEditable(estimatedZoningApprovalTimeline, isEditing)}
      />
      <InputNumber
        id="otherFees"
        className="right"
        label="Other Fees"
        elementProps={{ min: 1 }}
        isEditing={canBeEditable(otherFees, isEditing)}
      />
      <Input
        id="applicationRequirements"
        className="left"
        label="Application Requirements"
        isEditing={canBeEditable(applicationRequirements, isEditing)}
      />
      <TextArea
        id="notes"
        className="right"
        label="Zoning Notes"
        isEditing={canBeEditable(notes, isEditing)}
      />
      <InputNumber
        id="towerHeightRestrictions"
        className="left"
        label="Tower Height Restrictions"
        elementProps={{ min: 1 }}
        isEditing={canBeEditable(towerHeightRestrictions, isEditing)}
      />
      <DatePicker
        id="jurisdictionReviewDate"
        className="right"
        label="Jurisdiction Review Date"
        isEditing={canBeEditable(jurisdictionReviewDate, isEditing)}
      />
      <Input
        id="separationRequirements"
        className="left"
        label="Separation Requirements"
        isEditing={canBeEditable(separationRequirements, isEditing)}
      />
    </Form>
  );
};
