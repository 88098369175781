import React, { FC, PropsWithChildren, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Col, Form, Row } from 'antd';
import { momentizeObjectDates } from '@core/utils/methods';
import { FormProps, LeasingEngineeringWorkflow } from '@models/interfaces';
import { Panel } from '@shared/components';
import { Autocomplete, DatePicker, Input } from '@shared/modules';
import { DDVSelectors } from '@store/selectors';
import { LEASING_ENGINEERING_INFO_DATES } from '../../models/constants';

export const EngineeringPanel: FC<FormProps> = ({
  form,
  isEditing,
  data,
  onValuesChange,
  permissions: {
    failingMountAnalysisReviewed,
    mountAnalysisDetails,
    passingMountAnalysisReviewed,
    CDsLandlordApprovalRequired,
    CDsSentToLandlord,
    CDsReceivedFromLandlord,
    prelimCDsApprovedLeasing,
    finalCDsReviewedLeasing,
  },
}: PropsWithChildren<FormProps>) => {
  const dropdownsOptions = useSelector(DDVSelectors.getDDVOptions);

  useEffect(() => {
    if (data) {
      form?.setFieldsValue(
        momentizeObjectDates<LeasingEngineeringWorkflow>(
          {
            ...data,
            mountModelReplacement: data?.mountModelReplacement?.value,
          },
          LEASING_ENGINEERING_INFO_DATES,
        ),
      );
    }
  }, [data]);

  return (
    <Panel header="Engineering" className="engineering-panel" collapsible>
      <Form
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 14 }}
        onValuesChange={onValuesChange}
        form={form}
      >
        <Row>
          <Col span="11">
            <Autocomplete
              id="AEVendor"
              label="A/E CD Vendor"
              isEditing={false}
              options={dropdownsOptions?.AEVendor}
            />
            <Autocomplete
              id="AEMountVendor"
              label="A/E Mount Vendor"
              isEditing={false}
              options={dropdownsOptions?.AEMountVendor}
            />
            <DatePicker
              id="mountMappingOrder"
              label="Mount Mapping Ordered"
              isEditing={false}
            />
            <DatePicker
              id="mountMappingReceived"
              label="Mount Mapping Received"
              isEditing={false}
            />
            <DatePicker
              id="mountAnalysisOrdered"
              label="Mount Analysis Ordered"
              isEditing={false}
            />
            <DatePicker
              id="failingMountAnalysisReceived"
              label="Failing Mount Analysis Received"
              isEditing={false}
            />
            <DatePicker
              id="failingMountAnalysisReviewed"
              label="Failing Mount Analysis Reviewed"
              isEditing={isEditing && failingMountAnalysisReviewed}
            />
            <DatePicker
              id="passingMountAnalysisReceived"
              label="Passing Mount Analysis Received"
              isEditing={false}
            />
            <Autocomplete
              id="mountAnalysisDetails"
              label="Mount Analysis Details"
              isEditing={isEditing && mountAnalysisDetails}
              options={dropdownsOptions?.MountAnalysisDetails}
            />
            <Input
              id="mountModelReplacement"
              label="Mount Model Replacement"
              isEditing={false}
            />
          </Col>
          <Col span="11">
            <DatePicker
              id="passingMountAnalysisReviewed"
              label="Passing Mount Analysis Reviewed"
              isEditing={isEditing && passingMountAnalysisReviewed}
            />
            <Autocomplete
              id="civilVendor"
              label="Civil Vendor"
              isEditing={false}
              options={dropdownsOptions?.CivilVendor}
            />
            <DatePicker
              id="prelimCDsOrdered"
              label="Prelim CDs Ordered"
              isEditing={false}
            />
            <DatePicker
              id="prelimCDsReceived"
              label="Prelim CDs Received"
              isEditing={false}
            />
            <Autocomplete
              id="CDsLandlordApprovalRequired"
              label="CDs Landlord Approval Required"
              isEditing={isEditing && CDsLandlordApprovalRequired}
              options={dropdownsOptions?.CDsLandlordApprovalRequired}
            />
            <DatePicker
              id="CDsSentToLandlord"
              label="CDs Sent to Landlord"
              isEditing={isEditing && CDsSentToLandlord}
            />
            <DatePicker
              id="CDsReceivedFromLandlord"
              label="CDs Received from Landlord"
              isEditing={isEditing && CDsReceivedFromLandlord}
            />
            <DatePicker
              id="prelimCDsApprovedLeasing"
              label="Prelim CDs Approved - Leasing"
              isEditing={isEditing && prelimCDsApprovedLeasing}
            />
            <DatePicker
              id="finalCDsReceived"
              label="Final CDs Received"
              isEditing={false}
            />
            <DatePicker
              id="finalCDsReviewedLeasing"
              label="Final CDs Reviewed - Leasing"
              isEditing={isEditing && finalCDsReviewedLeasing}
            />
          </Col>
        </Row>
      </Form>
    </Panel>
  );
};
