import { EnvironmentType } from '../../models/enums';
import {
  ConnectionConfig,
  FirebaseConfig,
  ProcessEnvMap,
} from '../../models/interfaces';

export class Config {
  private static instance: Config;
  private readonly env: ProcessEnvMap;

  static get(): Config {
    this.instance = this.instance ?? new Config();

    return this.instance;
  }

  private constructor() {
    this.env = process.env as ProcessEnvMap;
  }

  // # Env
  get mode(): EnvironmentType {
    return this.env.NODE_ENV as EnvironmentType;
  }

  get isDevelopment(): boolean {
    return this.mode === EnvironmentType.DEV;
  }

  get production(): boolean {
    return this.mode === EnvironmentType.PROD;
  }

  // # Connection
  get connectionConfig(): ConnectionConfig {
    return {
      apiUrl: `${this.env.REACT_APP_API_URL}/api`,
      staticApiUrl: this.env.REACT_APP_API_URL,
      wsUrl: this.env.REACT_APP_SOCKET_URL,
      qoppa: this.env.REACT_APP_QOPPA_PAS,
      requestTimeout: +this.env.REACT_APP_REQUEST_TIMEOUT,
    };
  }

  // # Firebase
  get firebaseConfig(): FirebaseConfig {
    return {
      apiKey: this.env.REACT_APP_FIREBASE_API_KEY,
      authDomain: this.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
      projectId: this.env.REACT_APP_FIREBASE_PROJECT_ID,
      storageBucket: this.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
      messagingSenderId: this.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
      appId: this.env.REACT_APP_FIREBASE_APP_ID,
    };
  }

  get fireBaseVapidKey(): string {
    return this.env.REACT_APP_FIREBASE_VAPID_KEY;
  }

  // # Zoho
  get zohoSrc(): string {
    return this.env.REACT_APP_ZOHO_SCRIPT_SRC;
  }
}
