import {
  EngineeringManagerType,
  ProjectType,
  SubPage,
  Tab,
  UserRole,
} from 'providence-types';
import { createSelector, OutputSelector } from 'reselect';
import { getEnumKeyByEnumValue } from '@core/utils/methods';
import {
  BrowseColumnsSettings,
  ColumnSettings,
  EngineeringPermission,
  SitePermission,
  UserPermissions,
} from '@models/interfaces';
import {
  ColumnsSettingsSubmodulesKeys,
  UserPermissionKeys,
} from '@models/types';
import { AppState } from '../reducers';
import { UserState } from '../reducers/user.reducer';

const userState = (state: AppState): UserState => state.user;

export namespace UserSelectors {
  export const activeUser = createSelector(
    [userState],
    (state: UserState) => state.activeUser,
  );

  export const isAuthorized = createSelector(
    [userState],
    (state: UserState) => !!Object.keys(state.activeUser).length,
  );

  export const isFetching = createSelector(
    [userState],
    (state: UserState) => state.isFetching,
  );

  export const getColumnsSettings = (
    module: keyof BrowseColumnsSettings,
    subModule?: ColumnsSettingsSubmodulesKeys,
  ): OutputSelector<
    [typeof userState],
    ColumnSettings[],
    (res: UserState) => ColumnSettings[]
  > =>
    createSelector(
      [userState],
      ({
        activeUser: {
          settings: { columns },
        },
      }: UserState) =>
        (module === 'accounting' || module === 'home'
          ? ((columns[module] as any) || {})[subModule!]
          : columns?.[module]) || [],
    );

  export const getFeeAccess = (
    feeAccessPath?: keyof Pick<
      UserPermissions,
      | 'projects'
      | 'engineering'
      | 'leasing'
      | 'permitting'
      | 'accounting'
      | 'projectManagement'
    >,
  ): OutputSelector<
    [typeof userState],
    boolean,
    (state: UserState) => boolean
  > =>
    createSelector([userState], (state: UserState) =>
      feeAccessPath
        ? !!state.activeUser.permissions[feeAccessPath].browse.feeAccess
        : false,
    );

  export const getDefaultPMProjectType = createSelector(
    [userState],
    (state: UserState) => state.activeUser?.settings?.defaultPMProjectType,
  );

  export const getPermittingJurisdictionPermissions = createSelector(
    [userState],
    (state: UserState) =>
      state.activeUser?.permissions?.permitting?.jurisdiction,
  );

  export const getPermittingBrowsePermissions = createSelector(
    [userState],
    (state: UserState) => state.activeUser?.permissions?.permitting?.browse,
  );

  export const getSiteBrowsePermissions = createSelector(
    [userState],
    (state: UserState) => state.activeUser?.permissions?.site?.browse,
  );

  export const getEngineeringBrowsePermissions = createSelector(
    [userState],
    (state: UserState) => state.activeUser?.permissions?.engineering?.browse,
  );

  export const getEngineeringSiteAuditPermissions = createSelector(
    [userState],
    (state: UserState) => {
      const {
        tableAccess,
        attachmentAccess,
        siteInformationAuditFields: {
          // general fields
          siteAuditRequired,
          scheduledSiteWalk,
          siteWalkCompleted,
          siteAuditCompletedBy,
          siteAuditNote,
          followUpRequired,
          savedToFile,
          towerType,
          equipmentType,
          // LTE fields
          open48VBreakPositions,
          existingRectifiers,
          icebridgeLength,
          fiberAndDCTrunkInInnerduct,
          isFiberRunWithDCTrunkInSameDuct,
          powerPlantType,
          // NSB fields
          overallCompoundSize,
          topGuyTorqueOrPullOff,
          secondTorqueArmOrPullOff,
          thirdTorqueArmOrPullOff,
          fourthTorqueArmOrPullOff,
          fifthTorqueArmOrPullOff,
          powerProvider,
          telcoProvider,
        } = {} as EngineeringPermission.SiteInformationAuditFields,
      } = state.activeUser?.permissions?.engineering?.siteAudit || {};

      return {
        tableAccess,
        attachmentAccess,
        siteInformationAuditFields: {
          generalFields: {
            siteAuditRequired,
            scheduledSiteWalk,
            siteWalkCompleted,
            siteAuditCompletedBy,
            siteAuditNote,
            followUpRequired,
            savedToFile,
            towerType,
            equipmentType,
          },
          LTEFields: {
            open48VBreakPositions,
            existingRectifiers,
            icebridgeLength,
            fiberAndDCTrunkInInnerduct,
            isFiberRunWithDCTrunkInSameDuct,
            powerPlantType,
          },
          NSBFields: {
            overallCompoundSize,
            topGuyTorqueOrPullOff,
            secondTorqueArmOrPullOff,
            thirdTorqueArmOrPullOff,
            fourthTorqueArmOrPullOff,
            fifthTorqueArmOrPullOff,
            powerProvider,
            telcoProvider,
          },
        },
      };
    },
  );

  export const getEngineeringScopingPermissions = createSelector(
    [userState],
    (state: UserState) => state.activeUser?.permissions?.engineering?.scoping,
  );

  export const getUserId = createSelector(
    [userState],
    (state: UserState) => state.activeUser?.id,
  );

  export const getUserRoles = createSelector(
    [userState],
    (state: UserState) => state.activeUser?.roles,
  );

  export const hasUserRoles = (role: UserRole) =>
    createSelector([userState], (state: UserState) =>
      state.activeUser?.roles?.includes(role),
    );

  export const getUserNotifications = createSelector(
    [userState],
    (state: UserState) => state.notifications,
  );

  export const getSitePermissions = (
    key?: 'browse' | 'create',
  ): OutputSelector<
    [typeof userState],
    SitePermission.Main | SitePermission.Browse | SitePermission.Create,
    (
      state: UserState,
    ) => SitePermission.Main | SitePermission.Browse | SitePermission.Create
  > =>
    createSelector([userState], (state: UserState) =>
      key
        ? state.activeUser?.permissions?.site[key]
        : state.activeUser?.permissions?.site,
    );

  export const hasAccountingTableAccess = createSelector(
    [userState],
    (state: UserState) =>
      state.activeUser?.permissions?.accounting?.browse?.tableAccess,
  );

  export const getLeasingPermissions = createSelector(
    [userState],
    (state: UserState) => state.activeUser?.permissions?.leasing,
  );

  export const getProjectInformationPermissions = createSelector(
    [userState],
    (state: UserState) =>
      state.activeUser?.permissions?.projects?.create?.projectInformationFields,
  );

  export const getProjectBrowsePermissions = createSelector(
    [userState],
    (state: UserState) => state.activeUser?.permissions?.projects?.browse,
  );

  export const canEditProjectByProjectType = (
    projectType: ProjectType | string,
  ): OutputSelector<
    [typeof userState],
    boolean,
    (state: UserState) => boolean
  > =>
    createSelector(
      [userState],
      ({ activeUser: currentActiveUser }: UserState) => {
        let result = true;

        if (
          currentActiveUser.roles.includes(UserRole.EngineeringManager) &&
          currentActiveUser.engineeringManagerType !==
            EngineeringManagerType.All
        ) {
          const projectTypeByEMType = getEnumKeyByEnumValue(
            EngineeringManagerType,
            currentActiveUser.engineeringManagerType!,
          );

          result = projectTypeByEMType === projectType;
        }

        return result;
      },
    );

  export const canEdit = (
    area: UserPermissionKeys,
    subPage: SubPage,
    tab: Tab,
    defaultValue: boolean = false,
  ): OutputSelector<
    [(state: AppState) => UserState],
    boolean,
    (state: UserState) => boolean
  > =>
    createSelector(
      [userState],
      ({ activeUser: currentActiveUser }: UserState) => {
        const result =
          currentActiveUser.permissions[area]?.tabs?.[subPage]?.[tab].canEdit;

        return result !== undefined ? result : defaultValue;
      },
    );
}
