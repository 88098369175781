import { Resolver } from '@wellyes/react-router-extended';
import { HttpService } from '@core/services';
import {
  EquipmentHttpService,
  ProjectCategoryHttpService,
  SiteAuditHttpService,
} from '@core/services/http';
import { getIdParamFromUrl } from '@core/utils/methods';
import { EquipmentType } from '@models/enums';

export class SiteAuditDetailsResolver implements Resolver {
  async resolve(): Promise<void> {
    const [id] = getIdParamFromUrl();

    await HttpService.getHttpRequests(
      ProjectCategoryHttpService,
    ).getProjectCategories();

    await HttpService.getHttpRequests(EquipmentHttpService).getEquipmentTypes();

    await HttpService.getHttpRequests(
      EquipmentHttpService,
    ).getEquipmentsByTypes([EquipmentType.Cable]);

    await HttpService.getHttpRequests(
      EquipmentHttpService,
    ).getEquipmentsByManufacturers(['Raycap']);

    await HttpService.getHttpRequests(SiteAuditHttpService).getSiteAudit(id);
  }
}
