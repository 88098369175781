export enum HttpServices {
  Accounting,
  Auth,
  CountyStates,
  DefaultTowerAgent,
  DriverManagement,
  Dropdown,
  Engineering,
  Equipment,
  Financial,
  Leasing,
  Notifications,
  PaceTask,
  Permitting,
  ProgressStatus,
  ProjectCategory,
  Project,
  ProjectManagement,
  Site,
  User,
  Vendor,
}
