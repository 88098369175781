import React, { FC, PropsWithChildren, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'antd';
import classNames from 'classnames';
import { PaceTaskScreen } from 'providence-types';
import { HttpService } from '@core/services';
import { ProjectHttpService } from '@core/services/http';
import { useDidUpdateEffect, useSaveChanged } from '@core/utils/hooks';
import { isValidForm } from '@core/utils/methods';
import { FormProps } from '@models/interfaces';
import {
  getPaceTaskDifference,
  NotificationsLoader,
  paceTaskMapper,
  PaceTaskTable,
} from '@shared/components';
import { PrimaryButton } from '@shared/modules';
import { CommonActions, ProjectActions } from '@store/actions';
import {
  EngineeringSelectors,
  PaceTasksSelectors,
  ProjectCategoriesSelectors,
  UserSelectors,
} from '@store/selectors';
import {
  LeasingInformation,
  ProjectInformation,
  SiteInformation,
} from './components';

import './styles.scss';

const { useForm } = Form;

export const EngineeringSummary: FC<FormProps> = ({
  isEditing = false,
  toggleEditing,
}: PropsWithChildren<FormProps>) => {
  const dispatch = useDispatch();

  const [paceTaskForm] = useForm();

  const projectCategoriesTableVisible = useSelector(
    ProjectCategoriesSelectors.getProjectCategoriesTableVisible,
  );
  const currentPaceTasks = useSelector(PaceTasksSelectors.getPaceTasks);
  const {
    engineeringLeasingData,
    engineeringSiteData,
    engineeringProjectData,
    scopingInfo,
  } = useSelector(EngineeringSelectors.getSummaryData);
  const arePaceTasksChanged = useSelector(
    PaceTasksSelectors.arePaseTasksChanged,
  );
  const engineeringFetching = useSelector(EngineeringSelectors.isFetching);
  const PACEFetching = useSelector(PaceTasksSelectors.isFetching);
  const roles = useSelector(UserSelectors.getUserRoles);

  const onSubmit = async (): Promise<void> => {
    const [paceTasksData, keys] = getPaceTaskDifference(
      currentPaceTasks,
      paceTaskForm,
      roles,
    );

    if (await isValidForm(paceTaskForm, keys)) {
      try {
        await HttpService.getHttpRequests(
          ProjectHttpService,
        ).updateProjectPaceTasks(
          engineeringProjectData?.id,
          PaceTaskScreen.Engineering,
          paceTasksData,
        );

        toggleEditing?.();

        dispatch(
          ProjectActions.updateProjectPaceTaskFieldsFromSummaryPageTable.done(
            paceTasksData,
          ),
        );

        dispatch(ProjectActions.setArePACETasksChanged.done(false));

        NotificationsLoader.notificationSuccess(
          'PACE tasks have been updated!',
        );
      } catch (e) {
        console.error(e);
      }
    }
  };

  const onCancel = (): void => {
    paceTaskForm.setFieldsValue(paceTaskMapper(currentPaceTasks));

    dispatch(ProjectActions.setArePACETasksChanged.done(false));

    toggleEditing?.();
  };

  useEffect(
    () => (): void => {
      dispatch(CommonActions.setHasUnsubmittedData.done(false));
    },
    [],
  );

  useDidUpdateEffect(() => {
    dispatch(CommonActions.setHasUnsubmittedData.done(arePaceTasksChanged));
  }, [arePaceTasksChanged]);

  useSaveChanged(isEditing, onSubmit, onCancel);

  return (
    <div className="prov-engineering-summary ">
      <div
        className={classNames('tabs-wrap', {
          'tabs-wrap_with-actions': isEditing,
          'categories-table-open': projectCategoriesTableVisible,
        })}
      >
        <div className="prov-engineering-summary__wrap">
          <div className="info-panel">
            <SiteInformation
              className="info-panel__information"
              data={engineeringSiteData}
              expectedId={engineeringSiteData?.id}
            />
            <ProjectInformation
              className="info-panel__information"
              colSpan="24"
              data={{ ...engineeringProjectData, ...scopingInfo }}
              expectedId={engineeringProjectData?.id}
            />
            <LeasingInformation
              className="info-panel__information"
              data={engineeringLeasingData}
              expectedId={engineeringProjectData?.id}
            />
          </div>
          <PaceTaskTable
            isEditing={isEditing}
            data={currentPaceTasks || []}
            form={paceTaskForm}
          />
        </div>
      </div>
      {isEditing && (
        <div className="prov-leasing-handle__btns">
          <div className="action-wrap">
            <PrimaryButton
              title="Submit"
              type="primary"
              disabled={engineeringFetching || PACEFetching}
              onClick={onSubmit}
            />
            <PrimaryButton title="Cancel" type="default" onClick={onCancel} />
          </div>
        </div>
      )}
    </div>
  );
};
