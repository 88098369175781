import { browseReducer } from '../../core/utils/methods/browse-sorter-reducer';
import { createReducer, on } from '../../core/utils/store';
import {
  AddPermitTypeData,
  JurisdictionBrowse,
  JurisdictionDetails,
  JurisdictionDetailsContacts,
  JurisdictionDetailsPermitting,
  JurisdictionDetailsPermittingType,
  JurisdictionDetailsZoning,
  JurisdictionModalValues,
  JurisdictionOption,
  JurisdictionPermittingData,
  JurisdictionZoningData,
  PaginatedResponse,
  PayloadAndState,
  PermittingBrowse,
  PermittingDetails,
  PermittingJurisdictionAuditBody,
  PermittingWorkflowBody,
} from '../../models/interfaces';
import {
  JurisdictionPermittingSectionType,
  RemovePermitTypeData,
} from '../../models/types';
import { PermittingActions } from '../actions/permitting.actions';

export interface PermittingState {
  browseData: PaginatedResponse<PermittingBrowse>;
  jurisdictionList: JurisdictionOption[];
  detailsData: PermittingDetails;
  jurisdictionBrowseData: PaginatedResponse<JurisdictionBrowse>;
  jurisdictionDetailsData: JurisdictionDetails;
  isFetching: boolean;
  errors: boolean;
}

const initialState: PermittingState = {
  browseData: { items: [], total: 0 },
  jurisdictionList: [],
  detailsData: {} as PermittingDetails,
  jurisdictionBrowseData: { items: [], total: 0 },
  jurisdictionDetailsData: {} as JurisdictionDetails,
  isFetching: false,
  errors: false,
};

export const reducer = createReducer(
  initialState,
  // GET PERMITTING BROWSE DATA
  on(PermittingActions.getPermittingBrowseData, browseReducer('browseData')),
  // get jurisdiction list
  on(
    PermittingActions.getJurisdictionList,
    ({
      payload: jurisdictionList,
    }: PayloadAndState<JurisdictionOption[], PermittingState>) => ({
      jurisdictionList,
    }),
  ),
  // get details data
  on(
    PermittingActions.getPermittingDetailsData,
    ({
      payload: detailsData,
    }: PayloadAndState<PermittingDetails, PermittingState>) => ({
      detailsData,
    }),
  ),
  // add permitting type
  on(
    PermittingActions.permittingAddPermittingType,
    ({
      payload: { data },
      state: { detailsData },
    }: PayloadAndState<AddPermitTypeData, PermittingState>) => ({
      detailsData: {
        ...detailsData,
        site: {
          ...detailsData.site,
          jurisdiction: {
            ...detailsData.site.jurisdiction,
            permitting: {
              ...detailsData.site.jurisdiction!.permitting,
              permittingTypes: [
                ...detailsData.site.jurisdiction!.permitting.permittingTypes,
                data,
              ],
            },
          },
        },
      } as PermittingDetails,
    }),
  ),
  // update permitting type
  on(
    PermittingActions.permittingUpdatePermittingType,
    ({
      payload: {
        data: { id, ...updatedData },
      },
      state: { detailsData },
    }: PayloadAndState<AddPermitTypeData, PermittingState>) => ({
      detailsData: {
        ...detailsData,
        site: {
          ...detailsData.site,
          jurisdiction: {
            ...detailsData.site.jurisdiction,
            permitting: {
              ...detailsData.site.jurisdiction!.permitting,
              permittingTypes:
                detailsData.site.jurisdiction!.permitting.permittingTypes.map(
                  (permittingType: JurisdictionDetailsPermittingType) =>
                    permittingType.id !== id
                      ? permittingType
                      : { ...permittingType, ...updatedData },
                ),
            },
          },
        },
      } as PermittingDetails,
    }),
  ),
  // remove permitting type
  on(
    PermittingActions.permittingRemovePermittingType,
    ({
      payload: { permittingTypeId },
      state: { detailsData },
    }: PayloadAndState<RemovePermitTypeData, PermittingState>) => ({
      detailsData: {
        ...detailsData,
        site: {
          ...detailsData.site,
          jurisdiction: {
            ...detailsData.site.jurisdiction,
            permitting: {
              ...detailsData.site.jurisdiction!.permitting,
              permittingTypes:
                detailsData.site.jurisdiction!.permitting.permittingTypes.filter(
                  (permittingType: JurisdictionDetailsPermittingType) =>
                    permittingType.id !== permittingTypeId,
                ),
            },
          },
        },
      } as PermittingDetails,
    }),
  ),
  // update jurisdiction audit data
  on(
    PermittingActions.permittingUpdateJurisdictionAuditData,
    ({
      payload: {
        summarySection: { id: jurisdictionId, ...jurisdiction },
        zoningSection,
        permittingSection,
      },
      state: { detailsData },
    }: PayloadAndState<PermittingJurisdictionAuditBody, PermittingState>) => {
      const { id: zoningId, ...zoning } = zoningSection ?? {};
      const { id: permittingId, ...permitting } = permittingSection ?? {};

      return {
        detailsData: {
          ...detailsData,
          site: {
            ...detailsData.site,
            jurisdiction: {
              ...detailsData.site.jurisdiction,
              ...jurisdiction,
              zoning: {
                ...detailsData.site.jurisdiction!.zoning,
                ...zoning,
              },
              permitting: {
                ...detailsData.site.jurisdiction!.permitting,
                ...permitting,
              },
            },
          },
        } as PermittingDetails,
      };
    },
  ),
  // update permittting workflow permittingFields
  on(
    PermittingActions.permittingUpdateWorkflowData,
    ({
      payload: { projectPermittingNotes },
      state: { detailsData },
    }: PayloadAndState<PermittingWorkflowBody, PermittingState>) => ({
      detailsData:
        projectPermittingNotes !== undefined
          ? {
              ...detailsData,
              projectPermittingNotes,
            }
          : detailsData,
    }),
  ),
  // JURISDICTION
  // get jurisdiction browse data
  on(
    PermittingActions.getJurisdictionBrowseData,
    ({
      payload: jurisdictionBrowseData,
    }: PayloadAndState<
      PaginatedResponse<JurisdictionBrowse>,
      PermittingState
    >) => ({
      jurisdictionBrowseData,
    }),
  ),
  // add jurisdiction
  on(
    PermittingActions.addJurisdiction,
    ({
      payload: jurisdictionBrowseData,
    }: PayloadAndState<
      PaginatedResponse<JurisdictionBrowse>,
      PermittingState
    >) => ({
      jurisdictionBrowseData,
    }),
  ),
  // get jurisdiction details data
  on(
    PermittingActions.getJurisdictionDetailsData,
    ({
      payload: jurisdictionDetailsData,
    }: PayloadAndState<JurisdictionDetails, PermittingState>) => ({
      jurisdictionDetailsData,
    }),
  ),
  // ZONING PANEL
  // add jurisdiction zoning panel
  on(
    PermittingActions.addJurisdictionZoningPanel,
    ({
      payload,
      state: { jurisdictionDetailsData },
    }: PayloadAndState<JurisdictionDetailsZoning, PermittingState>) => ({
      jurisdictionDetailsData: {
        ...jurisdictionDetailsData,
        zoning: [...jurisdictionDetailsData.zoning, payload],
      },
    }),
  ),
  // update jurisdiction zoning panel
  on(
    PermittingActions.updateJurisdictionZoningPanel,
    ({
      payload,
      state: { jurisdictionDetailsData },
    }: PayloadAndState<JurisdictionModalValues, PermittingState>) => ({
      jurisdictionDetailsData: {
        ...jurisdictionDetailsData,
        zoning: jurisdictionDetailsData.zoning.map(
          (zoning: JurisdictionDetailsZoning) =>
            zoning.id !== payload.id
              ? zoning
              : {
                  ...zoning,
                  ...payload,
                },
        ),
      },
    }),
  ),
  // remove jurisdiction zoning panel
  on(
    PermittingActions.removeJurisdictionZoningPanel,
    ({
      payload: id,
      state: { jurisdictionDetailsData },
    }: PayloadAndState<string, PermittingState>) => ({
      jurisdictionDetailsData: {
        ...jurisdictionDetailsData,
        zoning: [
          ...jurisdictionDetailsData.zoning.filter(
            (zoning: JurisdictionDetailsZoning) => zoning.id !== id,
          ),
        ],
      },
    }),
  ),
  // JURISDICTION ZONING
  // update jurisdiction zoning
  on(
    PermittingActions.updateJurisdictionZoning,
    ({
      payload: { summarySection, zoningSection },
      state: { jurisdictionDetailsData },
    }: PayloadAndState<JurisdictionZoningData, PermittingState>) => ({
      jurisdictionDetailsData: {
        ...jurisdictionDetailsData,
        ...summarySection,
        zoning: jurisdictionDetailsData.zoning.map(
          (zoning: JurisdictionDetailsZoning) => {
            const currentUpdatedZoning = zoningSection.find(
              (updatedZoning: JurisdictionDetailsZoning) =>
                updatedZoning.id === zoning.id,
            );

            if (currentUpdatedZoning) {
              const { id, projectType, projectSubType, ...updatedZoningData } =
                currentUpdatedZoning;

              return {
                ...zoning,
                ...updatedZoningData,
              };
            }

            return zoning;
          },
        ),
      },
    }),
  ),
  // PERMITTING TYPE TABLE
  // add permitting type
  on(
    PermittingActions.jurisdictionAddPermittingType,
    ({
      payload: { permittingId, data },
      state: { jurisdictionDetailsData },
    }: PayloadAndState<AddPermitTypeData, PermittingState>) => ({
      jurisdictionDetailsData: {
        ...jurisdictionDetailsData,
        permitting: jurisdictionDetailsData.permitting.map(
          (permitting: JurisdictionDetailsPermitting) =>
            permitting.id !== permittingId
              ? permitting
              : {
                  ...permitting,
                  permittingTypes: [...permitting.permittingTypes, data],
                },
        ),
      },
    }),
  ),
  // update permitting type
  on(
    PermittingActions.jurisdictionUpdatePermittingType,
    ({
      payload: {
        permittingId,
        data: { id, ...updatedData },
      },
      state: { jurisdictionDetailsData },
    }: PayloadAndState<AddPermitTypeData, PermittingState>) => ({
      jurisdictionDetailsData: {
        ...jurisdictionDetailsData,
        permitting: jurisdictionDetailsData.permitting.map(
          (permitting: JurisdictionDetailsPermitting) =>
            permitting.id !== permittingId
              ? permitting
              : {
                  ...permitting,
                  permittingTypes: permitting.permittingTypes.map(
                    (permittingType: JurisdictionDetailsPermittingType) =>
                      permittingType.id !== id
                        ? permittingType
                        : {
                            ...permittingType,
                            ...updatedData,
                          },
                  ),
                },
        ),
      },
    }),
  ),
  // remove permitting type
  on(
    PermittingActions.jurisdictionRemovePermittingType,
    ({
      payload: { permittingId, permittingTypeId },
      state: { jurisdictionDetailsData },
    }: PayloadAndState<RemovePermitTypeData, PermittingState>) => ({
      jurisdictionDetailsData: {
        ...jurisdictionDetailsData,
        permitting: jurisdictionDetailsData.permitting.map(
          (permitting: JurisdictionDetailsPermitting) =>
            permitting.id !== permittingId
              ? permitting
              : {
                  ...permitting,
                  permittingTypes: permitting.permittingTypes.filter(
                    (permittingType: JurisdictionDetailsPermittingType) =>
                      permittingType.id !== permittingTypeId,
                  ),
                },
        ),
      },
    }),
  ),
  // PERMITTING PANEL
  // add jurisdiction permitting panel
  on(
    PermittingActions.addJurisdictionPermittingPanel,
    ({
      payload,
      state: { jurisdictionDetailsData },
    }: PayloadAndState<JurisdictionDetailsPermitting, PermittingState>) => ({
      jurisdictionDetailsData: {
        ...jurisdictionDetailsData,
        permitting: [...jurisdictionDetailsData.permitting, payload],
      },
    }),
  ),
  // update jurisdiction permitting panel
  on(
    PermittingActions.updateJurisdictionPermittingPanel,
    ({
      payload,
      state: { jurisdictionDetailsData },
    }: PayloadAndState<JurisdictionModalValues, PermittingState>) => ({
      jurisdictionDetailsData: {
        ...jurisdictionDetailsData,
        permitting: jurisdictionDetailsData.permitting.map(
          (permitting: JurisdictionDetailsPermitting) =>
            permitting.id !== payload.id
              ? permitting
              : {
                  ...permitting,
                  ...payload,
                },
        ),
      },
    }),
  ),
  // remove jurisdiction permitting panel
  on(
    PermittingActions.removeJurisdictionPermittingPanel,
    ({
      payload: id,
      state: { jurisdictionDetailsData },
    }: PayloadAndState<string, PermittingState>) => ({
      jurisdictionDetailsData: {
        ...jurisdictionDetailsData,
        permitting: [
          ...jurisdictionDetailsData.permitting.filter(
            (permitting: JurisdictionDetailsPermitting) => permitting.id !== id,
          ),
        ],
      },
    }),
  ),
  // JURISDICTION PERMITTING
  // update jurisdiction PERMITTING
  on(
    PermittingActions.updateJurisdictionPermitting,
    ({
      payload: { summarySection, permittingSection },
      state: { jurisdictionDetailsData },
    }: PayloadAndState<JurisdictionPermittingData, PermittingState>) => ({
      jurisdictionDetailsData: {
        ...jurisdictionDetailsData,
        ...summarySection,
        permitting: jurisdictionDetailsData.permitting.map(
          (permitting: JurisdictionDetailsPermitting) => {
            const currentUpdatedPermitting = permittingSection.find(
              (updatedPermitting: JurisdictionPermittingSectionType) =>
                updatedPermitting.id === permitting.id,
            );

            if (currentUpdatedPermitting) {
              const {
                id,
                projectType,
                projectSubType,
                ...updatedPermittingData
              } = currentUpdatedPermitting;

              return {
                ...permitting,
                ...updatedPermittingData,
              };
            }

            return permitting;
          },
        ),
      },
    }),
  ),
  // JURISDICTION - CONTACTS
  // add jurisdiction contact
  on(
    PermittingActions.addJurisdictionContact,
    ({
      payload,
      state: { jurisdictionDetailsData },
    }: PayloadAndState<JurisdictionDetailsContacts, PermittingState>) => ({
      jurisdictionDetailsData: {
        ...jurisdictionDetailsData,
        contacts: [...jurisdictionDetailsData.contacts, payload],
      },
    }),
  ),
  // update jurisdiction contact
  on(
    PermittingActions.updateJurisdictionContact,
    ({
      payload,
      state: { jurisdictionDetailsData },
    }: PayloadAndState<JurisdictionDetailsContacts, PermittingState>) => ({
      jurisdictionDetailsData: {
        ...jurisdictionDetailsData,
        contacts: jurisdictionDetailsData.contacts.map(
          (contact: JurisdictionDetailsContacts) =>
            contact.id !== payload.id ? contact : { ...contact, ...payload },
        ),
      },
    }),
  ),
  // remove jurisdiction contact
  on(
    PermittingActions.removeJurisdictionContact,
    ({
      payload: id,
      state: { jurisdictionDetailsData },
    }: PayloadAndState<string, PermittingState>) => ({
      jurisdictionDetailsData: {
        ...jurisdictionDetailsData,
        contacts: [
          ...jurisdictionDetailsData.contacts.filter(
            (contact: JurisdictionDetailsContacts) => contact.id !== id,
          ),
        ],
      },
    }),
  ),
);
