import React, { FC, PropsWithChildren } from 'react';
import { Form } from 'antd';
import classNames from 'classnames';
import { ModalWithFormProps } from '@models/interfaces';
import { Modal } from '@shared/modules';

export const FiltersModal: FC<ModalWithFormProps> = ({
  okText = 'Save',
  cancelText = 'Clear',
  children,
  className,
  formProps: { labelCol = { span: 6 }, form, ...formProps } = {},
  ...props
}: PropsWithChildren<ModalWithFormProps>) => (
  <Modal
    title="Filters"
    okText={okText}
    cancelText={cancelText}
    cancelButtonProps={{
      onClick: (): void => form?.resetFields(),
    }}
    className={classNames(className, 'prov-browse-page__filter-modal')}
    {...props}
  >
    <Form labelCol={labelCol} {...formProps} form={form}>
      {children}
    </Form>
  </Modal>
);
