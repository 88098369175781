import { actionCreatorFactory } from '@core/utils/factories';
import {
  AddAndUpdateDropdownValueResponse,
  OptionProps,
} from '@models/interfaces';
import { DropdownValues } from '@models/types';

const actionCreator = actionCreatorFactory('DROPDOWN_VALUES');

export namespace DropdownValuesActions {
  export const getAdminDropdownValuesDataAction =
    actionCreator.createAsyncAction<DropdownValues>('GET_DROPDOWN_VALUES_DATA');

  export const getAdminDropdownValuesOptionsAction =
    actionCreator.createAsyncAction<OptionProps[]>(
      'GET_DROPDOWN_VALUES_OPTIONS_DATA',
    );

  export const addAdminDropdownValuesDataAction =
    actionCreator.createAsyncAction<AddAndUpdateDropdownValueResponse>(
      'ADD_DROPDOWN_VALUES_DATA',
    );

  export const updateAdminDropdownValuesDataAction =
    actionCreator.createAsyncAction<AddAndUpdateDropdownValueResponse>(
      'UPDATE_DROPDOWN_VALUES_DATA',
    );

  export const deleteAdminDropdownValuesDataAction =
    actionCreator.createAsyncAction<string>('DELETE_DROPDOWN_VALUES_DATA');
}
