import React, {
  FC,
  HTMLAttributes,
  PropsWithChildren,
  ReactNode,
  ReactText,
  useRef,
  useState,
} from 'react';
import { DndProvider } from 'react-dnd';
import nextId from 'react-id-generator';
import { Table } from 'antd';
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import classNames from 'classnames';
import { Undefinable } from 'providence-types';
import { TableComponents } from 'rc-table/es/interface';
import { useDidUpdateEffect } from '@core/utils/hooks';
import { tableCount } from '@models/constants';
import { Browse } from '@models/types';
import { ColumnSettings } from '@shared/modules/browse-page/components';
import {
  COMPONENTS,
  DEFAULT_PAGINATION_OPTIONS,
  RND_CONTEXT,
} from './models/constants';
import { CustomTableProps } from './models/interfaces';
import { TableLegend } from './components';

import './styles.scss';

export const CustomTable: FC<CustomTableProps> = ({
  columns: columnsProps = [],
  scroll = { y: 'auto' },
  dataSource,
  onRowClick,
  rowClassName,
  className,
  pagination,
  place: { active, title } = tableCount(),
  moveRow,
  hoverable = true,
  module,
  subModule,
  legendProps,
  ...props
}: PropsWithChildren<CustomTableProps>) => {
  const [columns, setColumns] = useState<ColumnsType<Browse>>(columnsProps);

  const manager = useRef(RND_CONTEXT);
  const components: Undefinable<TableComponents<any>> = moveRow && COMPONENTS;
  const paginationProps: false | TablePaginationConfig =
    pagination !== false
      ? {
          ...DEFAULT_PAGINATION_OPTIONS,
          ...pagination,
          ...(active
            ? {
                showTotal: (total: number): ReactNode => (
                  <span className="prov-custom-table__count">
                    {`${title ? `${title} ` : ''}Count:`}
                    <span className="quantity">{total}</span>
                  </span>
                ),
              }
            : {}),
        }
      : false;

  useDidUpdateEffect(() => {
    if (!module) {
      setColumns(columnsProps);
    }
  }, [columnsProps]);

  const content = (
    <div className="prov-custom-table">
      <DndProvider manager={manager.current.dragDropManager!}>
        {(module || legendProps) && (
          <div className="prov-custom-table__addition-content">
            {legendProps && legendProps.items.length && (
              <TableLegend {...legendProps} />
            )}
            {module && (
              <ColumnSettings
                columns={columnsProps}
                setColumns={setColumns}
                module={module}
                subModule={subModule}
              />
            )}
          </div>
        )}

        <Table
          className="prov-custom-table__grid"
          columns={columns}
          dataSource={dataSource}
          rowKey={(record: any): ReactText =>
            record?.generatedId ||
            record?.id ||
            record?._id ||
            nextId('custom-table')
          }
          pagination={paginationProps}
          rowClassName={(record: any, index: number, indent: number): string =>
            classNames(
              index % 2 === 0 ? 'odd-row' : 'even-row',
              rowClassName &&
                (typeof rowClassName === 'string'
                  ? rowClassName
                  : rowClassName(record, index, indent)),
              { hoverable },
            )
          }
          scroll={scroll}
          onRow={(
            record: any,
            index?: number,
          ): HTMLAttributes<HTMLElement> => ({
            onClick: (e: any): void => onRowClick && onRowClick(record, e),
            ...(moveRow && {
              index,
              moveRow,
              className: 'draggable-row',
            }),
          })}
          components={components}
          {...props}
        />
      </DndProvider>
    </div>
  );

  return className ? <div className={className}>{content}</div> : content;
};
