import { Undefinable } from 'providence-types';
import { StorageItem } from '../../models/enums';
// TODO need to implement DI
export class LocalStorageService {
  setItem(key: StorageItem, value: string): this {
    if (LocalStorageService._supportsHTML5Storage()) {
      localStorage.setItem(key, value);
    }

    return this;
  }

  getItem(key: StorageItem): Undefinable<string> {
    if (LocalStorageService._supportsHTML5Storage()) {
      return localStorage.getItem(key)!;
    }
  }

  removeItem(key: StorageItem): this {
    if (LocalStorageService._supportsHTML5Storage()) {
      localStorage.removeItem(key);
    }

    return this;
  }

  clear(): void {
    if (LocalStorageService._supportsHTML5Storage()) {
      localStorage.clear();
    }
  }

  private static _supportsHTML5Storage(): boolean {
    try {
      return window && window.localStorage !== undefined;
    } catch {
      console.warn("Current browser doesn't support local storage");

      return false;
    }
  }
}
