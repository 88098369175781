import React, {
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';
import nextId from 'react-id-generator';
import { Row } from 'antd';
import classNames from 'classnames';
import { RADField } from 'providence-types';
import { SCOPING_SECTORS } from '@models/constants';
import { SectorEquipmentType } from '@models/enums';
import { EditableComponentProps, InformationProps } from '@models/interfaces';
import { SectorDataState } from '@models/types';
import { PrimaryButton } from '../../modules/ui-kit/primary-button';
import { SECTOR_TYPES } from './models/constants';
import { SectorEquipment, SectorSetupModal } from './components';

import './styles.scss';

type SectorsOfEquipmentProps = InformationProps &
  EditableComponentProps & {
    sectorsData: SectorDataState;
    setSectorsData: Dispatch<SetStateAction<SectorDataState>>;
  };
type SectorDataLength = {
  [key in SectorEquipmentType]: number;
};

export const SectorsOfEquipment: FC<SectorsOfEquipmentProps> = ({
  className,
  isEditing,
  sectorsData = SCOPING_SECTORS,
  setSectorsData,
}: PropsWithChildren<SectorsOfEquipmentProps>) => {
  const [currentSectors, setCurrentSectors] = useState<SectorEquipmentType[]>([
    SectorEquipmentType.Alpha,
  ]);

  const [sectorDataLength, setSectorDataLength] = useState<SectorDataLength>({
    [SectorEquipmentType.Alpha]: 0,
    [SectorEquipmentType.Beta]: 0,
    [SectorEquipmentType.Delta]: 0,
    [SectorEquipmentType.Gamma]: 0,
  });
  const [isSectorSetupOpen, setSectorSetup] = useState(false);

  const sectorsForDuplicate = SECTOR_TYPES.filter(
    (type: SectorEquipmentType) => !currentSectors.includes(type),
  );

  const handleSectorSetupModal = (): void => setSectorSetup(!isSectorSetupOpen);
  const onSave = (value: SectorEquipmentType): void => {
    setCurrentSectors([...currentSectors, value]);
    handleSectorSetupModal();
  };
  const deleteSector = (sector: SectorEquipmentType): void => {
    setCurrentSectors(
      currentSectors.filter(
        (currentSector: SectorEquipmentType) => currentSector !== sector,
      ),
    );
    setSectorsData((prevState: SectorDataState) => ({
      ...prevState,
      [SectorEquipmentType[sector]]: [],
    }));
  };

  useEffect((): void => {
    const newSectors = [
      ...(currentSectors ?? {}),
      ...(Object.keys(sectorsData ?? {}) as SectorEquipmentType[]).filter(
        (sectorName: SectorEquipmentType) =>
          sectorsData[sectorName].length &&
          !currentSectors.includes(sectorName) &&
          !currentSectors.includes(sectorName),
      ),
    ];

    if (
      newSectors.length !== (currentSectors?.length ?? 0) ||
      (Object.keys(sectorsData) as SectorEquipmentType[]).some(
        (sector: SectorEquipmentType) =>
          sectorsData[sector].length !== sectorDataLength[sector],
      )
    ) {
      setCurrentSectors(newSectors);
      setSectorDataLength(
        (Object.keys(sectorsData) as SectorEquipmentType[]).reduce(
          (acc: SectorDataLength, type: SectorEquipmentType) => ({
            ...acc,
            [type]: sectorsData[type].length,
          }),
          {
            ...sectorDataLength,
          },
        ),
      );
    }
  }, [sectorsData]);

  const filteredSelectors = useMemo(
    () =>
      (
        Object.keys(RADField)
          .reduce(
            (acc: string[], curItem: string) => [
              ...acc,
              currentSectors.find(
                (sector: string) => sector.charAt(0) === curItem,
              ) || '',
            ],
            [],
          )
          .filter((selector: string) => selector) as SectorEquipmentType[]
      ).map((sector: SectorEquipmentType) => (
        <SectorEquipment
          type={sector}
          key={nextId('sector-equipment')}
          className={className}
          isEditing={isEditing}
          sectorsForDuplicate={sectorsForDuplicate}
          deleteSector={deleteSector}
          data={sectorsData}
          setSectorsData={setSectorsData}
        />
      )),

    [currentSectors, isEditing, sectorDataLength],
  );

  return (
    <div className={classNames('prov-equipment-sectors', className)}>
      {filteredSelectors}
      <Row>
        {isEditing ? (
          <PrimaryButton
            title="Add Sector"
            icon="+"
            onClick={handleSectorSetupModal}
            disabled={!sectorsForDuplicate.length}
          />
        ) : null}
        <SectorSetupModal
          sectorsForCreate={sectorsForDuplicate}
          className="sector-setup__modal"
          visible={isSectorSetupOpen}
          onCancel={handleSectorSetupModal}
          onOk={onSave}
        />
      </Row>
    </div>
  );
};
