import { Resolver } from '@wellyes/react-router-extended';
import { Undefinable } from 'providence-types';
import { HttpService } from '@core/services';
import {
  ProjectCategoryHttpService,
  ProjectHttpService,
  SiteHttpService,
} from '@core/services/http';
import {
  getIdParamFromUrl,
  requestWithStoreWaiting,
} from '@core/utils/methods';
import store from '@store/index';
import { ProjectCategoryState } from '@store/reducers/admin/project-category.reducer';

export class ProjectCreateResolver implements Resolver {
  private static get _state(): ProjectCategoryState {
    return store.getState().admin.projectCategory;
  }

  private static get _isProjectCategoriesExist(): boolean {
    return !!Object.keys(ProjectCreateResolver._state.projectCategories).length;
  }

  private static get _id(): Undefinable<string> {
    let id;

    try {
      const [idFromUrl] = getIdParamFromUrl();

      id = idFromUrl;
      // eslint-disable-next-line no-empty
    } catch (e) {}

    return id;
  }

  async resolve(): Promise<void> {
    await HttpService.getHttpRequests(
      SiteHttpService,
    ).getFALocationNumberOptions();

    const id = ProjectCreateResolver._id;

    if (id) {
      await HttpService.getHttpRequests(ProjectHttpService).getProvidenceAgent(
        id,
      );
    }

    if (!ProjectCreateResolver._isProjectCategoriesExist) {
      await requestWithStoreWaiting(
        () =>
          HttpService.getHttpRequests(
            ProjectCategoryHttpService,
          ).getProjectCategories(),
        () => ProjectCreateResolver._state,
      );
    }
  }
}
