import React, { FC, PropsWithChildren, useEffect } from 'react';
import { Divider, Form } from 'antd';
import { canBeEditable, momentizeObjectDates } from '@core/utils/methods';
import { PermittingDetailsJurisdictionPermitting } from '@models/interfaces';
import { FormProps } from '@models/interfaces/form-props';
import { Panel, PermitTypeTable } from '@shared/components';
import { DatePicker, TextArea } from '@shared/modules';
import { PERMITTING_INFO_DATES, PermittingSection } from '../../models';

import './styles.scss';

type WorkflowPermittingSectionProps = FormProps & {
  permitting?: PermittingDetailsJurisdictionPermitting;
};

export const WorkflowPermittingSection: FC<WorkflowPermittingSectionProps> = ({
  isEditing,
  permissions: {
    e911AddressValidationDate,
    jurisdictionAuditComplete,
    prelimCDsReviewedPermitting,
    projectPermittingNotes,
    tableAccess,
  },
  data,
  form,
  onValuesChange,
}: PropsWithChildren<WorkflowPermittingSectionProps>) => {
  useEffect(() => {
    if (data) {
      form?.setFieldsValue(
        momentizeObjectDates<PermittingSection>(data, PERMITTING_INFO_DATES),
      );
    }
  }, [data]);

  return (
    <Panel
      header="Permitting"
      className="prov-workflow-permitting-section"
      collapsible
    >
      <Form
        className="prov-workflow-permitting-section__form"
        form={form}
        onValuesChange={onValuesChange}
      >
        <DatePicker
          id="jurisdictionAuditComplete"
          label="Jurisdiction Audit Complete"
          isEditing={canBeEditable(jurisdictionAuditComplete, isEditing)}
        />
        <DatePicker
          id="e911AddressValidationDate"
          label="E911 Address Validation Date"
          isEditing={canBeEditable(e911AddressValidationDate, isEditing)}
        />
        <DatePicker
          id="prelimCDsReviewedPermitting"
          label="Prelim CDs Reviewed"
          isEditing={canBeEditable(prelimCDsReviewedPermitting, isEditing)}
        />
        <TextArea
          id="projectPermittingNotes"
          label="Project Permitting Notes"
          isEditing={canBeEditable(projectPermittingNotes, isEditing)}
        />
      </Form>
      <Divider />
      <PermitTypeTable
        isEditing={isEditing}
        tableAccess={tableAccess}
        permittingId={data?.id}
        data={data?.permittingTypes || []}
        reqType="permitting"
      />
    </Panel>
  );
};
