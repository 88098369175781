import {
  IRequiresAttention,
  ISelectorOption,
  Undefinable,
} from 'providence-types';
import { mainDateFormat } from '@core/utils/methods';
import { DashboardItem } from '@models/interfaces';
import {
  assigneesIdsToNamedAssignees,
  projectCategoriesToStringArr,
} from '../../../helpers';

export const requiresAttentionSearcher = (
  data: DashboardItem<IRequiresAttention>[],
  searchValue: string,
  adminsList: ISelectorOption<string>[],
): DashboardItem<IRequiresAttention>[] => {
  const regExp = new RegExp(searchValue, 'gi');

  return !searchValue
    ? data
    : data.filter(
        ({
          notificationData: {
            notificationType,
            pastDueDate,
            date,
            dueDate,
            taskNote,
            actionField,
            message,
            assignees,
          },
          projectData: {
            projectType: { value },
            projectBundleId,
            site: { siteNumber },
            categories,
          },
        }: DashboardItem<IRequiresAttention>) =>
          [
            notificationType,
            pastDueDate.toString(),
            mainDateFormat(date),
            mainDateFormat(dueDate),
            taskNote,
            actionField,
            message,
            ...assigneesIdsToNamedAssignees(assignees, adminsList),
            projectBundleId.toString(),
            siteNumber,
            value,
            ...projectCategoriesToStringArr(categories),
          ].some((item: Undefinable<string>) => item && regExp.test(item)),
      );
};
