import { DropDownSelectorValues } from 'providence-types';
import { createSelector } from 'reselect';
import { getDDVByIdOrUndefined } from '@core/utils/methods';
import { PermittingDetailsCategories } from '@models/interfaces';
import { AppState } from '../../reducers';
import { EngineeringSOFToolState } from '../../reducers/engineering/sof-tool.reducer';

const SOFToolState = (state: AppState): EngineeringSOFToolState =>
  state.engineering.SOFTool;

export namespace SOFToolSelectors {
  export const browseData = createSelector(
    [SOFToolState],
    (state: EngineeringSOFToolState) => state.SOFToolBrowse,
  );

  export const browseFilters = createSelector(
    [SOFToolState],
    (state: EngineeringSOFToolState) => state.filtersOptions,
  );

  export const detailsHeaderData = createSelector(
    [SOFToolState],
    (state: EngineeringSOFToolState) => {
      const {
        details: {
          generalInfo: { site, categories },
        },
      } = state;

      const currentFieldCategories = categories.map(
        (category: PermittingDetailsCategories) => ({
          ...category,
          projectRanking: getDDVByIdOrUndefined(
            DropDownSelectorValues.ProjectRanking,
            category.projectRanking,
          ),
        }),
      );

      return {
        site,
        categories: currentFieldCategories,
      };
    },
  );

  export const detailsData = createSelector(
    [SOFToolState],
    (state: EngineeringSOFToolState) => state.details,
  );

  export const previewSrc = createSelector(
    [SOFToolState],
    (state: EngineeringSOFToolState) => state.previewSrc,
  );

  export const isFetching = createSelector(
    [SOFToolState],
    (state: EngineeringSOFToolState) => state.isFetching,
  );

  export const getProjectType = createSelector(
    [SOFToolState],
    (state: EngineeringSOFToolState) =>
      state.details.generalInfo.projectType.value,
  );
}
