import { Undefinable } from 'providence-types';
import { StoredRequest } from '@core/utils/store/stored-request-decorator';
import {
  AddAndUpdateDropdownValueResponse,
  AdminDropdownValuesData,
  OptionProps,
} from '@models/interfaces';
import { DropdownValuesActions } from '@store/actions';
import { CommonHttpService } from './common-http.service';

export class DropdownHttpService extends CommonHttpService {
  @StoredRequest(DropdownValuesActions.getAdminDropdownValuesDataAction)
  getDropdownValues(): Promise<AdminDropdownValuesData[]> {
    return this.http.callApi.get(`${this.apiUrl}/admin/drop-down-values`);
  }

  @StoredRequest(DropdownValuesActions.getAdminDropdownValuesOptionsAction)
  getDropdownValuesOptions(): Promise<OptionProps[]> {
    return this.http.callApi.get(
      `${this.apiUrl}/admin/drop-down-values/options`,
    );
  }

  @StoredRequest(DropdownValuesActions.addAdminDropdownValuesDataAction)
  addDropdownValue(
    key: string,
    value: string,
    isFirstNet: Undefinable<boolean>,
  ): Promise<AddAndUpdateDropdownValueResponse> {
    return this.http.callApi.post(
      `${this.apiUrl}/admin/drop-down-values`,
      { value: value?.trim(), isFirstNet },
      {
        params: { key },
      },
    );
  }

  @StoredRequest(DropdownValuesActions.updateAdminDropdownValuesDataAction)
  updateDropdownValue(
    dropDownValueId: string,
    value: string,
    isFirstNet: Undefinable<boolean>,
  ): Promise<AddAndUpdateDropdownValueResponse> {
    return this.http.callApi.patch(
      `${this.apiUrl}/admin/drop-down-values`,
      { value: value?.trim(), isFirstNet },
      {
        params: { dropDownValueId },
      },
    );
  }

  getDropdownValueValidation(
    id: string,
    key: string,
    value: string,
  ): Promise<boolean> {
    return this.http.callApi.get(
      `${this.apiUrl}/admin/drop-down-values/validation`,
      null,
      {
        params: { key, id, value: value.trim() },
      },
    );
  }

  @StoredRequest(DropdownValuesActions.deleteAdminDropdownValuesDataAction)
  async deleteDropdownValue(
    dropDownValueId: string,
    key: string,
  ): Promise<string> {
    await this.http.callApi.delete(
      `${this.apiUrl}/admin/drop-down-values`,
      null,
      {
        params: { dropDownValueId, key },
      },
    );

    return dropDownValueId;
  }
}
