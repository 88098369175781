import React from 'react';
import { Panel } from '@permitting/jurisdiction/details/models';
import { DropDownSelectorValues, ProjectType } from 'providence-types';
import { getDDVById } from '@core/utils/methods';
import {
  JurisdictionDetailsZoning,
  PermittingPermission,
} from '@models/interfaces';
import { ZoningLTEForm } from '../components/zoning-lte-form';
import { ZoningNSBForm } from '../components/zoning-nsb-form';

export const mapDataToZoningPanelData = (
  data: JurisdictionDetailsZoning[],
  zoningLTEFields: PermittingPermission.ZoningLTE,
  zoningNSBFields: PermittingPermission.ZoningNSB,
): Panel[] =>
  data.map((details: JurisdictionDetailsZoning) => {
    const { projectType, projectSubType, ...formFields } = details;

    const projectTypeDDV = getDDVById(
      DropDownSelectorValues.ProjectType,
      projectType,
    );
    const projectSubTypeDDV = getDDVById(
      DropDownSelectorValues.ProjectSubtype,
      projectSubType,
    );

    return {
      header: {
        id: formFields.id,
        projectType: projectTypeDDV,
        projectSubType: projectSubTypeDDV,
      },
      content:
        projectTypeDDV.value === ProjectType.LTE ? (
          <ZoningLTEForm permissions={zoningLTEFields} formValue={formFields} />
        ) : (
          <ZoningNSBForm permissions={zoningNSBFields} formValue={formFields} />
        ),
    };
  });
