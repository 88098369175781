import {
  ScopingEquipmentTableItem,
  SectorEquipmentDataResp,
  SectorEquipmentResp,
  SectorEquipmentTypeResp,
} from '../interfaces';
import { OtherEquipmentSumData, SectorDataState } from '../types';
import { SCOPING_SECTORS } from './scoping-sectors';

type ScopingSectorsAcc = {
  equipmentTypeNames: string[];
  scopingSectors: SectorDataState;
};

export const getOtherEquipmentInfo = (
  otherEquipment: ScopingEquipmentTableItem[],
): OtherEquipmentSumData =>
  otherEquipment?.reduce(
    (
      summary: OtherEquipmentSumData,
      curItem: ScopingEquipmentTableItem,
    ): OtherEquipmentSumData => ({
      otherEquipment: [
        ...summary.otherEquipment,
        {
          generatedId: curItem?.id,
          equipment: curItem?.equipment?.id,
          equipmentType: curItem?.equipment?.equipmentType?.id,
          manufacturer: curItem?.equipment?.manufacturer?.id,
          quantity: curItem?.quantity,
        },
      ],
      equipmentTypeNames: [
        ...summary.equipmentTypeNames,
        curItem?.equipment?.equipmentType?.name,
      ],
    }),
    { otherEquipment: [], equipmentTypeNames: [] },
  );

export const getScopingSectorsInfo = (
  scopingSectors: SectorEquipmentDataResp[],
): ScopingSectorsAcc =>
  scopingSectors?.reduce(
    (acc: ScopingSectorsAcc, sector: SectorEquipmentDataResp) => ({
      equipmentTypeNames: [
        ...acc.equipmentTypeNames,
        ...sector.sectorEquipmentTypes.map(
          (equipmentType: SectorEquipmentTypeResp) =>
            equipmentType.equipmentType.name,
        ),
      ],
      scopingSectors: {
        ...acc.scopingSectors,
        [sector.type]: sector.sectorEquipmentTypes
          .sort((a: SectorEquipmentTypeResp, b: SectorEquipmentTypeResp) =>
            a.equipmentType.name.localeCompare(b.equipmentType.name),
          )
          .map((equipmentType: SectorEquipmentTypeResp) => ({
            equipmentType: equipmentType.equipmentType.id,
            equipments: equipmentType.equipments.map(
              (equipment: SectorEquipmentResp) => ({
                ...equipment,
                equipment: equipment?.equipment?.id,
                manufacturer: equipment?.equipment?.manufacturer?.id,
              }),
            ),
          })),
      },
    }),
    {
      scopingSectors: SCOPING_SECTORS,
      equipmentTypeNames: [],
    },
  );
