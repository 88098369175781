import {
  arraySort,
  generatedIdMapper,
  transformFields,
} from '@core/utils/methods';
import { browseReducer } from '@core/utils/methods/browse-sorter-reducer';
import { createReducer, on } from '@core/utils/store';
import { EquipmentType } from '@models/enums';
import {
  IdAndValue,
  PaginatedResponse,
  PayloadAndState,
  ProceccedSiteAuditRaycapsData,
  ProcessEnvMap as KeyString,
  SiteAuditBrowse,
  SiteAuditCablesData,
  SiteAuditDetails,
  SiteAuditInformation,
  SiteAuditProjectInfo,
  SiteAuditRaycapsData,
  SiteBrowse,
} from '@models/interfaces';
import { SiteAuditActions } from '@store/actions';

export interface EngineeringSiteAuditState {
  siteAuditBrowseData: PaginatedResponse<SiteAuditBrowse>;
  cablesTableData: SiteAuditCablesData[];
  raycapsTableData: ProceccedSiteAuditRaycapsData[];
  originalCablesTableData: SiteAuditCablesData[];
  originalRaycapsTableData: ProceccedSiteAuditRaycapsData[];
  siteAuditSiteInfo: SiteBrowse;
  siteAuditData: Omit<SiteAuditInformation, 'raycaps' | 'cables'>;
  projectInfo: SiteAuditProjectInfo;
  isFetching: boolean;
  errors: boolean;
}

const initialState: EngineeringSiteAuditState = {
  siteAuditBrowseData: { items: [], total: 0 },
  cablesTableData: [],
  raycapsTableData: [],
  originalCablesTableData: [],
  originalRaycapsTableData: [],
  siteAuditSiteInfo: {} as SiteBrowse,
  siteAuditData: {} as SiteAuditInformation,
  projectInfo: {} as SiteAuditProjectInfo,
  isFetching: false,
  errors: false,
};

type ProcessedEquipment =
  | ProceccedSiteAuditRaycapsData
  | ProceccedSiteAuditRaycapsData;

const getProceccedRaycaps = (
  raycaps: SiteAuditRaycapsData[],
): ProceccedSiteAuditRaycapsData[] =>
  raycaps?.map((raycap: SiteAuditRaycapsData) => ({
    ...raycap,
    projectCategories: raycap.projectCategories.reduce(
      (categories: string[], category: IdAndValue) => [
        ...categories,
        category.id,
      ],
      [],
    ),
  }));

const filterEquipmentItems = (
  equipmentItems: ProcessedEquipment[],
  idItem: KeyString,
): ProcessedEquipment[] => [
  ...equipmentItems.filter(
    (equipment: ProcessedEquipment) =>
      equipment.generatedId !== idItem.generatedId,
  ),
];

const sortEquipments = <T>(data: T[]): T[] =>
  arraySort<T>(data, 'ASC', 'equipment' as keyof T);

export const reducer = createReducer(
  initialState,
  // GET SITE AUDIT BROWSE DATA
  on(
    SiteAuditActions.getSiteAuditBrowseDataAction,
    browseReducer('siteAuditBrowseData'),
  ),
  // UPDATE SITE AUDIT BROWSE DATA
  on(
    SiteAuditActions.updateSiteAuditBrowseDataAction,
    ({
      payload: siteAuditBrowseData,
    }: PayloadAndState<
      PaginatedResponse<SiteAuditBrowse>,
      EngineeringSiteAuditState
    >) => ({ siteAuditBrowseData }),
  ),
  // GET SITE AUDIT DETAILS
  on(
    SiteAuditActions.getSiteAuditDetailsAction,
    ({
      payload: {
        siteAudit: siteAuditPayload,
        site,
        id,
        projectBundleId,
        projectType,
      },
    }: PayloadAndState<SiteAuditDetails, EngineeringSiteAuditState>) => {
      const siteAudit = siteAuditPayload ?? {};
      const { cables = [], raycaps = [], ...restSiteAuditData } = siteAudit;
      const raycapsTableData = generatedIdMapper(
        getProceccedRaycaps(raycaps),
        'Raycap',
      ) as ProceccedSiteAuditRaycapsData[];
      const cablesTableData = generatedIdMapper(cables, EquipmentType.Cable);

      return {
        cablesTableData,
        raycapsTableData,
        originalCablesTableData: cablesTableData,
        originalRaycapsTableData: raycapsTableData,
        siteAuditSiteInfo: transformFields<SiteBrowse>(site, [
          'towerOwner',
          'equipmentType',
          'towerType',
          'riskCategory',
        ]),
        siteAuditData: transformFields<
          Omit<SiteAuditInformation, 'raycaps' | 'cables'>
        >(restSiteAuditData, [
          'siteAuditCompletedBy',
          'powerPlantType',
          'powerProvider',
          'telcoProvider',
        ]),
        projectInfo: { id, projectBundleId, projectType },
      };
    },
  ),
  // UPDATE SITE AUDIT DETAILS
  on(
    SiteAuditActions.updateSiteAuditDetailsAction,
    ({
      payload: { siteAudit: siteAuditPayload, site, id },
    }: PayloadAndState<SiteAuditDetails, EngineeringSiteAuditState>) => {
      const siteAudit = siteAuditPayload ?? {};
      const { cables = [], raycaps = [], ...restSiteAuditData } = siteAudit;
      const raycapsTableData = generatedIdMapper(
        getProceccedRaycaps(raycaps),
        'Raycap',
      ) as ProceccedSiteAuditRaycapsData[];
      const cablesTableData = generatedIdMapper(cables, EquipmentType.Cable);

      return {
        cablesTableData,
        raycapsTableData,
        originalCablesTableData: cablesTableData,
        originalRaycapsTableData: raycapsTableData,
        siteAuditSiteInfo: transformFields<SiteBrowse>(site, [
          'towerOwner',
          'equipmentType',
          'towerType',
        ]),
        siteAuditData: transformFields<
          Omit<SiteAuditInformation, 'raycaps' | 'cables'>
        >(restSiteAuditData, [
          'siteAuditCompletedBy',
          'powerPlantType',
          'powerProvider',
          'telcoProvider',
        ]),
        currentProjectId: id,
      };
    },
  ),
  // set/clear cables
  on(
    SiteAuditActions.setCablesAction,
    ({
      payload,
    }: PayloadAndState<SiteAuditCablesData[], EngineeringSiteAuditState>) => ({
      cablesTableData: payload,
      originalCablesTableData: payload,
    }),
  ),
  // add cable
  on(
    SiteAuditActions.addCableItemAction,
    ({
      payload,
      state: { cablesTableData },
    }: PayloadAndState<SiteAuditCablesData, EngineeringSiteAuditState>) => ({
      cablesTableData: sortEquipments([...cablesTableData, payload]),
    }),
  ),
  // update cable
  on(
    SiteAuditActions.updateCableItemAction,
    ({
      payload,
      state: { cablesTableData },
    }: PayloadAndState<SiteAuditCablesData, EngineeringSiteAuditState>) => ({
      cablesTableData: sortEquipments([
        ...filterEquipmentItems(
          cablesTableData,
          payload as unknown as KeyString,
        ),
        payload,
      ]),
    }),
  ),
  // remove cable
  on(
    SiteAuditActions.deleteCableItemAction,
    ({
      payload,
      state: { cablesTableData },
    }: PayloadAndState<KeyString, EngineeringSiteAuditState>) => ({
      cablesTableData: filterEquipmentItems(cablesTableData, payload),
    }),
  ),
  // set/clear raycaps
  on(
    SiteAuditActions.setRaycapsAction,
    ({
      payload,
    }: PayloadAndState<
      ProceccedSiteAuditRaycapsData[],
      EngineeringSiteAuditState
    >) => ({
      raycapsTableData: payload,
      originalRaycapsTableData: payload,
    }),
  ),
  // add raycap
  on(
    SiteAuditActions.addRaycapItemAction,
    ({
      payload,
      state: { raycapsTableData },
    }: PayloadAndState<
      ProceccedSiteAuditRaycapsData,
      EngineeringSiteAuditState
    >) => ({
      raycapsTableData: sortEquipments([...raycapsTableData, payload]),
    }),
  ),
  // update raycap
  on(
    SiteAuditActions.updateRaycapItemAction,
    ({
      payload,
      state: { raycapsTableData },
    }: PayloadAndState<
      ProceccedSiteAuditRaycapsData,
      EngineeringSiteAuditState
    >) => ({
      raycapsTableData: sortEquipments([
        ...filterEquipmentItems(
          raycapsTableData,
          payload as unknown as KeyString,
        ),
        payload,
      ]),
    }),
  ),
  // remove raycap
  on(
    SiteAuditActions.deleteRaycapItemAction,
    ({
      payload,
      state: { raycapsTableData },
    }: PayloadAndState<KeyString, EngineeringSiteAuditState>) => ({
      raycapsTableData: filterEquipmentItems(raycapsTableData, payload),
    }),
  ),
);
