import React, { FC, PropsWithChildren, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'antd';
import classNames from 'classnames';
import { Store } from 'rc-field-form/es/interface';
import {
  HttpService,
  ObjectComparatorService,
  ObjectDifferencesService,
} from '@core/services';
import { LeasingHttpService } from '@core/services/http';
import { useDidUpdateEffect, useSaveChanged } from '@core/utils/hooks';
import { isValidForm, momentizeObjectDates } from '@core/utils/methods';
import {
  EditableComponentProps,
  LeasingEngineeringWorkflow,
  LeasingInformation,
  StructuralInformation,
} from '@models/interfaces';
import { NotificationsLoader } from '@shared/components';
import { PrimaryButton } from '@shared/modules';
import { CommonActions } from '@store/actions';
import {
  LeasingSelectors,
  PaceTasksSelectors,
  ProjectCategoriesSelectors,
} from '@store/selectors';
import {
  LEASING_ENGINEERING_INFO_DATES,
  LEASING_INFO_DATES,
  STRUCTURAL_INFO_DATES,
} from './models/constants';
import { EngineeringPanel, LeasingPanel, StructuralPanel } from './components';
import { updateLeasingPaceTaskFields } from './helpers';

import './styles.scss';

const { useForm } = Form;

export const Workflow: FC<EditableComponentProps> = ({
  isEditing,
  toggleEditing,
  permissions: { engineeringFields, leasingFields, structuralFields },
  isActive,
}: PropsWithChildren<EditableComponentProps>) => {
  const dispatch = useDispatch();

  const [engineeringForm] = useForm();
  const [leasingForm] = useForm();
  const [structuralForm] = useForm();

  const {
    leasingInformation,
    currentProject: { scoping, ...currentProject },
  } = useSelector(LeasingSelectors.getLeasingWorkflowInfo);
  const projectCategoriesTableVisible = useSelector(
    ProjectCategoriesSelectors.getProjectCategoriesTableVisible,
  );
  const leasingFetching = useSelector(LeasingSelectors.isFetching);

  const paceTasks = useSelector(
    PaceTasksSelectors.getMainPaceTaskDates(
      'actualDate',
      // Engineering Panel
      'MountMappingOrdered',
      'MountMappingReceived',
      'MountAnalysisOrdered',
      'FailingMountAnalysisReceived',
      'PassingMountAnalysisReceived',
      'PassingMountAnalysisReviewedLeasing',
      'PrelimCDsOrdered',
      'PrelimCDsReceived',
      'FinalCDsReviewedLeasing',
      'FinalCDsReceived',
      // Leasing Panel
      'LeaseAuditComplete',
      'SOFIssuedDate',
      'ColoAppDrafted',
      'ColoAppApproved',
      'ColoAppSubmitted',
      'PALNPAReceived',
      'LeaseDraftReceived',
      'LMTSubmitted',
      'LMTApproved',
      'LeaseAmendmentFullyExecuted',
      'NTPApproved',
      'NTPRequested',
      // Structural Panel
      'StructuralOrdered',
      'PassingStructuralReceived',
      'FailingStructuralReceived',
      'ModDesignComplete',
      'IBMApproved',
      'ModConstructionStart',
      'ModConstructionComplete',
    ),
  );

  const getScopingData = (): Store => ({
    ...scoping,
    mountMappingOrder: paceTasks.MountMappingOrdered,
    mountMappingReceived: paceTasks.MountMappingReceived,
    mountAnalysisOrdered: paceTasks.MountAnalysisOrdered,
    failingMountAnalysisReceived: paceTasks.FailingMountAnalysisReceived,
    passingMountAnalysisReceived: paceTasks.PassingMountAnalysisReceived,
    passingMountAnalysisReviewed: paceTasks.PassingMountAnalysisReviewedLeasing,
    prelimCDsOrdered: paceTasks.PrelimCDsOrdered,
    prelimCDsReceived: paceTasks.PrelimCDsReceived,
    finalCDsReviewedLeasing: paceTasks.FinalCDsReviewedLeasing,
    finalCDsReceived: paceTasks.FinalCDsReceived,
  });
  const getLeasePanelData = (): Store => ({
    ...leasingInformation,
    auditDate: paceTasks.LeaseAuditComplete,
    SOFIssuedDate: paceTasks.SOFIssuedDate,
    coloAppDrafted: paceTasks.ColoAppDrafted,
    coloAppApproved: paceTasks.ColoAppApproved,
    coloAppSubmitted: paceTasks.ColoAppSubmitted,
    PALOrNPAReceived: paceTasks.PALNPAReceived,
    leaseDraftReceived: paceTasks.LeaseDraftReceived,
    LMTSubmitted: paceTasks.LMTSubmitted,
    LMTApproved: paceTasks.LMTApproved,
    leaseAmendmentFullyExecuted: paceTasks.LeaseAmendmentFullyExecuted,
    NTPApproved: paceTasks.NTPApproved,
    NTPRequested: paceTasks.NTPRequested,
  });

  const getStructuralData = (): Store => ({
    ...leasingInformation.structural,
    structuralOrdered: paceTasks.StructuralOrdered,
    passingStructuralReceived: paceTasks.PassingStructuralReceived,
    failingStructuralReceived: paceTasks.FailingStructuralReceived,
    modDesignComplete: paceTasks.ModDesignComplete,
    IBMApproved: paceTasks.IBMApproved,
    modConstructionStart: paceTasks.ModConstructionStart,
    modConstructionComplete: paceTasks.ModConstructionComplete,
  });

  const [workflowForm, setWorkflowForm] = useState<Array<Store>>([]);
  const [initialForm, setInitialForm] = useState<Array<Store>>([]);

  const [scopingData, setScopingData] = useState(getScopingData());
  const [leasePanelData, setLeasePanelData] = useState(getLeasePanelData());
  const [structuralPanelData, setStructuralData] = useState(
    getStructuralData(),
  );

  const arraysCompare = ObjectComparatorService.arraysCompare(
    initialForm,
    workflowForm,
  );

  useEffect(() => {
    setScopingData(getScopingData());
  }, [
    scoping,
    paceTasks.MountMappingOrdered,
    paceTasks.MountMappingReceived,
    paceTasks.MountAnalysisOrdered,
    paceTasks.FailingMountAnalysisReceived,
    paceTasks.PassingMountAnalysisReceived,
    paceTasks.PassingMountAnalysisReviewedLeasing,
    paceTasks.PrelimCDsOrdered,
    paceTasks.PrelimCDsReceived,
    paceTasks.FinalCDsReviewedLeasing,
    paceTasks.FinalCDsReceived,
  ]);

  useEffect(() => {
    setLeasePanelData(getLeasePanelData());
  }, [
    leasingInformation,
    paceTasks.LeaseAuditComplete,
    paceTasks.SOFIssuedDate,
    paceTasks.ColoAppDrafted,
    paceTasks.ColoAppApproved,
    paceTasks.ColoAppSubmitted,
    paceTasks.PALNPAReceived,
    paceTasks.LeaseDraftReceived,
    paceTasks.LMTSubmitted,
    paceTasks.LMTApproved,
    paceTasks.LeaseAmendmentFullyExecuted,
    paceTasks.NTPApproved,
    paceTasks.NTPRequested,
  ]);

  useEffect(() => {
    setStructuralData(getStructuralData());
  }, [
    leasingInformation.structural,
    paceTasks.StructuralOrdered,
    paceTasks.PassingStructuralReceived,
    paceTasks.FailingStructuralReceived,
    paceTasks.ModDesignComplete,
    paceTasks.IBMApproved,
    paceTasks.ModConstructionStart,
    paceTasks.ModConstructionComplete,
  ]);

  useEffect(
    () => (): void => {
      dispatch(CommonActions.setHasUnsubmittedData.done(false));
    },

    [],
  );

  useDidUpdateEffect(() => {
    dispatch(CommonActions.setHasUnsubmittedData.done(!arraysCompare));
  }, [arraysCompare]);

  const onSubmit = async (): Promise<void> => {
    if ((await isValidForm(leasingForm)) && (await isValidForm(structuralForm)))
      try {
        const [engineering, leasing, structural] = workflowForm;
        const [initialEngineering, initialLeasing, initialStructural] =
          initialForm;

        await HttpService.getHttpRequests(
          LeasingHttpService,
        ).updateLeasingWorkflow(currentProject?.id, {
          leasing: momentizeObjectDates<LeasingInformation>(
            ObjectDifferencesService.getObjectsDiff(
              initialLeasing,
              leasing,
            ) as LeasingInformation,
            LEASING_INFO_DATES,
            true,
          ),
          structural: momentizeObjectDates<StructuralInformation>(
            ObjectDifferencesService.getObjectsDiff(
              initialStructural,
              structural,
            ) as StructuralInformation,
            STRUCTURAL_INFO_DATES,
            true,
          ),
          engineering: momentizeObjectDates<LeasingEngineeringWorkflow>(
            ObjectDifferencesService.getObjectsDiff(
              initialEngineering,
              engineering,
            ) as LeasingEngineeringWorkflow,
            LEASING_ENGINEERING_INFO_DATES,
            true,
          ),
        });

        updateLeasingPaceTaskFields({
          leasingSection: momentizeObjectDates<LeasingInformation>(
            leasing as LeasingInformation,
            LEASING_INFO_DATES,
            true,
          ),
          structuralSection: momentizeObjectDates<StructuralInformation>(
            structural as StructuralInformation,
            STRUCTURAL_INFO_DATES,
            true,
          ),
          engineeringSection: momentizeObjectDates<LeasingEngineeringWorkflow>(
            engineering as LeasingEngineeringWorkflow,
            LEASING_ENGINEERING_INFO_DATES,
            true,
          ),
        });

        setInitialForm(workflowForm);

        if (isEditing) {
          toggleEditing?.();
        }

        NotificationsLoader.notificationSuccess(
          `Information has been updated!`,
        );
      } catch (e) {
        console.error(e);
      }
  };

  const onCancel = (): void => {
    const [engineering, leasing, structural] = initialForm;

    engineeringForm?.setFieldsValue(
      momentizeObjectDates<LeasingEngineeringWorkflow>(
        (engineering as LeasingEngineeringWorkflow) || [],
        LEASING_ENGINEERING_INFO_DATES,
      ),
    );
    leasingForm?.setFieldsValue(
      momentizeObjectDates<LeasingInformation>(
        (leasing as LeasingInformation) || [],
        LEASING_INFO_DATES,
      ),
    );
    structuralForm?.setFieldsValue(
      momentizeObjectDates<StructuralInformation>(
        (structural as StructuralInformation) || [],
        STRUCTURAL_INFO_DATES,
      ),
    );

    setWorkflowForm(initialForm);

    if (isEditing) {
      toggleEditing?.();
    }
  };

  const onValuesChange = (): void => {
    if (Object.keys(engineeringForm.getFieldsValue()).length) {
      const data = [
        engineeringForm.getFieldsValue(),
        leasingForm.getFieldsValue(),
        structuralForm.getFieldsValue(),
      ];

      setWorkflowForm(data);

      if (!Object.keys(initialForm).length) {
        setInitialForm(data);
      }
    }
  };

  useDidUpdateEffect(() => {
    onValuesChange();
  }, [isActive, isEditing]);

  useSaveChanged(isEditing, onSubmit, onCancel);

  return (
    <div className="prov-leasing-workflow">
      <div
        className={classNames('tabs-wrap', {
          'tabs-wrap_with-actions': isEditing,
          'categories-table-open': projectCategoriesTableVisible,
        })}
      >
        <EngineeringPanel
          isEditing={isEditing}
          permissions={engineeringFields}
          form={engineeringForm}
          data={scopingData}
          onValuesChange={onValuesChange}
        />
        <LeasingPanel
          isEditing={isEditing}
          permissions={leasingFields}
          form={leasingForm}
          data={leasePanelData}
          onValuesChange={onValuesChange}
        />
        <StructuralPanel
          isEditing={isEditing}
          permissions={structuralFields}
          form={structuralForm}
          data={structuralPanelData}
          onValuesChange={onValuesChange}
        />
      </div>
      {isEditing && (
        <div className="prov-leasing-handle__btns">
          <div className="action-wrap">
            <PrimaryButton
              title="Submit"
              type="primary"
              disabled={leasingFetching}
              onClick={onSubmit}
            />
            <PrimaryButton title="Cancel" type="default" onClick={onCancel} />
          </div>
        </div>
      )}
    </div>
  );
};
