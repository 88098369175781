import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { RouterProps } from 'react-router';
import { SubPage, Tab } from 'providence-types';
import { Summary } from '@client/pages/engineering/components';
import {
  canUserEditByProjectStatusAndRoles,
  getIdParamFromUrl,
} from '@core/utils/methods';
import { EditablePage } from '@shared/components';
import { PrimaryButton } from '@shared/modules';
import {
  ProjectSelectors,
  ScopingSelectors,
  UserSelectors,
} from '@store/selectors';

import './style.scss';

export const SummaryTab: FC<RouterProps> = () => {
  const [id] = getIdParamFromUrl();

  const { canCreateSOF, ...permissions } = useSelector(
    UserSelectors.getEngineeringScopingPermissions,
  );
  const projectType = useSelector(ScopingSelectors.getProjectType);
  const projectTypePermission = useSelector(
    UserSelectors.canEditProjectByProjectType(projectType),
  );
  const canEdit = useSelector(
    UserSelectors.canEdit(
      'engineering',
      SubPage.EngineeringScoping,
      Tab.Summary,
    ),
  );

  const userRoles = useSelector(UserSelectors.getUserRoles);
  const projectStatus = useSelector(ProjectSelectors.getProjectStatus);

  return (
    <div className="prov-scoping-summary-tab">
      <EditablePage
        editableComponent={Summary}
        canUserEdit={
          canEdit &&
          projectTypePermission &&
          canUserEditByProjectStatusAndRoles(projectStatus, userRoles)
        }
        permissions={permissions}
      >
        {canCreateSOF && projectTypePermission && (
          <PrimaryButton
            title="Create SOF"
            icon="+"
            target="_blank"
            href={`/engineering/sof-tool/${id}/details/summary`}
          />
        )}
      </EditablePage>
    </div>
  );
};
