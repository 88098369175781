import { RuleObject } from 'rc-field-form/es/interface';
import { MomentNull } from '@models/types';
import { getDiff } from '../helpers';
import { isExcludedValidationDate } from '../models/is-excluded-validation-date.constant';

export const FORECASTED_DATE = [
  {
    validator: (_: RuleObject, inputValue?: MomentNull): Promise<void> => {
      if (inputValue && !isExcludedValidationDate(inputValue)) {
        const diff = getDiff(inputValue!);

        return diff < 0
          ? Promise.reject(new Error('Must be today’s date or a future date.'))
          : Promise.resolve();
      }

      return Promise.resolve();
    },
  },
];
