import { ReactNode } from 'react';
import { ColumnsType } from 'antd/lib/table/interface';
import {
  projectCategoryRender,
  providenceAgentRender,
} from '@core/utils/methods';
import { IdAndValue, LeasingSite, SiteBrowse } from '@models/interfaces';
import { Browse } from '@models/types';

export const LEASING_BROWSE_COLUMNS: ColumnsType<Browse> = [
  {
    title: 'Alternate Site Info',
    width: 170,
    key: 'alternateSiteInfo',
    sorter: true,
    dataIndex: 'site',
    render: (site: SiteBrowse): ReactNode => site.alternateSiteInfo,
  },
  {
    title: 'City',
    key: 'city',
    dataIndex: 'site',
    width: 160,
    sorter: true,
    render: (site: LeasingSite): ReactNode => site?.city,
  },
  {
    title: 'County',
    key: 'county',
    dataIndex: 'site',
    width: 140,
    sorter: true,
    render: (site: LeasingSite): ReactNode => site?.county?.name,
  },
  {
    title: 'FA Location',
    dataIndex: 'site',
    key: 'FALocationNumber',
    width: 140,
    sorter: true,
    render: (site: LeasingSite): ReactNode => site?.FALocationNumber,
  },
  {
    title: 'Jurisdiction',
    key: 'jurisdiction',
    width: 180,
    dataIndex: 'site',
    sorter: true,
    render: (site: LeasingSite): ReactNode =>
      site?.jurisdiction?.jurisdictionName,
  },
  {
    title: 'Legacy Site ID',
    width: 150,
    key: 'legacySiteId',
    sorter: true,
    dataIndex: 'site',
    render: (site: SiteBrowse): ReactNode => site.legacySiteId,
  },
  {
    title: 'Oracle Project',
    dataIndex: 'categories',
    key: 'oracleProjectNumber',
    width: 150,
    sorter: true,
    render: projectCategoryRender({
      field: 'oracleProjectNumber',
    }),
  },
  {
    title: 'PACE Project',
    dataIndex: 'categories',
    key: 'PACEProjectNumber',
    width: 150,
    sorter: true,
    render: projectCategoryRender({
      field: 'PACEProjectNumber',
    }),
  },
  {
    title: 'Project Type',
    key: 'projectType',
    width: 140,
    dataIndex: 'projectType',
    sorter: true,
    render: (projectType: IdAndValue): ReactNode => projectType?.value,
  },
  {
    title: 'Project Work Type',
    key: 'projectWorkType',
    width: 180,
    dataIndex: 'projectWorkType',
    sorter: true,
    render: (projectWorkType: IdAndValue): ReactNode => projectWorkType?.value,
  },
  {
    title: 'Providence Agent',
    key: 'providenceAgent',
    width: 180,
    sorter: true,
    dataIndex: 'providenceAgent',
    render: providenceAgentRender,
  },
  {
    title: 'Site',
    dataIndex: 'site',
    key: 'siteNumber',
    width: 100,
    sorter: true,
    render: (site: SiteBrowse): ReactNode => site?.siteNumber,
  },
  {
    title: 'State',
    dataIndex: 'site',
    width: 100,
    key: 'state',
    sorter: true,
    render: (site: LeasingSite): ReactNode => site?.state?.name,
  },
  {
    title: 'Street Address',
    key: 'streetAddress',
    dataIndex: 'site',
    width: 170,
    sorter: true,
    render: (site: LeasingSite): ReactNode => site?.streetAddress,
  },
  {
    title: 'Submarket',
    key: 'submarket',
    width: 140,
    sorter: true,
    dataIndex: 'site',
    render: (site: SiteBrowse): ReactNode => site.submarket?.value,
  },

  {
    title: 'Tower Owner',
    dataIndex: 'site',
    width: 155,
    key: 'towerOwner',
    sorter: true,
    render: (site: LeasingSite): ReactNode => site?.towerOwner?.value,
  },
  {
    title: 'USID',
    width: 150,
    key: 'USID',
    sorter: true,
    dataIndex: 'site',
    render: (site: SiteBrowse): ReactNode => site.USID,
  },
  {
    title: 'Zip',
    key: 'zip',
    dataIndex: 'site',
    width: 100,
    sorter: true,
    render: (site: LeasingSite): ReactNode => site?.zip,
  },
];
