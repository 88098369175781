import { ReactText } from 'react';
import { MainPaceTask } from 'providence-types';
import { createSelector } from 'reselect';
import { momentizeObjectDates } from '@core/utils/methods';
import {
  IdAndValue,
  OtherEquipmentData,
  ProjectPaceTask,
} from '@models/interfaces';
import { SectorDataState } from '@models/types';
import { AppState } from '../../reducers';
import { EngineeringBrowseState } from '../../reducers/engineering/browse.reducer';
import { PaceTasksState } from '../../reducers/pace-tasks.reducer';

export const engineeringBrowseState = (
  state: AppState,
): EngineeringBrowseState => state.engineering.browse;
const paceTasksState = (state: AppState): PaceTasksState => state.paceTasks;

interface ProjectInfo {
  RADA?: number;
  RADB?: number;
  RADG?: number;
  RADD?: number;
  azimuthA?: number;
  azimuthB?: number;
  azimuthG?: number;
  azimuthD?: number;
  projectType?: string;
  mountType?: string;
  civilVendor?: string;
  craneRequired?: boolean;
  craneHeight?: number;
  scopingComplete?: Date;
  finalRFDSDate?: Date;
}

interface SiteInfo {
  FALocationNumber?: number;
  siteNumber?: string;
  streetAddress?: string;
  city?: string;
  state?: string;
  zip?: number;
  latitude?: ReactText;
  longitude?: ReactText;
  towerOwner?: string;
  towerType?: string;
  towerHeight?: number;
  equipmentType?: string;
}

interface ScopingInfo {
  promotionNotes?: string;
  scopingGroundNotes?: string;
  scopingTowerNotes?: string;
  demotionNotes?: string;
  RFDSStatus?: string;
}

interface SOFData {
  projectInfo: ProjectInfo;
  siteInfo: SiteInfo;
  scopingInfo: ScopingInfo;
  scopingSectors: SectorDataState;
  otherEquipment: OtherEquipmentData[];
}

export namespace EngineeringSelectors {
  export const getBrowseData = createSelector(
    [engineeringBrowseState],
    (state: EngineeringBrowseState) => state.engineeringBrowseData,
  );

  export const getDateilsData = createSelector(
    [engineeringBrowseState],
    (state: EngineeringBrowseState) => state.engineeringSiteData,
  );

  export const getSummaryData = createSelector(
    [engineeringBrowseState],
    ({
      engineeringLeasingData,
      engineeringSiteData,
      engineeringProjectData,
      scopingInfo,
    }: EngineeringBrowseState) => ({
      engineeringLeasingData,
      engineeringSiteData,
      engineeringProjectData,
      scopingInfo,
    }),
  );

  export const getWorkflowData = createSelector(
    [engineeringBrowseState],
    ({
      engineeringProjectData,
      engineeringWorkflowData,
      engineeringLeasingData,
      engineeringSiteData,
    }: EngineeringBrowseState) => ({
      engineeringProjectData,
      engineeringWorkflowData,
      engineeringLeasingData,
      engineeringSiteData,
    }),
  );

  export const getSOFData = createSelector(
    [engineeringBrowseState, paceTasksState],
    (
      {
        RAD,
        scopingInfo,
        scopingSectors,
        engineeringLeasingData,
        engineeringProjectData,
        engineeringWorkflowData,
        engineeringSiteData,
        otherEquipment,
      }: EngineeringBrowseState,
      { currentPaceTasks }: PaceTasksState,
    ): SOFData => ({
      projectInfo: momentizeObjectDates(
        {
          RADA: RAD.A,
          RADB: RAD.B,
          RADG: RAD.G,
          RADD: RAD.D,
          azimuthA: engineeringLeasingData?.leaseAudit?.azimuthA,
          azimuthB: engineeringLeasingData?.leaseAudit?.azimuthB,
          azimuthG: engineeringLeasingData?.leaseAudit?.azimuthG,
          azimuthD: engineeringLeasingData?.leaseAudit?.azimuthD,
          projectType: engineeringProjectData.projectType.value,
          mountType: (scopingInfo?.previousMountModel as any as IdAndValue)
            ?.value,
          civilVendor: engineeringProjectData?.civilVendor?.value,
          craneRequired:
            engineeringWorkflowData?.regulatoryCompliance?.craneRequired,
          craneHeight:
            engineeringWorkflowData?.regulatoryCompliance?.craneHeight,
          scopingComplete: currentPaceTasks?.find(
            (paceTask: ProjectPaceTask) =>
              paceTask.id === MainPaceTask.ScopingComplete,
          )?.projectPaceTaskData?.actualDate,
          finalRFDSDate: currentPaceTasks?.find(
            (paceTask: ProjectPaceTask) =>
              paceTask.id === MainPaceTask.FinalRFDSReceived,
          )?.projectPaceTaskData?.actualDate,
        } as ProjectInfo,
        ['scopingComplete', 'finalRFDSDate'],
      ),
      siteInfo: {
        FALocationNumber: engineeringSiteData?.FALocationNumber,
        siteNumber: engineeringSiteData?.siteNumber,
        streetAddress: engineeringSiteData?.streetAddress,
        city: engineeringSiteData?.city,
        state: engineeringSiteData?.state?.name,
        zip: engineeringSiteData?.zip,
        latitude: engineeringSiteData?.latitude,
        longitude: engineeringSiteData?.longitude,
        towerOwner: engineeringSiteData?.towerOwner as any as string,
        towerType: engineeringSiteData?.towerType?.value,
        towerHeight: engineeringSiteData?.towerHeight,
        equipmentType: engineeringSiteData?.equipmentType?.value,
      },
      scopingInfo: {
        promotionNotes: scopingInfo?.promotionNotes,
        scopingGroundNotes: scopingInfo?.scopingGroundNotes,
        scopingTowerNotes: scopingInfo?.scopingTowerNotes,
        demotionNotes: scopingInfo?.demotionNotes,
        RFDSStatus: engineeringWorkflowData?.RFDSPhase1?.RFDSStatus,
      },
      scopingSectors,
      otherEquipment,
    }),
  );

  export const getProjectType = createSelector(
    [engineeringBrowseState],
    (state: EngineeringBrowseState) =>
      state.engineeringProjectData.projectType.value,
  );

  export const isFetching = createSelector(
    [engineeringBrowseState],
    (state: EngineeringBrowseState) => state.isFetching,
  );
}
