import React, {
  Dispatch,
  FC,
  PropsWithChildren,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { Col, Form, Row } from 'antd';
import { SelectValue } from 'antd/lib/select';
import { Undefinable } from 'providence-types';
import {
  EquipmentAvailableSelectorValueService,
  HttpService,
} from '@core/services';
import { EquipmentHttpService } from '@core/services/http';
import { useDidUpdateEffect } from '@core/utils/hooks';
import { arrsToSelectableObject, toggleModal } from '@core/utils/methods';
import { ModalMainTypes } from '@models/enums';
import {
  EquipmentManufacturerData,
  EquipmentTabData,
  EquipmentTypeData,
  TabComponentProps,
} from '@models/interfaces';
import { DeleteModal, VirtualTable } from '@shared/components';
import { Autocomplete, PrimaryButton } from '@shared/modules';
import { EquipmentSelectors } from '@store/selectors';
import { EquipmentModal } from './modals';
import { EQUIPMENT_DEFAULT_VALUE, equipmentTabColumns } from './models';

import './styles.scss';

const availableSelectorValueService =
  new EquipmentAvailableSelectorValueService();

export const Equipment: FC<PropsWithChildren<TabComponentProps>> = ({
  isActive,
}: PropsWithChildren<TabComponentProps>) => {
  const equipmentService = useMemo(
    () => HttpService.getHttpRequests(EquipmentHttpService),
    [],
  );

  const { equipmentTypes, equipments } = useSelector(
    EquipmentSelectors.getEquipmentTypesWithEquipments,
  );

  const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false);
  const [isAddEditModalVisible, setAddEditModalVisible] =
    useState<boolean>(false);
  const [currentModalType, setCurrentModalType] = useState<ModalMainTypes>(
    ModalMainTypes.Add,
  );

  const [equipmentTypeSelectorValue, setEquipmentTypeSelectorValue] =
    useState<Undefinable<string>>(undefined);

  const [
    equipmentManufacturerSelectorValue,
    setEquipmentManufacturerSelectorValue,
  ] = useState<Undefinable<string>>(undefined);

  const [equipmentTypeSelectorOptions, setEquipmentTypeSelectorOptions] =
    useState<EquipmentTypeData[]>([]);

  const [
    equipmentManufacturerSelectorOptions,
    setEquipmentManufacturerSelectorOptions,
  ] = useState<EquipmentManufacturerData[]>([]);

  const [selectedItem, setSelectedItem] = useState<EquipmentTabData>(
    EQUIPMENT_DEFAULT_VALUE,
  );

  const toggleAddAndEditModal = (type: ModalMainTypes): void => {
    setCurrentModalType(type);
    setAddEditModalVisible(!isAddEditModalVisible);

    if (isAddEditModalVisible) {
      setSelectedItem({ ...EQUIPMENT_DEFAULT_VALUE });
    }
  };

  useEffect(() => {
    if (isActive) {
      availableSelectorValueService.equipmentTypes = equipmentTypes;
      availableSelectorValueService.init();

      setEquipmentTypeSelectorValue(undefined);
      setEquipmentManufacturerSelectorValue(undefined);

      setEquipmentTypeSelectorOptions(
        availableSelectorValueService.availableEquipmentTypeValue,
      );
      setEquipmentManufacturerSelectorOptions(
        availableSelectorValueService.availableEquipmentManufacturerValue,
      );
    }
  }, [equipmentTypes, isActive]);

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      await equipmentService.getEquipments(
        equipmentTypeSelectorValue,
        equipmentManufacturerSelectorValue,
      );
    };

    const condition =
      (equipmentTypeSelectorValue && equipmentManufacturerSelectorValue) ||
      (!equipmentTypeSelectorValue && !equipmentManufacturerSelectorValue);

    if (condition) {
      fetchData();
    }
  }, [equipmentTypeSelectorValue, equipmentManufacturerSelectorValue]);

  useDidUpdateEffect(() => {
    availableSelectorValueService.selectEquipmentType(
      equipmentTypeSelectorValue,
    );

    setEquipmentManufacturerSelectorOptions(
      availableSelectorValueService.availableEquipmentManufacturerValue,
    );
  }, [equipmentTypeSelectorValue]);

  useDidUpdateEffect(() => {
    availableSelectorValueService.selectEquipmentManufacturer(
      equipmentManufacturerSelectorValue,
    );

    setEquipmentTypeSelectorOptions(
      availableSelectorValueService.availableEquipmentTypeValue,
    );
  }, [equipmentManufacturerSelectorValue]);

  const remove = async (): Promise<void> => {
    await equipmentService.removeEquipment(selectedItem.id);

    setDeleteModalVisible(false);
  };

  const selectEquipment = (
    equipmentType: EquipmentTabData,
    setter: Dispatch<boolean>,
    prevState: boolean,
  ): void => {
    setSelectedItem(equipmentType);
    setter(!prevState);
  };

  return (
    <div className="prov-equipment">
      <DeleteModal
        onOk={remove}
        onCancel={toggleModal(setDeleteModalVisible)}
        visible={deleteModalVisible}
      />
      <EquipmentModal
        visible={isAddEditModalVisible}
        modalType={currentModalType}
        toggleModal={toggleAddAndEditModal}
        value={selectedItem}
        equipmentTypeSelectorValue={equipmentTypeSelectorValue}
        equipmentManufacturerSelectorValue={equipmentManufacturerSelectorValue}
      />
      <Row className="prov-equipment__table-handler">
        <Col span="5">
          <Form>
            <Autocomplete
              label="Select Equipment Type"
              id="equipment-type"
              options={arrsToSelectableObject<EquipmentTypeData>(
                equipmentTypeSelectorOptions,
                'id',
                'name',
              )}
              elementProps={{
                allowClear: true,
                value: equipmentTypeSelectorValue,
                onChange: (value: SelectValue): void =>
                  setEquipmentTypeSelectorValue(value as string),
              }}
            />
          </Form>
        </Col>
        <Col span="5">
          <Form>
            <Autocomplete
              label="Select Manufacturer"
              id="manufacturer"
              options={arrsToSelectableObject<EquipmentManufacturerData>(
                equipmentManufacturerSelectorOptions,
                'id',
                'name',
              )}
              elementProps={{
                allowClear: true,
                value: equipmentManufacturerSelectorValue,
                onChange: (value: SelectValue): void =>
                  setEquipmentManufacturerSelectorValue(value as string),
              }}
            />
          </Form>
        </Col>
      </Row>
      <VirtualTable
        dataSource={equipments}
        columns={equipmentTabColumns(
          (equipment: EquipmentTabData): void =>
            selectEquipment(
              equipment,
              () => toggleAddAndEditModal(ModalMainTypes.Edit),
              isAddEditModalVisible,
            ),
          (equipment: EquipmentTabData): void =>
            selectEquipment(
              equipment,
              setDeleteModalVisible,
              deleteModalVisible,
            ),
          !!(equipmentTypeSelectorValue && equipmentManufacturerSelectorValue),
        )}
        maxViewRow={9}
      />
      <PrimaryButton
        icon="+"
        title="Add"
        className="prov-equipment__add-button"
        onClick={(): void => toggleAddAndEditModal(ModalMainTypes.Add)}
        disabled={
          !equipmentTypeSelectorValue || !equipmentManufacturerSelectorValue
        }
      />
    </div>
  );
};
