import React, {
  FC,
  PropsWithChildren,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { Form } from 'antd';
import classNames from 'classnames';
import { HttpService } from '@core/services';
import { CountyStatesHttpService } from '@core/services/http';
import { isValidForm } from '@core/utils/methods';
import { serverValidator } from '@core/utils/validators';
import { WHITESPACE_INPUT_LIMIT_RULES } from '@models/constants';
import { ModalMainTypes } from '@models/enums';
import { AdminCountyData, StateWithCounties } from '@models/interfaces';
import { AddAndEditModal } from '@shared/components';
import { Input } from '@shared/modules';
import { CountySelectors } from '@store/selectors';
import { STATE_DEFAULT_VALUE, StateModalProps } from './models';

import './styles.scss';

const { useForm } = Form;

export const StateModal: FC<StateModalProps> = ({
  visible,
  modalType,
  toggleModal,
  selectorValue,
  setSelector,
}: PropsWithChildren<StateModalProps>) => {
  const [form] = useForm();
  const states = useSelector(CountySelectors.getStatesWithCounties);

  const countyStatesService = useMemo(
    () => HttpService.getHttpRequests(CountyStatesHttpService),
    [],
  );

  const [currentState, setCurrentState] =
    useState<AdminCountyData>(STATE_DEFAULT_VALUE);
  const state = states.find(
    (statesItem: StateWithCounties) => statesItem.id === selectorValue,
  );

  useEffect(() => {
    if (state) {
      setCurrentState(state);
    }
  }, [states, state]);

  const getInputData = (): { name: string } => {
    const formField = form.getFieldsValue(['state']);

    return {
      name: formField.state,
    };
  };

  const add = async ({
    name,
  }: Pick<AdminCountyData, 'name'>): Promise<void> => {
    if (await isValidForm(form)) {
      await countyStatesService.addCountyState(name);

      setSelector('');
      toggleModal(modalType);
    }
  };

  const edit = async ({
    name,
  }: Pick<AdminCountyData, 'name'>): Promise<void> => {
    if (await isValidForm(form)) {
      await countyStatesService.updateCountyState(currentState.id, name);

      setSelector(currentState.id);
      toggleModal(modalType);
    }
  };

  const deleteState = async (): Promise<void> => {
    await countyStatesService.deleteCountyState(currentState.id);

    setSelector('');
    toggleModal(modalType);
  };

  useEffect(() => {
    if (visible) {
      form.setFieldsValue({
        state: modalType === ModalMainTypes.Edit ? state?.name : '',
      });
    }
  }, [visible, state, modalType, form]);

  return (
    <AddAndEditModal
      onOk={
        modalType === ModalMainTypes.Add
          ? (): Promise<void> => add(getInputData())
          : (): Promise<void> => edit(getInputData())
      }
      onCancel={(): void => toggleModal(modalType)}
      visible={visible}
      type={modalType}
      title="State"
      okText="Save"
      cancelText="Delete"
      className={classNames('prov-admin-state-modal', {
        'add-modal': modalType === ModalMainTypes.Add,
      })}
      formProps={{
        labelCol: { span: 5 },
        wrapperCol: { span: 15 },
        form,
      }}
      cancelButtonProps={{
        onClick: deleteState,
      }}
    >
      <Input
        id="state"
        label="State"
        formItemProps={{
          rules: [
            serverValidator(
              {
                method: 'getCountyStateValidation',
                errorMessage: 'State must be unique',
              },
              CountyStatesHttpService,
              modalType === ModalMainTypes.Add ? undefined : currentState.id,
            ),
            ...WHITESPACE_INPUT_LIMIT_RULES,
          ],
        }}
      />
    </AddAndEditModal>
  );
};
