import React, { FC, ReactText, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Col, Form, Row } from 'antd';
import { Store } from 'antd/es/form/interface';
import { HttpService } from '@core/services';
import { ScopingHttpService } from '@core/services/http';
import { useDidUpdateEffect } from '@core/utils/hooks';
import {
  createOnChangeModalVisibility,
  momentizeObjectDates,
} from '@core/utils/methods';
import {
  BROWSE_DEFAULT_FILTER_VALUE,
  browseFiltersChange,
  tableCount,
} from '@models/constants';
import { RangePickerValues, ScopingBrowseFilter } from '@models/interfaces';
import { MainScopingBrowseFilter, MomentNull, SwitchType } from '@models/types';
import { NotificationsLoader } from '@shared/components';
import { DatePicker, PrimaryButton, RangePicker } from '@shared/modules';
import { BrowsePage } from '@shared/modules/browse-page';
import { ScopingSelectors } from '@store/selectors';
import {
  SCOPING_BROWSE_COLUMNS,
  SCOPING_BROWSE_DEFAULT_CONFIGURE_FILTER,
} from './models/constants';
import { Filters } from './filters';

import './styles.scss';

const { useForm } = Form;

export const ScopingBrowse: FC<RouteComponentProps> = () => {
  const [form] = useForm();
  const [scheduledForm] = useForm();
  const [exportForm] = useForm();

  const scopingService = useMemo(
    () => HttpService.getHttpRequests(ScopingHttpService),
    [],
  );

  const scopingBrowse = useSelector(ScopingSelectors.getScopingBrowseData);

  const [selectedRows, setSelectedRows] = useState<ReactText[]>([]);
  const [scheduleDate, setScheduleDate] = useState<MomentNull>(null);
  const [exportDate, setExportDate] = useState<RangePickerValues>({
    from: null,
    to: null,
  });
  const [filterValue, setFilterValue] = useState<MainScopingBrowseFilter>({
    ...BROWSE_DEFAULT_FILTER_VALUE,
    showCompletedProjects: false,
  });
  const [configureFilter, setConfigureFilter] = useState<ScopingBrowseFilter>(
    SCOPING_BROWSE_DEFAULT_CONFIGURE_FILTER,
  );

  const rowSelection = {
    selectedRowKeys: selectedRows,
    onChange: (rows: ReactText[]): void => setSelectedRows(rows),
  };

  useDidUpdateEffect(() => {
    scopingService.getScopingBrowse(filterValue);
  }, [filterValue]);

  const onSearchChanges = (search: string): void => {
    setFilterValue((prevState: MainScopingBrowseFilter) => ({
      ...prevState,
      search,
    }));
  };

  const onApplyConfigureFilter = (): void => {
    setFilterValue((prevState: MainScopingBrowseFilter) => ({
      ...prevState,
      ...configureFilter,
    }));
  };

  const onChangeConfigureFilter = (filter: ScopingBrowseFilter): void =>
    setConfigureFilter((prevState: ScopingBrowseFilter) => ({
      ...prevState,
      ...filter,
    }));

  const onOnlyCompletedSwitch = (value: boolean): void =>
    setFilterValue((prevState: MainScopingBrowseFilter) => ({
      ...prevState,
      showCompletedProjects: value as SwitchType,
    }));

  const onSubmitDateScheduleDate = async (): Promise<void> => {
    const date = scheduleDate?.format();

    if (date !== undefined) {
      await scopingService.updateScopingBrowse(
        { ...filterValue, projectIds: selectedRows },
        { date },
      );
    }

    setScheduleDate(null);
    setSelectedRows([]);
    scheduledForm.resetFields();
  };

  const onSubmitExport = async (): Promise<void> => {
    const notification = new NotificationsLoader({
      message: 'Loading',
      description: 'Please wait',
    });

    try {
      await scopingService.exportScheduledScoping(
        momentizeObjectDates(
          exportDate,
          ['from', 'to'],
          true,
        ) as RangePickerValues,
      );
      exportForm.resetFields();
      setExportDate({ to: null, from: null });
    } catch (e) {
      console.error(e);
    }
    notification.close();
  };

  return (
    <BrowsePage
      columns={SCOPING_BROWSE_COLUMNS}
      data={scopingBrowse?.items}
      switchInfo="Show Completed Projects"
      filters={
        <Filters
          formProps={{ form }}
          value={configureFilter}
          onChanges={onChangeConfigureFilter}
        />
      }
      tableProps={{
        rowSelection,
        pagination: {
          total: scopingBrowse?.total,
        },
        module: 'scoping',
      }}
      className="scoping-browse"
      place={tableCount(true)}
      filterFormProps={{ labelCol: { span: 8 }, form }}
      onChangeFilterModalVisibility={createOnChangeModalVisibility(
        form,
        configureFilter,
        filterValue,
        setConfigureFilter,
      )}
      cancelButtonProps={{
        onClick: (): void => {
          form.resetFields();
          setConfigureFilter(SCOPING_BROWSE_DEFAULT_CONFIGURE_FILTER);
        },
      }}
      onChange={browseFiltersChange(setFilterValue)}
      onSearchChanges={onSearchChanges}
      onApplyFilter={onApplyConfigureFilter}
      onSwitch={onOnlyCompletedSwitch}
      additional={
        <Row className="scoping-browse__schedule-form">
          <Col>
            <Row>
              <Form
                onValuesChange={(value: Store): void =>
                  setExportDate((prevState: RangePickerValues) => ({
                    ...prevState,
                    ...value.scheduledScopingDate,
                  }))
                }
                form={exportForm}
              >
                <RangePicker label="" id="scheduledScopingDate" />
                <PrimaryButton
                  onClick={onSubmitExport}
                  title="Download"
                  className="download-btn"
                  disabled={!exportDate.from || !exportDate.to}
                />
              </Form>
            </Row>
          </Col>
          <Col>
            <Form
              onValuesChange={(value: Store): void =>
                setScheduleDate(value.date)
              }
              form={scheduledForm}
            >
              <DatePicker id="date" label="Schedule Scoping for Selected" />
            </Form>
          </Col>
          <Col>
            <PrimaryButton
              title="Submit"
              disabled={scheduleDate === null || !selectedRows.length}
              onClick={onSubmitDateScheduleDate}
            />
          </Col>
        </Row>
      }
    />
  );
};
