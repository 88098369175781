import React, { FC, PropsWithChildren, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'antd';
import classNames from 'classnames';
import { PaceTaskScreen } from 'providence-types';
import { HttpService } from '@core/services';
import { ProjectHttpService } from '@core/services/http';
import { useDidUpdateEffect, useSaveChanged } from '@core/utils/hooks';
import { isValidForm } from '@core/utils/methods';
import { ALLOW_OOM_INFORMATION_PACE_TASK_OPTIONS } from '@models/constants';
import {
  FinancialOOMInformation,
  OptionProps,
  ProjectPaceTaskData,
} from '@models/interfaces';
import { SummaryProps } from '@models/types';
import {
  getPaceTaskDifference,
  NotificationsLoader,
  paceTaskMapper,
  PaceTaskTable,
  Panel,
} from '@shared/components';
import { Input, PrimaryButton } from '@shared/modules';
import {
  CommonActions,
  FinancialActions,
  ProjectActions,
} from '@store/actions';
import {
  FinancialSelectors,
  PaceTasksSelectors,
  ProjectCategoriesSelectors,
  ProjectManagementSelectors,
  UserSelectors,
} from '@store/selectors';
import { ProjectInformation } from '../project-information';
import { SiteInformation } from '../site-information';

import './styles.scss';

const { useForm } = Form;

export const ProjectManagementSummary: FC<SummaryProps> = ({
  isEditing = false,
  toggleEditing,
}: PropsWithChildren<SummaryProps>) => {
  const dispatch = useDispatch();

  const [form] = useForm();
  const [paceTaskForm] = useForm();

  const { engineering, id, leasing, notes, site, ...project } = useSelector(
    ProjectManagementSelectors.getPMSummaryInfo,
  );

  const currentPaceTasks = useSelector(PaceTasksSelectors.getPaceTasks);
  const projectCategoriesTableVisible = useSelector(
    ProjectCategoriesSelectors.getProjectCategoriesTableVisible,
  );
  const arePaceTasksChanged = useSelector(
    PaceTasksSelectors.arePaseTasksChanged,
  );
  const PACEFetching = useSelector(PaceTasksSelectors.isFetching);
  const financialOOMInformation = useSelector(
    FinancialSelectors.getOOMInformationData,
  );
  const roles = useSelector(UserSelectors.getUserRoles);

  useEffect(() => {
    form.setFieldsValue({
      siteNotes: site?.notes,
      projectNotes: notes,
      engineeringNotes: engineering?.RFDSPhase1?.engineeringNotes,
      leasingNotes: leasing?.leasingNotes,
      projectPermittingNotes: project?.projectPermittingNotes,
    });
  }, []);

  const onSubmit = async (): Promise<void> => {
    const [paceTasks, keys] = getPaceTaskDifference(
      currentPaceTasks,
      paceTaskForm,
      roles,
    );

    if (await isValidForm(paceTaskForm, keys)) {
      try {
        await HttpService.getHttpRequests(
          ProjectHttpService,
        ).updateProjectPaceTasks(
          id,
          PaceTaskScreen.ProjectManagement,
          paceTasks,
        );

        toggleEditing?.();

        dispatch(ProjectActions.setArePACETasksChanged.done(false));

        const OOMPaceTaskIds: string[] =
          ALLOW_OOM_INFORMATION_PACE_TASK_OPTIONS.map(
            ({ value }: OptionProps) => value as string,
          );

        (paceTasks as ProjectPaceTaskData[]).forEach(
          ({ paceTask: paceTaskId, actualDate }: ProjectPaceTaskData) => {
            if (paceTaskId && OOMPaceTaskIds.includes(paceTaskId)) {
              const updateOOMInformationRecord = financialOOMInformation.find(
                (oom: FinancialOOMInformation) =>
                  oom.PACETaskIdentification === paceTaskId,
              );

              if (updateOOMInformationRecord) {
                dispatch(
                  FinancialActions.updateOOMInformationDataAction.done({
                    ...updateOOMInformationRecord,
                    actualDate,
                  }),
                );
              }
            }
          },
        );

        NotificationsLoader.notificationSuccess(
          'PACE tasks have been updated!',
        );
      } catch (e) {
        console.error(e);
      }
    }
  };

  const onCancel = async (): Promise<void> => {
    paceTaskForm.setFieldsValue(paceTaskMapper(currentPaceTasks));

    toggleEditing?.();

    dispatch(ProjectActions.setArePACETasksChanged.done(false));
  };

  useEffect(
    () => (): void => {
      dispatch(CommonActions.setHasUnsubmittedData.done(false));
    },

    [],
  );

  useDidUpdateEffect(() => {
    dispatch(CommonActions.setHasUnsubmittedData.done(arePaceTasksChanged));
  }, [arePaceTasksChanged]);

  useSaveChanged(isEditing, onSubmit, onCancel);

  return (
    <div className="prov-project-management-summary">
      <div
        className={classNames('tabs-wrap', {
          'tabs-wrap_with-actions': isEditing,
          'categories-table-open': projectCategoriesTableVisible,
        })}
      >
        <div className="prov-project-management-summary__wrap">
          <div className="info-panel">
            <SiteInformation data={site} className="info-panel__information" />
            <ProjectInformation
              data={{
                structuralNotes: leasing?.structural?.structuralComments,
                structuralResult: leasing?.structural?.structuralResult?.value,
                ...project,
              }}
              className="info-panel__information"
            />
            <Form
              labelCol={{ span: 10 }}
              wrapperCol={{ span: 14 }}
              form={form}
              className="info-panel__notes"
            >
              <Panel header="Notes">
                <Input id="siteNotes" label="Site Notes" isEditing={false} />
                <Input
                  id="projectNotes"
                  label="Project Notes"
                  isEditing={false}
                />
                <Input
                  id="engineeringNotes"
                  label="Engineering Notes"
                  isEditing={false}
                />
                <Input
                  id="leasingNotes"
                  label="Leasing Notes"
                  isEditing={false}
                />
                <Input
                  id="projectPermittingNotes"
                  label="Project Permitting Notes"
                  isEditing={false}
                />
              </Panel>
            </Form>
          </div>
          <PaceTaskTable
            isEditing={isEditing}
            data={currentPaceTasks || []}
            form={paceTaskForm}
          />
        </div>
      </div>
      {isEditing && (
        <div className="prov-project-management-summary__btns">
          <div className="action-wrap">
            <PrimaryButton
              title="Submit"
              type="primary"
              disabled={PACEFetching}
              onClick={onSubmit}
            />
            <PrimaryButton title="Cancel" type="default" onClick={onCancel} />
          </div>
        </div>
      )}
    </div>
  );
};
