import { Resolver } from '@wellyes/react-router-extended';
import { HttpService } from '@core/services';
import { PermittingHttpService } from '@core/services/http';
import { getIdParamFromUrl } from '@core/utils/methods';

export class JurisdictionDetailsResolver implements Resolver {
  async resolve(): Promise<void> {
    const [jurisdictionId] = getIdParamFromUrl();

    await HttpService.getHttpRequests(
      PermittingHttpService,
    ).getJurisdictionDetailsData(jurisdictionId);
  }
}
