import React, { ReactNode } from 'react';
import { readonlyBooleanRender } from '@core/utils/methods';
import { actionButtons } from '@models/constants';
import { AdminDropdownValuesData } from '@models/interfaces';
import { ColumnsFunc } from '@models/types';
import { VirtualTableColumn } from '@shared/components';

export const getAdminDropdownValuesColumns = (
  selectorValue: string,
  editFunc: ColumnsFunc<AdminDropdownValuesData>,
  deleteFunc: ColumnsFunc<AdminDropdownValuesData>,
): Array<VirtualTableColumn> => [
  ...(selectorValue === 'Frequency'
    ? [
        {
          title: 'First Net',
          key: 'isFirstNet',
          dataIndex: 'isFirstNet',
          width: 150,
          render: readonlyBooleanRender,
        },
      ]
    : []),
  {
    title: 'Value',
    dataIndex: 'value',
    key: 'value',
    render: (value: string): ReactNode => (
      <span className="column-text">{value}</span>
    ),
  },
  actionButtons({ editFunc, deleteFunc }) as VirtualTableColumn,
];
