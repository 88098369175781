import { RuleObject } from 'rc-field-form/es/interface';
import { MomentNull } from '@models/types';
import { getDiff } from '../helpers';
import { isExcludedValidationDate } from '../models/is-excluded-validation-date.constant';

export const ACTUAL_DATE = [
  {
    validator: (_: RuleObject, inputValue?: MomentNull): Promise<void> => {
      if (inputValue && !isExcludedValidationDate(inputValue)) {
        const MAX_DAYS = 30;
        const diff = getDiff(inputValue!);
        const isFutureDate = diff > 0;

        let errorMessage = '';

        if (isFutureDate || Math.abs(diff) > MAX_DAYS) {
          errorMessage = isFutureDate
            ? 'Must be today’s date or a prior date.'
            : 'Must be within 30 calendar days of current date.';
        }

        return errorMessage
          ? Promise.reject(new Error(errorMessage))
          : Promise.resolve();
      }

      return Promise.resolve();
    },
  },
];
