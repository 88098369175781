import React, { ReactNode } from 'react';
import { FileSearchOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import { ISelectorOption } from 'providence-types';
import {
  findFromSelectorOptions,
  mainDateFormat,
  tableSorter,
} from '@core/utils/methods';
import { actionButtons } from '@models/constants';
import { DirectMessageData } from '@models/interfaces';

export const DIRECT_MESSAGE_COLUMNS = (
  usersList: ISelectorOption<string>[],
  showMessage: (directMessage: DirectMessageData) => void,
  removeFn: (directMessage: DirectMessageData) => void,
): ColumnsType =>
  [
    {
      dataIndex: 'directMessageData',
      key: 'status',
      width: 45,
      sorter: tableSorter(['directMessageData', 'completed']),
      className: 'empty-header',
      render: ({
        completed,
      }: DirectMessageData['directMessageData']): ReactNode => (
        <i className={`circle circle_${completed ? 'gray' : 'green'}`} />
      ),
    },
    {
      title: 'Sender',
      dataIndex: 'directMessageData',
      key: 'senderId',
      width: 170,
      render: ({
        senderId,
      }: DirectMessageData['directMessageData']): ReactNode =>
        findFromSelectorOptions(senderId, usersList),
    },
    {
      title: 'Receivers',
      dataIndex: 'directMessageData',
      key: 'receivers',
      width: 200,
      render: ({
        receivers,
      }: DirectMessageData['directMessageData']): ReactNode => (
        <div className="list">
          {receivers.map((receiver: string) => (
            <span className="list__item" key={receiver}>
              {findFromSelectorOptions(receiver, usersList)}
            </span>
          ))}
        </div>
      ),
    },
    {
      title: 'Area',
      dataIndex: 'directMessageData',
      key: 'areaKey',
      width: 170,
      sorter: tableSorter(['directMessageData', 'areaKey']),
      render: ({
        areaKey,
      }: DirectMessageData['directMessageData']): ReactNode => areaKey,
    },
    {
      title: 'Project Bundle Id',
      dataIndex: 'projectData',
      key: 'projectBundleId',
      width: 170,
      sorter: tableSorter(['projectData', 'projectBundleId']),
      render: ({
        projectBundleId,
      }: DirectMessageData['projectData']): ReactNode => projectBundleId,
    },
    {
      title: 'Site Number',
      dataIndex: 'projectData',
      key: 'siteNumber',
      width: 170,
      sorter: tableSorter(['projectData', 'siteNumber']),
      render: ({ siteNumber }: DirectMessageData['projectData']): ReactNode =>
        siteNumber,
    },
    {
      title: 'Message',
      dataIndex: 'directMessageData',
      key: 'message',
      sorter: tableSorter(['directMessageData', 'message']),
      className: 'overflow',
      render: ({
        message,
      }: DirectMessageData['directMessageData']): ReactNode => message,
    },
    {
      title: 'Created At',
      dataIndex: 'directMessageData',
      key: 'createdAt',
      width: 150,
      sorter: tableSorter(['directMessageData', 'createdAt'], true),
      render: ({
        createdAt,
      }: DirectMessageData['directMessageData']): ReactNode =>
        mainDateFormat(createdAt),
    },
    {
      key: 'show-button',
      width: 56,
      render: (directMessage: DirectMessageData): ReactNode => (
        <FileSearchOutlined
          className="view-icon"
          onClick={(): void => showMessage(directMessage)}
        />
      ),
    },
    actionButtons({
      deleteFunc: removeFn,
      width: 86,
    }),
  ] as ColumnsType;
