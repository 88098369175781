import { ReactNode } from 'react';
import { ColumnsType, ColumnType } from 'antd/lib/table';
import { MainPaceTask } from 'providence-types';
import {
  priceCommaReplacer,
  projectCategoryRender,
  projectPaceTaskDataRender,
  providenceAgentRender,
  readOnlyDatePickerRenderer,
  readOnlyInputNumberRender,
} from '@core/utils/methods';
import { actionButtons } from '@models/constants';
import {
  AccountingBrowse,
  IdAndValue,
  OOMInformation,
  SiteBrowse,
} from '@models/interfaces';
import { Browse, ColumnsFunc } from '@models/types';

export const getAccountingColumns = (
  editFunc?: ColumnsFunc<AccountingBrowse>,
): ColumnsType<Browse> => [
  {
    title: 'PACE Project',
    dataIndex: 'categories',
    key: 'PACEProjectNumber',
    width: 150,
    sorter: true,
    render: projectCategoryRender({
      field: 'PACEProjectNumber',
    }),
  },
  {
    title: 'Oracle Project',
    dataIndex: 'categories',
    key: 'oracleProjectNumber',
    width: 150,
    sorter: true,
    render: projectCategoryRender({
      field: 'oracleProjectNumber',
    }),
  },
  {
    title: 'Project Category',
    dataIndex: 'categories',
    key: 'projectCategory',
    width: 190,
    sorter: true,
    render: projectCategoryRender({
      field: 'projectCategory',
    }),
  },
  {
    title: 'FA Location',
    dataIndex: 'site',
    key: 'FALocationNumber',
    width: 130,
    sorter: true,
    render: (site: SiteBrowse): ReactNode =>
      readOnlyInputNumberRender(site?.FALocationNumber),
  },
  {
    title: 'Site Number',
    dataIndex: 'site',
    key: 'siteNumber',
    width: 140,
    sorter: true,
    render: (site: SiteBrowse): ReactNode => site?.siteNumber,
  },
  {
    title: 'Legacy Site ID',
    width: 150,
    key: 'legacySiteId',
    sorter: true,
    dataIndex: 'site',
    render: (site: SiteBrowse): ReactNode => site.legacySiteId,
  },
  {
    title: 'Alternate Site Info',
    width: 170,
    key: 'alternateSiteInfo',
    sorter: true,
    dataIndex: 'site',
    render: (site: SiteBrowse): ReactNode => site.alternateSiteInfo,
  },
  {
    title: 'USID',
    width: 150,
    key: 'USID',
    sorter: true,
    dataIndex: 'site',
    render: (site: SiteBrowse): ReactNode => site.USID,
  },
  {
    title: 'Submarket',
    key: 'submarket',
    width: 140,
    sorter: true,
    dataIndex: 'site',
    render: (site: SiteBrowse): ReactNode => site.submarket?.value,
  },
  {
    title: 'Providence Agent',
    key: 'providenceAgent',
    width: 180,
    sorter: true,
    dataIndex: 'providenceAgent',
    render: providenceAgentRender,
  },
  {
    title: 'Tower Owner',
    key: 'towerOwner',
    width: 145,
    dataIndex: 'site',
    sorter: true,
    render: (site: SiteBrowse): ReactNode => site?.towerOwner?.value,
  },
  {
    title: 'Project Type',
    dataIndex: 'projectType',
    key: 'projectType',
    width: 140,
    sorter: true,
    render: (projectType: IdAndValue): ReactNode => projectType?.value,
  },
  {
    title: 'Project Work Type',
    dataIndex: 'projectWorkType',
    key: 'projectWorkType',
    width: 180,
    sorter: true,
    render: (projectWorkType: IdAndValue): ReactNode => projectWorkType?.value,
  },
  {
    title: 'SS010 Actual',
    key: 'SS010',
    dataIndex: 'projectPaceTaskData',
    width: 140,
    render: projectPaceTaskDataRender({
      field: 'actualDate',
      task: MainPaceTask.CandidateApproved,
    }),
  },
  {
    title: 'SS010 Invoice Number',
    key: 'SS010InvoiceNumber',
    dataIndex: 'projectPaceTaskData',
    width: 190,
    render: projectPaceTaskDataRender({
      field: 'invoiceNumber',
      task: MainPaceTask.CandidateApproved,
    }),
  },
  {
    title: 'SS010 Invoice Date',
    key: 'SS010InvoiceDate',
    dataIndex: 'projectPaceTaskData',
    width: 170,
    render: projectPaceTaskDataRender({
      field: 'invoiceDate',
      task: MainPaceTask.CandidateApproved,
    }),
  },
  {
    title: 'RE066 Actual',
    key: 'RE066',
    dataIndex: 'projectPaceTaskData',
    width: 140,
    render: projectPaceTaskDataRender({
      field: 'actualDate',
      task: MainPaceTask.ZoningAppDeemedComplete,
    }),
  },
  {
    title: 'RE066 Invoice Number',
    key: 'RE066InvoiceNumber',
    dataIndex: 'projectPaceTaskData',
    width: 190,
    render: projectPaceTaskDataRender({
      field: 'invoiceNumber',
      task: MainPaceTask.ZoningAppDeemedComplete,
    }),
  },
  {
    title: 'RE066 Invoice Date',
    key: 'RE066InvoiceDate',
    dataIndex: 'projectPaceTaskData',
    width: 170,
    render: projectPaceTaskDataRender({
      field: 'invoiceDate',
      task: MainPaceTask.ZoningAppDeemedComplete,
    }),
  },
  {
    title: 'RE015 Actual Date',
    key: 'RE015ActualDate',
    dataIndex: 'projectPaceTaskData',
    width: 160,
    render: projectPaceTaskDataRender({
      field: 'actualDate',
      task: MainPaceTask.LeasingComplete,
    }),
  },
  {
    title: 'RE015 OOM Amount',
    key: 'RE015OOMAmount',
    dataIndex: 'OOMInformationData',
    width: 190,
    render: (value: Record<MainPaceTask, OOMInformation>): ReactNode => {
      const currenValue = value[MainPaceTask.LeasingComplete]?.OOMAmount;

      return currenValue ? `$ ${priceCommaReplacer(currenValue)}` : currenValue;
    },
  },
  {
    title: 'RE015 OOM Date',
    key: 'RE015OOMDate',
    dataIndex: 'OOMInformationData',
    width: 190,
    render: (value: Record<MainPaceTask, OOMInformation>): ReactNode => {
      const currentValue = value[MainPaceTask.LeasingComplete]?.OOMDate;

      return currentValue
        ? readOnlyDatePickerRenderer(currentValue)
        : currentValue;
    },
  },
  {
    title: 'RE015 OOM Total Amount',
    key: 'RE015OOMTotalAmount',
    dataIndex: 'OOMInformationData',
    width: 210,
    render: (value: Record<MainPaceTask, OOMInformation>): ReactNode => {
      const currenValue = value[MainPaceTask.LeasingComplete]?.OOMTotalAmount;

      return currenValue ? `$ ${priceCommaReplacer(currenValue)}` : currenValue;
    },
  },
  {
    title: 'RE015 Invoice Number',
    key: 'RE015InvoiceNumber',
    dataIndex: 'projectPaceTaskData',
    width: 190,
    render: projectPaceTaskDataRender({
      field: 'invoiceNumber',
      task: MainPaceTask.LeasingComplete,
    }),
  },
  {
    title: 'RE015 Invoice Date',
    key: 'RE015InvoiceDate',
    dataIndex: 'projectPaceTaskData',
    width: 170,
    render: projectPaceTaskDataRender({
      field: 'invoiceDate',
      task: MainPaceTask.LeasingComplete,
    }),
  },
  {
    title: 'RE020 Actual Date',
    key: 'RE020SiteAcqCompleteActual',
    dataIndex: 'projectPaceTaskData',
    width: 190,
    render: projectPaceTaskDataRender({
      field: 'actualDate',
      task: MainPaceTask.SiteAcqComplete,
    }),
  },
  {
    title: 'RE020 OOM Amount',
    key: 'RE020OOMAmount',
    dataIndex: 'OOMInformationData',
    width: 190,
    render: (value: Record<MainPaceTask, OOMInformation>): ReactNode => {
      const currenValue = value[MainPaceTask.SiteAcqComplete]?.OOMAmount;

      return currenValue ? `$ ${priceCommaReplacer(currenValue)}` : currenValue;
    },
  },
  {
    title: 'RE020 OOM Date',
    key: 'RE020OOMDate',
    dataIndex: 'OOMInformationData',
    width: 190,
    render: (value: Record<MainPaceTask, OOMInformation>): ReactNode => {
      const currentValue = value[MainPaceTask.SiteAcqComplete]?.OOMDate;

      return currentValue
        ? readOnlyDatePickerRenderer(currentValue)
        : currentValue;
    },
  },
  {
    title: 'RE020 OOM Total Amount',
    key: 'RE020OOMTotalAmount',
    dataIndex: 'OOMInformationData',
    width: 210,
    render: (value: Record<MainPaceTask, OOMInformation>): ReactNode => {
      const currenValue = value[MainPaceTask.SiteAcqComplete]?.OOMTotalAmount;

      return currenValue ? `$ ${priceCommaReplacer(currenValue)}` : currenValue;
    },
  },
  {
    title: 'RE020 Invoice Number',
    key: 'siteAcqCompleteRE020InvoiceNumber',
    dataIndex: 'projectPaceTaskData',
    width: 190,
    render: projectPaceTaskDataRender({
      field: 'invoiceNumber',
      task: MainPaceTask.SiteAcqComplete,
    }),
  },
  {
    title: 'RE020 Invoice Date',
    key: 'siteAcqCompleteRE020InvoiceDate',
    dataIndex: 'projectPaceTaskData',
    width: 200,
    render: projectPaceTaskDataRender({
      field: 'invoiceDate',
      task: MainPaceTask.SiteAcqComplete,
    }),
  },
  {
    title: 'RE097 PACE Actual Date',
    key: 'RE097',
    dataIndex: 'projectPaceTaskData',
    width: 200,
    render: projectPaceTaskDataRender({
      field: 'PACEActualDate',
      task: MainPaceTask.OOMTruedUp,
    }),
  },
  {
    title: 'RE060 PACE Actual Date',
    key: 'RE060',
    dataIndex: 'projectPaceTaskData',
    width: 200,
    render: projectPaceTaskDataRender({
      field: 'PACEActualDate',
      task: MainPaceTask.FinalSiteAcqAccp,
    }),
  },
  { ...actionButtons({ editFunc }), fixed: 'right' } as ColumnType<Browse>,
];
