import { ProjectCategory } from '@models/interfaces';

export const PROJECT_CATEGORY_SUM_DEFAULT_VALUE: ProjectCategory = {
  id: undefined,
  projectCategory: undefined,
  projectRanking: undefined,
  PACEProjectNumber: undefined,
  oracleProjectNumber: undefined,
  projectAssignedDate: undefined,
  frequency: undefined,
  strategicDriver: undefined,
  isFirstNet: false,
};
