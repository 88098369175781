import { createSelector, OutputSelector } from 'reselect';
import { arrsToSelectableObject } from '../../core/utils/methods';
import {
  JurisdictionDetailsSelectorValues,
  PermittingDetailsSelectorValues,
} from '../../models/interfaces';
import { AppState } from '../reducers';
import { PermittingState } from '../reducers/permitting.reducer';

const permittingState = (state: AppState): PermittingState => state.permitting;

type PermittingDetailsDataKeys =
  | 'project'
  | 'scoping'
  | 'site'
  | 'leasing'
  | 'categories'
  | 'permittingUsers';

export namespace PermittingSelectors {
  export const getJurisdictionList = createSelector(
    [permittingState],
    (state: PermittingState) => state.jurisdictionList,
  );

  export const getJurisdictionListLikeSelectorOptions = createSelector(
    [permittingState],
    (state: PermittingState) =>
      arrsToSelectableObject(state.jurisdictionList, 'id', 'jurisdictionName'),
  );

  export const getPermittingBrowseData = createSelector(
    [permittingState],
    (state: PermittingState) => state.browseData,
  );

  export const getPermittingDetailsData = (
    keys: Array<PermittingDetailsDataKeys>,
  ): OutputSelector<
    [typeof permittingState],
    Partial<PermittingDetailsSelectorValues>,
    (res: PermittingState) => Partial<PermittingDetailsSelectorValues>
  > =>
    createSelector([permittingState], (state: PermittingState) => {
      const {
        categories,
        leasing,
        permittingUsers,
        site,
        scoping,
        ...project
      } = state.detailsData;

      const obj: PermittingDetailsSelectorValues = {
        project,
        categories,
        leasing,
        permittingUsers,
        scoping,
        site,
      };

      return keys.reduce(
        (
          acc: Partial<PermittingDetailsSelectorValues>,
          v: PermittingDetailsDataKeys,
        ) => ({ ...acc, [v]: obj[v] }),
        {} as Partial<PermittingDetailsSelectorValues>,
      );
    });

  export const getPermittingDetailsHeaderData = createSelector(
    [permittingState],
    (state: PermittingState) => state.detailsData.site,
  );

  export const getJurisdictionBrowseData = createSelector(
    [permittingState],
    (state: PermittingState) => state.jurisdictionBrowseData,
  );

  export const getJurisdictionDetailsData = (
    keys: Array<'summary' | 'zoning' | 'permitting' | 'contacts'>,
  ): OutputSelector<
    [typeof permittingState],
    Partial<JurisdictionDetailsSelectorValues>,
    (res: PermittingState) => Partial<JurisdictionDetailsSelectorValues>
  > =>
    createSelector([permittingState], (state: PermittingState) => {
      const { contacts, permitting, zoning, id, ...summary } =
        state.jurisdictionDetailsData;

      const obj: JurisdictionDetailsSelectorValues = {
        summary,
        zoning,
        permitting,
        contacts,
      };

      return keys.reduce(
        (
          acc: Partial<JurisdictionDetailsSelectorValues>,
          v: 'summary' | 'zoning' | 'permitting' | 'contacts',
        ) => ({ ...acc, [v]: obj[v] }),
        {} as Partial<JurisdictionDetailsSelectorValues>,
      );
    });

  export const getProjectType = createSelector(
    [permittingState],
    (state: PermittingState) => state.detailsData.projectType.value,
  );
}
