import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { UserRole } from 'providence-types';
import { UserSelectors } from '@store/selectors';
import {
  AccountingNotificationsTable,
  DirectMessageTable,
  RequiresAttentionTable,
  ToDoTable,
} from '../components';
import { DashboardProps } from '../models';

export const Dashboard: FC<DashboardProps> = ({
  type,
  history,
}: DashboardProps) => {
  const isAdmin = useSelector(UserSelectors.hasUserRoles(UserRole.Admin));
  const isAccounting = useSelector(
    UserSelectors.hasUserRoles(UserRole.Accounting),
  );

  const isAssignedTab = type === 'assigned';
  const showAccountingTable =
    (isAssignedTab && isAccounting && !isAdmin) || type === 'all';

  return (
    <>
      {showAccountingTable && (
        <AccountingNotificationsTable history={history} />
      )}
      {isAssignedTab && <DirectMessageTable history={history} />}
      <RequiresAttentionTable type={type} history={history} />
      <ToDoTable type={type} history={history} />
    </>
  );
};
