import React, { FC, PropsWithChildren, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Form } from 'antd';
import { HttpService } from '@core/services';
import { SiteHttpService } from '@core/services/http';
import {
  arrsToSelectableObject,
  getFormData,
  isValidForm,
} from '@core/utils/methods';
import { ModalMainTypes } from '@models/enums';
import {
  OptionProps,
  RequestContactsModalData,
  StateWithCounties,
} from '@models/interfaces';
import { AddAndEditModal } from '@shared/components';
import {
  Autocomplete,
  Input,
  InputNumber,
  PhoneNumber,
  TextArea,
} from '@shared/modules';
import { SharedSelectors, SiteSelectors } from '@store/selectors';
import { ContactModalProps } from './models/interfaces';

const { useForm } = Form;

export const SiteContactModal: FC<ContactModalProps> = ({
  formValue,
  visible,
  modalType,
  toggleModal,
}: PropsWithChildren<ContactModalProps>) => {
  const [form] = useForm();

  const siteService = useMemo(
    () => HttpService.getHttpRequests(SiteHttpService),
    [],
  );

  const { dropdownsOptions, states } = useSelector(
    SharedSelectors.getDropdownOptionsAndStates,
  );
  const currentSite = useSelector(SiteSelectors.getCurrentSite);

  const getFormDataFunc = (): RequestContactsModalData => {
    const values = form.getFieldsValue();

    return getFormData(formValue, form, {
      contactType: values.contactType
        ? {
            value: dropdownsOptions.ContactType.find(
              (option: OptionProps) => option.value === values.contactType,
            )?.viewValue,
            id: values.contactType,
          }
        : undefined,
      projectType: values.projectType
        ? {
            value: dropdownsOptions.ProjectType.find(
              (option: OptionProps) => option.value === values.projectType,
            )?.viewValue,
            id: values.projectType,
          }
        : undefined,
      state: values.state
        ? states?.find((state: StateWithCounties) => state.id === values.state)
        : undefined,
    }) as RequestContactsModalData;
  };

  const add = async ({
    id,
    ...values
  }: RequestContactsModalData): Promise<void> => {
    if (await isValidForm(form)) {
      try {
        await siteService.createSiteContact(currentSite.id, values);
        toggleModal(modalType);
        form.resetFields();
      } catch (e) {
        console.error(e);
      }
    }
  };

  const edit = async (values: RequestContactsModalData): Promise<void> => {
    if (await isValidForm(form)) {
      try {
        await siteService.updateSiteContact(values);
        toggleModal(modalType);
        form.resetFields();
      } catch (e) {
        console.error(e);
      }
    }
  };

  useEffect(() => {
    if (visible) {
      form.setFieldsValue(formValue);
    }
  }, [visible]);

  return (
    <AddAndEditModal
      onOk={
        modalType === ModalMainTypes.Add
          ? (): Promise<void> => add(getFormDataFunc())
          : (): Promise<void> => edit(getFormDataFunc())
      }
      onCancel={(): void => {
        toggleModal(modalType);
        form.resetFields();
      }}
      visible={visible}
      type={modalType}
      className="prov-contacts__modal"
      okText={`${modalType === ModalMainTypes.Add ? 'Add' : 'Edit'} Contact`}
      cancelText="Cancel"
      title="Contact"
      formProps={{
        labelCol: { span: 7 },
        wrapperCol: { span: 15 },
        form,
      }}
    >
      <Autocomplete
        id="contactType"
        label="Contact Type"
        options={dropdownsOptions?.ContactType || []}
        formItemProps={{
          rules: [
            {
              required: true,
              message: 'Contact Type is required!',
            },
          ],
        }}
      />
      <Input
        id="contactName"
        label="Contact Name"
        formItemProps={{
          rules: [
            {
              required: true,
              message: 'Contact Name is required!',
            },
          ],
        }}
      />
      <Input id="contactAddress" label="Contact Address" />
      <Input id="city" label="City" />
      <Autocomplete
        id="state"
        label="State"
        options={arrsToSelectableObject<StateWithCounties>(
          states,
          'id',
          'name',
        )}
      />
      <InputNumber id="zip" label="Zip" />
      <PhoneNumber id="phone" label="Phone" />
      <PhoneNumber id="fax" label="Fax" />
      <Input id="email" label="Email" />
      <TextArea id="notes" label="Contact Notes" />
    </AddAndEditModal>
  );
};
