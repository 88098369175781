import { DashboardProjectData, ProjectCategory } from '@models/interfaces';

export const projectCategoriesToStringArr = (
  categories: DashboardProjectData['categories'],
): string[] =>
  categories.reduce(
    (
      acc: string[],
      {
        PACEProjectNumber,
        oracleProjectNumber,
      }: Required<
        Pick<ProjectCategory, 'PACEProjectNumber' | 'oracleProjectNumber'>
      >,
    ) => [...acc, PACEProjectNumber, oracleProjectNumber],
    [],
  );
