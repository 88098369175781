import { RulePresetValue } from 'providence-types';

export const getCurrentRuleValue = (value: RulePresetValue): string => {
  switch (value) {
    case RulePresetValue.NotNull:
      return 'Not Null';
    case RulePresetValue.Null:
      return 'Null';
    default:
      throw new Error('Incorrect value');
  }
};
