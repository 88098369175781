import React, {
  FC,
  memo,
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'antd';
import { Store } from 'rc-field-form/es/interface';
import { ObjectComparatorService } from '@core/services';
import { useDidUpdateEffect } from '@core/utils/hooks';
import { momentizeObjectDates } from '@core/utils/methods';
import { ProjectPaceTask } from '@models/interfaces';
import { ProjectActions } from '@store/actions';
import { PaceTasksSelectors } from '@store/selectors';
import { CustomTable } from '../../components/custom-table';
import { TableContainer } from '../../components/table-container';
import {
  getPaceTaskColumns,
  paceTaskMapper,
  PaceTaskTableComponentProps,
  PaceTaskTableProps,
} from './models';

const PaceTaskTableComponent: FC<PaceTaskTableComponentProps> = memo(
  ({
    form,
    data,
    onChange,
    isEditing,
    hoverable,
  }: PropsWithChildren<PaceTaskTableComponentProps>) => (
    <TableContainer header="PACE Tasks">
      <Form form={form} className="summary-table" onValuesChange={onChange}>
        <CustomTable
          columns={getPaceTaskColumns(isEditing)}
          dataSource={data}
          pagination={false}
          hoverable={hoverable}
        />
      </Form>
    </TableContainer>
  ),
);

export const PaceTaskTable: FC<PaceTaskTableProps> = ({
  isEditing,
  data,
  form,
  hoverable,
}: PropsWithChildren<PaceTaskTableProps>) => {
  const dispatch = useDispatch();

  const arePaceTasksChanged = useSelector(
    PaceTasksSelectors.arePaseTasksChanged,
  );

  const [paceTasksCompare, setPaceTasksCompare] = useState<boolean>(true);

  useEffect(() => {
    form.setFieldsValue(paceTaskMapper(data));
  }, [data]);

  useDidUpdateEffect(() => {
    dispatch(ProjectActions.setArePACETasksChanged.done(!paceTasksCompare));
  }, [paceTasksCompare]);

  useDidUpdateEffect(() => {
    if (!arePaceTasksChanged) {
      setPaceTasksCompare(true);
    }
  }, [arePaceTasksChanged]);

  const onChange = useCallback(
    (changedValues: Store, values: Store): void => {
      setPaceTasksCompare(
        ObjectComparatorService.arraysCompare(
          data,
          Object.values(values).map((paceTask: ProjectPaceTask) => ({
            ...paceTask,
            projectPaceTaskData: momentizeObjectDates(
              paceTask.projectPaceTaskData,
              [
                'PACEActualDate',
                'PACEForecastedDate',
                'actualDate',
                'forecastedDate',
              ],
              true,
              true,
            ),
          })),
        ),
      );
    },
    [data],
  );

  return (
    <PaceTaskTableComponent
      data={data}
      isEditing={isEditing}
      hoverable={hoverable}
      onChange={onChange}
      form={form}
    />
  );
};
