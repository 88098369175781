import React, {
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { HttpService } from '@core/services';
import { ProjectHttpService, SiteHttpService } from '@core/services/http';
import { getIdParamFromUrl } from '@core/utils/methods';
import { Details } from '@shared/modules/details';
import { SiteSelectors } from '@store/selectors';

export const SiteDetails: FC<RouteComponentProps> = (
  props: PropsWithChildren<RouteComponentProps>,
) => {
  const {
    match: { params },
  } = props;

  const site = useSelector(SiteSelectors.getCurrentSite);

  const projectService = useMemo(
    () => HttpService.getHttpRequests(ProjectHttpService),
    [],
  );

  const [id] = getIdParamFromUrl();

  const isParamMatchLocation = useCallback(
    (locationIdParam: string): boolean =>
      Object.keys(params).includes(locationIdParam),
    [params],
  );

  const isParamMatchProjectOrPM =
    isParamMatchLocation('projectId') || isParamMatchLocation('pmId');

  useEffect(() => {
    if (isParamMatchLocation('siteId')) {
      HttpService.getHttpRequests(SiteHttpService).getSite(id);
    }

    if (isParamMatchProjectOrPM) {
      projectService.getProject(id);
      projectService.getSiteByProjectId(id);
    }
  }, [id, isParamMatchLocation, isParamMatchProjectOrPM]);

  return <Details category={isParamMatchProjectOrPM} {...props} site={site} />;
};
