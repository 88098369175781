import {
  EngineeringRegulatoryCompliance,
  EngineeringRFDSPhase1,
} from '@models/interfaces';

export const RFDS_INFO_DATES: Array<keyof EngineeringRFDSPhase1> = [
  'prelimRFDSReceived',
  'revisedRFDSReceived',
  'prelimRFDSPromoted',
  'finalRFDSReceived',
  'finalRFDSPromotedToCX',
  'dateOfSuccessfulPromotion',
  'CI004',
  'CSSDataComplete',
  'phase1Ordered',
  'phase1Complete',
  'phase1Uploaded',
];

export const REGULATORY_COMPLIANCE_INFO_DATES: Array<
  keyof EngineeringRegulatoryCompliance
> = [
  'SOWSubmit',
  'SOWRejected',
  'CNTPReceived',
  'FAAFileDate',
  'escalateDate',
  'determinationDate',
  'EHSSubmitted',
  'EHSApproved',
  'RFSSROrderedDate',
  'RFSSRReceivedDate',
  'PALOrNPAReceived',
];
