import React, { FC, PropsWithChildren, ReactNode } from 'react';
import { Col, Row } from 'antd';
import { FormInstance } from 'antd/lib/form';
import moment from 'moment';
import { DatePicker, FormItem } from '@shared/modules';
import { changeValue, validator } from './models/constants';
import { PrivateDatePickerProps, RangePickerProps } from './models/interfaces';

import './styles.scss';

const PrivateDatePicker: FC<PrivateDatePickerProps> = ({
  id,
  type,
  value,
  formItemProps,
  elementProps,
  onChange,
  disabled,
  required = false,
  ...props
}: PropsWithChildren<PrivateDatePickerProps>) => (
  <Col span="11">
    <DatePicker
      id={type}
      formItemProps={{
        ...formItemProps,
        name: [
          ...(Array.isArray(formItemProps?.name) ? formItemProps!.name : [id]),
          type,
        ],
        rules: [
          ...(formItemProps?.rules || []),
          validator(type, required, value),
        ],
      }}
      elementProps={{
        ...elementProps,
        placeholder: `${type === 'from' ? 'Start' : 'End'} Date`,
        onChange: changeValue(type, onChange, value),
        value: value?.[type],
        disabled,
      }}
      {...props}
    />
  </Col>
);

const PrivateRangePicker: FC<Omit<RangePickerProps, 'label'>> = ({
  startDateProps,
  endDateProps,
  ...props
}: PropsWithChildren<Omit<RangePickerProps, 'label'>>) => (
  <Row className="prov-range-picker">
    <PrivateDatePicker type="from" {...{ ...props, ...startDateProps }} />
    <PrivateDatePicker type="to" {...{ ...props, ...endDateProps }} />
  </Row>
);

export const RangePicker: FC<RangePickerProps> = ({
  id,
  label,
  startDateProps,
  endDateProps,
  formItemProps: { rules, name, ...formItemProps } = {},
  required = false,
  ...props
}: PropsWithChildren<RangePickerProps>) => (
  <FormItem
    id={id}
    label={label}
    editingElement={
      <PrivateRangePicker
        id={id}
        startDateProps={{
          ...startDateProps,
          formItemProps: {
            name,
            ...startDateProps?.formItemProps,
            rules: [
              ...(startDateProps?.formItemProps?.rules || []),
              ...(rules || []),
            ],
          },
        }}
        required={required}
        endDateProps={{
          ...endDateProps,
          formItemProps: {
            name,
            ...endDateProps?.formItemProps,
            rules: [
              ...(endDateProps?.formItemProps?.rules || []),
              ...(rules || []),
            ],
          },
        }}
        {...props}
      />
    }
    readOnlyElement={(formInstance: FormInstance): ReactNode => {
      const startDate = formInstance.getFieldValue([
        ...(Array.isArray(name) ? name : [id]),
        'from',
      ]);
      const endDate = formInstance.getFieldValue([
        ...(Array.isArray(name) ? name : [id]),
        'to',
      ]);

      return (
        startDate &&
        endDate &&
        `${moment(startDate).format(startDateProps?.format)} - ${moment(
          endDate,
        ).format(endDateProps?.format)}`
      );
    }}
    formItemProps={{ ...formItemProps, name }}
    {...props}
  />
);
