import { Guard } from '@wellyes/react-router-extended';
import {
  SubPage,
  Tab,
  TabPermissionsType,
  Undefinable,
} from 'providence-types';
import { UserPermissionKeys } from '@models/types';
import store from '../../store';

export class TabAccessGuard implements Guard {
  private readonly _area: UserPermissionKeys;
  private readonly _subPage: SubPage;
  private readonly _tab: Tab;
  private readonly _defaultValue: boolean;

  constructor(
    area: UserPermissionKeys,
    subPage: SubPage,
    tab: Tab,
    defaultValue: boolean = false,
  ) {
    this._area = area;
    this._subPage = subPage;
    this._tab = tab;
    this._defaultValue = defaultValue;
  }

  private get _userTabPermissions(): Undefinable<TabPermissionsType> {
    return store.getState().user.activeUser?.permissions?.[this._area]?.tabs;
  }

  canActivate(): Promise<boolean> | boolean {
    const result =
      this._userTabPermissions?.[this._subPage]?.[this._tab]?.haveAccess;

    return result !== undefined ? result : this._defaultValue;
  }
}
